import React, { Fragment } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { ContainerLabel, CountrySelectorStyled } from './style';

interface ICountrySelector extends PhoneInputProps {
  label?: string;
  overWrite?: string;
}

const CountrySelector = ({ label, overWrite, ...rest }: ICountrySelector) => (
  <>
    {label && (
      <ContainerLabel>
        <p>{label}</p>
      </ContainerLabel>
    )}

    {overWrite && (
      <div className={overWrite}>
        <CountrySelectorStyled {...rest} />
      </div>
    )}

    {!overWrite && <CountrySelectorStyled {...rest} />}
  </>
);

CountrySelector.defaultProps = {
  label: undefined,
  overWrite: undefined,
};

export default CountrySelector;
