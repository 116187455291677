import React from 'react';
import { Tag } from './style';

interface Props {
  type: string;
  text: string;
}

const Badge = ({ type, text }:Props) => (
  <Tag type={type}>{text}</Tag>
);

export default Badge;
