import OnboardingCard from './OnboardingCards';
import OnboardingPhases from './OnboardingPhases';

import {
  Container, Content, PhasesCard,
} from './styles';

const OnboardingPage: React.FC = () => (
  <Container>
    <Content>
      <OnboardingCard />
    </Content>
    <PhasesCard>
      <OnboardingPhases />
    </PhasesCard>
  </Container>
);

export default OnboardingPage;
