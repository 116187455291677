import { OwnerExtractProps } from './types';

export const initialValuesOwnerExtract: OwnerExtractProps = {
  incomes: {
    total: 'R$ 1500,00',
    reservations: [
      {
        id: 1,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 2,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 3,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 4,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 5,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 6,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 7,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 8,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Airbnb',
      },
      {
        id: 9,
        check_in_date: '29/08/2022',
        check_out_date: '30/08/2022',
        dailys_value: 'R$ 500,00',
        platform: 'Booking',
      },
    ],
  },
  incomes_future: {
    total: 'R$ 0,00',
    reservations: [{
      id: 1,
      check_in_date: '29/08/2022',
      check_out_date: '30/08/2022',
      dailys_value: 'R$ 1500,00',
      platform: 'Booking',
    },
    ],
  },
  others_incomes: {
    total: 'R$ 150,00',
    manual_fits: [{
      id: 1,
      description: 'Ressarcimento',
      value: 'R$ 150,00',
    }],
  },
  expenses: {
    total: 'R$ 1000,00',
    reservations_cleanings: [{
      id: 1,
      check_in_date: '29/08/2022',
      check_out_date: '30/08/2022',
      dailys_value: 'R$ 500,00',
      platform: '',
    },
    {
      id: 2,
      check_in_date: '29/08/2022',
      check_out_date: '30/08/2022',
      dailys_value: 'R$ 500,00',
      platform: '',
    }],
    posted_expenses: [{
      id: 1,
      code: '',
      register_date: '',
      expense_date: '',
      category: 'Lâmpada',
      description: '',
      value: 'R$ 500,00',
      owner_approved: false,
      status: '',
      paid_by: '',
    },
    {
      id: 2,
      code: '',
      register_date: '',
      expense_date: '',
      category: 'Descarga',
      description: '',
      value: 'R$ 500,00',
      owner_approved: false,
      status: '',
      paid_by: '',
    }],
  },
  commission: {
    total: 'R$ 886,40',
    seazone: 'R$ 886,40',
  },
  implantation_fee: 'R$ 0,00',
  others_expenses: {
    total: 'R$ 100,00',
    manual_fits: [{
      id: 1,
      description: 'Ressarcimento',
      value: 'R$ 100,00',
    }],
  },
  wallet: {
    debit_balance: 'R$ 0,00',
    transfer: 'R$ 3364,61',
  },
};
