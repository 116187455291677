import React from 'react';

import Grid from './Grid';
import Header from './Header';
import TedListFab from './Header/Fab/TedListFab';

import {
  useMobile,
} from '../../hooks/useMobile/useMobile';

import {
  Container,
} from './styles';

const HostTedList = () => {
  const { isMobile } = useMobile();

  if (isMobile) {
    return (
      <Container>
        <TedListFab />
        <Grid />
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Grid />
    </Container>
  );
};

export { HostTedList };
