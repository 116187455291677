/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Checkbox } from '@mui/material';
import Lottie from 'lottie-react';
import { useOwnerTedList } from '../../../../hooks/OwnerTedListHook/useOwnerTedList';

import animationSource from '../../../../assets/animations/source/tedListConfirmation.json';

import {
  Title,
  Backdrop,
  Container,
  VoucherItem,
  ButtonsContainer,
  AddVoucherButton,
  CheckBoxContainer,
  VoucherListContainer,
  NoDataMessage,
  Header,
  AnimationContainer,
  BackdropAnimation,
} from './styles';

import Tooltip from '../../../Tooltip';
import FormButton from '../../../FormButton';
import UploadFile from '../../../PaymentVoucher/Cards/Modal/AddVoucherScreen/UploadFile/UploadFile';

import {
  FileProps,
  FileReference,
  FileAWSFormat,
  FileReferenceResponse,
} from '../../../../context/FileContext/types';

import { useToast } from '../../../../context/ToastContext';
import { useFile } from '../../../../hooks/FileHook/useFile';
import { initialValuesFile } from '../../../../context/FileContext/FileContext';
import { sendTedVouchers } from '../../../../services/OwnerTedList/request';
import ModalConfirmDeleteVoucher from './ModalConfirmDeleteVoucher';

interface IVoucherList {
  id: number;
  name: string;
}

const Modal = () => {
  const toast = useToast();

  const {
    openModalID,
    handleOpenModalID,
    handleOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    handleDeleteVoucherIsConfirmed,
    modalImage,
    handleListItems,
    listItems,
  } = useOwnerTedList();

  const { uploadFileToS3, createFileReference } = useFile();

  const newItem = listItems.find((i) => i.id === openModalID);

  const initialFileArray = newItem?.statmentImage !== null ? [newItem?.statmentImage] : [];

  const [noVoucher, setNoVoucher] = React.useState<boolean>(false);
  const [confirmationScreen, setConfirmationScreen] = React.useState<boolean>(false);
  const [voucherList, setVoucherList] = React.useState<IVoucherList[]>([]);
  const [sendingFileArray, setSendingFileArray] = useState<any[]>(initialFileArray);
  const [fileArray, setFileArray] = useState<FileProps[]>(initialFileArray);
  const [voucherFile, setVoucherFile] = useState<FileProps>(initialValuesFile);

  const handleDeleteVoucher = (id: number) => {
    const newVoucherList = voucherList.filter((voucher) => voucher.id !== id);
    setVoucherList(newVoucherList);
  };

  const limitVoucherNameCharacters = (name: string) => {
    if (name.length > 25) {
      return `${name.substring(0, 20)}...`;
    }
    return name;
  };

  const validateConfirmation = () => {
    if (noVoucher) {
      return true;
    }
    if (fileArray?.length > 0) {
      return true;
    }
    return false;
  };

  const validate = validateConfirmation();

  const handleUploadFile = async (fileItem: FileProps) => {
    let fileResponse: any = { uid: null };
    try {
      const fileReference: FileReference = {
        name: fileItem.name,
        category: 'voucher-file',
        content_type: fileItem.MIMEtype,
      };
      const responseFile: FileReferenceResponse = await createFileReference(fileReference);
      const params: FileAWSFormat = {
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        key: responseFile.storage.fields.key,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        file: fileItem.file,
        fileId: responseFile.uid,
      };
      await uploadFileToS3(fileItem, params);
      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
      // setHasSendedFile(true);
    } catch (e: unknown) {
      if (e instanceof Error) {
        // setLoad(false);
      }
    }
    return fileResponse;
  };

  async function sendFileDataToS3() {
    try {
      // handleChangeLoading(true);
      if (fileArray?.length > 0) {
        fileArray.forEach(async (item) => {
          const response = await handleUploadFile(item);

          setSendingFileArray([...sendingFileArray, response]);
        });
      }
      // handleChangeLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Não foi possível fazer o upload da imagem');
      }
      // handleChangeLoading(false);
    }
  }

  const handleRequestDeleteFile = () => {
    handleOpenModalConfirmDeleteVoucher(true);
  };

  const sendNullToRemoveFile = async () => {
    try {
      const params = {
        tedID: openModalID,
        fileUID: null,
      };

      await sendTedVouchers(params);

      const newItems = listItems.map((item) => {
        if (item.id === openModalID) {
          return {
            ...item,
            statmentImage: null,
          };
        }
        return item;
      });

      handleListItems(newItems);
      handleOpenModalConfirmDeleteVoucher(false);
      handleDeleteVoucherIsConfirmed(false);

      if (!noVoucher) {
        toast.success('Comprovante removido com sucesso!');
      }
    } catch (e) {
      toast.error('Não foi possível remover o comprovante!');
      handleOpenModalConfirmDeleteVoucher(false);
      handleDeleteVoucherIsConfirmed(false);
    }
  };

  useEffect(() => {
    if (deleteVoucherIsConfirmed) {
      sendNullToRemoveFile();
    }
  }, [deleteVoucherIsConfirmed]);

  const handleSendVouchers = async () => {
    if (sendingFileArray && !noVoucher) {
      try {
        const params = {
          tedID: openModalID,
          fileUID: sendingFileArray[0].uid,
        };

        await sendTedVouchers(params);

        const newItems = listItems.map((item) => {
          if (item.id === openModalID) {
            return {
              ...item,
              statmentImage: fileArray[0],
            };
          }
          return item;
        });
        handleListItems(newItems);
        setConfirmationScreen(true);
      } catch (e) {
        toast.error('Não foi possível enviar os comprovantes');
      }
    } else {
      await sendNullToRemoveFile();
      handleOpenModalID(0);
    }
  };

  // returns
  const VoucherListHaveData = () => (
    <>
      {voucherList.map((voucher) => (
        <VoucherItem key={voucher.id}>
          <h1>
            <Tooltip text={voucher.name}>
              <>{limitVoucherNameCharacters(voucher.name)}</>
            </Tooltip>
          </h1>
          <button type="button" onClick={() => handleDeleteVoucher(voucher.id)}>
            Excuir
          </button>
        </VoucherItem>
      ))}
    </>
  );

  return (
    <>
      <Container>
        <Header>
          <Title>Anexe os comprovantes do TED</Title>

          {/* <AddVoucherButton>
            <p>+</p>
            <h1>Adicionar comprovante</h1>
          </AddVoucherButton> */}

          <UploadFile
            disabled={confirmationScreen || fileArray?.length > 0}
            sendFileDataToS3={sendFileDataToS3}
            setSendingFileArray={setSendingFileArray}
            fileArray={fileArray}
            setFileArray={setFileArray}
            voucherFile={voucherFile}
            setVoucherFile={setVoucherFile}
            setHasSendedFile={() => {}}
            handleRequestDeleteFile={handleRequestDeleteFile}
            requestDeleteIsConfirmed={deleteVoucherIsConfirmed}
          />
        </Header>

        <VoucherListContainer>
          {fileArray?.length === 0 && (
            <NoDataMessage>
              Não há nenhum comprovante adicionado.
              <span>
                Adicione um comprovante ou marque a opção para enviar sem
                comprovantes
              </span>
            </NoDataMessage>
          )}

          <CheckBoxContainer onClick={() => {
            if (confirmationScreen || fileArray?.length > 0) {
              return null;
            }
            return setNoVoucher(!noVoucher);
          }}
          >
            <Checkbox disabled={confirmationScreen || fileArray?.length > 0} checked={noVoucher} />
            <p>Nao possui comprovantes</p>
          </CheckBoxContainer>
        </VoucherListContainer>

        <ButtonsContainer>
          {confirmationScreen ? (
            <AnimationContainer>
              <h1>Comprovante Anexado</h1>
              <div>
                <Lottie
                  onComplete={() => {
                    handleOpenModalID(0);
                  }}
                  loop={false}
                  animationData={animationSource}
                />
              </div>
            </AnimationContainer>
          ) : (
            <>
              <FormButton
                variant="outlined"
                onClick={() => handleOpenModalID(0)}
              >
                Cancelar
              </FormButton>
              <FormButton
                disable={!validate}
                onClick={() => handleSendVouchers()}
              >
                Confirmar
              </FormButton>
            </>
          )}
        </ButtonsContainer>
      </Container>
      <Backdrop onClick={() => handleOpenModalID(0)} />
    </>
  );
};

const OpenModal = () => {
  const { openModalID } = useOwnerTedList();

  if (openModalID > 0) {
    return <Modal />;
  }

  return <></>;
};

export default OpenModal;
