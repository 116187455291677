import { IType } from '../../paths';

export type IIndicationsStatus = 'Won' | 'In_Progress' | 'Lost' | 'Canceled';
export type ITranslatedIndicationsStatus = 'Aprovado' | 'Em andamento' | 'Perda' | 'Cancelado';

export enum IIndicationStatusEnum {
  won = 'Won',
  progress = 'In_Progress',
  lost = 'Lost',
  canceled = 'Canceled',
}

export interface IConversion {
  progress: number;
  isMaxBar: boolean;
}

export interface IIndications {
  allotments: IAllotmentIndication[];
  propretys: IPropertyIndication[];
  spots: IInvestmentIndication[];
}

export interface IIndicationsBase {
  id: number;
  type?: IType;
  comment: string;
  partner_id: number;
  created_at: Date;
  updated_at: Date;
  owner_name: string;
  owner_phone_number: string;
  status: IIndicationsStatus;
  status_change_date: Date;
}

export interface IAllotmentIndication extends IIndicationsBase {
  allotment_city: string;
  allotment_state: string;
  allotment_street: string;
  allotment_neighborhood: string;
  allotment_address_number: string;
  allotment_dimension: string;
  allotment_area: string;
  allotment_value: number;
  geographical_coordinate: string;
  document_link: string;
  photo_link: string;
  allow_barter: boolean;
}

export interface IPropertyIndication extends IIndicationsBase {
  property_city: string;
  property_state: string;
  property_type: string;
  property_street: string;
  property_number: string;
  property_complement: string;
  property_neighborhood: string;
  condominium_name?: number;
  property_is_in_coverage_area: boolean;
  property_under_construction: boolean;
  property_has_furniture: boolean;
  owner_is_aware_of_indication: boolean;
  owner_received_the_ebook: boolean;
  lost_reason?: string;
  pipedrive_stage?: string;
}

export interface IInvestmentIndication {
  id?: string;
  partner_id: number;
  enterprise_id: string;
  enterprise_unit_id: string;
  investor_name: string;
  investor_email: string;
  investor_phone: string;
  comment: string;
  lost_reason?: string;
  pipedrive_stage?: string;
  created_at?: Date;
  updated_at?: Date;
  type?: IType;
  status?: IIndicationsStatus;
}
