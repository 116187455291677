import request from '../../request';
import { RequestAmenities } from './types';

export const getAmenities = async (propertyId: number): Promise<RequestAmenities[]> => {
  const { data } = await request.get('/property/amenities/', {
    params: {
      property: propertyId,
    },
  });
  return data as RequestAmenities[];
};
