/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import SimpleSelect from '../../SimpleSelect';
import TextField from '../../TextField/TextField';
import {
  ButtonDiscard,
  ButtonSave,
  ColInput,
  Container,
  ContentClose,
} from './styles';
import deleteIcon from '../../../assets/icons/generals/bashRed.svg';
import {
  patchOwnerContact,
  postOwnerContact,
} from '../../../services/Owner/request';
import { OwnerContact } from '../../../services/Owner/types';
import { phone } from '../../../utils/InputMask/Number';
import ModalConfirmDeleteContact from '../Modal/ModalConfirmDeleteContact';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

interface Props {
  contact: OwnerContact;
  getListOwnerContact: () => void;
}

const CardPhone = ({ contact, getListOwnerContact }: Props): JSX.Element => {
  const [openModalDeleteContact, setOpenModalDeleteContact] = useState<boolean>(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [resetForm, setResetForm] = useState<boolean>(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { isViewMode } = useViewMode();

  const initialValues = {
    name: contact ? contact.contact_name : '',
    phone: contact ? contact.phone_number : '',
    email: contact ? contact.email : '',
    contact_subject: contact ? contact.contact_subject : '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        if (contact.id) {
          await patchOwnerContact({
            contact_name: values.name || name,
            contact_subject: values.contact_subject || contactSubject,
            phone_number: values.phone || phoneNumber,
            email: values.email || email,
          },
          contact.id);
          setShowSaveButton(false);
          toast.success('Contato atualizado com sucesso!');
        } else {
          await postOwnerContact({
            contact_name: values.name || name,
            contact_subject: values.contact_subject || contactSubject,
            phone_number: values.phone || phoneNumber,
            email: values.email || email,
          });
          setShowSaveButton(false);
          toast.success('Contato salvo com sucesso!');
        }
        getListOwnerContact();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  function handleCancel() {
    setResetForm(!resetForm);
    setShowSaveButton(false);
  }

  function handleSetValue(value: string, field: string) {
    setShowSaveButton(true);
    switch (field) {
      case 'name':
        setName(value);
        break;
      case 'phone':
        setPhoneNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        setContactSubject(value);
    }
  }

  useEffect(() => {
    setName(contact.contact_name);
    setEmail(contact.email);
    setPhoneNumber(contact.phone_number);
    setContactSubject(contact.contact_subject);
  }, [resetForm]);

  return (
    <div data-cy={`container-phone-${contact.id || 'new'}`}>
      <Container data-cy={'list-phones'} onSubmit={formik.handleSubmit}>
        <ContentClose>
          <Close onClick={() => setOpenModalDeleteContact(true)} />
        </ContentClose>
        <div className="contentPhone">
          <ColInput>
            <TextField
              label="Nome do contato"
              id="name"
              placeholder="Digite aqui.."
              formik={formik}
              value={name}
              onChange={(nameText) => handleSetValue(nameText.target.value, 'name')}
              dataCy="name-field"
            />
          </ColInput>
          <ColInput>
            <TextField
              label="Telefone"
              id="phone"
              placeholder="Digite aqui.."
              formik={formik}
              value={phone(phoneNumber)}
              mask="phone"
              onChange={(phoneText) => handleSetValue(phoneText.target.value, 'phone')}
              dataCy="phone-field"
            />
          </ColInput>
          <ColInput>
            <TextField
              label="E-mail"
              id="email"
              placeholder="Digite aqui.."
              formik={formik}
              value={email}
              onChange={(emailText) => handleSetValue(emailText.target.value, 'email')}
              dataCy="email-field"
            />
          </ColInput>
          <ColInput>
            <SimpleSelect
              id="contact_subject"
              placeholder="Selecione"
              label="Assunto do contato"
              options={[
                {
                  value: '01',
                  valueLabel: 'Sapron',
                },
                {
                  value: '02',
                  valueLabel: 'Teste',
                },
              ]}
              formik={formik}
              onChange={(contactText) => handleSetValue(contactText.target.value as string,
                'contactSubject')}
              firstValue={contactSubject}
              dataCy="contact-subject"
            />
          </ColInput>
          {!isViewMode && (
            <ColInput className="delete">
              {!showSaveButton && (
                <img
                  src={deleteIcon}
                  alt=""
                  onKeyDown={() => setOpenModalDeleteContact(true)}
                  onClick={() => setOpenModalDeleteContact(true)}
                />
              )}
              {showSaveButton && (
                <>
                  <ButtonDiscard type="button" onClick={handleCancel}>
                    Cancelar
                  </ButtonDiscard>
                  <ButtonSave data-cy="btn-save-contact" type="submit">
                    Salvar
                  </ButtonSave>
                </>
              )}
            </ColInput>
          )}
        </div>
      </Container>
      <ModalConfirmDeleteContact
        idContact={contact.id || 0}
        openModal={openModalDeleteContact}
        setOpenModal={setOpenModalDeleteContact}
        updateListContact={getListOwnerContact}
      />
    </div>
  );
};

export default CardPhone;
