import styled from 'styled-components';

type Props = {
  isCustomStyle: boolean;
};

export const Container = styled.div<Props>`
  width: 100vw;
  height: 100vh;
  padding-left: 120px;

  @media (max-width: 950px) {
    padding-left: 0
  }

  @media (max-width: 900px) {
    margin-top: 55px;
    height: calc(100vh - 155px);
  }

  @media (max-width: 800px) {
    margin-top: 0;
    height: calc(100vh - 155px);
  }

  @media (max-width: 900px) {
    height: calc(100vh - 155px);
  }
`;
