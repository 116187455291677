/* eslint-disable no-await-in-loop */
import moment from 'moment';

import { ViewAgenda as ViewAgendaIcon } from '@mui/icons-material';
import { Button, Fab } from '@mui/material';

import { useToast } from '../../../../../context/ToastContext';
import { useOwnerTedList } from '../../../../../hooks/OwnerTedListHook/useOwnerTedList';
import { addZeroLeft } from '../../../../../utils/Formatter';

import {
  getOwnerTeds,
  syncOwnerTeds,
  verifyTaskStatus,
} from '../../../../../services/OwnerTedList/request';

import { Container } from './styles';
import { sleep } from '../../../../../utils/Sleep';
import { UseFormik } from '../../../../../utils/Formik/types';

interface IGenerateOrLoadListButton {
  children: string;
  formik: UseFormik<any>;
  fab?: boolean;
}

const GenerateOrLoadListButton = ({
  children,
  formik,
  fab,
}: IGenerateOrLoadListButton) => {
  const toast = useToast();
  const { handleListItems, handleLoading, loading } = useOwnerTedList();

  const handleGetTeds = async () => {
    const formattedPeriod = moment(formik.values.period).format('YYYY-MM');

    const params = {
      period: formattedPeriod,
      termToFilter: formik.values.search,
    };

    await getOwnerTeds(params)
      .then((res) => {
        handleListItems(res.formatDataToListItem);

        if (res.formatDataToListItem.length === 0) {
          toast.alert('Nenhum dado foi encontrado');
        }

        handleLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao buscar os TEDs');
        handleLoading(false);
      });
  };

  const pollingVerifyTaskStatus = async (taskID: string) => {
    const tries = 50;
    const conditionalTries = tries - 1;
    let counter = 0;

    while (counter !== tries) {
      await sleep(2000);
      const { status } = await verifyTaskStatus(taskID);

      if (status === 'SUCCESS') {
        await handleGetTeds();
        break;
      }

      if (counter === conditionalTries) {
        toast.error('Erro ao gerar o arquivo, tente novamente mais tarde');
        handleLoading(false);
        break;
      }

      counter += 1;
    }
  };

  const genTaskID = async () => {
    const monthWithZeroLeft = addZeroLeft(`${formik.values.period.getMonth() + 1}`);
    const formattedPeriod = `${formik.values.period.getFullYear()}-${monthWithZeroLeft}`;
    const params = {
      period: formattedPeriod,
    };
    try {
      const { task_id: taskID } = await syncOwnerTeds(params);
      const { status } = await verifyTaskStatus(taskID);

      return { status, taskID };
    } catch (err) {
      toast.error('Erro ao gerar o id do arquivo');
      handleLoading(false);
      return false;
    }
  };

  const handleLoadTedList = async () => {
    handleLoading(true);
    const responseFromGenTaskId = await genTaskID();

    if (responseFromGenTaskId) {
      const { status, taskID } = responseFromGenTaskId;
      switch (status) {
        case 'FAILURE':
          toast.error('Falha ao sincronizar lista de TEDs, tente novamente mais tarde.');
          handleLoading(false);
          break;
        case 'PENDING':
          await pollingVerifyTaskStatus(taskID);
          break;
        case 'SUCCESS':
          await handleGetTeds();
          break;
        default:
          toast.error('Erro ao sincronizar lista de TEDs');
          handleLoading(false);
          break;
      }
    }
  };

  if (fab) {
    return (
      <Fab
        size="small"
        color="primary"
        disabled={loading}
        variant="extended"
        aria-label="export-csv"
        onClick={() => handleLoadTedList()}
      >
        <ViewAgendaIcon style={{ marginRight: '5px' }} />
        {children}
      </Fab>
    );
  }

  return (
    <Container>
      <Button
        type="button"
        disabled={loading}
        onClick={() => handleLoadTedList()}
        data-cy="btn-generate-or-load-list-tedList"
      >
        <h1>{children}</h1>
      </Button>
    </Container>
  );
};

GenerateOrLoadListButton.defaultProps = {
  fab: undefined,
};

export default GenerateOrLoadListButton;
