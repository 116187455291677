import styled, { keyframes } from 'styled-components';
import { gridTemplateColumnsTedListDesktop } from '../../utils';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 40px;
  padding: 0 10px 0 0;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  background: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const GridHeader = styled.div`
  height: 100%;
  max-height: 40px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  grid-template-columns: ${gridTemplateColumnsTedListDesktop};
  display: grid;
  margin-top: 20px;
  padding: 0 10px;
  width: fit-content;
  justify-content: center;
  text-align: left;
  user-select: none;
  
  div {
    display: flex;
  }

  &:nth-child(n) {
    opacity: 0;
    animation: ${showItems} 0.3s 0.1s ease forwards;
  }

   &:nth-child(2n) {
    opacity: 0;
    animation: ${showItems} 0.3s 0.3s ease forwards;
  }
`;

export const GridValue = styled.div``;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: normal;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;

    img {
      width: 7px;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
  }
`;

export const ItemLine = styled.div``;

export const ReservationCode = styled.div`
  flex-direction: column;
  gap: 20px;
`;

export const ImmobileCode = styled.div`
`;

export const ReservationDate = styled.div`
`;

export const ReservationStaff = styled.div`
`;

export const WhatsApp = styled.div`
`;

export const CheckIn = styled.div`
`;

export const PendingValue = styled.div``;

export const TotalValue = styled.div``;

export const ContainerArrows = styled.div``;

export const Image = styled.img``;
