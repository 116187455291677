import styled from 'styled-components';

export const Content = styled.div`
  width: 95%;
  max-height: 95%;
  border-radius: 10px;
  margin-top: 27px;
`;

export const PhasesTitle = styled.div`
  height: 68px;
  border-radius: 10px 10px 0 0;
  background: #dedee6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${({ theme }) => theme.palette.blue._900.hex()};
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const PhasesContent = styled.div`
  display: grid;
  grid-template-columns: 95% 5%;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  min-height: calc(100vh - 125px);
  border-radius: 0 0 10px 10px;
  position: relative;
`;

export const Phases = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 10px;
`;

export const PhaseContent = styled.div<{ length: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${({ length }) => length}%;
  gap: 1rem;
  position: relative;
`;

export const Phase = styled.h1<{ isActive: boolean, isLong?: boolean }>`
  font-weight: ${({ isActive }) => (isActive
    ? ({ theme }) => theme.fonts.weights.bold
    : ({ theme }) => theme.fonts.weights.normal)};
  font-size: ${({ isActive }) => (isActive ? '13px' : '10px')};
  font-size: ${({ isLong }) => (isLong && '11px')};
  color: ${({ isActive }) => (isActive ? '#000000' : '#B5BAC8')};
  cursor: pointer;
  transition: all 700ms;

  &:hover{
    color: '#000000';
    font-size: '14px';
  }
`;

export const PhaseFinal = styled.h1<{ length: number; isActive: boolean }>`
  position: absolute;
  font-weight: ${({ isActive }) => (isActive
    ? ({ theme }) => theme.fonts.weights.bold
    : ({ theme }) => theme.fonts.weights.normal)};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: ${({ isActive }) => (isActive ? '14px' : '10px')};
  bottom: 9%;
  right: 3rem;
`;

export const PhasesBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin-top: 10px;
`;

export const PhaseBar = styled.div<{ length: number, isConcluded: boolean }>`
  width: 100%;
  height: 100%;
  border-left: ${({ isConcluded }) => (isConcluded ? '1px solid #41B592' : '1px solid #b5bac8')};
  cursor: pointer;
`;

export const CheckIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const PhaseImageContainer = styled.div`
  width: 15px;
  height: 15px;
`;
