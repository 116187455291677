import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Controller from './pages/Controller';
import Dashboard from './pages/Dashboard';
import Estimate from './pages/Estimate';
import Expenses from './pages/Expenses';
import HostProperties from './pages/HostProperties';
import HostTedList from './pages/HostTedList';
import InsertData from './pages/InsertData';
import LinkPage from './pages/LinkPage';
import Login from './pages/Login';
import NfList from './pages/NfList';
import Onboarding from './pages/Onboarding';
import OwnerTedList from './pages/OwnerTedList';
import Owners from './pages/Owners';
import PaymentVoucher from './pages/PaymentVoucher';

import PropertySearch from './pages/PropertySearch';
import { checkIfTokenExpired } from './utils/Auth';
import { ErrorMessage } from './utils/Messages';
// import NewPassword from './pages/NewPasswordPage';
import CompReservation from './pages/CompReservation';
import SendingVouchers from './pages/SendingVouchers';
// import ForgotPassword from './pages/ForgotPassword';
import { useToast } from './context/ToastContext';
import { useUser } from './context/UserContext';
import DefaultLayout from './layouts/DefaultLayout/DefaultLayout';
import OwnerLayout from './layouts/OwnerLayout/OwnerLayout';
import Conciliation from './pages/Conciliation';
import HostClosePage from './pages/FinancialClose/HostClosePage/HostClosePage';
import OwnerClosePage from './pages/FinancialClose/OwnerClosePage/OwnerClosePage';
import PropertyClosePage from './pages/FinancialClose/PropertyClosePage/PropertyClosePage';
import FinancialFeedback from './pages/FinancialFeedback';
import EditData from './pages/InsertData/EditData';
import InsertAddListingDataPage from './pages/InsertData/InsertAddListingDataPage/InsertAddListingDataPage';
import InsertChangePropertyHostDataPage from './pages/InsertData/InsertChangePropertyHostDataPage/InsertChangePropertyHostDataPage';
import InsertChangePropertyStatusPage from './pages/InsertData/InsertChangePropertyStatusPage';
import InsertHostDataPage from './pages/InsertData/InsertHostDataPage/InsertHostDataPage';
import InsertOwnerDataPage from './pages/InsertData/InsertOwnerDataPage/InsertOwnerDataPage';
import InsertPropertyAmenitiesDataPage from './pages/InsertData/InsertPropertyAmenitiesData/InsertPropertyAmenitiesDataPage';
import InsertPropertyDataPage from './pages/InsertData/InsertPropertyDataPage/InsertPropertyDataPage';
import InsertPropertyRulesDataPage from './pages/InsertData/InsertPropertyRulesDataPage/InsertPropertyRulesDataPage';
import OwnerPropertyDetails from './pages/Owners/PropertyDetailsOwner';
import PersonalDataHost from './pages/PersonalDataHostPage';
import PersonalDataOwnerPage from './pages/PersonalDataOwnerPage';

import PropertyDetailsPage from './pages/PropertyDetails';
import ViewHosts from './pages/ViewHosts';
import ViewOwners from './pages/ViewOwners';
import { checkKeyMainRoute, getMainRoute } from './utils/Routes';

import PreCheckin, {
  PreCheckinMainPage,
  PreCheckinFinishPage,
  PreCheckinNotFoundPage,
  PreCheckinForgotToken,
} from './pages/PreCheckin';

import {
  Partners,
  PartnersEarningsPage,
  PartnersEarningsRescuePage,
  PartnersIndicatePage,
  PartnersIndicatePageCardsSelection,
  PartnersIndicatePageForm,
  PartnersIndicationsPage,
  PartnersIndicationsViewsContentPage,
  PartnersPanelPage,
  PartnersPortfolioCardsPage,
  PartnersPortfolioPage,
  PartnersQuestionsPage,
  PartnersWelcomePage,
  PartnersWithdrawPage,
  partnersRoutes,
} from './pages/Partners';

import InsertAddPartnerData from './components/InsertData/InsertAddPartnerData';
import ManagementPanelPage from './pages/ManagementPanel/ManagementPanelPage';
import ForgotPassword from './pages/ForgotPassword';
import NewPasswordPage from './pages/NewPasswordPage';
import GuestDamagePage from './pages/GuestDamage/GuestDamagePage';
import { useViewMode } from './context/ViewMode/ViewMode';
import OwnerPerspectiveDashboard from './pages/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';
import { OwnerTransferControlProvider } from './context/OwnerPage/OwnerTransferControlContext/OwnerTransferControlContext';
import { OwnerServicesTermsProvider } from './context/OwnerPage/OwnerServicesTermsContext/OwnerServicesTermsContext';
import { OwnerExtractProvider } from './context/OwnerPage/OwnerExtractContext/OwnerExtractContext';
import TimeLine from './pages/TimeLine/TimeLine';
import InsertB2BCondominium from './components/InsertData/InsertAddB2BCondominium';
import InsertSpot from './components/InsertData/InsertAddSpot';

type RequireAuthProps = {
  children: ReactElement;
  permissions: string[];
  verifyHostOps?: boolean;
};

const RequireAuth = ({
  children,
  permissions,
  verifyHostOps = false,
}: RequireAuthProps) => {
  const toast = useToast();
  const { user, userInformation, propertiesIdsOwnerLogged } = useUser();
  const roles = userInformation?.roles || [];
  const propertiesOwner = propertiesIdsOwnerLogged || [];

  const isTokenExpired = checkIfTokenExpired();
  if (isTokenExpired) {
    toast.error(ErrorMessage.expiredToken());
    window.localStorage.removeItem('sapron-pms-user');
  }

  let userRedirectTo = '/login';

  function getAuth() {
    if (
      !user
      || !userInformation
      || isTokenExpired
      || roles.length === 0
      || permissions.length === 0
    ) {
      return false;
    }
    let userHasPermission = !!permissions.find((role) => roles.includes(role));
    const isOwnerAndHaveProperties = roles.includes('Owner') && propertiesOwner.length > 0;
    if (isOwnerAndHaveProperties) {
      const url = window.location.pathname.trim();
      const splittedUrl = url.split('/') || [];
      if (splittedUrl.length >= 3 && !splittedUrl.includes('parceiros')) {
        userHasPermission = propertiesOwner.includes(Number(splittedUrl[2]));
      }
    }
    const isHostOps = roles.includes('Host') && userInformation.host?.is_host_ops === true;
    if (userHasPermission) {
      if (isHostOps && verifyHostOps) {
        userRedirectTo = getMainRoute[checkKeyMainRoute(userInformation)];
        return false;
      }
      return true;
    }
    if (userInformation) {
      userRedirectTo = getMainRoute[checkKeyMainRoute(userInformation)];
    }

    return false;
  }

  const isUserAuthenticated = getAuth();

  return isUserAuthenticated ? children : <Navigate to={userRedirectTo} />;
};

RequireAuth.defaultProps = {
  verifyHostOps: false,
};

const InicialRedirect = () => {
  const { userInformation } = useUser();
  let userRedirecTo = '/login';
  if (userInformation) {
    userRedirecTo = getMainRoute[checkKeyMainRoute(userInformation)];
  }

  return (
    <Navigate to={userRedirecTo} />
  );
};

const Router = () => {
  const { isViewMode } = useViewMode();
  const viewModeOwnerPermission = isViewMode ? ['SeazoneAdministrative', 'SeazoneOnboarding'] : [''];

  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route
          path="/"
          element={(
            <RequireAuth
              permissions={[]}
            >
              <InicialRedirect />
            </RequireAuth>
        )}
        />
        <Route
          path="/painel-perspectiva-do-proprietario"
          element={(
            <RequireAuth permissions={['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding']}>
              <OwnerPerspectiveDashboard />
            </RequireAuth>
          )}
        />
        <Route
          path="/multicalendar"
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative', 'Attendant', 'Host']}
            >
              <TimeLine />
            </RequireAuth>
        )}
        />
        {/*
          <Route
            element={<NewPassword />}
            path="/criarnovasenha"
          />
          <Route
            element={<ForgotPassword />}
            path="/recuperarsenha"
          />
        */}
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant']}
            >
              <Estimate />
            </RequireAuth>
          )}
          path="/orcamento"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant']}
            >
              <PropertySearch />
            </RequireAuth>
          )}
          path="/buscadepropriedades"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding']}
            >
              <PropertyDetailsPage />
            </RequireAuth>
          )}
          path="/buscadepropriedades/:id"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Host']}
            >
              <PersonalDataHost />
            </RequireAuth>
          )}
          path="/minhasinformacoes"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant']}
            >
              <CompReservation />
            </RequireAuth>
          )}
          path="/compreservas"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant']}
            >
              <SendingVouchers />
            </RequireAuth>
          )}
          path="/vouchersenviados"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant', 'SeazoneAdministrative']}
            >
              <PaymentVoucher />
            </RequireAuth>
          )}
          path="/comprovantespagamento"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Host']}
            >
              <Controller />
            </RequireAuth>
          )}
          path="/controle"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Host']}
              verifyHostOps
            >
              <Dashboard />
            </RequireAuth>
          )}
          path="/dashboard"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative']}
              verifyHostOps
            >
              <Expenses />
            </RequireAuth>
          )}
          path="/despesas"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Host', 'SeazoneAdministrative', 'Admin']}
              verifyHostOps
            >
              <Expenses />
            </RequireAuth>
          )}
          path="/reembolso"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Host']}
            >
              <LinkPage />
            </RequireAuth>
          )}
          path="/links"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <HostClosePage />
            </RequireAuth>
          )}
          path="/fechamentoanfitriao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <PropertyClosePage />
            </RequireAuth>
          )}
          path="/fechamentoimovel"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <OwnerClosePage />
            </RequireAuth>
          )}
          path="/fechamentoproprietario"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <OwnerTedList />
            </RequireAuth>
          )}
          path="/listadetedsproprietario"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <HostTedList />
            </RequireAuth>
          )}
          path="/listadetedsanfitriao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <NfList />
            </RequireAuth>
          )}
          path="/listadenfs"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Host', 'SeazoneOnboarding', 'Attendant']}
            >
              <HostProperties />
            </RequireAuth>
          )}
          path="/propriedades"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <Onboarding />
            </RequireAuth>
          )}
          path="/onboarding"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertData />
            </RequireAuth>
          )}
          path="/inserirdados"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertB2BCondominium />
            </RequireAuth>
          )}
          path="/inserirdados/condominio-b2b"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertSpot />
            </RequireAuth>
          )}
          path="/inserirdados/spot"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <ViewOwners />
            </RequireAuth>
          )}
          path="/proprietarios"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <ViewHosts />
            </RequireAuth>
          )}
          path="/anfitrioes"
        />
        {/* <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertPropertyDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/propriedade"
        /> */}
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertAddPartnerData />
            </RequireAuth>
          )}
          path="/inserirdados/parceiro"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertAddPartnerData />
            </RequireAuth>
          )}
          path="/editardados/parceiro"
        />
        {/* <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertOwnerDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/proprietario"
        /> */}
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertHostDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/anfitriao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertPropertyAmenitiesDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/comodidades"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertPropertyRulesDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/regras"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertAddListingDataPage />
            </RequireAuth>
          )}
          path="/inserirdados/novo-listing"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertChangePropertyStatusPage />
            </RequireAuth>
          )}
          path="/inserirdados/mudar-status-imovel"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <FinancialFeedback />
            </RequireAuth>
          )}
          path="/gabaritofinanceiro"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneAdministrative']}
            >
              <Conciliation />
            </RequireAuth>
          )}
          path="/conciliacao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding', ...viewModeOwnerPermission]}
            >
              <EditData />
            </RequireAuth>
          )}
          path="/editardados"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding', ...viewModeOwnerPermission]}
            >
              <InsertPropertyDataPage />
            </RequireAuth>
          )}
          path="/editardados/propriedade"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertHostDataPage />
            </RequireAuth>
          )}
          path="/editardados/anfitriao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertChangePropertyHostDataPage />
            </RequireAuth>
          )}
          path="/editardados/mudar-anfitriao"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding']}
            >
              <InsertOwnerDataPage />
            </RequireAuth>
          )}
          path="/editardados/proprietario"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding', ...viewModeOwnerPermission]}
            >
              <InsertAddListingDataPage />
            </RequireAuth>
          )}
          path="/editardados/listing"
        />
        {/* <Route path="/precheckinfinalizado" element={<FinishPreCheckinPage />} /> */}
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant']}
            >
              <ManagementPanelPage />
            </RequireAuth>
          )}
          path="/painelgerenciamento"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding']}
            >
              <GuestDamagePage />
            </RequireAuth>
          )}
          path="/danosdehospede"
        />
        <Route
          element={(
            <RequireAuth
              permissions={['Admin', 'Attendant', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding']}
            >
              <GuestDamagePage />
            </RequireAuth>
          )}
          path="/danosdehospede/:id"
        />
      </Route>

      <Route
        path="/"
        element={(
          <OwnerTransferControlProvider>
            <OwnerServicesTermsProvider>
              <OwnerExtractProvider>
                <OwnerLayout />
              </OwnerExtractProvider>
            </OwnerServicesTermsProvider>
          </OwnerTransferControlProvider>
      )}
      >
        <Route
          path="/proprietario"
          element={(
            <RequireAuth
              permissions={['Admin', 'Owner', ...viewModeOwnerPermission]}
            >
              <Owners />
            </RequireAuth>
        )}
        />
        <Route
          path="/proprietario/:id"
          element={(
            <RequireAuth
              permissions={['Admin', 'Owner', ...viewModeOwnerPermission]}
            >
              <OwnerPropertyDetails />
            </RequireAuth>
        )}
        />
        <Route
          path="/meusdados"
          element={(
            <RequireAuth
              permissions={['Admin', 'Owner', ...viewModeOwnerPermission]}
            >
              <PersonalDataOwnerPage />
            </RequireAuth>
        )}
        />
      </Route>
      {/* <Route path="/precheckin" element={<PreCheckinPage />} /> */}

      <Route
        path={`/${partnersRoutes.partners.main}`}
        element={(
          <RequireAuth permissions={['Admin', 'Partner']}>
            <Partners />
          </RequireAuth>
        )}
      >
        <Route path={partnersRoutes.panel.main} element={<PartnersPanelPage />} />

        <Route path={partnersRoutes.welcome.main} element={<PartnersWelcomePage />} />

        <Route path={partnersRoutes.indications.main} element={<PartnersIndicationsPage />}>
          <Route path=":filter" element={<PartnersIndicationsViewsContentPage />} />
        </Route>

        <Route path={partnersRoutes.indicate.main} element={<PartnersIndicatePage />}>
          <Route path="" element={<PartnersIndicatePageCardsSelection />} />
          <Route path={`${partnersRoutes.indicate.form.main}/:type`} element={<PartnersIndicatePageForm />} />
        </Route>

        <Route
          element={<PartnersEarningsPage />}
          path={partnersRoutes.earnings.main}
        />

        <Route
          element={<PartnersEarningsRescuePage />}
          path={partnersRoutes.earnings.rescue.main}
        />

        <Route path={partnersRoutes.withdraw.main} element={<PartnersWithdrawPage />} />
        <Route path={partnersRoutes.questions.main} element={<PartnersQuestionsPage />} />

        <Route path={partnersRoutes.portfolio.main} element={<PartnersPortfolioPage />}>
          <Route path=":filter" element={<PartnersPortfolioCardsPage />} />
        </Route>
      </Route>

      <Route path="/precheckin" element={<PreCheckin />}>
        <Route path="" element={<PreCheckinNotFoundPage />} />
        <Route path=":token" element={<PreCheckinMainPage />} />
        <Route path="finalizar" element={<PreCheckinFinishPage />} />
        <Route path="pagina-nao-encontrada" element={<PreCheckinNotFoundPage />} />
        <Route path="token-nao-encontrado" element={<PreCheckinForgotToken />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/recuperar-senha" element={<ForgotPassword />} />
      <Route path="/criarnovasenha" element={<NewPasswordPage />} />

    </Routes>
  );
};

export default Router;
