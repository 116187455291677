import React, { useState, useEffect, SyntheticEvent } from 'react';

import {
  Box,
  Autocomplete,
  LinearProgress,
  AutocompleteChangeReason,
} from '@mui/material';

import { ArrowDropDown } from '@mui/icons-material';

import {
  Label,
  Container,
  StarSymbol,
  ListOptions,
  LabelContainer,
  StyledTextField,
  DropdownContainer,
} from './styles';

import { ErrorIcon } from '../TextField/styles';
import HelperText from '../HelperText/HelperText';

import { Type } from '../HelperText/types';
import { UseFormik } from '../../utils/Formik/types';

export interface SelectProps {
  id?: string;
  optionText?: string;
  optionValue?: string;
}

interface AutoCompleteProps {
  id: string;
  label?: string;
  dataCy?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  resetInput?: boolean;
  options?: SelectProps[];
  labelClassName?: string;
  formik?: UseFormik<any>;
  onChange?: (a: string) => void;
}

const DropdownAutocomplete: React.FC<AutoCompleteProps> = ({
  id,
  label,
  formik,
  onChange,
  required,
  helperText,
  placeholder,
  dataCy = '',
  options = [],
  disabled = false,
  resetInput = false,
  loading = undefined,
  labelClassName = '',
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);

  const [value, setValue] = useState<SelectProps>({
    optionText: '',
    optionValue: '',
  });

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  const handleChange = async (
    event: SyntheticEvent,
    option: any,
    reason: AutocompleteChangeReason,
  ) => {
    const labelOption = typeof option === 'string' ? option : option?.optionText;
    const valueOption = typeof option === 'string' ? option : option?.optionValue;

    setValue({
      optionText: labelOption,
      optionValue: valueOption,
    });

    if (formik) {
      formik?.setFieldValue(id, valueOption);
      formik?.setFieldTouched(
        id, true, false,
      );
    }

    if (onChange) {
      onChange(valueOption || '');
    }

    if (reason === 'clear') {
      // To do
    }
  };

  useEffect(() => {
    if (resetInput) {
      setValue({
        optionText: '',
        optionValue: '',
      });
    }
  }, [resetInput]);

  const getOptionLabel = (option: any) => {
    const labelOption = typeof option?.optionText === 'string' ? option?.optionText : '';
    setIsTyping(labelOption !== '');
    return labelOption;
  };

  return (
    <Container>
      <LabelContainer>
        {required && <StarSymbol>*</StarSymbol>}
        <Label className={labelClassName} htmlFor={id}>
          {label}
        </Label>
      </LabelContainer>
      <Autocomplete
        id={id}
        data-cy={dataCy}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        disabled={loading ? true : disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setIsTyping(false);
          setIsOnFocus(false);
        }}
        value={value}
        onChange={handleChange}
        options={options}
        renderOption={(props, option) => (
          <ListOptions
            {...props}
            key={option.optionValue}
          >
            {option.optionText}
          </ListOptions>
        )}
        sx={{
          borderRadius: '10px',
        }}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onFocus={() => {
              formik?.setFieldTouched(
                id, true, false,
              );
              setIsOnFocus(true);
            }}
            onChange={() => {
              formik?.setFieldTouched(
                id, true, false,
              );
              setIsTyping(true);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && (
                  <Box sx={{
                    bottom: 1,
                    left: '50%',
                    width: '98%',
                    overflow: 'hidden',
                    position: 'absolute',
                    transform: 'translateX(-50%)',
                    borderRadius: '0 0 50px 50px',
                  }}
                  >
                    <LinearProgress />
                  </Box>
                  )}

                  {!isTyping && (
                  <DropdownContainer
                    isOnFocus={isOnFocus}
                    onClick={!disabled ? () => setOpen(true) : () => {}}
                  >
                    {!loading && <ArrowDropDown />}
                  </DropdownContainer>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {!!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

export default DropdownAutocomplete;
