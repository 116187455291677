import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  OwnerContact,
  PropertyOwner,
  BankDetails,
  OwnerInvoiceDetails,
  PropertyInvoiceBank,
} from '../../services/Owner/types';

import {
  getOwnerBank,
  getOwnerInvoice,
  getOwnerContact,
  getPropertiesOwner,
  getOwnerPropertyInvoiceBank,
} from '../../services/Owner/request';

import { useViewMode } from '../../context/ViewMode/ViewMode';

import Breadcrumb, { Way } from '../Breadcrump/Breadcrump';
import Phones from './Phones';
import AccountBankManager from './AccountBankManager';
import TransferControl from './TransferControl';
import Invoices from './Invoices';
import Contacts from './Contacts';
import AccountPropertyManager from './AccountPropertyManager';

import { Container } from './styles';

const PersonalDataOwner: React.FC = () => {
  const location = useLocation();
  const { isViewMode, mode } = useViewMode();
  const [listAccountsBank, setListAccountBank] = useState<Array<BankDetails>>([]);
  const [listOwnerInvoice, setListOwnerInvoice] = useState<Array<OwnerInvoiceDetails>>([]);
  const [propertiesListMain, setPropertiesListMain] = useState<Array<PropertyOwner>>([]);
  const [propertyInvoiceBank, setPropertyInvoiceBank] = useState<Array<PropertyInvoiceBank>>([]);
  const [listContact, setListContact] = useState<Array<OwnerContact>>([]);
  const [way, setWay] = useState<Array<Way>>();

  const getPropertyBankByID = (id: number): PropertyInvoiceBank | undefined => {
    if (propertyInvoiceBank) {
      return propertyInvoiceBank.find((item) => item.bank_details?.id === id);
    }
    return undefined;
  };

  async function getListBankAccountOwner() {
    const validateMode = (mode && mode) || undefined;
    const result = await getOwnerBank(validateMode);
    result.sort((a, b) => {
      if (a.is_default > b.is_default) { return -1; } if (a.is_default < b.is_default) { return 1; }
      return 0;
    });
    setListAccountBank(result);
  }

  const getProperties = async () => {
    const validateMode = (mode && mode) || undefined;

    const values = await getPropertiesOwner({}, validateMode);
    setPropertiesListMain(values);
  };

  const getPropertyInvoiceBank = async () => {
    const validateMode = (mode && mode) || undefined;
    const result = await getOwnerPropertyInvoiceBank(validateMode);
    setPropertyInvoiceBank(result);
  };

  const getListOwnerContact = async () => {
    const result = await getOwnerContact({ mode });
    setListContact(result);
  };

  async function getListOwnerInvoices() {
    const validateMode = (mode && mode) || undefined;
    const result = await getOwnerInvoice(validateMode);
    result.sort((a, b) => {
      if (a.is_default !== undefined && b.is_default !== undefined) {
        if (a.is_default > b.is_default) { return -1; }
        if (a.is_default < b.is_default) { return 1; }
      }
      return 0;
    });
    setListOwnerInvoice(result);
  }

  function handleAddCardContact() {
    setListContact([...listContact, {
      contact_name: '',
      contact_subject: '',
      email: '',
      phone_number: '',
    }]);
  }

  useEffect(() => {
    getListBankAccountOwner();
    getProperties();
    getPropertyInvoiceBank();
    getListOwnerContact();
    getListOwnerInvoices();
    getProperties();
    const waysAux = [{
      title: 'Meus dados' || '',
      path: '#',
    }];
    if (location && location.state) {
      const aux: any = location.state;
      if (aux.title && aux.path) {
        waysAux.unshift({
          title: aux.title,
          path: aux.path,
        });
      }
    } else {
      waysAux.unshift({ title: 'Home', path: '/proprietario' });
    }

    setWay(waysAux);
  }, []);

  return (
    <Container>
      <Breadcrumb ways={way} />
      <Phones />
      <AccountBankManager
        isViewMode={isViewMode}
        listAccountsBank={listAccountsBank}
        getListBankAccountOwner={getListBankAccountOwner}
        getPropertyBankByID={getPropertyBankByID}
      />
      <AccountPropertyManager
        propertiesListMain={propertiesListMain}
        listAccountsBank={listAccountsBank}
        listOwnerInvoice={listOwnerInvoice}
        propertyInvoiceBank={propertyInvoiceBank}
        getListOwnerInvoices={getListOwnerInvoices}
      />
      <TransferControl />
      <Invoices
        listOwnerInvoice={listOwnerInvoice}
        getListOwnerInvoices={getListOwnerInvoices}
      />
      <Contacts
        isViewMode={isViewMode}
        listContact={listContact}
        getListOwnerContact={getListOwnerContact}
        handleAddCardContact={handleAddCardContact}
      />
    </Container>
  );
};

export default PersonalDataOwner;
