/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import moment from 'moment';

import {
  ColumnDef,
  flexRender,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';

import { useVirtualizer } from '@tanstack/react-virtual';
import { useUser } from '../../../context/UserContext';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';

import { ExpenseResponseProps, ExpenseStatus } from '../../../services/Expenses/types';
import { dataMapperExpenseList } from './utils';
import { translateExpenseStatusInvert } from '../../../utils/Translator';

import { ExpenseChecked } from '../Expenses';
import LoadMoreData from './LoadMoreData';
import Tooltip from '../../Tooltip';
import { Options } from './Options';
import { ArrowDown, ArrowTop } from '../../IconsComponents/Arrow';

import { Circle } from '../Grid/ItemContainer/styles';
import {
  Table,
  Arrows,
  HeadItem,
  BodyItem,
  Container,
  RadioButton,
} from './style';

export type ExpensesTableStructure = {
  id: any;
  imovel: string;
  registerDate: string;
  expenseDate: string;
  approvalDate: string;
  reason: string;
  value: string;
  status: string;
  refund: string;
  paidBy: string;
  options: string;
};

interface INewTable {
  checkAll: boolean;
  setCheckAll: (checked: boolean) => void;
  listUpdateWhoPays: ExpenseChecked[];
  setListUpdateWhoPays: (a: ExpenseChecked[]) => void;
}

export default function NewTable({
  listUpdateWhoPays,
  setListUpdateWhoPays,
  checkAll,
  setCheckAll,
}: INewTable) {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const tableContainerRef = React.useRef<any>(null);

  const {
    expensesList,
    globalFilter,
    setGlobalFilter,
    handleExpenseData,
    handleOpenExpensesInformationModal,
  } = useExpense();

  const [
    sorting,
    setSorting,
  ] = React.useState<SortingState>([]);

  const flatData = React.useMemo(() => dataMapperExpenseList(expensesList?.results || []), [expensesList]);
  const isUserAdmin = (userInformation?.main_role === 'Seazone' && (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding')));

  function handleCheck(item: ExpensesTableStructure) {
    const listHasItem = listUpdateWhoPays.find((listItem) => listItem.id === item.id);

    if (listHasItem) {
      setListUpdateWhoPays(listUpdateWhoPays.filter((itemList) => itemList.id !== item.id));
    } else {
      setListUpdateWhoPays([...listUpdateWhoPays, { id: item.id, checked: true, status: translateExpenseStatusInvert[item.status] }]);
    }
  }

  const columns = React.useMemo<ColumnDef<ExpensesTableStructure, any>[]>(() => [{
    size: isUserAdmin ? 80 : 60,
    header: () => {
      if (isUserAdmin) {
        return (
          <Tooltip text={checkAll ? 'Cancelar seleção' : 'Selecionar todos'}>
            <RadioButton
              type="radio"
              id="radioAll"
              name="radioAll"
              defaultChecked={checkAll}
              onClick={() => setCheckAll(!checkAll)}
            />
          </Tooltip>
        );
      }

      return null;
    },
    accessorKey: 'selectAll',
    cell: (info) => {
      const isChecked = listUpdateWhoPays.some((item) => item.id === info.row.original.id);

      if (isUserAdmin) {
        return (
          <RadioButton
            type="radio"
            id={`radio-${info.row.original.id}`}
            name={`radio-${info.row.original.id}`}
            defaultChecked={isChecked}
            onClick={() => handleCheck(info.row.original)}
          />
        );
      }

      return null;
    },
  },
  {
    size: 200,
    header: 'Imóvel',
    accessorKey: 'imovel',
    cell: (info) => info.getValue(),
  },
  {
    accessorFn: (row) => row.registerDate,
    size: 200,
    id: 'registerDate',
    header: 'Data de registro',
    cell: (info) => info.getValue(),
    sortingFn: (
      rowA, rowB, columnId,
    ) => {
      const dateA = moment((rowA?.original as any)[columnId], 'DD/MM/YYYY');
      const dateB = moment((rowB?.original as any)[columnId], 'DD/MM/YYYY');
      return dateA?.diff(dateB);
    },
  },
  {
    accessorFn: (row) => row.expenseDate,
    size: 200,
    id: 'expenseDate',
    header: 'Data de despesa',
    cell: (info) => info.getValue(),
    sortingFn: (
      rowA, rowB, columnId,
    ) => {
      const dateA = moment((rowA?.original as any)[columnId], 'DD/MM/YYYY');
      const dateB = moment((rowB?.original as any)[columnId], 'DD/MM/YYYY');
      return dateA?.diff(dateB);
    },
  },
  {
    accessorFn: (row) => row.approvalDate,
    size: 200,
    id: 'approvalDate',
    header: 'Data de aprovação',
    cell: (info) => info.getValue(),
    sortingFn: (
      rowA, rowB, columnId,
    ) => {
      const dateA = moment((rowA?.original as any)[columnId], 'DD/MM/YYYY');
      const dateB = moment((rowB?.original as any)[columnId], 'DD/MM/YYYY');
      return dateA?.diff(dateB);
    },
  },
  {
    size: 400,
    header: 'Motivo',
    accessorKey: 'reason',
    cell: (info) => info.getValue(),
  },
  {
    size: 200,
    header: 'Valor',
    accessorKey: 'value',
  },
  {
    size: 200,
    header: 'Status',
    accessorKey: 'status',
  },
  {
    size: 200,
    header: 'Reembolso',
    accessorKey: 'refund',
    meta: { filterVariant: 'select' },
    sortingFn: (
      rowA, rowB, columnId,
    ) => {
      const dateA = moment((rowA?.original as any)[columnId], 'DD/MM/YYYY');
      const dateB = moment((rowB?.original as any)[columnId], 'DD/MM/YYYY');
      return dateA?.diff(dateB);
    },
  },
  {
    size: 200,
    header: 'Quem paga ?',
    accessorKey: 'paidBy',
    meta: { filterVariant: 'range' },
  },
  {
    header: 'Ações',
    accessorKey: 'options',
    cell: (info) => {
      const item = expensesList?.results
        ?.find((expense) => expense.id === info.row.original.id) as ExpenseResponseProps;

      if (!item) return null;

      return (
        <Options
          item={item}
          key={info.row.index}
          index={info.row.index}
          listUpdateWhoPays={listUpdateWhoPays}
          setListUpdateWhoPays={setListUpdateWhoPays}

        />
      );
    },
  }], [expensesList?.results, checkAll, listUpdateWhoPays]);

  const table = useReactTable({
    data: flatData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters: globalFilter,
    },
    filterFns: {},
    onColumnFiltersChange: setGlobalFilter,
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined'
      && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const handleClick = (value: ExpensesTableStructure) => {
    const item = expensesList?.results?.find((expense) => expense.id === value.id);

    if (item) {
      handleExpenseData({
        ...item,
        maintenance_files: item.maintenance_files,
        statement_files: item.statement_files,
      });
      handleOpenExpensesInformationModal(true);
    }
  };

  return (
    <Container>
      <Table ref={tableContainerRef}>
        <thead>
          {table.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers?.map((header) => {
                const isFilterValid = Boolean(header.id !== 'options' && header.id !== 'selectAll');

                const FiltersArrows = () => {
                  if (isFilterValid) {
                    return (
                      <>
                        {{
                          asc: <ArrowDown />,
                          desc: <ArrowTop />,
                        }[header.column.getIsSorted() as string] ?? (
                          <Arrows>
                            <ArrowTop />
                            <ArrowDown />
                          </Arrows>
                        )}
                      </>
                    );
                  }

                  return null;
                };

                return (
                  <th
                    style={{
                      width: header.getSize(),
                    }}
                    key={header.id}
                  >
                    <HeadItem
                      type="button"
                      onClick={header.column.getToggleSortingHandler()}
                      activeSort={Boolean(isFilterValid && header.column.getIsSorted())}
                    >
                      <span>
                        {flexRender(header.column.columnDef.header,
                          header.getContext())}
                      </span>
                      <FiltersArrows />
                    </HeadItem>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {rowVirtualizer.getVirtualItems()?.map((virtualRow) => {
            const row = rows[virtualRow.index];
            const statusValue = translateExpenseStatusInvert[row.original.status];

            return (
              <BodyItem status={statusValue} key={row.id}>
                {row.getVisibleCells()?.map((cell, cellIndex) => {
                  const clickInactived = cellIndex === 0 || cellIndex === row.getVisibleCells().length - 1;
                  const isStatusCell = cell.column.columnDef.header === 'Status';

                  if (isStatusCell) {
                    return (
                      <td
                        style={{
                          width: cell.column.getSize(),
                        }}
                        key={cell.id}
                        onClick={
                          clickInactived
                            ? undefined
                            : () => handleClick(row.original)
                        }
                      >
                        <span
                          style={{
                            gap: '10px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Circle status={statusValue as ExpenseStatus} />
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </span>
                      </td>
                    );
                  }

                  return (
                    <td
                      style={{
                        width: cell.column.getSize(),
                      }}
                      key={cell.id}
                      onClick={
                        clickInactived
                          ? undefined
                          : () => handleClick(row.original)
                      }
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </BodyItem>
            );
          })}
        </tbody>
      </Table>
      <LoadMoreData />
    </Container>
  );
}
