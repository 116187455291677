import { useEffect } from 'react';
import { useFormik } from 'formik';

import iconHouse from '../../../../../assets/icons/generals/house.svg';
import iconCalendar from '../../../../../assets/icons/generals/calendar2.svg';

import { useGridFinances } from '../../../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';
import { useFinancialStatement } from '../../../../../hooks/HostDashboardHook/HostGridFinances/useFinancialStatement';

import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import SimpleSelect from '../../../../SimpleSelect';
import DownloadButton from '../../../../OwnerPage/OwnerReport/Buttons/DownloadButton';

import {
  Form,
  Container,
  Content,
  ContainerSelect,
  Label,
} from './styles';

interface IFilters {
  propertiesCodes: SelectOption[];
}

const Filters = ({ propertiesCodes }: IFilters) => {
  const years = [2024, 2023];
  const { setOpenFinancialStatementModal } = useFinancialStatement();
  const {
    propertyId,
    setPropertyId,
    year,
    setYear,
  } = useGridFinances();

  const formik = useFormik({
    initialValues: {
      year,
      property_id: propertyId,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    setPropertyId(!['', 'null', 'undefined'].includes(`${formik.values?.property_id}`) ? Number(formik.values?.property_id) : undefined);
  }, [formik.values?.property_id]);

  useEffect(() => {
    if (formik.values?.year) {
      setYear(Number(formik.values?.year));
    }
  }, [formik.values?.year]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container>
        <Content className="content-filters">
          <ContainerSelect>
            <Label>Selecione o imóvel</Label>
            <SimpleSelect
              dataCy="select-properties-list"
              id="property_id"
              placeholder="Todos"
              formik={formik}
              icon={iconHouse}
              showIcon
              options={propertiesCodes || []}
            />
          </ContainerSelect>
          <ContainerSelect>
            <Label>Selecione o ano</Label>
            <SimpleSelect
              dataCy="select-year-list"
              id="year"
              placeholder="Selecione"
              formik={formik}
              icon={iconCalendar}
              showIcon
              options={(years || []).map((itemYear) => ({
                value: itemYear,
                valueLabel: itemYear,
              }))}
            />
          </ContainerSelect>
        </Content>

        <Content className="button-download">
          <DownloadButton
            type="button"
            label="Baixar extrato"
            onClick={() => setOpenFinancialStatementModal(true)}
            variantForMobile="secondary"
            variantForDesktop="secondary"
          />
        </Content>
      </Container>
    </Form>
  );
};

export default Filters;
