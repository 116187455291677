import React, {
  FC,
  memo, useContext,
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import moment, { Moment } from 'moment';
import {
  FormContainer,
  BoldText,
  ValueContainer,
  TotalPriceContainer,
  SaveButtonContainer,
  MidlleText,
  BoldTextMargin,
  ContainerName,
  Space,
  BoldSpan,
  DataGrid,
  EditGuest,
  Alert,
  Reason,
  Notes,
  InformationDiarias,
} from './styles';
import {
  DatePicker,
  SimpleSelect,
  FormButton,
  TextField,
  QuantityField,
  GenderSelect,
  RadioYesOrNo,
  RadioTypePerson,
} from '../../../index';
import { useReservationForm } from '../../../../hooks/ReservationHook/useReservationForm';
import { useToast } from '../../../../context/ToastContext';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import {
  currencyToNumber, formatCPFtoSubmit,
  getNameFormatted, numberToCurrency, numberToPhone,
} from '../../../../utils/Formatter';
import FormGridRow from '../../../FormGridRow';
import AutoComplete from '../../../Autocomplete';
import { getOtas } from '../../../../services/Property/request';
import { Property, RequestOta } from '../../../../services/Property/types';
import { RequestPartner } from '../../../../services/Partner/types';
import { getPartners } from '../../../../services/Partner/request';
import FormAddress from '../../../FormAddress';
import { SelectProps } from '../../../Autocomplete/Autocomplete';
import { getGuest, putGuestReservation } from '../../../../services/Guest/request';
import { GuestFormData, RequestGuest } from '../../../../services/Guest/types';
import { ReservationFormData, RequestReasons, BlockFormData } from '../../../../services/Reservation/types';
import { postBlocking, postReservation } from '../../../../services/Reservation/request';
import { ErrorMessage, useToastErrorMessage } from '../../../../utils/Messages';
import { useUser } from '../../../../context/UserContext';
import { UserLogged } from '../../../../services/User/types';
import { useLoader } from '../../../../context/LoaderContext';
import { CalendarContext } from '../../Calendar';
import DatePickerRange from '../../../DatePickerRange';
import { getOneSignalExternalUserId, sendOneSignalPushNotificationByUserId } from '../../../../services/OneSignal/OneSignal';

interface Props {
  props?: object;
  onClose: () => void,
  isReservation?: boolean;
}

interface PlayerData {
  external_user_id: string;
}

const ReservationForm: FC<Props> = ({
  onClose,
  isReservation,
}) => {
  const { getReservation } = useContext(CalendarContext);
  const { setLoad } = useLoader();
  const [guests, setGuests] = useState<RequestGuest[]>([]);
  const [dataOta, setDataOta] = useState<RequestOta[]>([]);
  const [dataReasons, setDataReasons] = useState<RequestReasons[]>([]);
  const [dataPartner, setDataPartner] = useState<RequestPartner[]>([]);
  const [isNewGuest, setIsNewGuest] = useState<boolean>(false);
  const [isEditingGuest, setIsEditingGuest] = useState<boolean>(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const getData = async () => {
    try {
      setDataOta(await getOtas());
      setDataPartner(await getPartners());
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    getData();
    setDataReasons([
      {
        id: 0,
        name: 'Proprietário',
        value: 'Owner use',
      },
      {
        id: 1,
        name: 'Manutenção',
        value: 'Maintenance',
      },
      {
        id: 2,
        name: 'Limpeza',
        value: 'Cleaning',
      },
      {
        id: 3,
        name: 'Anfitrião',
        value: 'Host',
      },
    ]);
  }, []);

  const { data } = useReservationForm();
  const { user } = useUser();

  const getPrices = () => {
    let total = 0;
    data?.properties.forEach((item: Property) => {
      total += item.price || 0;
    });
    return total;
  };
  const initialsDays = (checkin: Moment, checkout: Moment) => {
    const begin = checkin;
    const end = checkout;
    const duration = moment.duration(end.diff(begin));

    return Math.round(duration.asDays());
  };

  const validationBlock = Yup.object().shape({
    reason: Yup.string().required(),
    checkInDate: Yup.date().required(),
    checkOutDate: Yup
      .date()
      .when('checkInDate',
        (checkInDate: any, schema: Yup.DateSchema) => (
          checkInDate && schema.min(checkInDate, 'A data deve ser maior que data de check-in')))
      .required(),
  });

  function replaceAll(
    string: string, search: string, replace: string,
  ) {
    return string.split(search).join(replace);
  }

  const validation = !isNewGuest
    ? Yup.object().shape({
      ota: Yup.string().required(),
      checkInDate: Yup.date().required(),
      checkOutDate: Yup
        .date()
        .when('checkInDate',
          (checkInDate: any, schema: Yup.DateSchema) => (
            checkInDate && schema.min(checkInDate, 'A data deve ser maior que data de check-in')))
        .required(),
      guest: Yup.string().required(),
      indication: Yup.string(),
      adults: Yup.number().moreThan(0, 'O valor deve ser maior que 0').required(),
    })
    : Yup.object().shape({
      email: Yup.string().required().email('E-mail inválido'),
      adults: Yup.number().moreThan(0, 'O valor deve ser maior que 0').required(),
      phone1: Yup.string().required(),
      phone2: Yup.string(),
      corporate_name: Yup.string(),
      trading_name: Yup.string(),
      ota: Yup.string().required(),
      checkInDate: Yup.date().required(),
      checkOutDate: Yup.date().required(),
      guest: Yup.string().required(),
      indication: Yup.string(),
      lastName: Yup.string().required(),
      gender: Yup.string().required(),
      born: Yup.string().required(),
      typePerson: Yup.string().required(),
      cpf: Yup.string(),
      cnpj: Yup.string(),
      rg: Yup.string().required(),
      street: Yup.string().required(),
      zipCode: Yup.string().required(),
      neighborhood: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      country: Yup.string().required(),
      number: Yup.string(),
    });

  const initialValues = {
    ota: '',
    checkInDate: data?.start?.toDate(),
    checkOutDate: data?.start?.format('YYYY-MM-DD') === data?.end?.format('YYYY-MM-DD')
      ? data?.end?.add(1, 'day')?.toDate() : data?.end?.toDate(),
    indication: '',
    corporateName: '',
    tradingName: '',
    phone1: '',
    phone2: '',
    guest: '',
    firstName: '',
    lastName: '',
    email: '',
    adults: 0,
    children: 0,
    guaranteeValue: numberToCurrency(data?.guaranteeValue),
    totalPaid: numberToCurrency(getPrices()
    * initialsDays(moment(data?.start), moment(data?.end))),
    notes: '',
    hasPet: false,
    petsNumber: 0,
    typePerson: 'individual',
    cpf: '',
    cnpj: '',
    rg: '',
    born: new Date(),
    gender: '',
    street: '',
    zipCode: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    country: 'BR',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const ota = dataOta.find((item) => item.id === Number(values.ota));
        setLoad(true);

        if (!data?.properties || data.properties.length === 0) {
          throw new Error('Propriedades inválidas');
        }

        if (isEditingGuest === true) {
          const guestSelected = guests.find((item) => item.id === Number(values.guest));
          if (guestSelected) {
            const editingGuest : GuestFormData = {
              ...guestSelected,
              id: Number(values.guest),
              user: {
                ...guestSelected.user,
                main_role: 'Guest',
                gender: values.gender,
                birth_date: moment(values.born).format('YYYY-MM-DD'),
                is_individual: values.typePerson === 'individual',
                cpf: replaceAll(
                  replaceAll(
                    values.cpf, '.', '',
                  ), '-', '',
                ),
                cnpj: values.cnpj,
                corporate_name: values.corporateName,
                trading_name: values.tradingName,
                id: guestSelected.user.id,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone_number1: values.phone1,
                phone_number2: values.phone2,
              },
            };
            if (editingGuest.user.cpf === guestSelected.user.cpf || values.typePerson !== 'individual') {
              delete editingGuest.user.cpf;
            }
            if (values.typePerson === 'individual' || guestSelected.user.cnpj === editingGuest.user.cnpj) {
              delete editingGuest.user.cnpj;
            }
            await putGuestReservation(Number(values.guest), editingGuest);
          }
        }

        await postReservation({
          ...values,
          guest: getNameFormatted(values.guest),
          lastName: getNameFormatted(values.lastName),
          cpf: formatCPFtoSubmit(values.cpf),
          properties: data.properties,
          totalValue: currencyToNumber(values.totalPaid),
          totalPaid: currencyToNumber(values.totalPaid),
          guaranteeValue: currencyToNumber(values.guaranteeValue),
          user: user as UserLogged,
          ota,
          isNewGuest,
        } as ReservationFormData);

        await getReservation();

        const oneSignalData = await getOneSignalExternalUserId();
        const players = oneSignalData?.players || [];
        let propertiesHostId: string = '';
        let propertyCode: string = '';
        const propertyReservationDate: string = moment(values.checkInDate || '').format('DD/MM/YYYY');
        data.properties.forEach((property) => {
          propertiesHostId = property?.host?.id.toString() || '';
          propertyCode = property.code || '';
        });

        players.forEach((player: PlayerData) => {
          if (player?.external_user_id
            // @ts-ignore
            && propertiesHostId === player.external_user_id && !window.Cypress) {
            sendOneSignalPushNotificationByUserId(player.external_user_id, `Uma nova reserva foi realizada para o imóvel ${propertyCode} com Check-in no dia ${propertyReservationDate}`);
          }
        });
        onClose();

        toast.success('Reserva efetuada com sucesso!');
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
      setLoad(false);
      return values;
    },
  });

  const formikBlock = useFormik({
    initialValues: {
      guests: '',
      checkInDate: data?.start?.toDate(),
      checkOutDate: data?.start?.format('YYYY-MM-DD') === data?.end?.format('YYYY-MM-DD')
        ? data?.end?.add(1, 'day')?.toDate() : data?.end?.toDate(),
      notes: '',
      reason: '',
    },
    validationSchema: validationBlock,
    onSubmit: async (values) => {
      try {
        const reason = dataReasons.find((item) => item.id === Number(values.reason));
        setLoad(true);

        if (!data?.properties || data.properties.length === 0) {
          throw new Error('Propriedades inválidas');
        }
        await postBlocking({
          ...values,
          properties: data.properties,
          blockingReason: reason,
        } as unknown as BlockFormData);
        await getReservation();

        setLoad(false);
        onClose();
        toast.success('Pedido de bloqueio realizado com sucesso!');
      } catch (e: any) {
        setLoad(false);
        if ([`${e?.error}`, `${e?.message}`].includes('property has extra day preparation')) {
          toast.error('Não é possível inserir um bloqueio neste período, pois o imóvel já está reservado para preparo de reserva.');
        } else if (e instanceof Error) {
          toastErrorRequest(e);
        } else {
          toast.error('Erro ao realizar bloqueio');
        }
      }
      return values;
    },
  });

  const dailyQuantity = () => {
    const form = isReservation ? formik : formikBlock;
    const begin = moment(form.values.checkInDate);
    const end = moment(form.values.checkOutDate);
    const duration = moment.duration(end.diff(begin));

    return Math.round(duration.asDays());
  };

  const selectedGuest = (): null | RequestGuest & { phone: string } => {
    const guest = guests.find((item) => item.id === Number(formik.values.guest));
    if (!guest || isNewGuest) {
      return null;
    }

    const phone = guest.user.phone_number1 || guest.user.phone_number2;

    return {
      ...guest,
      phone: numberToPhone(phone, true),
    };
  };

  useEffect(() => {
    const stringDate = selectedGuest()?.user?.birth_date || '02/01/1850';
    const dateSplitted = stringDate.split('/');
    const dateFormatted = dateSplitted.length === 3 ? `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}` : '1850-01-02';

    formik.setValues({
      ...initialValues,
      ota: formik.values.ota,
      guest: formik.values.guest,
      firstName: selectedGuest()?.user?.first_name || '',
      lastName: selectedGuest()?.user?.last_name || '',
      email: selectedGuest()?.user?.email || '',
      born: new Date(dateFormatted),
      phone1: selectedGuest()?.user?.phone_number1 || '',
      phone2: selectedGuest()?.user?.phone_number2 || '',
      cnpj: selectedGuest()?.user?.cnpj || '',
      cpf: selectedGuest()?.user?.cpf || '',
      rg: selectedGuest()?.user?.rg || '',
      gender: selectedGuest()?.user?.gender || '',
    });
  }, [isEditingGuest]);

  const handleGetGuest = async (value: string): Promise<SelectProps[]> => {
    const guestsData = await getGuest(value);
    setGuests(guestsData);
    return guestsData.map((guest) => ({
      optionText: `${guest.user.first_name} ${guest.user.last_name}`,
      optionValue: guest.id.toString(),
      cpf: guest.user.cpf,
      cnpj: guest.user.cnpj,
    }));
  };

  const removeGuest = () => {
    formik.setFieldValue('guest', '');
    setIsNewGuest(false);
  };

  const allowPet = data?.properties.some((property: Property) => property.allow_pet);

  useEffect(() => {
    if (formik.values.hasPet && formik.values.petsNumber === 0) {
      formik.setFieldValue('petsNumber', 1);
      formik.setFieldTouched(
        'petsNumber', true, false,
      );
    }
  }, [formik.values.hasPet, formik.values.petsNumber]);

  return (
    <FormContainer
      onContextMenu={(event) : void => {
        event.stopPropagation();
      }}
    >
      <form
        onSubmit={isReservation ? formik.handleSubmit : formikBlock.handleSubmit}
        onChange={isReservation ? formik.handleChange : formikBlock.handleChange}
        data-cy="form-create-reservation"
      >
        <div>
          <BoldText>*Datas selecionadas</BoldText>
          <DataGrid>
            <DatePickerRange
              hasInitialDates
              formik={isReservation ? formik : formikBlock}
              id1="checkInDate"
              id2="checkOutDate"
              minDate={new Date('2000-01-02')}
              showingLabel={false}
            />
          </DataGrid>
          <InformationDiarias>
            <MidlleText>
              {`${data?.properties?.length}`}
              {' '}
              {data?.properties?.length === 1 ? 'anúncio selecionado' : 'anúncios selecionados'}
            </MidlleText>
            <MidlleText>
              {`${dailyQuantity()} `}
              {dailyQuantity() === 1 ? 'diária selecionada' : 'diárias selecionadas'}

            </MidlleText>
          </InformationDiarias>
          {isReservation && (
            <>
              <ValueContainer>
                Preço por noite:
                <BoldSpan>{numberToCurrency(getPrices())}</BoldSpan>
              </ValueContainer>
              <TotalPriceContainer>
                <MidlleText>Total: </MidlleText>
                <BoldText>
                  {
                  getPrices() !== undefined
                  && numberToCurrency(getPrices() * dailyQuantity())
                }
                </BoldText>
              </TotalPriceContainer>
              <SimpleSelect
                id="ota"
                dataCy="ota"
                placeholder="Selecione"
                label="* OTA - Origem da reserva"
                formik={formik}
                options={
                  (dataOta || []).map<SelectOption>(({ id, name }) => ({
                    value: id,
                    valueLabel: name,
                  }))
                }
              />
            </>
          )}
          {formik.values.ota && (
            <>
              <BoldTextMargin>Dados do hóspede</BoldTextMargin>
              <ContainerName>
                {(selectedGuest() && !isEditingGuest) && (
                  <EditGuest>
                    <BoldText>
                      {`${selectedGuest()?.user?.first_name} ${selectedGuest()?.user?.last_name}`}
                    </BoldText>
                    <MidlleText>
                      {`${selectedGuest()?.phone} - ${selectedGuest()?.user?.email}`}
                    </MidlleText>
                    <FormButton
                      type="button"
                      link
                      onClick={() => setIsEditingGuest(true)}
                    >
                      Editar Informações
                    </FormButton>
                    <FormButton
                      type="button"
                      link
                      onClick={removeGuest}
                    >
                      Trocar de hóspede
                    </FormButton>
                  </EditGuest>
                )}
                {selectedGuest() && isEditingGuest && (
                <>
                  <FormGridRow>
                    <TextField
                      label="*Nome"
                      formik={formik}
                      id="firstName"
                      dataCy="firstName"
                      value={formik.values.firstName}
                      pattern="[a-z\s]+$"
                    />
                  </FormGridRow>
                  <FormGridRow>
                    <TextField
                      label="*Sobrenome"
                      formik={formik}
                      id="lastName"
                      dataCy="lastName"
                      value={formik.values.lastName}
                    />
                  </FormGridRow>

                  <FormGridRow>
                    <TextField
                      label="*E-mail"
                      formik={formik}
                      id="email"
                      dataCy="email"
                      value={formik.values.email}
                    />
                  </FormGridRow>

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="*Telefone Principal"
                        placeholder="+00 (00) 00000-0000"
                        formik={formik}
                        id="phone1"
                        dataCy="phone1"
                        mask="phone"
                        value={formik.values.phone1}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Telefone Secundário"
                        placeholder="+00 (00) 00000-0000"
                        formik={formik}
                        id="phone2"
                        dataCy="phone2"
                        mask="phone"
                        value={formik.values.phone2}
                      />
                    </Grid>
                  </FormGridRow>

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <GenderSelect formik={formik} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <DatePicker
                        formik={formik}
                        label="*Data de nasc."
                        id="born"
                        dataCy="born"
                        minDate={new Date('1850-01-02')}
                        viewsCustom={['day', 'month', 'year']}
                        disableCloseOnSelect={false}
                      />
                    </Grid>
                  </FormGridRow>
                  <FormGridRow>
                    <RadioTypePerson
                      labelPlacement="end"
                      id="typePerson"
                      formik={formik}
                    />
                  </FormGridRow>

                  {formik.values.typePerson === 'legal' && (
                  <>
                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="*CNPJ"
                          formik={formik}
                          id="cnpj"
                          dataCy="cnpj"
                          mask="cnpj"
                          value={formik.values.cnpj}
                        />
                      </Grid>
                    </FormGridRow>

                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="Razão Social"
                          formik={formik}
                          id="corporateName"
                          dataCy="corporateName"
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="Nome Fantasia"
                          formik={formik}
                          id="tradingName"
                          dataCy="tradingName"
                        />
                      </Grid>
                    </FormGridRow>
                  </>
                  )}

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="CPF"
                        formik={formik}
                        id="cpf"
                        dataCy="cpf"
                        mask="cpf"
                        value={formik.values.cpf}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="*Nº de identidade"
                        formik={formik}
                        id="rg"
                        dataCy="rg"
                        value={selectedGuest()?.user?.rg}
                      />
                    </Grid>
                  </FormGridRow>
                  <FormAddress formik={formik} />
                </>
                )}
                {!selectedGuest() && (
                  <FormGridRow>
                    <AutoComplete
                      label="*Pesquise por: Nome, CPF ou CNPJ"
                      formik={formik}
                      id="guest"
                      dataCy="guest"
                      addOption="+ Novo hóspede"
                      asyncOptions={handleGetGuest}
                      setNewValue={setIsNewGuest}
                    />
                  </FormGridRow>
                )}
                { formik.values.guest && formik.values.guest !== '' && isNewGuest && (
                  <>
                    <FormGridRow>
                      <TextField
                        label="*Sobrenome"
                        formik={formik}
                        id="lastName"
                        dataCy="lastName"
                      />
                    </FormGridRow>

                    <FormGridRow>
                      <TextField
                        label="*E-mail"
                        formik={formik}
                        id="email"
                        dataCy="email"
                      />
                    </FormGridRow>

                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="*Telefone Principal"
                          placeholder="+00 (00) 00000-0000"
                          formik={formik}
                          id="phone1"
                          dataCy="phone1"
                          mask="phone"
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="Telefone Secundário"
                          placeholder="+00 (00) 00000-0000"
                          formik={formik}
                          id="phone2"
                          dataCy="phone2"
                          mask="phone"
                        />
                      </Grid>
                    </FormGridRow>

                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        <GenderSelect formik={formik} />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <DatePicker
                          formik={formik}
                          label="*Data de nasc."
                          id="born"
                          dataCy="born"
                          minDate={new Date('1850-01-02')}
                          viewsCustom={['day', 'month', 'year']}
                          disableCloseOnSelect={false}
                        />
                      </Grid>
                    </FormGridRow>
                    <FormGridRow>
                      <RadioTypePerson
                        labelPlacement="end"
                        id="typePerson"
                        formik={formik}
                      />
                    </FormGridRow>

                    {formik.values.typePerson === 'legal' && (
                      <>
                        <FormGridRow grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              label="*CNPJ"
                              formik={formik}
                              id="cnpj"
                              dataCy="cnpj"
                              mask="cnpj"
                            />
                          </Grid>
                        </FormGridRow>

                        <FormGridRow grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              label="Razão Social"
                              formik={formik}
                              id="corporateName"
                              dataCy="corporateName"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              label="Nome Fantasia"
                              formik={formik}
                              id="tradingName"
                              dataCy="tradingName"
                            />
                          </Grid>
                        </FormGridRow>
                      </>
                    )}

                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="CPF"
                          formik={formik}
                          id="cpf"
                          dataCy="cpf"
                          mask="cpf"
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="*Nº de identidade"
                          formik={formik}
                          id="rg"
                          dataCy="rg"
                        />
                      </Grid>
                    </FormGridRow>
                    <FormAddress formik={formik} />
                  </>
                )}
              </ContainerName>
              <QuantityField id="adults" title="*Adultos" formik={formik} dataCy="btn-add-adults" />
              <QuantityField id="children" title="Crianças" formik={formik} dataCy="btn-add-children" />

              { !allowPet && (
                <Alert
                  severity="warning"
                  variant="filled"
                >
                  Uma das propriedades não aceita pets!
                </Alert>
              )}

              <RadioYesOrNo
                formLabel="*Vai levar pet?"
                id="hasPet"
                labelPlacement="end"
                formik={formik}
              />

              {formik.values.hasPet && (
                <QuantityField
                  id="petsNumber"
                  title="Pets"
                  initialQuantity={1}
                  formik={formik}
                  dataCy="btn-add-pets"
                />
              )}
              <Space />

              <FormGridRow grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="*Valor total"
                    formik={formik}
                    id="totalPaid"
                    dataCy="totalPaid"
                    mask="money"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Caução"
                    formik={formik}
                    id="guaranteeValue"
                    dataCy="guaranteeValue"
                    mask="money"
                  />
                </Grid>
              </FormGridRow>

              <FormGridRow>
                <SimpleSelect
                  id="indication"
                  dataCy="indication"
                  placeholder="Selecione"
                  label="Indicação"
                  formik={formik}
                  options={
                    (dataPartner || []).map<SelectOption>(({
                      id,
                      user: userPartner,
                    }) => ({ value: id, valueLabel: userPartner.trading_name }))
                  }
                />
              </FormGridRow>
            </>
          )}
          {!isReservation && (
            <>
              <Reason>
                <FormGridRow>
                  <SimpleSelect
                    id="reason"
                    dataCy="reason"
                    placeholder="Selecione"
                    label="Motivo do bloqueio"
                    formik={formikBlock}
                    options={
                      (dataReasons || []).map<SelectOption>(({ id, name }) => ({
                        value: id,
                        valueLabel: name,
                      }))
                    }
                  />
                </FormGridRow>
              </Reason>
            </>
          )}
          <Notes>
            <FormGridRow>
              <TextField
                label="Notas"
                formik={isReservation ? formik : formikBlock}
                id="notes"
                dataCy="notes"
                type="textarea"
                placeholder={!isReservation ? 'Adicione alguma observação sobre o bloqueio dessas datas' : ' '}
              />
            </FormGridRow>
          </Notes>
        </div>
        <SaveButtonContainer>
          <FormButton
            type="submit"
            dataCy="btn-save"
          >
            Salvar
          </FormButton>
        </SaveButtonContainer>
      </form>
    </FormContainer>
  );
};

export default memo(ReservationForm);
