import styled from 'styled-components';

import { TEDLISTPAGEMARGIN } from './utils';

export const Container = styled.div`
  border-radius: 10px;
  margin: ${TEDLISTPAGEMARGIN / 2}px;
  width: calc(100% - ${TEDLISTPAGEMARGIN}px);
  height: calc(100% - ${TEDLISTPAGEMARGIN}px);

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0px;
  }
`;
