import arrowBottom from '../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../assets/icons/generals/arrowTop.svg';
import { Container, Title } from './styles';

interface Props {
  onClick: () => void;
}

const LineGrid = ({ onClick }: Props) => (
  <Container>
    <Title className="name" onClick={() => onClick()}>
      Nome
      <div
        role="button"
        tabIndex={0}
        className="icons"
        onClick={() => onClick()}
        onKeyDown={() => onClick()}
      >
        <img src={arrowTop} alt="arrowTop" />
        <img src={arrowBottom} alt="arrowBottom" />
      </div>
    </Title>
    <Title>
      Telefone
      <div className="icons">
        <img src={arrowTop} alt="arrowTop" />
        <img src={arrowBottom} alt="arrowBottom" />
      </div>
    </Title>
    <Title className="email">
      E-mail
      <div className="icons">
        <img src={arrowTop} alt="arrowTop" />
        <img src={arrowBottom} alt="arrowBottom" />
      </div>
    </Title>
    <Title className="address">
      Endereço do anfitrião
      <div className="icons">
        <img src={arrowTop} alt="arrowTop" />
        <img src={arrowBottom} alt="arrowBottom" />
      </div>
    </Title>
    <Title className="qtd-property">
      Quantidade de imóveis
      <div className="icons">
        <img src={arrowTop} alt="arrowTop" />
        <img src={arrowBottom} alt="arrowBottom" />
      </div>
    </Title>
  </Container>
);

export default LineGrid;
