import React from 'react';

import { Copy } from 'react-feather';

import AdjustButton from './AdjustButton';

import Tooltip from '../../../Tooltip';

import { IStatementImage, StatusProps } from '../../types';

import { convertStatusPropsToStatusToShow } from '../../utils';

import {
  Item,
  Title,
  Circle,
  Container,
  ItemContainer,
  LeftItemContainer,
} from './styles';

import { numberToCurrency } from '../../../../utils/Formatter';
import { Number as formaterNumber } from '../../../../utils/InputMask';
import { useCustomToast } from '../../../../context/CustomToastContext';

interface BodyProps {
  id: number;
  name: string;
  nameToTed: string;
  transfer: string;
  cpf: string;
  bank: string;
  agency: string;
  account: string;
  pixKey: string;
  pixKeyType: string;
  status: StatusProps;
  cnpj: string;
  accountType: string;
  bankNumber: string;
  statementImage: IStatementImage;
}

interface ICopyItem {
  text: string;
  size?: number;
  reduce?: number;
}

interface ITooltipText {
  text: string;
  children: React.ReactNode;
}

const reduceLetter = (reduceText: string, max?: number) => {
  const defaultMax = 12;
  if (reduceText) {
    if (max) {
      if (reduceText?.length > max) {
        return `${reduceText?.substring(0, max)}...`;
      }
      return reduceText?.substring(0, max);
    }
    if (reduceText?.length > defaultMax) {
      return `${reduceText?.substring(0, defaultMax)}...`;
    }
    return reduceText;
  }

  return '';
};

const CopyItem = ({ text, size = 17, reduce = 0 }: ICopyItem) => {
  const customToast = useCustomToast();
  const copyString = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
    customToast.addToast({
      title: 'texto copiado para a área de transferência',
      description: '',
      size: 'small',
      type: 'success',
      customMaxTime: 1000,
      hideTimeLine: true,
    });
    return text;
  };

  return (
    <Item>
      <Tooltip text={text}>
        <Title onClick={() => copyString(text)} pointer>
          {reduce !== 0
            ? reduceLetter(text, Number(reduce))
            : reduceLetter(text)}
          <Copy className="copy-icon" size={size} />
        </Title>
      </Tooltip>
    </Item>
  );
};

const TooltipTitle = ({ text, children }: ITooltipText) => (
  <Tooltip text={text}>
    <Title pointer>{children}</Title>
  </Tooltip>
);

function validadeTransfer(value: string) {
  const numberTransfer = Number(value);
  if (!numberTransfer) {
    return 0;
  }
  return numberTransfer;
}

function unformattedCpf(cpfUnformatted: string) {
  return cpfUnformatted.replace(/\D/g, '');
}

const Body = ({
  id,
  cpf,
  cnpj,
  bank,
  name,
  agency,
  status,
  pixKey,
  account,
  transfer,
  nameToTed,
  bankNumber,
  pixKeyType,
  accountType,
  statementImage,
}: BodyProps) => {
  const statusToShow = convertStatusPropsToStatusToShow(status);

  const GRIDITEMS = [
    {
      name: 'Status',
      element: (
        <>
          <AdjustButton statementImage={statementImage} tedId={id} />
        </>
      ),
    },
    {
      name: 'Ajustar',
      element: (
        <>
          <Item>
            <Circle status={status || 'Not done'} />
            <Title>{statusToShow}</Title>
          </Item>
        </>
      ),
    },
    {
      name: 'Nome',
      element: (
        <>
          {' '}
          <Item>
            <TooltipTitle text={name}>{reduceLetter(name, 30)}</TooltipTitle>
          </Item>
        </>
      ),
    },
    {
      name: 'Nome para TED',
      element: (
        <>
          <Item>
            <TooltipTitle text={nameToTed}>
              {reduceLetter(nameToTed, 30)}
            </TooltipTitle>
          </Item>
        </>
      ),
    },
    {
      name: 'Repasse',
      element: (
        <>
          <Item>
            <Title>{numberToCurrency(validadeTransfer(transfer))}</Title>
          </Item>
        </>
      ),
    },
    {
      name: 'CPF',
      element: (
        <>
          <Item>
            <Title>{formaterNumber.cpf(unformattedCpf(cpf)) || cpf}</Title>
          </Item>
        </>
      ),
    },
    {
      name: 'CNPJ',
      element: <CopyItem text={formaterNumber.cnpj(cnpj) || cnpj} />,
    },
    {
      name: 'Número do banco',
      element: <CopyItem text={bankNumber} />,
    },
    {
      name: 'Banco',
      element: <CopyItem text={bank} />,
    },
    {
      name: 'Agência',
      element: <CopyItem text={agency} />,
    },
    {
      name: 'Tipo de conta',
      element: <CopyItem text={accountType} />,
    },
    {
      name: 'Conta',
      element: <CopyItem text={account} reduce={7} />,
    },
    {
      name: 'PIX',
      element: <CopyItem text={pixKey} />,
    },
    {
      name: 'Tipo da chave Pix',
      element: (
        <>
          <Item>
            <Title>{pixKeyType}</Title>
          </Item>
        </>
      ),
    },
  ];

  return (
    <Container>
      <ItemContainer status={status}>
        <LeftItemContainer>
          {GRIDITEMS.map((item) => item.element)}
        </LeftItemContainer>
      </ItemContainer>
    </Container>
  );
};

CopyItem.defaultProps = {
  size: 17,
  reduce: 0,
};

export default Body;
