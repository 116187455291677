/* eslint-disable max-len */
import { useState } from 'react';

import { OwnerInvoiceDetails } from '../../../services/Owner/types';

import CardsDataFiscalNote from '../../CardDataFiscalNote';
import ModalNewDataFiscalNote from '../Modal/ModalNewDataFiscalNote';

import { Container } from './styles';

interface IInvoices {
  listOwnerInvoice: Array<OwnerInvoiceDetails>;
  getListOwnerInvoices: () => void;
}

const Invoices = ({
  listOwnerInvoice = [],
  getListOwnerInvoices,
}: IInvoices) => {
  const [openModalFiscalNote, setOpenModalFiscalNote] = useState<boolean>(false);
  const [dataFiscalNote, setDataFiscalNote] = useState<OwnerInvoiceDetails>({} as OwnerInvoiceDetails);

  function handleOpenEditModalFiscalNote(fiscalNote: OwnerInvoiceDetails) {
    setOpenModalFiscalNote(true);
    setDataFiscalNote(fiscalNote);
  }

  function handleCloseModalDataFiscalNote() {
    setOpenModalFiscalNote(false);
    getListOwnerInvoices();
  }

  return (
    <Container>
      <h2>Dados para nota fiscal</h2>
      <span>
        Preencha os dados que o senhor(a) quer que vá para sua nota
        fiscal lançada mensalmente.
      </span>
      <section>
        {listOwnerInvoice?.map((item) => (
          <CardsDataFiscalNote
            key={item.id}
            dataInvoice={item}
            handleOpenEditModalFiscalNote={handleOpenEditModalFiscalNote}
            getListOwnerInvoices={getListOwnerInvoices}
          />
        ))}
      </section>
      {openModalFiscalNote && (
        <ModalNewDataFiscalNote
          setOpenModal={handleCloseModalDataFiscalNote}
          openModal={openModalFiscalNote || false}
          dataFiscalNote={dataFiscalNote}
          setDataFiscalNote={handleOpenEditModalFiscalNote}
        />
      )}
    </Container>
  );
};

export default Invoices;
