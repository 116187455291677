import React, { FC } from 'react';

import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';

import { GRIDITEMS, translateTedLabel } from '../../utils';

import { useOwnerTedList } from '../../../../hooks/OwnerTedListHook/useOwnerTedList';

import {
  Container,
  GridTitle,
  GridHeader,
  GridValue,
  ContainerArrows,
  Image,
  RadioButton,
  ContainerButtonStatus,
} from './styles';

import Tooltip from '../../../Tooltip';
import FormButton from '../../../FormButton';

interface IGridValueComponent {
  label: string;
  key: string;
}

const GridValueComponent: FC<IGridValueComponent> = ({ label, key }) => {
  const {
    orderGridBy,
    setOrderGridBy,
    buttonUpdateStatusIsVisible,
    handleOpenModalUpdateTedStatus,
  } = useOwnerTedList();

  const handleSortGrid = () => {
    setOrderGridBy({
      order_by: translateTedLabel[label],
      order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <GridValue key={key}>
      <GridTitle>
        {label !== 'Ajustar' && (
          <div className="titleContainer">
            <h1>{label}</h1>
            <Tooltip text={`Ordenar ${label}`}>
              <ContainerArrows
                onClick={() => handleSortGrid()}
              >
                <Image src={arrowTop} alt="Seta para cima" />
                <Image src={arrowBottom} alt="Seta para baixo" />
              </ContainerArrows>
            </Tooltip>

            {label === 'Status' && buttonUpdateStatusIsVisible && (
              <ContainerButtonStatus>
                <FormButton
                  type="button"
                  onClick={() => handleOpenModalUpdateTedStatus(true)}
                >
                  Atualizar status
                </FormButton>
              </ContainerButtonStatus>
            )}
          </div>
        )}
      </GridTitle>
    </GridValue>
  );
};

const Header = () => {
  const items = GRIDITEMS;

  const {
    listItems,
    handleUpdateAllCheckedsOfTedSelected,
  } = useOwnerTedList();

  const checkIfAllTedIsSelected = () => {
    const totalSelected = listItems.filter((item) => item.checked).length;
    return totalSelected === listItems.length;
  };

  const handleCheckAll = () => {
    const allIsChecked: boolean = checkIfAllTedIsSelected();
    handleUpdateAllCheckedsOfTedSelected(!allIsChecked);
  };

  return (
    <Container>
      <GridHeader>
        <Tooltip text="Selecionar todos">
          <RadioButton
            data-cy="radio-button-select-all"
            type="radio"
            id={'radio'}
            name={'radio'}
            checked={checkIfAllTedIsSelected()}
            onClick={() => handleCheckAll()}
          />
        </Tooltip>
        {items.map((item) => (
          <GridValueComponent key={item} label={item} />
        ))}
      </GridHeader>
    </Container>
  );
};

export default Header;
