import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white._210.hex()};

  :not(.ignore-margin) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
  }

  @media (max-width: 640px) {
    :not(.ignore-padding) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
`;

export const ContentMoreLess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 18px;

  .buttons-message-slack {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1180px) {
    display: flex;
  }

  @media (max-width: 900px) {
    height: auto;
  }
`;

export const Selectors = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonBlock = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentCardsFinancial = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 980px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-gap: 5px;
    align-content: space-between;
    justify-content: space-between;
  }

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
`;

export const Divider = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey._340.hex()};
  margin-top: 25px;
  margin-bottom: 35px;
`;

export const MoreProperties = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin-bottom: 30px;
  border: 1px solid ${({ theme }) => theme.palette.blue._910.hex()};
  border-radius: 10px;
  margin-top: 1rem;

  img {
    margin-left: 9px;
  }

  .more {
    transform: rotate(0deg);
  }

  .less {
    transform: rotate(180deg);
  }

  p {
    color: ${({ theme }) => theme.palette.blue._910.hex()};
    font-size: 1rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    padding: 1rem 0;
  }
`;

export const StyledHeaderMobile = styled.div`
  margin-bottom: 80px;
  width: 100%;
`;

export const TextBig = styled.h2`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  padding: 1rem 0;
`;

export const ContainerBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    cursor: pointer;
  }
`;
