import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 575px;
  padding: 15px 30px;
  margin-top: 23px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${({ theme }) => theme.palette.grey._240.hex()};
  border-radius: 10px;


  > section {
    margin-top: 10px;
    overflow-y: scroll;
    height: 400px;
    width: 100%;
    margin-bottom: 10px;
  }

  >p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.grey._750.hex()};
    margin-top: 15px;
    margin-bottom: 15px;
  }

  > span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  > h2 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 15px;
  }
`;
