import { useEffect, useState } from 'react';
import { Container, MoreAmenities, Wrapper } from './style';
import Badge from '../Badge/Badge';
import { getAmenities } from '../../services/Property/Amenities/request';
import { RequestAmenities } from '../../services/Property/Amenities/types';
import { NameAmenities } from './NameAmenities';
import arrowDown from '../../assets/icons/generals/arrow_down.svg';

type Props = {
  propertyId: number ;
};

const AmenitiesProperty = ({ propertyId }: Props): JSX.Element => {
  const [limitAmenities, setLimitAmenities] = useState(12);
  const [amenities, setAmenities] = useState<RequestAmenities>();

  const getAmenitiesList = async () => {
    const values = await getAmenities(propertyId);
    setAmenities(values[0]);
  };

  useEffect(() => {
    getAmenitiesList();
  }, []);

  function handleMoreLess() {
    if (amenities) {
      setLimitAmenities(limitAmenities === 12 ? Object.keys(amenities).length : 12);
    }
  }

  return (
    <Wrapper>
      <Container>
        {amenities && NameAmenities.map((amenitieName, i) => {
          if (i <= limitAmenities) {
            return (
              <p>
                {amenitieName.amenitieDescription}
                { /* @ts-ignore */ }
                <Badge type={amenities[amenitieName.key] ? 'yes' : 'no'} text={amenities[amenitieName.key] ? 'Sim' : 'Não'} />
              </p>
            );
          }
          return null;
        }) }
      </Container>
      {amenities && (
      <MoreAmenities
        onClick={() => handleMoreLess()}
      >
        {limitAmenities === 12 ? 'Mais' : 'Menos'}
        {' '}
        comodidades
        <img className={limitAmenities === 12 ? 'more' : 'less'} src={arrowDown} alt="Seta para baixo" />
      </MoreAmenities>
      )}
    </Wrapper>
  );
};
export default AmenitiesProperty;
