import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

export const DeploymentFeeValuesContainer = styled.div`
  animation: ${fadeIn} 1s 0.2s ease forwards;
  margin-top: 27px;
  h1 {
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-size: 18px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
`;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  padding: 22px 15px;
  flex-direction: column;

  .MuiFormControl-root {
    div > label {
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
`;

export const BoxHeader = styled.div`
  width: 100%;
`;

export const BoxTitle = styled.h2`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  padding-bottom: 8px;
`;

export const TotalBoxContainer = styled.div`
  display: flex;
  padding: 22px 18px;
  align-items: center;
  gap: 2rem;
  border-radius: 10px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white._350.hex()};

  input {
    width: 90%;
    border: none;
    position: relative;
    padding: 10px 10px;
    background: ${({ theme }) => theme.palette.white._400.hex()};
    font-size: 1.1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._375.hex()};
  }
`;

export const QuadriBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 11px;
  margin-bottom: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

export const Title = styled.h1`
  width: 30%;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const Border = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._345.hex()};
  width: 70%;
  margin-bottom: 10px;
  margin-left: 20px;
`;

export const SubText = styled.p`
  align-self: flex-start;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.grey._385.hex()};
  margin-top: 5px;
`;

export const TotalValueText = styled.p`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 19px; */
  /* letter-spacing: -0.02em; */
`;

export const TotalValueBorder = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._375.hex()};
  width: 50%;
  margin-bottom: 8px;
`;

export const TotalValuePrice = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.blue._900.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 11px;
  p {
    color: ${({ theme }) => theme.palette.yellow._900.hex()};
    background-color: ${({ theme }) => theme.palette.white._80.hex()};
    font-size: 10px;
    line-height: 14px;
    margin-left: 11px;
    font-family: ${({ theme }) => theme.fonts.familys._5};
  }
`;

export const SelectBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  padding: 22px 18px 30px;
  flex-direction: column;
  margin-top: 30px;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const UploadText = styled.h2<{ marginTop?: number }>`
  align-self: flex-start;
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._250.hex()};

  ${({ marginTop }) => marginTop && css`
    margin-top: 1rem;
  `}
`;

export const UploadFile = styled.div`
  display: flex;
  padding: 0 12px;
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.grey._350.hex()};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const TrashButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: none;
  background-color: ${({ theme }) => theme.palette.grey._350.hex()};
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey._445.hex()};
  }
  img{
    width: 70%;
    height: 70%;
  }
`;

export const AreaSelected = styled.div`
    width: 300px;
    padding: 15px 20px 15px 40px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const CardHostSelected = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: ${({ theme }) => theme.palette.white._350.hex()};
  border-radius: 3px;
  p, button {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.grey._760.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    cursor: pointer
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${({ theme }) => theme.palette.grey._380.hex()};
    color: ${({ theme }) => theme.palette.white._350.hex()};
  }
`;

export const SearchBar = styled.div`
  display: flex;
  position: relative;
  max-width: 300px;
  max-height: 35px;
  margin-left: 16px;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    width: 20px;
    transition: 0.2s;
    z-index: 2;
  }
  input {
    padding: 15px 20px 15px 50px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.281);
    }
    &::placeholder {
      padding: 10px;
      font-size: 1rem;
    }
    @media (max-width: 1400px) {
      width: 300px;
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
`;

export const ButtonDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  button {
    min-width: 100px;
    max-height: 35px;
  }
`;

export const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 68vw;
  right: 0;
  z-index: 9999;
  button {
    min-width: 100px;
    max-height: 35px;
  }
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  padding: 22px 15px;
  flex-direction: column;
  h2{
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
  h1 {
    margin-top: 20px;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 16px;
  }
`;

export const End = styled.div`
  padding: 10px;
`;

export const SwitchContainer = styled.div<{ flexDirection?: string }>`
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label {
    width: 100%;
    text-align: start;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: ${({ theme }) => theme.fonts.weights.normal};
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 146px;
  margin-top: 5rem;

  label {
    margin-bottom: 1rem;
  }
`;

export const Percent = styled.p`
  padding: 0 5px;
  color: ${({ theme }) => theme.palette.blue._900.hex()};
  font-weight: 600;
  font-size: 12px;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  margin-left: 0.8rem;

  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }
`;
