import { Dispatch, SetStateAction } from 'react';
import { FileProps } from '../../../../../context/FileContext/types';
import CardsList from './CardsList';
import cardsJSON from './cards.json';

import { Container } from './style';
import { ICategoryes } from './types';
import { AllInformationExpenseProps } from '../../../../../services/Expenses/types';

type FileReferenceResponse = {
  id?: string;
  uid: string,
  name: string,
  category?: 'Maintenance' | 'Statement',
  content_type: string,
  storage: {
    url: string,
    fields: {
      acl: string,
      content_type?: string,
      key: string,
      AWSAccessKeyId: string,
      policy: string,
      signature: string
    }
  }
};

interface UploadButtonProps {
  statementFiles: FileProps[];
  maintenanceFiles: FileProps[];
  setStatementFiles: Dispatch<SetStateAction<FileProps[]>>;
  setMaintenanceFiles: Dispatch<SetStateAction<FileProps[]>>;
  uploadStatementFile: FileProps;
  setUploadStatementFile:Dispatch<SetStateAction<FileProps>>;
  uploadMaintenanceFile: FileProps;
  setUploadMaintenanceFile:Dispatch<SetStateAction<FileProps>>;
  setSendingFileArray: Dispatch<SetStateAction<FileReferenceResponse[]>>;
  allInformationExpenseFiles: AllInformationExpenseProps;
  setAllInformationExpenseFiles:Dispatch<SetStateAction<AllInformationExpenseProps>>;
}

const UploadButton = ({
  maintenanceFiles,
  statementFiles,
  uploadMaintenanceFile,
  uploadStatementFile,
  setMaintenanceFiles,
  setStatementFiles,
  setUploadMaintenanceFile,
  setUploadStatementFile,
  setSendingFileArray,
  allInformationExpenseFiles,
  setAllInformationExpenseFiles,
}: UploadButtonProps) => (
  <Container>
    {cardsJSON.map((item) => (
      <CardsList
        key={item.title}
        title={item.title}
        required={item.required}
        category={item.category as ICategoryes}
        maintenanceFiles={maintenanceFiles}
        setMaintenanceFiles={setMaintenanceFiles}
        statementFiles={statementFiles}
        setStatementFiles={setStatementFiles}
        uploadStatementFile={uploadStatementFile}
        setUploadStatementFile={setUploadStatementFile}
        uploadMaintenanceFile={uploadMaintenanceFile}
        setUploadMaintenanceFile={setUploadMaintenanceFile}
        setSendingFileArray={setSendingFileArray}
        allInformationExpenseFiles={allInformationExpenseFiles}
        setAllInformationExpenseFiles={setAllInformationExpenseFiles}
      />
    ))}
  </Container>
);

export default UploadButton;
