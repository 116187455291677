import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline';
import {
  Person as PersonIcon,

} from '@mui/icons-material';
import { GroupContainer, RightTitleContainer } from './styles';
import { Text } from '../../Text';

const GroupRenderer = ({ group }: ReactCalendarGroupRendererProps) => (
  <GroupContainer>
    <Text $color="#000" fontSize="14">{group.title}</Text>
    <RightTitleContainer>
      <PersonIcon fontSize="small" />
      <Text $color="#686868" fontSize="10" $ellipsis>
        {group.rightTitle}
      </Text>
    </RightTitleContainer>
  </GroupContainer>
);

export default GroupRenderer;
