import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { setLocale } from 'yup';
import ReactGA from 'react-ga4';
import moment from 'moment';
import OneSignalReact from 'react-onesignal';
import TagManager from 'react-gtm-module';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ModalProvider } from './context/ModalContext';
import { UserProvider } from './context/UserContext';
import GlobalStyles from './styles/GlobalStyle';
import theme from './styles/themes';
import Router from './Router';
import { ToastProvider } from './context/ToastContext';
import { ErrorMessage } from './utils/Messages';
import { LoaderProvider } from './context/LoaderContext';
import FilterBarProvider from './context/FilterBar';
import { FileProvider } from './context/FileContext/FileContext';
import { ChecklistProvider } from './context/ControllerPage/ChecklistContext';
import EstimateCartProvider from './context/EstimateCart';
import { MenuLateralProvider } from './context/MenuLateral';
import { queryClient } from './utils/QueryClient/QueryClient';
import { SendingVoucherProvider } from './context/SendingVoucherContext';
import { CompleteReservationProvider } from './context/CompleteReservationContext/CompleteReservationContext';
import { AbortRequestProvider } from './context/RequestContext/AbortRequestContext';
import { OnboardingHandoverProvider } from './context/OnboardingContext/OnboardingHandoverContext';
import { OnboardingPageProvider } from './hooks/useOnboarding/useOnboarding';
import { ConciliationProvider } from './context/ConciliationPage/ConciliationContext';
import { ViewModeProvider } from './context/ViewMode/ViewMode';
import { RequestSupportModalProvider } from './context/RequestSuportModalContext';
import { GeolocationProvider } from './context/GeolocationContext/GeolocationContext';
import { CustomPopupsProvider } from './context/CustomPopupsContext/CustomPopupsContext';
import CustomPopupsLayout from './layouts/CustomPopupsLayout/CustomPopupsLayout';
import ReCaptchaMsg from './components/ReCaptchaMsg';

setLocale({
  mixed: { required: ErrorMessage.required(), default: ErrorMessage.invalid() },
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_KEY as string,
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA_KEY as string,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  },
]);

const start = moment();
localStorage.setItem('startUse', start.toString());

const App: FC<{}> = () => {
  useEffect(() => {
    // @ts-ignore
    if (!window.Cypress) {
      OneSignalReact.init({
        appId: '7409d890-6c9e-4399-8da9-9a8fd0ce6d55',
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ViewModeProvider>
          <CompleteReservationProvider>
            <SendingVoucherProvider>
              <UserProvider>
                <ChecklistProvider>
                  <EstimateCartProvider>
                    <MenuLateralProvider>
                      <ModalProvider>
                        <ToastProvider>
                          <FilterBarProvider>
                            <LoaderProvider>
                              <OnboardingHandoverProvider>
                                <FileProvider>
                                  <AbortRequestProvider>
                                    <OnboardingPageProvider>
                                      <ConciliationProvider>
                                        <RequestSupportModalProvider>
                                          <GeolocationProvider>
                                            <BrowserRouter>
                                              <ReCaptchaMsg />

                                              <GlobalStyles />
                                              <CustomPopupsProvider>
                                                <CustomPopupsLayout />
                                              </CustomPopupsProvider>
                                              <Router />
                                            </BrowserRouter>
                                          </GeolocationProvider>
                                        </RequestSupportModalProvider>
                                      </ConciliationProvider>
                                    </OnboardingPageProvider>
                                  </AbortRequestProvider>
                                </FileProvider>
                              </OnboardingHandoverProvider>
                            </LoaderProvider>
                          </FilterBarProvider>
                        </ToastProvider>
                      </ModalProvider>
                    </MenuLateralProvider>
                  </EstimateCartProvider>
                </ChecklistProvider>
              </UserProvider>
            </SendingVoucherProvider>
          </CompleteReservationProvider>
        </ViewModeProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export const AppWithReCaptcha = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
    language="pt-BR"
    container={{
      element: 'recaptcha-msg-container',
      parameters: {
        badge: 'bottomright', // optional, default undefined
        theme: 'dark', // optional, default undefined
      },
    }}
  >
    <App />
  </GoogleReCaptchaProvider>
);

export default AppWithReCaptcha;
