/* eslint-disable @typescript-eslint/naming-convention */
import { ContentCopy } from '@mui/icons-material';
import { RequestHost } from '../../../services/Host/types';

import Tooltip from '../../Tooltip';
import { Container, Name, Text } from './styles';

interface Props {
  host: RequestHost,
  setOpenModal: (a: boolean) => void;
  setHostSelected: (a: RequestHost) => void
}

const LineGrid = ({
  host, setOpenModal, setHostSelected,
}: Props) => {
  const phone = host.user?.phone_number1 || host.user?.phone_number2 || 'Não informado';
  let labelAddress = 'Usuário não encontrado';
  let totalAddress = '';

  if (host.user?.main_address) {
    const {
      street, number, complement, neighborhood, city, state, country, postal_code,
    } = host.user.main_address;
    totalAddress = `${street}, ${number} ${complement} - ${neighborhood}, ${city} - ${state}, ${postal_code} - ${country}`;
    labelAddress = totalAddress.length > 30 ? `${street}, ${number}` : totalAddress;
  }

  const copyToClipboard = (text: string | EventTarget | any) => {
    navigator.clipboard.writeText(text);
  };

  function handleClick() {
    setHostSelected(host);
    setOpenModal(true);
  }

  return (
    <Container>
      <Name onClick={handleClick}>{host.name || host.user?.corporate_name}</Name>
      <Text>
        <p>
          {phone}
          {' '}

        </p>
        <ContentCopy onClick={() => copyToClipboard(phone)} />
      </Text>
      <Text className="email">
        <p>
          {host.user?.email || 'Não informado'}
          {' '}
        </p>
        <ContentCopy onClick={() => copyToClipboard(host.user?.email || 'Não informado')} />
      </Text>
      <Text className="address">
        <Tooltip text={totalAddress}>
          <p>
            {labelAddress}
            {' '}
            ...
          </p>
        </Tooltip>
        <Tooltip text="Copiar endereço completo">

          <ContentCopy onClick={() => copyToClipboard(totalAddress || 'Não informado')} />
        </Tooltip>
      </Text>
      <Text className="qtd-property"><p>{host.properties ? host.properties?.toString() : '0'}</p></Text>
    </Container>
  );
};

export default LineGrid;
