import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputAdornment } from '@mui/material';
import {
  Mail, Lock, Eye, EyeOff,
} from 'react-feather';
import ReactGA from 'react-ga4';

import moment from 'moment';
import OneSignalReact from 'react-onesignal';
import { getUserInformation, login } from '../../services/User/request';
import { UserAuth } from '../../services/User/types';

import { useUser } from '../../context/UserContext';
import { useToast } from '../../context/ToastContext';

import TextField from '../../components/TextField';

import {
  ColoredBar,
  LoginContainer,
  PageContainer,
  Text,
  // ForgetPasswordText,
  InputContainer,
  ForgetPasswordText,
  ButtonsContainer,
  LogoImage,
} from './styles';
import { checkKeyMainRoute, getMainRoute } from '../../utils/Routes';
import LoginWithReCaptcha from './LoginWithReCaptcha';
import { AnimatedLogo } from '../../assets/animations/components/AnimatedLogo';

export type ILoading = {
  is: boolean;
  status: 'Idle' | 'Success' | 'Error';
};

const Login: FC<{}> = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { setUser, setUserInformation, logout } = useUser();

  const [type, setType] = useState<string>('password');
  const [loading, setLoading] = useState<ILoading>({
    is: false,
    status: 'Idle',
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('E-mail obrigatório')
        .email('Digite um e-mail válido'),
      password: Yup.string()
        .min(6, 'No mínimo 6 caracteres')
        .required('Senha obrigatória'),
    }),

    onSubmit: async (formData: UserAuth) => {
      try {
        setLoading((old) => ({ ...old, is: true, status: 'Idle' }));

        const { data } = await login(formData);

        await setUser({
          email: formData.email,
          ...data,
        });

        const userDetails = await getUserInformation();

        const start = moment();
        if (!localStorage.getItem('startUse')) {
          localStorage.setItem('startUse', start.toString());
        }

        ReactGA.event(`${userDetails?.main_role}-${userDetails?.user_id}-${userDetails?.nickname || userDetails?.first_name}`, {
          category: `${userDetails?.main_role}-${userDetails?.user_id}-${userDetails?.nickname || userDetails?.first_name}`,
          action: 'Fez login',
        });

        ReactGA.event(`${userDetails?.main_role}-${userDetails?.user_id}-${userDetails?.nickname || userDetails?.first_name}`, {
          category: `${userDetails?.main_role}-${userDetails?.user_id}-${userDetails?.nickname || userDetails?.first_name}`,
          action: `Último acesso ${moment().format('DD/MM/YYYY HH:mm')}`,
        });

        localStorage.setItem('userDetail', `${userDetails?.main_role}-${userDetails?.user_id}-${userDetails?.nickname || userDetails?.first_name}`);

        ReactGA.set({ userId: userDetails?.user_id });

        if (userDetails) {
          await setUserInformation(userDetails);
          // @ts-ignore
          if (userDetails.roles.includes('Host') && userDetails?.host?.id !== undefined && !window.Cypress) {
            OneSignalReact.setExternalUserId(userDetails.host?.id.toString());
          }
          navigate(getMainRoute[checkKeyMainRoute(userDetails)]);

          setLoading((old) => ({ ...old, is: false, status: 'Success' }));
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error('Falha ao efetuar login' || e.message);
        }
        setLoading((old) => ({ ...old, is: false, status: 'Error' }));
      }
    },
  });

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    document.title = 'Sapron | Login';
  }, []);

  return (
    <PageContainer>
      <ColoredBar>
        <LogoImage>
          <AnimatedLogo />
        </LogoImage>
      </ColoredBar>
      <form onSubmit={formik.handleSubmit}>
        <LoginContainer>
          <Text>
            Acesse o sistema
          </Text>
          <InputContainer>
            <TextField
              id="email"
              dataCy="email"
              type="email"
              formik={formik}
              placeholder="Seu e-mail"
              startAdornment={(
                <InputAdornment position="start">
                  <Mail strokeWidth={'1'} size={20} />
                </InputAdornment>
                )}
            />
            <TextField
              id="password"
              dataCy="password"
              type={type}
              placeholder="Senha"
              formik={formik}
              startAdornment={(
                <InputAdornment position="start">
                  <Lock strokeWidth={'1'} size={20} />
                </InputAdornment>
                )}
              endAdornment={(
                <InputAdornment position="start">
                  {type === 'password'
                    ? <EyeOff onClick={() => setType('text')} strokeWidth={'1'} cursor="pointer" size={20} />
                    : <Eye onClick={() => setType('password')} strokeWidth={'1'} cursor="pointer" size={20} />}
                </InputAdornment>
                )}
            />
            <ButtonsContainer>
              <ForgetPasswordText to="/recuperar-senha">
                Esqueci a senha
              </ForgetPasswordText>

              <LoginWithReCaptcha loading={loading} />
            </ButtonsContainer>
          </InputContainer>
        </LoginContainer>
      </form>
    </PageContainer>
  );
};

export default Login;
