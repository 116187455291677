import { useMemo } from 'react';

import { useExpensesReport } from '../../../../../hooks/OwnerPage/OwnerReport/useExpensesReport';

import Backdrop from '../../../../Backdrop';
import CloseButton from '../../Buttons/CloseButton';
import TooltipInfo from '../../../TooltipInfo';

import {
  Container,
  Content,
  Header,
  TitleContainer,
  Title,
  CardContainer,
  CardContent,
  ContentItems,
  Item,
  TextContainer,
  Text,
  TextSmall,
  TextBold,
  TextBoldBig,
  Footer,
} from './styles';
import { FilesContainer, PhotosContainer } from '../../../../Expenses/Grid/ExpensesInformationModal/styles';
import FilesList from '../../../../Expenses/Header/NewExpensesModal/UploadButtons/CardsList/Item/CardContent/FilesList';

const ExpensesModal = () => {
  const { expensesModal, handleExpensesModal } = useExpensesReport();

  const expense = useMemo(() => (expensesModal.expense), [expensesModal]);
  if (Object.keys(expense).length === 0) return null;

  const handleCloseModal = () => {
    handleExpensesModal({
      open: false,
      expense: {},
    });
  };

  return (
    <>
      <Backdrop zIndex={1000} onClose={() => {}}>
        <Container onClick={(e) => e.stopPropagation()}>
          <Header>
            <TitleContainer>
              <Title>Detalhes da despesa</Title>
              <CloseButton onClick={() => handleCloseModal()} />
            </TitleContainer>
            <CardContainer>
              <CardContent>
                <Text>Imóvel</Text>
                <TextBoldBig>{expense.property_code}</TextBoldBig>
              </CardContent>
              <CardContent>
                <Text>Valor</Text>
                <TextBoldBig>{expense.value}</TextBoldBig>
              </CardContent>
            </CardContainer>
          </Header>
          <Content>
            <ContentItems>
              <Item>
                <TextContainer>
                  <Text>Data de aprovação</Text>
                  <TooltipInfo size="lg" title="Data de aprovação" subtitle="Data em que a despesa foi considerada para o fechamento do imóvel." />
                </TextContainer>
                <TextBold>{expense.approval_date}</TextBold>
              </Item>
              <Item>
                <TextContainer>
                  <Text>Data de registro</Text>
                  <TooltipInfo size="lg" title="Data de registro" subtitle="Data em que a despesa foi registrada." />
                </TextContainer>
                <TextBold>{expense.register_date}</TextBold>
              </Item>
              <Item>
                <Text>Motivo</Text>
                <TextBold>{expense.reason}</TextBold>
              </Item>
              <Item>
                <Text>Descrição</Text>
                <TextSmall>{expense.description}</TextSmall>
              </Item>
              <Item className="attachments">
                <Text>Anexos</Text>
                <PhotosContainer>
                  {expense.expenses_files.length > 0
            && expense.expenses_files.map(((expenseFile, index) => (
              <FilesContainer>
                <FilesList
                  index={index}
                  MIMEtype={expenseFile.file.MIMEtype}
                  preview={
                    expenseFile.file?.previewURL
                    || expenseFile.file?.url
                    }
                  id={expenseFile.file.id}
                  text={expenseFile.file.name}
                  onDelete={() => {}}
                  noDelete
                />
              </FilesContainer>
            )))}
                  {expense.expenses_files.length === 0 && <Text>Sem anexos</Text>}
                </PhotosContainer>
              </Item>
            </ContentItems>
          </Content>
        </Container>
      </Backdrop>
    </>
  );
};

export default ExpensesModal;
