import React, { FC } from 'react';

import {
  ContainerBox,
  Text,
  ButtonContainer,
} from './styles';

import ModalTemplate from '../../../../Modal/ModalTemplate';
import FormButton from '../../../../FormButton';

import { useHostTedList } from '../../../../../hooks/HostTedListHook/useHostTedList';

interface Props {
  dataCy?: string;
}

const ModalConfirmDeleteVoucher: FC<Props> = () => {
  const {
    openModalConfirmDeleteVoucher,
    handleOpenModalConfirmDeleteVoucher,
    handleDeleteVoucherIsConfirmed,
  } = useHostTedList();

  return (
    <ModalTemplate
      open={openModalConfirmDeleteVoucher}
      handleClose={handleOpenModalConfirmDeleteVoucher}
    >
      <ContainerBox>
        <Text>
          Você realmente deseja remover o comprovante?
        </Text>
        <ButtonContainer>
          <FormButton variant="outlined" onClick={() => handleOpenModalConfirmDeleteVoucher(false)}>Cancelar</FormButton>
          <FormButton type="button" onClick={() => handleDeleteVoucherIsConfirmed(true)}>Confirmar</FormButton>
        </ButtonContainer>
      </ContainerBox>
    </ModalTemplate>

  );
};

export default ModalConfirmDeleteVoucher;
