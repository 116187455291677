import styled, { css } from 'styled-components';

import { ArrowRight } from 'react-feather';

import { TextField } from '@mui/material';

import { LocalizationProvider } from '@mui/lab';

import MuiDateRangePickerDay, {
  DateRangePickerDayProps,
} from '@mui/lab/DateRangePickerDay';

import {
  animationTime,
  shimmerAnimation,
} from '../Calendar/LoadingShimmer/animationShimmer';

export const RightArrowIndicator = styled(ArrowRight)`
  align-self: center;
  margin: 0 10px;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const StyledLocalizationProvider = styled(LocalizationProvider)``;

export const Container = styled.div<{ hidden: Boolean }>`
  && label,
  strong,
  input {
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    font-family: Inter;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    padding-bottom: 8px;
    display: block;
    @media (max-width: 580px) {
      font-size: 14px;
    }
    @media (max-width: 420px) {
      font-size: 12px;
    }
  }
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  .css-plngxy-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey._450.hex()} !important;
  }
  .MuiOutlinedInput-input {
    /* max-width: 150px !important; */
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1rem !important;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  text-align: start;
  margin-right: 0.5rem;
  padding-bottom: 4px !important;

  @media (max-width: 500px) {
    font-size: 0.9rem !important;
  }
`;

export const ContainerTextField = styled.div<{ disabled?: boolean, labelVariant?: 'primary' | 'secondary' }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: ${({ labelVariant }) => (labelVariant === 'primary' ? 'row' : 'column')};
  
  .MuiOutlinedInput-input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 10px;
    fieldset {
      border-color: ${({ theme }) => theme.palette.grey._450.hex()};
      border-radius: 10px;
      height: 45px;
    }
    &:hover fieldset,
    .Mui-focused fieldset,
    fieldset:focus,
    fieldset:active {
      border: solid 1px ${({ theme }) => theme.palette.green.main.hex()} !important;
    }
    &
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 10px;
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.grey._720.hex()};
      @media (max-width: 580px) {
        font-size: 12px;
      }
    }
    .css-b55ri7-MuiInputBase-input-MuiOutlinedInput-input {
    -webkit-text-fill-color: ${({ theme }) => theme.palette.grey._900.hex()};
  }
    & ::placeholder {
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      @media (max-width: 580px) {
        font-size: 12px;
      }
    }
  }
`;

export const AdornmentContainer = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: auto;
    color: ${({ disabled, theme }) => (disabled ? theme.palette.red._500.hex() : theme.palette.grey._600.hex())};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ theme }) => theme.palette.grey._600.hex()};
  }
`;

export const DateRangePickerDay = styled(MuiDateRangePickerDay)(({
  isHighlighting,
  isStartOfHighlighting,
  isEndOfHighlighting,
}) => ({
  ...(isHighlighting && {
    borderRadius: 0,
  }),
  ...(isStartOfHighlighting && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isEndOfHighlighting && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<DateRangePickerDayProps<Date>>;

export const CustomDay = styled.div<{ loading?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: ${({ theme }) => theme.palette.grey._420.hex()};
  }
`;

export const UnnavailableDayMarker = styled.div<{ loading?: boolean }>`
  width: ${({ loading }) => (loading ? 15 : 1.4)}px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  background: ${({ theme, loading }) => (loading ? theme.palette.grey._420.hex() : theme.palette.orange.main.hex())};
  ${({ loading }) => loading
    && css`
      border-radius: 50%;
      animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
`;
