import styled from 'styled-components';
import { ExpenseStatus } from '../../../../services/Expenses/types';
import { gridTemplateColumns } from '../utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 950px;
  width: 100%;
  overflow: auto;

  .style-checkbox {
    width: 1.2em;
    height: 1.2em;
    clip-path: circle(42% at 50% 50%);
    margin-right: 15px;
  }
  .clciJg {
    margin: 0!important;
  }
`;

export const RadioButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;

export const ItemContainer = styled.div<{
  animationDelay: number;
  status: ExpenseStatus;
}>`
  background: #fff;
  align-items: center;
  justify-content: start;
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  grid-gap: 0.9rem;
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
  width: 100%;

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 400;
    font-size: 14px;
  }

  background: ${({ status, theme }) => status === 'Approved' && `${theme.palette.green._50.hex()}`};
  color: ${({ status, theme }) => status === 'Approved' && `${theme.palette.blue._950.hex()}`};

  &:last-child {
    margin-bottom: 0;
  }

  opacity: 1;

  .status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .immobile {
    p {
      font-weight: ${({ theme }) => theme.fonts.weights.bold};
    }
  }

  .reason {
    p {
      font-size: 12px;
    }
  }
`;

export const Circle = styled.div<{
  status: ExpenseStatus;
}>`
   border-radius: 10px;
    display: flex;

    width: 10px;
    height: 10px;

    background: ${({ status, theme }) => status === 'Pre_Approved' && `${theme.palette.blue._100.hex()}`};
    background: ${({ status, theme }) => status === 'Approved' && `${theme.palette.green.main.hex()}`};
    background: ${({ status, theme }) => status === 'Analyzing' && `${theme.palette.yellow._500.hex()}`};
    background: ${({ status, theme }) => status === 'Denied' && `${theme.palette.orange._700.hex()}`};
    background: ${({ status, theme }) => status === 'Canceled' && `${theme.palette.grey._450.hex()}`};
    background: ${({ status, theme }) => status === 'Pending' && `${theme.palette.orange._600.hex()}`};
`;

export const Content = styled.div`
  width: 120%;
  height: 150%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Division = styled.div`
  /* border-left: 1px solid #0000001d; */
  justify-self: flex-end;
`;

export const MenuItemContainer = styled.div`
  overflow: hidden;
  display: flex;
  gap: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const DescriptionContainer = styled.div`
  display: flex;
`;

export const ContainerNotes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 300px;

  textarea {
    margin-left: 1.5rem !important;
  }
`;
