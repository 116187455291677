import React, { FC } from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';
import { useOwnerTedList } from '../../../../../hooks/OwnerTedListHook/useOwnerTedList';

import ModalTemplate from '../../../../Modal/ModalTemplate';
import FormButton from '../../../../FormButton';
import SimpleSelect from '../../../../SimpleSelect';

import { IStatus } from '../../../../../services/OwnerTedList/types';
import { changeOwnerTedStatus } from '../../../../../services/OwnerTedList/request';

import {
  ContainerBox,
  Text,
  ButtonContainer,
} from './styles';

interface Props {
  dataCy?: string;
}

const ModalUpdateTedStatus: FC<Props> = () => {
  const {
    openModalUpdateTedStatus,
    handleOpenModalUpdateTedStatus,
    listItems,
    handleListItems,
  } = useOwnerTedList();

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const options = [
    {
      id: 'Not_Concluded',
      name: 'Não concluído',
    },
    {
      id: 'Concluded',
      name: 'Concluído',
    },
    {
      id: 'Pending',
      name: 'Pendente',
    },
    {
      id: 'Exception',
      name: 'Exceção',
    },
  ];

  const validation = Yup.object().shape({
    tedStatus: Yup.string().required().min(7, 'Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      tedStatus: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const tedsFiltered: number[] = listItems.filter((item) => item.checked).map((ted) => ted.id);

      const params = {
        ted_status: values.tedStatus as IStatus,
        ted_ids: tedsFiltered,
      };

      try {
        await changeOwnerTedStatus(params);

        const newListItems = [...listItems];

        listItems.forEach((item, index) => {
          tedsFiltered.forEach((tedId) => {
            if (tedId === item.id) {
              newListItems[index].status = values.tedStatus;
            }
          });
        });

        handleListItems(newListItems);
        toast.success('Status atualizado com sucesso!');
        handleOpenModalUpdateTedStatus(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <ModalTemplate
      open={openModalUpdateTedStatus}
      handleClose={handleOpenModalUpdateTedStatus}
    >
      <ContainerBox
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <Text>
          Essa ação irá atualizar o status de todas as TEDs selecionadas
        </Text>

        <SimpleSelect
          id="tedStatus"
          placeholder="Selecione"
          label="Novo status:"
          formik={formik}
          options={(options).map(({ id, name }) => ({
            value: id,
            valueLabel: name,
          }))}
        />
        <ButtonContainer>
          <FormButton type="button" variant="outlined" onClick={() => handleOpenModalUpdateTedStatus(false)}>Cancelar</FormButton>
          <FormButton type="submit">Salvar</FormButton>
        </ButtonContainer>
      </ContainerBox>
    </ModalTemplate>

  );
};

export default ModalUpdateTedStatus;
