import {
  GridHostProps,
  ReservationProps,
  ExpenseProps,
  GridHostDetailsProps,
} from '../../../context/FinancialClosePage/FinancialCloseHostContext/types';

import {
  hostIsSelected,
  initialValuesFinancialCloseHost,
} from '../../../context/FinancialClosePage/FinancialCloseHostContext/utils';

import {
  FinancialCloseHostResponse,
  ProperPayHostFranchiseFeeResponse,
  ProperPayHostMonthlyDataReduced,
  ProperPayHostMonthlyResults,
  ReservationHostResponseProps,
} from './types';

import {
  checkEmptyString,
  formatDateToShow,
  getStringFormatted,
  numberToCurrency,
} from '../../../utils/Formatter';

import {
  translatedPaidBy,
  translateExpenseReason,
  translateExpenseStatus,
} from '../../../utils/Translator';

import { getManualFitValueColor } from '../utils';

const MAX_LENGHT_USERNAME = 80;
const MAX_DIGITS_SUBSTRING = 20;

export const formatFinancialCloseHostDetails = (data: ReservationHostResponseProps[],
  properPayHostMonthlyResults: ProperPayHostMonthlyResults[]) => {
  const reservations: ReservationProps[] = [];
  const expenses: ExpenseProps[] = [];

  data.forEach((item, index) => {
    const expensesMock = initialValuesFinancialCloseHost.expenses[0];

    item?.expenses?.forEach((expense) => {
      expenses.push({
        id: expense?.id || index + 1,
        code: expense?.id?.toString() || expensesMock.code,
        property_code: expense?.property?.code || expensesMock.property_code,
        register_date: expense?.register_date ? formatDateToShow(new Date(expense.register_date))
          : expensesMock.register_date,
        expense_date: expense?.expense_date
          ? formatDateToShow(new Date(expense?.expense_date)) : expensesMock.expense_date,
        category: translateExpenseReason[expense?.reason || expensesMock.category],
        description: expense?.description || expensesMock.description,
        value: numberToCurrency(expense?.value || 0),
        owner_approved: expense?.owner_approval || expensesMock.owner_approved,
        status: translateExpenseStatus[expense?.expense_status || expensesMock.status],
        paid_by: translatedPaidBy[expense?.paid_by || expensesMock.paid_by],
      });
    });
  });

  properPayHostMonthlyResults.forEach((item, index) => {
    const propertyManualFit = data
      .filter((prop) => prop?.property?.id === item?.property?.id)?.[0] || {
      manual_fit_by_property: 0,
    } as any;

    const reservationMock = initialValuesFinancialCloseHost.reservations[0];

    reservations.push({
      id: item?.id || index + 1,
      property: {
        id: item?.property?.id || reservationMock.property.id,
        code: item?.property?.code || reservationMock.property.code,
      },
      reserves: {
        number: '-' as any,
        income: numberToCurrency(item?.revenue || 0),
      },
      cleanings: {
        number: '-' as any,
        income: numberToCurrency(item?.cleaning_fee || 0),
      },
      commission: numberToCurrency(item?.commission || 0),
      onboarding: numberToCurrency(item?.onboarding_expenses || 0),
      expense_value: numberToCurrency(item?.refund_expenses || 0),
      property_manual_fit: {
        value: numberToCurrency(propertyManualFit?.manual_fit_by_property || 0),
        customColor: getManualFitValueColor(propertyManualFit?.manual_fit_by_property || 0),
      },
      transfer: {
        transfer_value: numberToCurrency(item?.transfer || 0),
        transfer_future: '-' as any,
      },
    });
  });

  return { reservations, expenses } as GridHostDetailsProps;
};

export const formatProperPayCloseHostDetails = (
  properPayHostMonthlyResults: ProperPayHostMonthlyResults[]) => {
  const reservations: ReservationProps[] = [];
  const expenses: ExpenseProps[] = [];
  properPayHostMonthlyResults.forEach((item, index) => {
    const reservationMock = initialValuesFinancialCloseHost.reservations[0];

    reservations.push({
      id: item?.id || index + 1,
      property: {
        id: item?.property?.id || reservationMock.property.id,
        code: item?.property?.code || reservationMock.property.code,
      },
      reserves: {
        number: '-' as any,
        income: numberToCurrency(item?.revenue || 0),
      },
      cleanings: {
        number: '-' as any,
        income: numberToCurrency(item?.cleaning_fee || 0),
      },
      commission: numberToCurrency(item?.commission || 0),
      onboarding: numberToCurrency(item?.onboarding_expenses || 0),
      expense_value: numberToCurrency(item?.refund_expenses || 0),
      property_manual_fit: {
        value: numberToCurrency(item.manual_fit || 0),
        customColor: getManualFitValueColor(item.manual_fit),
      },
      transfer: {
        transfer_value: numberToCurrency(item?.transfer || 0),
        transfer_future: '-' as any,
      },
    });
  });

  return { reservations, expenses } as GridHostDetailsProps;
};

export const formatProperPayDataHost = (
  properPayData: any,
  franchiseFeeData: ProperPayHostFranchiseFeeResponse,
): GridHostProps[] => {
  const response = properPayData.map((property: any) => {
    const dataFormatted: GridHostProps = { ...initialValuesFinancialCloseHost };

    dataFormatted.id = Number(property.host.id);
    const hostFranchiseFee = franchiseFeeData?.franchise_fee?.find((item) => `${item.host_id}` === `${property.host.id}`)?.total || 0;

    dataFormatted.host = {
      id: property?.host?.id || 0,
      name: getStringFormatted(
        checkEmptyString(`${property?.host?.fullname}`)?.trim()
|| 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING),

      reservation_commission_fee: `${(property?.host?.reservation_commission_fee || 0) * 100}%`,
      franchise_fee: numberToCurrency(hostFranchiseFee),
    };

    dataFormatted.reserves = {
      number: '-' as any,
      income: numberToCurrency(property.revenue || 0),
    };

    dataFormatted.cleanings = {
      number: '-' as any,
      income: numberToCurrency(property.cleaning_fee || 0),
    };

    dataFormatted.commission = numberToCurrency(property.commission || 0);

    dataFormatted.onboarding = numberToCurrency(property.onboarding_expenses || 0);

    dataFormatted.expense_value = numberToCurrency((
      property.refund_expenses || 0)
      + (property.debited_expenses || 0));

    dataFormatted.host_manual_fit = {
      value: numberToCurrency(property?.manual_fit || 0),
      customColor: getManualFitValueColor(property?.manual_fit || 0),
    };

    dataFormatted.transfer = numberToCurrency(property.transfer || 0);

    dataFormatted.reservations = [];
    dataFormatted.expenses = [];
    dataFormatted.status = property?.status || 'Open';
    dataFormatted.revenue_ids = property?.revenue_ids || [];
    dataFormatted.checked = hostIsSelected(dataFormatted.id);
    dataFormatted.expanded = false;

    return dataFormatted;
  });

  return response;
};

export const formatFinancialDataHost = (
  data: FinancialCloseHostResponse,
  year: string,
  month: string,
  properPayData: ProperPayHostMonthlyDataReduced,
  franchiseFeeData: ProperPayHostFranchiseFeeResponse,
): GridHostProps[] => {
  const financialData: GridHostProps[] = [];

  Object.keys(data).forEach((hostId: string) => {
    const dataFormatted: GridHostProps = { ...initialValuesFinancialCloseHost };
    const keyHost = data?.[hostId];
    const key = keyHost?.[year]?.[`${Number(month)}`];

    dataFormatted.id = Number(hostId);

    const hostFranchiseFee = franchiseFeeData?.franchise_fee?.filter((item) => `${item.host_id}` === `${hostId}`)?.[0]?.total || 0;

    dataFormatted.host = {
      id: keyHost?.host?.id || 0,
      name: keyHost?.host?.user?.is_individual
        ? getStringFormatted(
          checkEmptyString(`${keyHost?.host?.user?.first_name}
${keyHost?.host?.user?.last_name}`)?.trim()
|| 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
        )
        : getStringFormatted(
          checkEmptyString(`${keyHost?.host?.user?.corporate_name}`)?.trim()
|| 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
        ),
      reservation_commission_fee: `${(keyHost?.host?.reservation_commission_fee || 0) * 100}%`,
      franchise_fee: numberToCurrency(hostFranchiseFee),
    };

    dataFormatted.reserves = {
      number: '-' as any,
      income: numberToCurrency(properPayData?.[dataFormatted.id]?.revenue || 0),
    };

    dataFormatted.cleanings = {
      number: '-' as any,
      income: numberToCurrency(properPayData?.[dataFormatted.id]?.cleaning_fee || 0),
    };

    dataFormatted.commission = numberToCurrency(
      properPayData?.[dataFormatted.id]?.commission || 0);

    dataFormatted.onboarding = numberToCurrency(properPayData
      ?.[dataFormatted.id]?.onboarding_expenses || 0);

    dataFormatted.expense_value = numberToCurrency((
      properPayData?.[dataFormatted.id]?.refund_expenses || 0)
      + (properPayData?.[dataFormatted.id]?.debited_expenses || 0));

    dataFormatted.royalties = numberToCurrency(key?.legacy_royalties_seazone || 0);

    dataFormatted.host_manual_fit = {
      value: numberToCurrency(key?.manual_fit || 0),
      customColor: getManualFitValueColor(key?.manual_fit || 0),
    };

    dataFormatted.transfer = numberToCurrency(properPayData
      ?.[dataFormatted.id]?.transfer || 0);

    dataFormatted.reservations = [];
    dataFormatted.expenses = [];
    dataFormatted.status = key?.status || 'Open';
    dataFormatted.revenue_ids = key?.revenue_ids || [];
    dataFormatted.checked = hostIsSelected(dataFormatted.id);
    dataFormatted.expanded = false;

    financialData.push(dataFormatted);
  });

  return financialData;
};
