import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 75%;
  height: 100%;
  display: grid;
  grid-template-rows: 85% 15%;
  gap: 0 10px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
  height: 20%;
  margin-top: 50px;
  margin-left: 30px;
  button {
    max-width: 100px;
    max-height: 35px;
  }
`;

export const PhasesCard = styled.div`
  width: 25%;
  height: 100%;
`;
