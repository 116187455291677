import { useFormik } from 'formik';
import React from 'react';
import { useToast } from '../../../context/ToastContext';
import { patchExpenses } from '../../../services/Expenses/request';
import { useToastErrorMessage } from '../../../utils/Messages';
import SimpleSelect from '../../SimpleSelect';
import ModalTemplate from './ModalTemplate';
import {
  Container, ButtonCancel, ButtonSave, ContentButtons, Title,
} from './styles';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';

type ExpenseChecked = {
  id: number,
  checked: boolean
};
interface Props {
  listUpdateWhoPays: ExpenseChecked[];
}

const options = [
  {
    id: 'Owner',
    name: 'Proprietário',
  },
  {
    id: 'Host',
    name: 'Anfitrião',
  },
  {
    id: 'Seazone',
    name: 'Seazone',
  },
  {
    id: 'Guest',
    name: 'Hóspede',
  },
];

const ModalSelectWhoPays = ({
  listUpdateWhoPays,
}: Props) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const {
    setModalSelectWhoPays,
    openModalSelectWhoPays,
  } = useExpense();

  const formik = useFormik({
    initialValues: {
      paidBy: '',
    },
    onSubmit: async (values) => {
      try {
        await listUpdateWhoPays.map(async (item) => {
          await patchExpenses(item.id, { paid_by: values.paidBy });
        });
        toast.success('Despesa atualizada com sucesso!');
        setModalSelectWhoPays(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      } finally {
        formik.setFieldValue('paidBy', '');
      }
    },
  });

  return (
    <ModalTemplate
      open={openModalSelectWhoPays}
      handleClose={setModalSelectWhoPays}
    >
      <Container onSubmit={formik.handleSubmit}>
        <Title>Selecione quem pagará as despesas selecionadas</Title>
        <SimpleSelect
          id="paidBy"
          placeholder="Selecione"
          label="Quem paga:"
          formik={formik}
          options={
        (options).map(({ id, name }) => ({
          value: id,
          valueLabel: name,
        }))
      }
        />
        <ContentButtons>
          <ButtonCancel type="button" onClick={() => setModalSelectWhoPays(false)}>Cancelar</ButtonCancel>
          <ButtonSave type="submit">Salvar</ButtonSave>
        </ContentButtons>
      </Container>
    </ModalTemplate>
  );
};

export default ModalSelectWhoPays;
