export const NameAmenities = [
  {
    id: 1,
    amenitieDescription: 'Ar condicionado',
    key: 'air_conditioning',
  },
  {
    id: 2,
    amenitieDescription: 'Espaço de trabalho exclusivo',
    key: 'exclusive_work_space',
  },
  {
    id: 3,
    amenitieDescription: 'Louça',
    key: 'dishware',
  },
  {
    id: 4,
    amenitieDescription: 'Secadores de roupa',
    key: 'clothes_dryer',
  },
  {
    id: 5,
    amenitieDescription: 'Secador de cabelo',
    key: 'hairdryer',
  },
  {
    id: 6,
    amenitieDescription: 'Aquecimento central',
    key: 'central_heating',
  },
  {
    id: 7,
    amenitieDescription: 'Jacuzzi',
    key: 'jacuzzi',
  },
  {
    id: 8,
    amenitieDescription: 'Cozinha',
    key: 'kitchen',
  },
  {
    id: 9,
    amenitieDescription: 'Piscina',
    key: 'swimming_pool',
  },
  {
    id: 10,
    amenitieDescription: 'TV',
    key: 'dishware',
  },
  {
    id: 11,
    amenitieDescription: 'Máquina de lavar',
    key: 'washing_machine',
  },
  {
    id: 12,
    amenitieDescription: 'Wifi',
    key: 'wifi',
  },
  {
    id: 13,
    amenitieDescription: 'Banheira',
    key: 'bathtube',
  },
  {
    id: 14,
    amenitieDescription: 'Sabonete',
    key: 'bath_soap',
  },
  {
    id: 15,
    amenitieDescription: 'Água quente',
    key: 'hot_water',
  },
  {
    id: 16,
    amenitieDescription: 'Chuveiro externo',
    key: 'outdoor_shower',
  },
  {
    id: 17,
    amenitieDescription: 'Shampoo',
    key: 'shampoo',
  },
  {
    id: 18,
    amenitieDescription: 'Roupa de cama ',
    key: 'bedding',
  },
  {
    id: 19,
    amenitieDescription: 'Lugar para armazenar roupas',
    key: 'place_to_store_clothes',
  },
  {
    id: 20,
    amenitieDescription: 'Varal',
    key: 'clothes_line',
  },
  {
    id: 21,
    amenitieDescription: 'Cabides',
    key: 'hangers',
  },
  {
    id: 22,
    amenitieDescription: 'Ferro de passar',
    key: 'iron',
  },
  {
    id: 23,
    amenitieDescription: 'Cortinas blackout',
    key: 'blackout_curtains',
  },
  {
    id: 24,
    amenitieDescription: 'Assinatura de TV',
    key: 'tv_subscription',
  },
  {
    id: 25,
    amenitieDescription: 'Mesa de sinuca',
    key: 'snooker_table',
  },
  {
    id: 26,
    amenitieDescription: 'Protetor de rede de janela',
    key: 'window_net_protector',
  },
  {
    id: 27,
    amenitieDescription: 'Ventilador de teto',
    key: 'ceiling_fan',
  },
  {
    id: 28,
    amenitieDescription: 'Lareira interna',
    key: 'indoor_fireplace',
  },
  {
    id: 29,
    amenitieDescription: 'Ventilador portátil',
    key: 'portable_fan',
  },
  {
    id: 30,
    amenitieDescription: 'Alarme de monóxido de carbono',
    key: 'carbon_monoxide_alarm',
  },
  {
    id: 31,
    amenitieDescription: 'Utensílios de churrasco',
    key: 'barbecue_utensils',
  },
  {
    id: 32,
    amenitieDescription: 'Máquina de café',
    key: 'coffee_machine ',
  },
  {
    id: 33,
    amenitieDescription: 'Mesa de jantar',
    key: 'dining_table',
  },
  {
    id: 34,
    amenitieDescription: 'Máquina de lavar louça',
    key: 'dishwasher',
  },
  {
    id: 35,
    amenitieDescription: 'Freezer',
    key: 'freezer',
  },
  {
    id: 36,
    amenitieDescription: 'Chaleira',
    key: 'kettle',
  },
  {
    id: 37,
    amenitieDescription: 'Microondas',
    key: 'microwave',
  },
  {
    id: 38,
    amenitieDescription: 'Minibar',
    key: 'minibar',
  },
  {
    id: 39,
    amenitieDescription: 'Máquina de café expresso',
    key: 'espresso_machine',
  },
  {
    id: 40,
    amenitieDescription: 'Forno',
    key: 'oven',
  },
  {
    id: 41,
    amenitieDescription: 'Cafeteira de filtro',
    key: 'filter_coffee_maker',
  },
  {
    id: 42,
    amenitieDescription: 'Geladeira',
    key: 'refrigerator',
  },
  {
    id: 43,
    amenitieDescription: 'Fogão',
    key: 'stove',
  },
  {
    id: 44,
    amenitieDescription: 'Torradeira',
    key: 'toaster',
  },
  {
    id: 45,
    amenitieDescription: 'Taças de vinho',
    key: 'wine_glasses',
  },
  {
    id: 46,
    amenitieDescription: 'Vista para o mar',
    key: 'sea_view',
  },
  {
    id: 47,
    amenitieDescription: 'Acesso ao lago',
    key: 'lake_access',
  },
  {
    id: 48,
    amenitieDescription: 'Lavanderia',
    key: 'washhouse_around',
  },
  {
    id: 49,
    amenitieDescription: 'Entrada privada',
    key: 'private_entrance',
  },
  {
    id: 50,
    amenitieDescription: 'Vista para água',
    key: 'waters_view',
  },
  {
    id: 51,
    amenitieDescription: 'Churrasqueira',
    key: 'barbecue',
  },
  {
    id: 52,
    amenitieDescription: 'Jardim ou quintal',
    key: 'garden_or_yard',
  },
  {
    id: 53,
    amenitieDescription: 'Pátio ou varanda',
    key: 'courtyard_or_balcony',
  },
  {
    id: 54,
    amenitieDescription: 'Estacionamento',
    key: 'parking_lot',
  },
  {
    id: 55,
    amenitieDescription: 'Estacionamento gratuito na rua',
    key: 'free_street_parking_lot',
  },
  {
    id: 56,
    amenitieDescription: 'Estacionamento pago na rua',
    key: 'paid_street_parking_lot',
  },
  {
    id: 57,
    amenitieDescription: 'Pago dentro do estacionamento',
    key: 'paid_inside_parking_lot',
  },
  {
    id: 58,
    amenitieDescription: 'Academia',
    key: 'gym',
  },
  {
    id: 59,
    amenitieDescription: 'Sauna',
    key: 'steam_room',
  },
  {
    id: 60,
    amenitieDescription: 'Térreo',
    key: 'ground_floor',
  },

];
