import { motion, Variants } from 'framer-motion';
import moment from 'moment';
import {

  TableContainer,

} from './styles';
import { ControllerProperPayStatus } from '../../../../services/FinancialClose/types';

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const theadVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};

const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

interface FinancialUpdateTableProps {
  isLoading: boolean;
  properPayStatusController: ControllerProperPayStatus;
}

export const FinancialUpdateTable = ({
  isLoading,
  properPayStatusController,
}: FinancialUpdateTableProps) => {
  function translateStatus(status: string) {
    switch (status) {
      case 'Completed': {
        return 'Completo';
      }
      case 'Failed': {
        return 'Falha';
      }
      case 'Running': {
        return 'Em andamento';
      }
      default: {
        return status;
      }
    }
  }
  return (
    <TableContainer>
      <motion.table
        variants={tableVariants}
        initial="closed"
        animate="open"
        key="table"
      >
        <motion.thead variants={theadVariants} initial="hidden" animate="visible">
          <tr>
            <th>
              <motion.div variants={animationDiv} key="1">
                Nome da tabela
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="1">
                Início da transferência
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="1">
                Fim da transferência
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="1">
                Status
              </motion.div>
            </th>
          </tr>
        </motion.thead>
        <motion.tbody
          variants={tbodyVariants}
          initial="hidden"
          animate="visible"
        >
          {isLoading ? (
            <></>
          ) : (
            <>
              <motion.tr variants={animationDivWithDelay}>
                <td>
                  <div>
                    Etapa de processamento
                  </div>
                </td>
                <td>
                  <div>
                    {properPayStatusController.controller_processing
                      ? moment(properPayStatusController?.controller_processing[0]?.started_at).format('DD/MM/YYYY - HH:mm') || '-'
                      : '-'}
                  </div>
                </td>
                <td>
                  <div>
                    {properPayStatusController?.controller_processing
                      ? moment(properPayStatusController?.controller_processing[0].completed_at).format('DD/MM/YYYY - HH:mm') || '-'
                      : '-'}
                  </div>
                </td>
                <td>
                  <div>
                    {properPayStatusController?.controller_processing ? translateStatus(properPayStatusController?.controller_processing[0].status || '-') : '-'}
                  </div>
                </td>
              </motion.tr>
              {properPayStatusController?.import_controller
            && properPayStatusController?.import_controller.map((table) => (
              <motion.tr variants={animationDivWithDelay}>
                <td>
                  <div>
                    {table?.table_name !== null ? table.table_name : '-'}
                  </div>
                </td>
                <td>
                  <div>
                    {table?.started_at !== null ? moment(table.started_at).format('DD/MM/YYYY - HH:mm') : '-'}
                  </div>
                </td>
                <td>
                  <div>
                    {table?.completed_at !== null ? moment(table.completed_at).format('DD/MM/YYYY - HH:mm') : '-'}
                  </div>
                </td>
                <td>
                  <div>
                    {translateStatus(table?.status || '')}
                  </div>
                </td>
              </motion.tr>
            ))}
            </>
          )}

        </motion.tbody>
      </motion.table>
    </TableContainer>
  );
};
