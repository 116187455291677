import styled, { keyframes } from 'styled-components';

export const animate = keyframes`
  from {
    stroke-dashoffset: 320;
  }
  to {
    stroke-dashoffset: auto;
  }
`;

export const ProgressIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  position: relative;
`;

export const ProgressBar = styled.svg`
  width: 130px;
  height: 130px;
  transform: rotate(90deg);
`;

export const ProgressCircle = styled.circle<{
  dashOffset?: number
  isComplete?: boolean
  haveFailed?: boolean
}>`
  width: 130px;
  height: 130px;
  fill: none;
  stroke-width: 10;
  transform: translate(15px, 15px);
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
  stroke-linecap: round;
  transition: stroke-dashoffset 800ms ease-in-out;

  &:first-child {
    stroke-dashoffset: 0;
    stroke: #D9D9D9;
    fill: none;
  }

  &:nth-child(2) {
    stroke-dashoffset: ${({ dashOffset }) => `calc(320 - ${dashOffset})`};
    stroke: ${({ isComplete }) => (isComplete === true ? '#41B592' : '#0D4BD0')};
    stroke: ${({ haveFailed }) => (haveFailed && '#DE002B')} ;
    fill: none;
    animation: ${animate} 1s ease-in-out forwards;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #E4EAEF;
  width: 80px;
  height: 80px;
  border-radius: 99999px;
`;
