import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

export const OwnerDiv = styled.div`
  animation: ${fadeIn} 1s 0.2s ease forwards;
  margin-top: 27px;
  h1 {
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-size: 18px;
  }
`;

export const AreaSelected = styled.div`
    width: 300px;
    padding: 15px 20px 15px 40px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const CardOwnerSelected = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: ${({ theme }) => theme.palette.white._350.hex()};
  border-radius: 3px;
  p, button {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.grey._760.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    cursor: pointer
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${({ theme }) => theme.palette.grey._380.hex()};
    color: ${({ theme }) => theme.palette.white._350.hex()};
  }
`;

export const SearchBar = styled.div`
  display: flex;
  position: relative;
  max-width: 300px;
  max-height: 35px;
  margin-left: 16px;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    width: 20px;
    transition: 0.2s;
    z-index: 2;
  }
  input {
    padding: 15px 20px 15px 50px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.281);
    }
    &::placeholder {
      padding: 10px;
      font-size: 1rem;
    }
    @media (max-width: 1400px) {
      width: 300px;
    }
  }
`;

export const SearchContainer = styled.form`
  display: flex;
`;

export const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 68vw;
  right: 0;
  z-index: 10;
  button {
    min-width: 100px;
    max-height: 35px;
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  button {
    min-width: 100px;
    max-height: 35px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 16px;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  h1 {
    margin-top: 10px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const ResultsContainer = styled.div`
  margin-top: 22px;
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const ResultsText = styled.div<{ isLast?: boolean }>`
  margin-top: 18px;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.grey._765.hex()};
    margin-bottom: 18px;
  }
  border-bottom: ${({ isLast }) => !isLast && '0.5px solid #C1C1C1'} ;
`;

export const NewOwnerButton = styled.button`
  width: 100%;
  height: 60px;
  background: ${({ theme }) => theme.palette.white._300.hex()};
  border-radius: 5px;
  border: none;
  margin: 4px 0 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 700ms;
  &:hover {
    background: ${({ theme }) => theme.palette.white._600.hex()};
  }
`;

export const NewOwnerContainer = styled.div`
  margin-left: 36px;
  display: flex;
  img {
    max-width: 18px;
    max-height: 20px;
  }
  p {
    color: ${({ theme }) => theme.palette.blue._900.hex()};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    margin-left: 12px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;

export const Border = styled.div`
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._345.hex()};
  width: 75%;
  margin-bottom: 10px;
  margin-left: 20px;
`;
