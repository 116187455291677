/* eslint-disable no-restricted-syntax */
import {
  FC,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';

import { postHostBlocking } from '../../../../services/Reservation/request';

import { useToast } from '../../../../context/ToastContext';
import { ErrorMessage, useToastErrorMessage } from '../../../../utils/Messages';
import { useLoader } from '../../../../context/LoaderContext';
import { useReservationDetails } from '../../../../context/ReservationDetailsContext';

import { SimpleSelect, FormButton, TextField } from '../../../index';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import FormGridRow from '../../../FormGridRow';
import DatePickerRange from '../../../DatePickerRange';

import {
  FormContainer,
  BoldText,
  SaveButtonContainer,
  MidlleText,
  DataGrid,
  Reason,
  Notes,
  InformationDiarias,
} from './styles';

type ExistingReservationProps = {
  checkin: Moment;
  checkout: Moment;
};

interface Props {
  onClose: () => void,
  isReservation?: boolean;
}

const BlockReservationForm: FC<Props> = ({
  onClose,
  isReservation,
}) => {
  const { setLoad } = useLoader();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { groupSelect, items, setItems } = useReservationDetails();

  const reasonData = [
    {
      id: 0,
      name: 'Proprietário',
      value: 'Owner use',
    },
    {
      id: 1,
      name: 'Manutenção',
      value: 'Maintenance',
    },
    {
      id: 2,
      name: 'Limpeza',
      value: 'Cleaning',
    },
    {
      id: 3,
      name: 'Anfitrião',
      value: 'Host',
    },
  ];

  function checkAvailability(
    desiredCheckin: Moment,
    desiredCheckout: Moment,
    existingReservations: ExistingReservationProps[],
  ) {
    for (const reservation of existingReservations) {
      const { checkin, checkout } = reservation;
      // Check if there is an overlap of dates
      if (desiredCheckout.isAfter(checkin) && desiredCheckin.isBefore(checkout)) {
        return false; // If there is an overlap, the reservation is not available
      }
    }
    return true; // If there is no overlap, the reservation is available
  }

  const propertyInfos = useMemo(() => {
    const filteredGroup = items.filter((item: any) => `${item?.property?.id}` === `${groupSelect?.propertyId}`);
    const bookings: ExistingReservationProps[] = [];

    filteredGroup.forEach((item: any) => {
      bookings.push({
        checkin: moment(item.check_in_date),
        checkout: moment(item.check_out_date),
      });
    });

    return {
      existingBookings: bookings,
      hasExtraDayPreparation: filteredGroup?.[0]?.property?.extra_day_preparation !== 0,
    };
  }, [items]);

  const validationBlock = Yup.object().shape({
    reason: Yup.string().required('Motivo do bloqueio é obrigatório'),
    checkInDate: Yup.date().required('Data de check-in é obrigatória'),
    checkOutDate: Yup.date()
      .min(Yup.ref('checkInDate'),
        'Data de check-out deve ser maior que a data de check-in')
      .required('Data de check-out é obrigatória'),
  });

  const formikBlock = useFormik({
    initialValues: {
      guests: '',
      checkInDate: new Date(moment(groupSelect.date).format('YYYY/MM/DD')),
      checkOutDate: new Date(moment(groupSelect.date).add(1, 'day').format('YYYY/MM/DD')),
      notes: '',
      reason: '',
    },
    validationSchema: validationBlock,
    onSubmit: async (values) => {
      try {
        const isAvailable = checkAvailability(
          moment(values.checkInDate),
          moment(values.checkOutDate),
          propertyInfos.existingBookings,
        );

        if (!isAvailable) {
          toast.error(`Não é possível inserir um bloqueio neste período, pois o imóvel já está reservado${propertyInfos.hasExtraDayPreparation ? ' para preparo de reserva.' : '.'}`);
          return;
        }

        const reason = reasonData.find((item) => item.id === Number(values.reason));
        setLoad(true);

        const payload: any = {
          blockingReason: reason,
          checkInDate: values.checkInDate,
          checkOutDate: values.checkOutDate,
          notes: values.notes,
          property: groupSelect.propertyId,
        };

        const data: any = await postHostBlocking(payload);

        const transformedItems = {
          id: data.id,
          group: groupSelect?.propertyId,
          title: '',
          start_time: moment(data.check_in_date, 'YYYY-MM-DD').add(12, 'hours').valueOf(),
          end_time: moment(data.check_out_date, 'YYYY-MM-DD').add(12, 'hours').valueOf(),
          price: '0',
          status: 'Concluded',
          is_blocking: true,
          blocking_reason: data?.blocking_reason,
          is_last_minute: false,
          is_block_for_pricing: false,
        };

        setItems((prev: any) => [...prev, transformedItems]);
        setLoad(false);
        onClose();
        toast.success('Bloqueio realizado com sucesso!');
      } catch (e: any) {
        setLoad(false);
        if ([`${e?.error}`, `${e?.message}`].includes('property has extra day preparation')) {
          toast.error('Não é possível inserir um bloqueio neste período, pois o imóvel já está reservado para preparo de reserva.');
        } else if (e instanceof Error) {
          toastErrorRequest(e);
        } else {
          toast.error('Erro ao realizar bloqueio');
        }
      }
    },
  });

  const dailyQuantity = () => {
    const form = formikBlock;
    const begin = moment(form.values.checkInDate);
    const end = moment(form.values.checkOutDate);
    const duration = moment.duration(end.diff(begin));

    return Math.round(duration.asDays());
  };

  return (
    <FormContainer
      onContextMenu={(event) : void => {
        event.stopPropagation();
      }}
    >
      <form
        onSubmit={formikBlock.handleSubmit}
        onChange={formikBlock.handleChange}
        data-cy="form-create-reservation"
      >
        <div>
          <BoldText>*Datas selecionadas</BoldText>
          <DataGrid>
            <DatePickerRange
              hasInitialDates
              formik={formikBlock}
              id1="checkInDate"
              id2="checkOutDate"
              minDate={new Date('2000-01-02')}
              showingLabel={false}
            />
          </DataGrid>
          <InformationDiarias>
            <MidlleText>
              1 anúncio selecionado
            </MidlleText>
            <MidlleText>
              {`${dailyQuantity()} `}
              {dailyQuantity() === 1 ? 'diária selecionada' : 'diárias selecionadas'}

            </MidlleText>
          </InformationDiarias>

          <>
            <Reason>
              <FormGridRow>
                <SimpleSelect
                  id="reason"
                  dataCy="reason"
                  placeholder="Selecione"
                  label="Motivo do bloqueio"
                  formik={formikBlock}
                  options={
                      (reasonData || []).map<SelectOption>(({ id, name }) => ({
                        value: id,
                        valueLabel: name,
                      }))
                    }
                />
              </FormGridRow>
            </Reason>
          </>
          <Notes>
            <FormGridRow>
              <TextField
                label="Notas"
                formik={formikBlock}
                id="notes"
                dataCy="notes"
                type="textarea"
                placeholder={!isReservation ? 'Adicione alguma observação sobre o bloqueio dessas datas' : ' '}
              />
            </FormGridRow>
          </Notes>
        </div>
        <SaveButtonContainer>
          <FormButton
            type="submit"
            dataCy="btn-save"
          >
            Salvar
          </FormButton>
        </SaveButtonContainer>
      </form>
    </FormContainer>
  );
};

export default BlockReservationForm;
