import { OwnerContact } from '../../../services/Owner/types';

import ButtonAdd from '../../ButtonAdd';
import CardPhone from '../CardPhone';

import { Container, Content } from './styles';

interface IContacts {
  isViewMode: boolean,
  listContact: Array<OwnerContact>,
  getListOwnerContact: () => Promise<void>,
  handleAddCardContact: () => void,
}

const Contacts = ({
  isViewMode,
  listContact = [],
  getListOwnerContact,
  handleAddCardContact,
}: IContacts) => (
  <Container>
    <h2>Telefones para contato</h2>
    <Content className="column">
      <section>
        {listContact?.map((item) => (
          <CardPhone key={item.id} contact={item} getListOwnerContact={getListOwnerContact} />
        ))}
      </section>
      {!isViewMode && (
        <ButtonAdd
          testid="add-contact-phone"
          onClick={() => handleAddCardContact()}
          text="Adicionar novo contato"
          dataCy="add-contact-phone"
        />
      )}
    </Content>
  </Container>
);

export default Contacts;
