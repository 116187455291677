import React, { useEffect, useState } from 'react';
import SimpleSelect, { ISelectProps, SelectOption } from './SimpleSelect';
import { State } from '../../services/Address/types';
import { getStates } from '../../services/Address/request';

type Props = Omit<ISelectProps, 'options' | 'label'> & {
  label?: string;
  labelClassName?: string;
};

const StateSelect: React.FC<Props> = ({
  id,
  label = 'UF',
  placeholder = 'Selecione',
  disabled = false,
  formik,
  required,
  disableRequireSymbol = false,
  labelClassName,
}) => {
  const [state, setState] = useState<SelectOption[]>([]);
  const getData = async () => {
    const states = await getStates();
    setState(states.map<SelectOption>((item: State) => ({
      value: item.acronym,
      valueLabel: item.acronym,
    })));
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <SimpleSelect
      label={label}
      id={id}
      placeholder={placeholder}
      options={state}
      formik={formik}
      disabled={disabled}
      required={required}
      disableRequireSymbol={disableRequireSymbol}
      labelClassName={labelClassName}
    />
  );
};

export default StateSelect;
