import styled, { keyframes } from 'styled-components';

import { EnumStatus, StatusProps, EnumStatusColors } from '../../types';

import { gridTemplateColumnsTedListDesktop } from '../../utils';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Circle = styled.div<{
  status: StatusProps;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  background-color: ${({ status }) => {
    switch (status) {
      case EnumStatus.done:
        return EnumStatusColors.done;

      case EnumStatus.notDone:
        return EnumStatusColors.notDone;

      case EnumStatus.withAdjustments:
        return EnumStatusColors.withAdjustments;

      case EnumStatus.exception:
        return EnumStatusColors.exception;

      default:
        return EnumStatusColors.notDone;
    }
  }};
`;

export const Container = styled.div`
  width: max-content;
  margin-top: 10px;

  opacity: 0;
  animation: ${showItems} 0.4s 0.1s ease-in-out forwards;

  &:nth-child(2n) {
    opacity: 0;
    animation: ${showItems} 0.4s 0.2s ease-in-out forwards;
  }
`;

export const Title = styled.h1<{
  pointer?: boolean;
}>`
  gap: 10px;
  display: flex;
  align-items: center;

  font-size: 1.1rem;
  font-weight: normal;
  cursor: ${({ pointer }) => pointer && 'pointer'};
  font-family: ${({ theme }) => theme.fonts.familys._1};

  &:hover {
    .copy-icon {
      transform: ${({ pointer }) => pointer && 'translateY(-5px)'};
    }
  }

  .copy-icon {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: translateY(-5px);
    }
  }
`;

export const TitleReservationCode = styled.h1`
  font-size: 1.1rem;
  width: fit-content;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const ButtonContainer = styled.div`
  button {
    border: 2px solid ${({ theme }) => theme.palette.blue._850.hex()};
    border-radius: 5px;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.palette.blue._850.hex()};
  }
`;

export const LeftItemContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${gridTemplateColumnsTedListDesktop};
`;

export const ItemContainer = styled.div<{ status: StatusProps }>`
  display: flex;
  border-radius: 7px;
  padding: 15px 10px;
  border: 1px solid transparent;
  position: relative;
  transition: 0.2s;
  padding: 15px 10px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, status }) => (status === 'Exception'
    ? theme.palette.blue._30.hex() : theme.palette.white._350.hex())
};

  opacity: 0;
  animation: ${showItems} 0.4s 0s ease forwards;

  :hover {
    border: 1px solid #00000029;
  }
`;

export const EditButton = styled.button`
  background: #f1f1f6;
  border: none;
  height: 100%;
  width: 60px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transition: 0.3s;

  @media (max-width: 1400px) {
    width: 5%;
  }

  &:disabled {
    background: ${({ theme }) => theme.palette.grey._650.hex()};
  }

  img {
    transition: 0.2s;
  }
  :hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    img {
      transform: translateY(-3px);
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px 0 0;
`;

export const ReservationCode = styled.div``;

export const ImmobileCode = styled.div``;

export const ReservationDate = styled.div``;

export const ReservationStaff = styled.div``;

export const WhatsApp = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  img {
    width: 15px;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;

    :hover {
      transform: translateY(-3px);
    }
  }
`;

export const ContainerWithRedText = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row;

  img {
    width: 15px;
    margin: 0px;
    cursor: pointer;
    transition: 0.2s;

    :hover {
      transform: translateY(-3px);
    }
  }

  p {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: red;
  }
`;

export const PendingValue = styled.div`
  h1 {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.red._550.hex()};
  }
`;

export const TotalValue = styled.div``;

export const CheckBoxContainer = styled.div``;

export const RadioButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;
