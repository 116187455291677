import React, { useEffect, useState } from 'react';
import SimpleSelect, { ISelectProps, SelectOption } from './SimpleSelect';
import { Country } from '../../services/Address/types';
import { getCountry } from '../../services/Address/request';

type Props = Omit<ISelectProps, 'options' | 'label'> & {
  label?: string;
  labelClassName?: string;
  valueIsFullName?: boolean;
};

const CountrySelect: React.FC<Props> = ({
  id,
  label = 'País',
  placeholder = 'Selecione',
  disabled = false,
  formik,
  labelClassName,
  valueIsFullName = false,
  required,
}) => {
  const [state, setState] = useState<SelectOption[]>([]);
  const getData = async () => {
    const states = await getCountry();
    if (valueIsFullName) {
      setState(states.map<SelectOption>((item: Country) => ({
        value: item.name,
        valueLabel: item.name,
      })));
    } else {
      setState(states.map<SelectOption>((item: Country) => ({
        value: item.acronym,
        valueLabel: item.name,
      })));
    }
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <SimpleSelect
      label={label}
      id={id}
      placeholder={placeholder}
      options={state}
      formik={formik}
      disabled={disabled}
      required={required}
      labelClassName={labelClassName}
      defaultSelected="BR"
    />
  );
};

export default CountrySelect;
