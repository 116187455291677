/* eslint-disable no-nested-ternary */
import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  Fragment,
  memo,
  KeyboardEvent,
} from 'react';

import { useFormik } from 'formik';
import { useInView } from 'react-intersection-observer';

import theme from '../../../../styles/themes';
import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';
import arrowBottomWhite from '../../../../assets/icons/generals/arrowBottomWhite.svg';
import arrowTopWhite from '../../../../assets/icons/generals/arrowTopWhite.svg';
import addIcon from '../../../../assets/icons/financialClose/add.svg';

import {
  Container,
  Content,
  ContainerTable,
  Table,
  TableHeader,
  TableBody,
  Tr, Th, Td,
  ThContainer,
  TdContainer,
  TitleContainer,
  Title,
  ContainerArrows,
  Image,
  MoreVertButtonContainer,
  MoreVertButton,
  MoreVertIcon,
  RadioButton,
  ExpandIcon,
  CollapseIcon,
  KeyboardArrowUpIcon,
  WrapperGridRow,
  ContainerGridRow,
  CollapseRow,
  manualFitColor,
  ContainerInformation,
  ContentInformation,
  TextFieldContainer,
  PencilIcon,
  CheckButton,
  CheckIcon,
  CancelButton,
  CancelIcon,
  ContainerSpinner,
  Spinner,
  DropdownContainer,
  TooltipContainer,
} from '../styles';

import SimpleSelect from '../../../SimpleSelect';
import Tooltip from '../../../Tooltip';
import TooltipInfo from '../../Tooltip';

import { useFinancialClose } from '../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseHost } from '../../../../hooks/FinancialCloseHook/useFinancialCloseHost';

import { useToast } from '../../../../context/ToastContext';
import useKeys from '../../../../hooks/GlobalHook/useKeys';

import {
  GridHostProps,
  ReservationProps,
  ExpenseProps,
  OrderGridOptions,
  GridHostDetailsProps,
} from '../../../../context/FinancialClosePage/FinancialCloseHostContext/types';

import { sortFinancialDataOfHost } from '../../../../context/FinancialClosePage/FinancialCloseHostContext/utils';
import { translatedPaidBy } from '../../../../utils/Translator';

import { ExpensePatchProps } from '../../../../services/Expenses/types';
import { patchExpenses } from '../../../../services/Expenses/request';
import { getFinancialCloseHostDetails } from '../../../../services/FinancialClose/Host/request';
import { formatDate } from '../../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { isBeforeOctober2022 } from '../../../../services/FinancialClose/utils';
import { useLoader } from '../../../../context/LoaderContext';

type EditTextFieldProps = {
  id: number;
  isEdit: boolean;
};

const GridHost: FC = () => {
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);
  const { setLoad } = useLoader();

  useEffect(() => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    openActions,
    handleOpenActions,
    actionSelected,
    activeLinearProgress,
    dateSelected,
  } = useFinancialClose();

  const {
    financialDataHost,
    handleSetFinancialDataHost,
    handleUpdateExpenseHost,
    handleUpdateCheckedOfHostSelected,
    handleUpdateAllCheckedsOfHostSelected,
    handleUpdateExpandedOfHostSelected,
    handleAddReservationsAndExpensesHost,
    handleOpenModalAddManualFitHost,
    handleOpenModalEditManualFitHost,
    handleOpenModalFranchseFeeHost,
  } = useFinancialCloseHost();

  const hostsSelected = useMemo(() => financialDataHost
    .filter((item) => item.checked), [financialDataHost]);

  const [startDate] = useState<string>(formatDate(dateSelected).start_date);

  const [editExpensePaidBy, setEditExpensePaidBy] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'host',
    order: 'asc',
  });

  const DEFAULT_LIMIT = 15;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataHost.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataHost.length);

  const [isLoadMoreDatas, setIsLoadMoreDatas] = useState<boolean>(false);

  const { ref, inView } = useInView();

  const toast = useToast();

  const shorcutESC = useKeys('Escape');

  const formik = useFormik({
    initialValues: {
      paid_by: '',
    },
    onSubmit: () => {},
  });

  const handleEditFieldOfExpense = (fieldClicked: string, expense: ExpenseProps) => {
    const editing: EditTextFieldProps = {
      id: expense.id,
      isEdit: true,
    };

    if (fieldClicked === 'paid_by') {
      formik.setFieldValue('paid_by', expense.paid_by);
      setEditExpensePaidBy({ ...editing });
    }
  };

  const handleResetFieldOfExpenseEdited = (fieldToUpdate: string) => {
    if (fieldToUpdate === 'paid_by') {
      setEditExpensePaidBy({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('paid_by', '');
    }
  };

  const handleUpdateExpenseState = (fieldUpdated: string, currentExpense: ExpenseProps) => {
    let expenseUpdated: ExpenseProps = {
      ...currentExpense,
    };

    if (fieldUpdated === 'paid_by') {
      expenseUpdated = {
        ...expenseUpdated,
        paid_by: translatedPaidBy[formik.values.paid_by],
      };
    }

    handleUpdateExpenseHost({ ...expenseUpdated });
  };

  const handlePatchExpense = async (fieldToUpdate: string, expense: ExpenseProps) => {
    try {
      let dataUpdated: ExpensePatchProps = {};

      if (fieldToUpdate === 'paid_by') {
        dataUpdated = {
          ...dataUpdated,
          paid_by: formik.values.paid_by,
        };
      }

      await patchExpenses(expense.id, dataUpdated);

      handleUpdateExpenseState(fieldToUpdate, expense);
      handleResetFieldOfExpenseEdited(fieldToUpdate);

      toast.success('Despesa atualizada com sucesso!');
    } catch (err) {
      toast.error('Não foi possível atualizar o valor!');
    }
  };

  const handleSortFinancialCloseData = () => {
    let response: GridHostProps[] = financialDataHost;

    if (orderGridBy.order_by === 'host') {
      response = sortFinancialDataOfHost(
        financialDataHost, orderGridBy.order_by, orderGridBy.order,
      );
    } else {
      response = sortFinancialDataOfHost(
        financialDataHost,
        orderGridBy.order_by,
        orderGridBy.order,
        financialDataHost.filter((item) => item.expanded).map((item) => item.id),
      );
    }
    return response;
  };

  useEffect(() => {
    const response = handleSortFinancialCloseData();
    handleSetFinancialDataHost([...response]);
  }, [orderGridBy]);

  const handleUpdateChecked = (host: GridHostProps) => {
    handleUpdateCheckedOfHostSelected(host.id);
  };

  type OnClickEventAction = 'open' | 'close';
  const handleUpdateExpandedRows = async (item: GridHostProps, action: OnClickEventAction) => {
    setLoad(true);

    if (action === 'open') {
      const response: GridHostDetailsProps = await getFinancialCloseHostDetails({
        revenueIds: item?.revenue_ids,
        hostId: item.host.id,
        startDate,
      });

      handleAddReservationsAndExpensesHost(item.id, response);
    }
    handleUpdateExpandedOfHostSelected(item.id);
    setLoad(false);
  };

  const handleClickMoreVertButton = (host: GridHostProps) => {
    handleOpenActions(!openActions);
    handleUpdateAllCheckedsOfHostSelected(false);
    handleUpdateCheckedOfHostSelected(host.id, true);
  };

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataHost.length > 0
      && limitFinancialData >= financialDataHost.length
      ? financialDataHost.length : limitFinancialData + DEFAULT_LIMIT);
  };

  const checkIfAllHostsIsSelected = () => {
    const totalSelected = financialDataHost.filter((item) => item.checked).length;
    return totalSelected === financialDataHost.length;
  };

  const handleCheckAll = () => {
    const allIsChecked: boolean = checkIfAllHostsIsSelected();
    handleUpdateAllCheckedsOfHostSelected(!allIsChecked);
  };

  useEffect(() => {
    if (inView) {
      setIsLoadMoreDatas(true);

      setTimeout(() => {
        handleLoadNext();
      }, 300);
    }
  }, [inView]);

  const handleShorcutESCPressed = () => {
    if (shorcutESC) {
      handleResetFieldOfExpenseEdited('paid_by');
    }
  };

  useEffect(() => {
    handleShorcutESCPressed();
  }, [shorcutESC]);

  const handleShorcutEnterPressedExpense = (
    e: KeyboardEvent<HTMLInputElement>,
    field: string,
    expense: ExpenseProps,
  ) => {
    if (e.key === 'Enter') {
      handlePatchExpense(field, expense);
    }
  };

  const shouldHideColumn = () => {
    const [year, month] = startDate.split('-');
    const hide = isBeforeOctober2022(`${year}-${month}-01`);
    return !hide;
  };

  const itemIsSelectedInGrid = () => {
    if (hostsSelected.length === 0) {
      toast.error('Você deve selecionar um anfitrião para adicionar um ajuste');
      return false;
    }
    return true;
  };

  const openAddManualFitModal = () => {
    if (itemIsSelectedInGrid()) {
      handleOpenModalAddManualFitHost(true);
    }
  };

  const openEditManualFitModal = (host: GridHostProps) => {
    if (host.manual_fit === 0 as number) {
      toast.error('Primeiro adicione um ajuste clicando em "Ajuste Direto Anfitrião +"');
      return;
    }

    handleUpdateAllCheckedsOfHostSelected(false);
    handleUpdateCheckedOfHostSelected(host.id, true);
    handleOpenModalEditManualFitHost(true);
  };

  const openFranchiseFeeModal = (host: GridHostProps) => {
    handleUpdateAllCheckedsOfHostSelected(false);
    handleUpdateCheckedOfHostSelected(host.id, true);
    handleOpenModalFranchseFeeHost(true);
  };

  return (
    <Container>
      <Content
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <ContainerTable>
          <Table>

            {/* Header Main Grid */}
            <TableHeader>
              <Tr>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                    maxWidth={widthWindow < 1440 ? 34 : 60}
                  >
                    <Tooltip text="Selecionar todos">
                      <RadioButton
                        data-cy="radio-button-select-all"
                        type="radio"
                        id={'radio'}
                        name={'radio'}
                        checked={checkIfAllHostsIsSelected()}
                        onClick={() => handleCheckAll()}
                      />
                    </Tooltip>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={180}
                  >
                    <TitleContainer>
                      <Title data-cy="title-host" disableWidth>Anfitrião</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar anfitriões (Z-A)' : 'Ordenar anfitriões (A-Z)'}`}>
                        <ContainerArrows onClick={() => setOrderGridBy({
                          order_by: 'host',
                          order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                        })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-amount">Número de Reservas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-income">Receita de Reservas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-commission-property">Comissão Total Imóvel</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-commission">Comissão Anfitrião</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-cleanings-amount">Número de Limpezas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-cleanings-income">Receita de Limpezas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <>
                      <TitleContainer>
                        <Title data-cy="title-expenses">Despesas</Title>
                      </TitleContainer>
                      <TitleContainer />
                    </>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-onboarding">Onboarding</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                  hidden={shouldHideColumn()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <>
                      <TitleContainer>
                        <Title data-cy="title-royalties">Royalties</Title>
                      </TitleContainer>
                      <TitleContainer />
                    </>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title isInputData data-testid="text-manualfit-host" data-cy="title-adjustments_directs">Ajuste Direto Anfitrião</Title>
                      <Image
                        data-cy="btn-add-manualfit-host"
                        data-testid="btn-add-manualfit-host"
                        src={addIcon}
                        alt="ícone adicionar ajuste"
                        style={{ cursor: 'pointer' }}
                        onClick={() => openAddManualFitModal()}
                      />
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title isInputData data-testid="text-franchise-fee" data-cy="title-franchise-fee">Taxa de franquia</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-transfer">Repasse</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                  />
                </Th>
              </Tr>
            </TableHeader>

            {/* Body Main Grid */}
            <TableBody>
              { financialDataHost.length > 0 && financialDataHost
                .slice(0, limitFinancialData).map((item) => (
                  <Fragment key={item.id}>
                    <Tr data-cy="grid-row-host" key={item.id}>
                      <Td customColor={item.status}>
                        <TdContainer
                          disableBorder
                          disableBorderRadius
                          minWidth={20}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <RadioButton
                            data-cy={`radio-button-select-host-${item.id}`}
                            type="radio"
                            id={`radio_${item.id}`}
                            name={`radio_${item.id}`}
                            checked={item.checked}
                            onClick={() => handleUpdateChecked(item)}
                          />
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          maxWidth={500}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title data-cy={`host-${item.id}-name`} disableWidth>{item.host.name}</Title>
                            <TooltipContainer>
                              <TooltipInfo text={`Comissão: ${item.host.reservation_commission_fee}`} />
                              {item.expanded ? (
                                <Tooltip text="Recolher">
                                  <CollapseIcon
                                    data-cy={`btn-collapse-grid-row-${item.id}`}
                                    onClick={() => handleUpdateExpandedRows(item, 'close')}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip text="Expandir">
                                  <ExpandIcon
                                    data-cy={`btn-expand-grid-row-${item.id}`}
                                    onClick={() => handleUpdateExpandedRows(item, 'open')}
                                  />
                                </Tooltip>
                              )}
                            </TooltipContainer>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.reserves.number}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.reserves.income}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>-</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.commission}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.cleanings.number}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.cleanings.income}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.expense_value}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {item.onboarding}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td
                        customColor={item.status}
                        hidden={shouldHideColumn()}
                      >
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.royalties}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title
                              data-testid="text-manualfit-host-total-value"
                              className={`manualfit-total-value-color-is-${item.host_manual_fit.customColor}`}
                              customColor={manualFitColor[item.host_manual_fit.customColor]}
                            >
                              {item.host_manual_fit.value}
                            </Title>
                            <Tooltip text="Editar">
                              <PencilIcon
                                data-cy="btn-edit-manualfit-host"
                                data-testid="btn-edit-manualfit-host"
                                onClick={() => openEditManualFitModal(item)}
                              />
                            </Tooltip>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title
                              data-testid="text-franchise-fee"
                            >
                              {item.host.franchise_fee}
                            </Title>
                            <Tooltip text="Editar">
                              <PencilIcon
                                data-cy="btn-edit-franchise-fee"
                                data-testid="btn-edit-franchise-fee"
                                onClick={() => openFranchiseFeeModal(item)}
                              />
                            </Tooltip>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {item.transfer}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <MoreVertButtonContainer>
                          <MoreVertButton
                            data-cy={`btn-more-options-${item.id}`}
                            type="button"
                            onClick={() => handleClickMoreVertButton(item)}
                          >
                            <MoreVertIcon />
                          </MoreVertButton>
                        </MoreVertButtonContainer>
                      </Td>
                    </Tr>

                    {item.expanded && (
                      <Tr>
                        <Td
                          colSpan={100}
                          customColor={theme.palette.white._350.hex()}
                          className="td-secondary-grid-fixed-top"
                        >

                          {/* Reservations Grid */}
                          <WrapperGridRow>
                            <ContainerGridRow>
                              <Title className="reservation-list" data-cy="title-reservations-list">Lista de imóveis</Title>
                              <ContainerTable className="reservation-table">
                                <Table>

                                  {/* Header Reservations Grid */}
                                  <TableHeader>
                                    <Tr>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-property-code" disableWidth>Imóvel</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar imóveis (Z-A)' : 'Ordenar imóveis (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_property_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-amount" disableWidth>N° de Reservas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar N° de Reservas (Z-A)' : 'Ordenar N° de Reservas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_number',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-income" disableWidth>Receita Reservas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Receita Reservas (Z-A)' : 'Ordenar Receita Reservas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_incomes',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-cleanings-amount" disableWidth>N° de Limpezas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar N° de Limpezas (Z-A)' : 'Ordenar N° de Limpezas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_cleanings_number',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-cleanings-income" disableWidth>Receita Limpezas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Receita Limpezas (Z-A)' : 'Ordenar Receita Limpezas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_cleanings_incomes',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-commission" disableWidth>{shouldHideColumn() ? 'Comissão' : 'Comissão (legado)'}</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Comissão (Z-A)' : 'Ordenar Comissão (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_commission',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-onboarding-commission" disableWidth>Onboarding</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Comissão Onboarding (Z-A)' : 'Ordenar Comissão Onboarding (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_onboarding_commission',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-adjustment-property" isInputData disableWidth>Ajuste Direto Anfitrião</Title>
                                            <TooltipInfo text="Ajustes relacionados a imóvel" />
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Ajuste Direto Anfitrião (Z-A)' : 'Ordenar Ajuste Direto Anfitrião (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_manualfit_property',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-transfer" disableWidth>Repasse</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Repasse (Z-A)' : 'Ordenar Repasse (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_transfer_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-transfer-future" disableWidth>Repasse Futuro</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Repasse Futuro (Z-A)' : 'Ordenar Repasse Futuro (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_transfer_future',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={1000}
                                        />
                                      </Th>
                                    </Tr>
                                  </TableHeader>

                                  {/* Body Reservations Grid */}
                                  <TableBody>
                                    {item.reservations.length === 0
                                      ? (
                                        <ContainerInformation>
                                          <ContentInformation>
                                            <Title>Nenhuma reserva foi encontrada!</Title>
                                          </ContentInformation>
                                        </ContainerInformation>
                                      ) : item.reservations.map((reservation: ReservationProps) => (
                                        <Tr key={reservation.id}>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.property.code}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.reserves.number}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.reserves.income}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.cleanings.number}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.cleanings.income}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.commission}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.onboarding}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title
                                                  className={
                                                  `property-manualfit-total-value-color-is-
                                                  ${reservation.property_manual_fit?.customColor}`
}
                                                  customColor={manualFitColor[reservation
                                                    ?.property_manual_fit?.customColor || '']}
                                                  disableWidth
                                                >
                                                  {reservation?.property_manual_fit?.value}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              minWidth={150}
                                              customColor={theme.palette.grey._310.hex()}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation.transfer.transfer_value}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              minWidth={150}
                                              customColor={theme.palette.grey._310.hex()}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {reservation
                                                    .transfer.transfer_future}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              disableBorder
                                              minWidth={1000}
                                              customColor={theme.palette.grey._330.hex()}
                                            />
                                          </Td>
                                        </Tr>
                                      ))}
                                  </TableBody>
                                </Table>
                              </ContainerTable>
                            </ContainerGridRow>
                          </WrapperGridRow>

                          {/* Expenses Grid */}
                          <WrapperGridRow>
                            <ContainerGridRow
                              customColor={theme.palette.grey._315.hex()}
                            >
                              <Title className="expense-list" data-cy="title-expenses-list">Lista de despesas</Title>
                              <ContainerTable className="expense-table">
                                <Table>

                                  {/* Header Expenses Grid */}
                                  <TableHeader>
                                    <Tr>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-property-code" disableWidth>Imóvel</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Imóvel (Z-A)' : 'Ordenar Imóvel (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_property_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-code" disableWidth>Despesa</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Despesa (Z-A)' : 'Ordenar Despesa (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-register-date" disableWidth>Data de inserção</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Data de Inserção (Z-A)' : 'Ordenar Data de Inserção (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_register_date',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-date" disableWidth>Data da despesa</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Data da Despesa (Z-A)' : 'Ordenar Data da Despesa (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_date',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-category" disableWidth>Categoria</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Categoria (Z-A)' : 'Ordenar Categoria (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_category',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-description" disableWidth>Descrição</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Descrição (Z-A)' : 'Ordenar Descrição (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_description',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-value" disableWidth>Valor</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor da Despesa (Z-A)' : 'Ordenar Valor da Despesa (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-owner-approved" disableWidth>Pedido autorização</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Pedido Autorização (Z-A)' : 'Ordenar Pedido Autorização (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_owner_approved',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-expense-status" disableWidth>Status</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Status (Z-A)' : 'Ordenar Status (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_status',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Pago por</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Pago Por (Z-A)' : 'Ordenar Pago Por (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_paid_by',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={1000}
                                        />
                                      </Th>
                                    </Tr>
                                  </TableHeader>

                                  {/* Body Expenses Grid */}
                                  <TableBody>
                                    {item.expenses.length === 0
                                      ? (
                                        <ContainerInformation>
                                          <ContentInformation>
                                            <Title>Nenhuma despesa foi encontrada!</Title>
                                          </ContentInformation>
                                        </ContainerInformation>
                                      ) : item.expenses.map((expense: ExpenseProps) => (
                                        <Tr key={expense.id}>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.property_code}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.code}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.register_date}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.expense_date}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.category}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.description}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.value}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.owner_approved ? 'Sim' : 'Não'}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.status}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editExpensePaidBy.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-paid_by" disableWidth>
                                                    {expense.paid_by}
                                                  </Title>
                                                  <Tooltip text="Editar">
                                                    <PencilIcon
                                                      data-cy="btn-edit-paid_by"
                                                      onClick={() => handleEditFieldOfExpense('paid_by', expense)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editExpensePaidBy.id === expense.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-edit-paid_by"
                                                        onClick={() => setEditExpensePaidBy({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>

                                                    <DropdownContainer>
                                                      <SimpleSelect
                                                        dataCy="input-paid_by"
                                                        id="paid_by"
                                                        placeholder="Selecione"
                                                        required
                                                        formik={formik}
                                                        options={[
                                                          { value: 'Host', valueLabel: 'Anfitrião' },
                                                          { value: 'Owner', valueLabel: 'Proprietário' },
                                                          { value: 'Guest', valueLabel: 'Hóspede' },
                                                          { value: 'Seazone', valueLabel: 'Seazone' },
                                                        ]}
                                                        onKeyPress={
                                                          (e: KeyboardEvent<HTMLInputElement>) => {
                                                            handleShorcutEnterPressedExpense(
                                                              e, 'paid_by', expense,
                                                            );
                                                          }
                                                        }
                                                      />
                                                    </DropdownContainer>

                                                    <Tooltip text="Salvar">
                                                      <CheckButton
                                                        data-cy="btn-save-paid_by"
                                                        type="submit"
                                                        onClick={() => handlePatchExpense('paid_by', expense)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-paid_by" disableWidth>
                                                    {expense.paid_by}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-paid_by"
                                                      onClick={() => handleEditFieldOfExpense('paid_by', expense)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              disableBorder
                                              minWidth={1000}
                                              customColor={theme.palette.grey._315.hex()}
                                            />
                                          </Td>
                                        </Tr>
                                      ))}
                                  </TableBody>
                                </Table>
                              </ContainerTable>
                              <CollapseRow>
                                <KeyboardArrowUpIcon
                                  onClick={() => handleUpdateExpandedRows(item, 'close')}
                                />
                              </CollapseRow>
                            </ContainerGridRow>
                          </WrapperGridRow>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
            </TableBody>
          </Table>

          {financialDataHost && financialDataHost?.length > DEFAULT_LIMIT
            && financialDataHost?.length > limitFinancialData && (
            <ContainerSpinner ref={ref}>
              <Spinner isLoading={isLoadMoreDatas} />
            </ContainerSpinner>
          )}
        </ContainerTable>

      </Content>
    </Container>
  );
};

export default memo(GridHost);
