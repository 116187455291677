import { Moment } from 'moment';
import { useQuery } from 'react-query';
import { getControllerPageData } from '../../services/Control/request';
import { RequestEvent, TypeProps } from '../../services/Control/types';

const getCheckinDates = async (
  initial: string,
  final: string,
  type: TypeProps,
) => {
  let newResponseDataCheckin: RequestEvent[];
  try {
    const response = await getControllerPageData(
      initial, final, type,
    );

    newResponseDataCheckin = response.map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
      address: item.address,
      city: item.city,
      warm_bed: item.warm_bed,
      total_price: item.total_price,
      ota: item.ota,
      check_in_date: item.check_in_date,
      date: item.check_in_date,
      check_out_date: item.check_out_date,
      adult_guest_quantity: item.adult_guest_quantity,
      is_paid: item.is_paid,
      has_pet: item.has_pet,
      property_code: item.property_code,
      reservation: item?.check_in?.reservation || item.id,
      status: item?.check_in?.status || '',
      was_contacted: item?.check_in?.was_contacted || false,
      card_concluded: item?.check_in?.card_concluded || false,
      check_out_time: item?.check_in?.check_in_time || '',
      check_in_time: item?.check_in?.check_in_time || '',
      hour: item.check_in?.check_in_time || '',
      cleaning_date: item?.cleaning?.cleaning_date || '',
      cleaning_time: item?.cleaning?.cleaning_time || '',
      type: item?.type || 'checkin',
      checklist: item?.check_in?.checklist,
      code: item.code,
      guest_id: item?.guest_id || '-1',
      blocking_reason: item?.blocking_reason || '',
      is_blocking: item?.is_blocking || false,
      child_guest_quantity: item.child_guest_quantity || 0,
      baby_guest_quantity: item.baby_guest_quantity || 0,
      is_pre_checkin_completed: item.is_pre_checkin_completed || false,
      is_pre_checkin_link_sent: item.is_pre_checkin_link_sent || false,
      pre_checkin_fullfilled_at: item.pre_checkin_fullfilled_at,
      link_sent_at: item.link_sent_at,
      property_type: item.property_type || '',
    }));
    return newResponseDataCheckin;
  } catch (error) {
    newResponseDataCheckin = [];
    return newResponseDataCheckin;
  }
};

const getCheckoutDates = async (
  initial: string,
  final: string,
  type: TypeProps,
) => {
  let newResponseDataCheckout: RequestEvent[];
  try {
    const response = await getControllerPageData(
      initial, final, type,
    );

    newResponseDataCheckout = response.map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
      address: item.address,
      city: item.city,
      warm_bed: item.warm_bed,
      total_price: item.total_price,
      ota: item.ota,
      check_in_date: item.check_in_date,
      check_out_date: item.check_out_date,
      date: item.check_out_date,
      adult_guest_quantity: item.adult_guest_quantity,
      is_paid: item.is_paid,
      has_pet: item.has_pet,
      property_code: item.property_code,
      reservation: item?.check_out?.reservation || item.id,
      status: item?.check_out?.status || '',
      was_contacted: item?.check_out?.was_contacted || false,
      card_concluded: item?.check_out?.card_concluded || false,
      check_out_time: item?.check_out?.check_out_time || '',
      hour: item.check_out?.check_out_time || '',
      check_in_time: item?.check_in?.check_in_time || '',
      cleaning_date: item?.cleaning?.cleaning_date || '',
      cleaning_time: item?.cleaning?.cleaning_time || '',
      type: item?.type || 'checkout',
      checklist: item?.check_out?.checklist,
      code: item.code,
      child_guest_quantity: item.child_guest_quantity || 0,
      baby_guest_quantity: item.baby_guest_quantity || 0,
      guest_id: item?.guest_id || '-1',
      is_pre_checkin_completed: item.is_pre_checkin_completed || false,
      is_pre_checkin_link_sent: item.is_pre_checkin_link_sent || false,
      pre_checkin_fullfilled_at: item.pre_checkin_fullfilled_at,
      link_sent_at: item.link_sent_at,
      property_type: item.property_type || '',
    }));

    return newResponseDataCheckout;
  } catch (error) {
    newResponseDataCheckout = [];
    return newResponseDataCheckout;
  }
};

const getCleaningDates = async (
  initial: string,
  final: string,
  type: TypeProps,
) => {
  let newResponseDataCleaning: RequestEvent[];
  try {
    const response = await getControllerPageData(
      initial, final, type,
    );

    newResponseDataCleaning = response.map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
      address: item.address,
      city: item.city,
      warm_bed: item.warm_bed,
      total_price: item.total_price,
      ota: item.ota,
      check_in_date: item.check_in_date,
      check_out_date: item.check_out_date,
      adult_guest_quantity: item.adult_guest_quantity,
      is_paid: item.is_paid,
      has_pet: item.has_pet,
      property_code: item.property_code,

      reservation: item?.cleaning?.reservation || item.id,
      status: item?.cleaning?.status || '',
      card_concluded: item?.cleaning?.card_concluded || false,
      was_checked: item?.cleaning?.was_checked || false,
      maid_name: item?.cleaning?.maid_name || '',
      bedsheets_quantity: item?.cleaning?.bedsheets_quantity || 0,
      cleaning_price: item?.cleaning?.cleaning_price || 0,
      cleaning_duration: item?.cleaning?.cleaning_duration || '0',

      check_out_time: item?.check_out?.check_out_time || '',
      check_in_time: item?.check_in?.check_in_time || '',

      cleaning_date: item?.cleaning?.cleaning_date || '',
      date: item.check_out_date || '',
      cleaning_time: item?.cleaning?.cleaning_time || '',
      hour: item.cleaning?.cleaning_time || '',
      type: item?.type || 'cleaning',
      checklist: item?.cleaning?.checklist,
      code: item.code,
      child_guest_quantity: item.child_guest_quantity || 0,
      baby_guest_quantity: item.baby_guest_quantity || 0,

      is_pre_checkin_completed: item.is_pre_checkin_completed || false,
      is_pre_checkin_link_sent: item.is_pre_checkin_link_sent || false,
      pre_checkin_fullfilled_at: item.pre_checkin_fullfilled_at,
      link_sent_at: item.link_sent_at,
      property_type: item.property_type || '',
    }));

    return newResponseDataCleaning;
  } catch (error) {
    newResponseDataCleaning = [];
    return newResponseDataCleaning;
  }
};

export function useEventCheckin(dates: Moment[], mobile: boolean) {
  let firstDate = null;
  if (dates.length > 0) {
    firstDate = dates[0].format('YYYY-MM-DD');
  }
  return useQuery(
    ['event-checkin', firstDate], async () => {
      const data: RequestEvent[] = await getCheckinDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'checkin',
      );
      return data;
    }, {
      enabled: dates.length > 0 && firstDate !== null,
      staleTime: 1000 * 60 * 15,
    },
  );
}
export function useEventCheckout(dates: Moment[], mobile: boolean) {
  let firstDate = null;
  if (dates.length > 0) {
    firstDate = dates[0].format('YYYY-MM-DD');
  }
  return useQuery(
    ['event-checkout', firstDate], async () => {
      const data: RequestEvent[] = await getCheckoutDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'checkout',
      );
      return data;
    }, {
      enabled: dates.length > 0 && firstDate !== null,
      staleTime: 1000 * 60 * 15,
    },
  );
}

export function useEventCleaning(dates: Moment[], mobile: boolean) {
  let firstDate = null;
  if (dates.length > 0) {
    firstDate = dates[0].format('YYYY-MM-DD');
  }
  return useQuery(
    ['event-cleaning', firstDate], async () => {
      const data: RequestEvent[] = await getCleaningDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'cleaning',
      );
      return data;
    }, {
      enabled: dates.length > 0 && firstDate !== null,
      staleTime: 1000 * 60 * 15,
    },
  );
}

export function useEventList(dates: Moment[], mobile: boolean) {
  let firstDate = null;
  if (dates.length > 0) {
    firstDate = dates[0].format('YYYY-MM-DD');
  }
  return useQuery(
    ['event-list', firstDate], async () => {
      const checkins: RequestEvent[] = await getCheckinDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'checkin',
      );
      const checkouts: RequestEvent[] = await getCheckoutDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'checkout',
      );
      const cleanings: RequestEvent[] = await getCleaningDates(
        dates[0].format('YYYY-MM-DD'),
        dates[mobile ? 0 : 6].format('YYYY-MM-DD'),
        'cleaning',
      );
      const arrayAux: Array<RequestEvent> = [];

      checkins.forEach((element) => {
        arrayAux.push(element);
      });
      cleanings.forEach((element) => {
        arrayAux.push(element);
      });
      checkouts.forEach((element) => {
        arrayAux.push(element);
      });

      return arrayAux;
    }, {
      enabled: dates.length > 0 && firstDate !== null,
      staleTime: 1000 * 60 * 15,
    },
  );
}
