/* eslint-disable max-len */
import moment from 'moment';

import { OwnerExtractProps, ReservationProps } from '../../../context/OwnerPage/OwnerExtractContext/types';

import {
  initialValuesOwnerExtract,
} from '../../../context/OwnerPage/OwnerExtractContext/utils';
import { translateExpenseReason } from '../../../utils/Translator';
import { numberToCurrency } from '../../../utils/Formatter';

import {
  OwnerExtractResponseProps,
} from './types';

export const calculateTotal = (accumulator: number, curr: number) => accumulator + curr;

const formatValue = (value: number) => {
  if (['0', '-0', 'O.00', '-0.00'].includes(`${value}`)) return '';
  return numberToCurrency(value);
};

export const formatOwnerExtractData = (data: OwnerExtractResponseProps): OwnerExtractProps[] => {
  const financialData: OwnerExtractProps[] = [];

  const dataFormatted: OwnerExtractProps = { ...initialValuesOwnerExtract };

  // Incomes
  const reservations: Array<ReservationProps> = [];
  const otasInformations = data?.income?.revenue?.list_otas || {};

  Object.keys(data?.income?.revenue?.list_otas || []).forEach((ota) => {
    otasInformations?.[ota]?.forEach((item: any) => {
      reservations.push({
        id: reservations.length + 1,
        check_in_date: item?.check_in_date ? moment(item.check_in_date).format('DD/MM/YYYY') : '',
        check_out_date: item?.check_out_date ? moment(item.check_out_date).format('DD/MM/YYYY') : '',
        dailys_value: formatValue(Number(item?.daily_net_value || 0)),
        platform: ota,
      });
    });
  });

  const reservationsFuture: Array<ReservationProps> = [];
  // const otasInformationsFuture = {};

  // Object.keys(data?.income?.future_reservations || []).forEach((ota) => {
  //   otasInformationsFuture?.[ota]?.forEach((item) => {
  //     reservationsFuture.push({
  //       id: reservationsFuture.length + 1,
  //       check_in_date: item?.check_in_date ? moment(item.check_in_date).format('DD/MM/YYYY') : '',
  //       check_out_date: item?.check_out_date ? moment(item.check_out_date).format('DD/MM/YYYY') : '',
  //       dailys_value: formatValue(item?.daily_net_value || 0),
  //       platform: ota,
  //     });
  //   });
  // });

  dataFormatted.incomes = {
    ...dataFormatted.incomes,
    total: formatValue(data?.income?.revenue?.total_revenues || 0),
    reservations: reservations.filter((item) => Number(item.dailys_value) !== 0
      && !(['Website', 'Blocking'].includes(item.platform))),
  };

  dataFormatted.incomes_future = {
    reservations: reservationsFuture.filter((item) => Number(item.dailys_value) !== 0
      && !(['Website', 'Blocking'].includes(item.platform))),
    total: formatValue(0),
  };

  const incomeManualFits = Object.keys(data?.income?.manual_fit?.list_data || {}).length === 0
    ? [] : (data?.income?.manual_fit?.list_data || []);

  dataFormatted.others_incomes = {
    total: formatValue(data?.income?.manual_fit?.total_value || 0),
    manual_fits: incomeManualFits?.map((item, index) => ({
      ...item,
      id: item?.id || index + 1,
      description: item?.description || '',
      value: formatValue(Number(item?.value || 0)),
    })) || [],
  };

  // Expenses
  const expensesPosted = Object.keys(data?.outgoing?.expenses || {}).length === 0
    ? [] : (data?.outgoing?.expenses?.list_data || []);

  dataFormatted.expenses.posted_expenses = expensesPosted.map((item, index) => ({
    id: item?.id || index + 1,
    code: item?.code || '',
    register_date: item?.register_date || '',
    expense_date: item?.expense_date || '',
    category: item?.reason ? translateExpenseReason[item.reason] : '',
    description: item?.description || '',
    value: formatValue(item?.value || 0),
    owner_approved: item?.owner_approved || false,
    status: item?.status || '',
    paid_by: item?.paid_by || '',
  }));

  dataFormatted.expenses.reservations_cleanings = [];
  dataFormatted.expenses.total = formatValue(data?.outgoing?.expenses?.total_value || 0);

  dataFormatted.commission = {
    seazone: formatValue(data?.outgoing?.commission?.total_value || 0),
    total: formatValue(data?.outgoing?.commission?.total_value || 0),
  };

  dataFormatted.implantation_fee = formatValue(data?.outgoing?.implantation_fee || 0);

  const outgoingManualFits = Object.keys(data?.outgoing?.manual_fit?.list_data || {}).length === 0
    ? [] : (data?.outgoing?.manual_fit?.list_data || []);

  dataFormatted.others_expenses = {
    total: formatValue(data?.outgoing?.manual_fit?.total_value || 0),
    manual_fits: outgoingManualFits?.map((item, index) => ({
      ...item,
      id: item?.id || index + 1,
      description: item?.description || '',
      value: formatValue(Number(item?.value || 0)),
    })) || [],
  };

  // Wallet
  dataFormatted.wallet = {
    debit_balance: formatValue(0),
    transfer: formatValue((data?.wallet?.ted?.transfer || 0) * -1),
  };

  financialData.push(dataFormatted);

  return financialData;
};
