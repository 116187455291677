import moment from 'moment';
import { ExpenseResponseProps } from '../../../services/Expenses/types';
import { translateExpenseReason, translateExpenseStatus, translatedPaidBy } from '../../../utils/Translator';
import { numberToCurrency } from '../../../utils/Formatter';

export function formatStringToBRL(value: number) {
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
}

function validateRecord(record: any) {
  if (!record) return '';
  return record;
}

function formatRefund(refund: string) {
  if (!refund) return '-';

  const isDate = moment(refund, 'YYYY-MM-DD', true).isValid();

  if (isDate) {
    return moment(refund).format('DD/MM/YYYY');
  }

  return refund;
}

export function dataMapperExpenseList(list: ExpenseResponseProps[]) {
  if (!list) return [];

  return list?.map((expense) => ({
    id: expense?.id,
    imovel: expense?.property?.code?.toString() || '-',
    registerDate: moment(expense?.register_date).format('DD/MM/YYYY') || '-',
    expenseDate: moment(expense?.expense_date).format('DD/MM/YYYY') || '-',
    approvalDate: expense?.approval_date ? moment(expense?.approval_date).format('DD/MM/YYYY') : 'Pendente',

    reason: expense.reason
      ? validateRecord(translateExpenseReason[expense.reason])
      : '-',

    value: expense?.value
      ? numberToCurrency(expense.value)
      : '-',

    status: expense?.expense_status
      ? validateRecord(translateExpenseStatus[expense.expense_status])
      : '-',

    refund: formatRefund(expense?.refund || '-'),

    paidBy: expense?.paid_by ? translatedPaidBy[expense?.paid_by || ''] : '-',
    options: '',
  }));
}
