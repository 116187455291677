import styled from 'styled-components';

const ZINDEX = 100;

export const Header = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  padding: 50px;
  width: 600px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 30px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${ZINDEX + 1};
  text-align: center;
  align-items: center;
  background: white;
  border-radius: 5px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: none;
  }

  overflow-x: hidden;
`;

export const Title = styled.h1`
  font-size: 2rem;
`;

export const AddVoucherButton = styled.div`
  padding: 15px;
  border: 3px dashed black;
  width: 100%;
  opacity: 0.3;
  border-radius: 15px;
  text-align: center;
  display: flex;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
  flex-wrap: nowrap;

  p {
    font-size: 3rem;
    font-weight: medium;
  }

  h1 {
    font-size: 1.5rem;
  }
`;

export const BackdropAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
`;

export const AnimationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
  h1 {
    font-size: 1.5rem;
    opacity: 0.6;
  }
  
  div {
    width: 70px;
  }
`;

export const VoucherListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: initial;
`;

export const VoucherItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: #ebf0f5;
  border-radius: 5px;
  padding: 10px;
`;

export const NoDataMessage = styled.h1`
  font-size: 1.7rem;
  opacity: 0.5;
  align-self: center;

  display: flex;
  flex-direction: column;
  gap: 3px;

  span {
    font-size: 1rem;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZINDEX};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
`;
