import { FC, useEffect, useState } from 'react';

import SearchIcon from '../../../../assets/icons/generals/search.svg';
import AddOwnerIcon from '../../../../assets/icons/generals/addOwner.svg';

import { getOwners } from '../../../../services/InsertData/request';
import { GetOwnersData } from '../../../../services/InsertData/types';

import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';
import { useToast } from '../../../../context/ToastContext';

import FormButton from '../../../FormButton';
import Tooltip from '../../../Tooltip';

import {
  OwnerDiv,
  SearchBar,
  SearchContainer,
  ButtonDiv,
  ContentContainer,
  ResultsContainer,
  ResultsText,
  NewOwnerButton,
  TitleContainer,
  Border,
  NewOwnerContainer,
  ButtonSection,
  CardOwnerSelected,
  AreaSelected,
} from './styles';

const CardOwner: FC = () => {
  const [listOwner, setListOwner] = useState<Array<GetOwnersData>>([]);
  const [listFiltered, setListFiltered] = useState<Array<GetOwnersData>>([]);
  const { handleClickPhase, setIsOpenModal } = useOnboardingPage();

  const {
    setOwner,
    owner,
    reloadOwners,
    infosPipeDrive,
    idNewOwner,
  } = useOnboardingHandover();

  const toast = useToast();

  async function searchOwnerByName(name: string) {
    return listOwner?.filter((item) => {
      const currentName = (`${item?.user?.first_name} ${item?.user?.last_name}`?.toLowerCase()?.trim() || `${item?.user?.trading_name}`?.toLowerCase()?.trim());
      const typedName = `${name}`?.toLowerCase()?.trim() || '';
      return currentName.includes(typedName);
    });
  }

  async function handleSearchOwner(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    const resultFilter = await searchOwnerByName(formProps.name as string);

    setListFiltered(resultFilter);
  }

  async function getAllOwners() {
    const result = await getOwners();
    setListOwner(result);
    return result;
  }

  useEffect(() => {
    getAllOwners();
  }, []);

  useEffect(() => {
    if (reloadOwners) {
      getAllOwners();
    }
  }, [reloadOwners]);

  function handleNextPage() {
    if (owner?.id) {
      setOwner(owner);
      handleClickPhase(1);
    } else {
      toast.error('Selecione um proprietário!');
    }
  }

  useEffect(() => {
    async function search() {
      const result = await searchOwnerByName(`${infosPipeDrive.owner_infos?.first_name} ${infosPipeDrive.owner_infos?.last_name}`);
      if (result.length > 0) {
        if (result.length === 1) {
          setOwner(result[0]);
        } else {
          setListFiltered(result);
        }
      } else {
        setIsOpenModal(true);
      }
    }
    if (infosPipeDrive.owner_infos?.first_name && listOwner.length > 0) {
      search();
    }
  }, [infosPipeDrive, listOwner]);

  useEffect(() => {
    async function searchById() {
      const ownersUpdates = await getAllOwners();
      const result = ownersUpdates.filter((item) => item.user.id === idNewOwner);
      if (result.length === 1) {
        setOwner(result[0]);
      }
    }

    if (idNewOwner !== -1) {
      searchById();
    }
  }, [idNewOwner]);

  const sortedListFields = listFiltered?.sort((a, b) => {
    if (a.user.trading_name > b.user.trading_name) {
      return 1;
    } if (a.user.trading_name < b.user.trading_name) {
      return -1;
    } if (a.user.first_name > b.user.first_name) {
      return 1;
    } if (a.user.first_name < b.user.first_name) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <OwnerDiv>
        <TitleContainer>
          <h1>Proprietário PF/PJ</h1>
          <Border />
        </TitleContainer>
        <ContentContainer>
          <h1>Nome completo do proprietário *</h1>
          <SearchContainer onSubmit={handleSearchOwner}>
            <SearchBar>
              <img src={SearchIcon} alt="icone de pesquisa" />
              {owner?.user && (
                <AreaSelected>
                  <CardOwnerSelected>
                    <p>{owner?.user?.trading_name ? owner?.user?.trading_name : `${owner?.user?.first_name} ${owner?.user?.last_name}`}</p>
                    <Tooltip text="Remover proprietário e escolher outro">
                      <button type="button" onClick={() => setOwner({} as GetOwnersData)}>x</button>
                    </Tooltip>
                  </CardOwnerSelected>
                </AreaSelected>
              )}
              {!owner?.user && (
                <>
                  <input type="text" name="name" placeholder="" />
                </>
              )}
            </SearchBar>
            <ButtonDiv>
              <FormButton type="submit">Buscar</FormButton>
            </ButtonDiv>
          </SearchContainer>

        </ContentContainer>
        <ResultsContainer>
          {listFiltered.length > 0 && <h1>Resultados:</h1>}
          {sortedListFields?.map((item) => (
            <ResultsText key={item.id} onClick={() => setOwner(item)}>
              <p>{item?.user?.trading_name ? item?.user?.trading_name : `${item?.user?.first_name} ${item?.user?.last_name}`}</p>
            </ResultsText>
          ))}
        </ResultsContainer>
        <NewOwnerButton onClick={() => setIsOpenModal(true)}>
          <NewOwnerContainer>
            <img src={AddOwnerIcon} alt="ícone de adicionar prorpietario" />
            <p>
              Adicionar ou editar proprietário
            </p>
          </NewOwnerContainer>
        </NewOwnerButton>
      </OwnerDiv>
      <ButtonSection>
        <FormButton type="button" onClick={() => handleNextPage()}>Avançar</FormButton>
      </ButtonSection>
    </>
  );
};

export default CardOwner;
