import React from 'react';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import MoreVert from '@mui/icons-material/MoreVert';
import { ButtonContainer, StyledMenu } from './styles';
import { pathNFList } from '../../../../../services/NFlist/request';
import { IStatusNFList } from '../../../../../services/NFlist/types';
import { useToast } from '../../../../../context/ToastContext';
import { useNfList } from '../../../../../hooks/NfListHook/useNfList';

interface IAdjustButton {
  nfId: number;
}

const AdjustButton = ({ nfId }: IAdjustButton) => {
  const toast = useToast();
  const { handleListItems, listItems } = useNfList();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const verifyItemStatus = () => {
    const item = listItems.find((itemStats: any) => itemStats.id === nfId);
    if (item) {
      return item.status;
    }
    return null;
  };

  const actualItemStatus = verifyItemStatus();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (newStatus: IStatusNFList) => {
    handleClose();
    const status: IStatusNFList = newStatus || 'Concluded';

    const params = {
      nfId,
      newStatus,
    };

    const response = pathNFList(params)
      .then(() => {
        toast.success('Status alterado com sucesso !');

        const newListItems = listItems.map((item: { id: number; status: string; }) => {
          if (item.id === nfId) {
            // eslint-disable-next-line no-param-reassign
            item.status = status;
          }
          return item;
        });
        handleListItems(newListItems);
      })
      .catch(() => toast.error('Erro ao concluir TED'));
    return response;
  };

  return (
    <>
      <ButtonContainer>
        <Button onClick={handleClick} startIcon={<MoreVert />}>
          Ajustar
        </Button>
        <StyledMenu
          className="menu"
          id="simple-menu"
          elevation={2}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actualItemStatus !== 'Concluded' && (
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#54DBB2',
                border: '2px solid #54DBB2',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Concluded')}
            >
              Concluir
            </MenuItem>
          )}

          {actualItemStatus === 'Concluded' && (
          <>
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#FF9900',
                border: '2px solid #FF9900',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Pending')}
            >
              Com ajustes
            </MenuItem>
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#FF2732',
                border: '2px solid #FF2732',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Not_Concluded')}
            >
              Não concluído
            </MenuItem>
          </>
          )}

          <MenuItem
            disabled
            style={{
              fontWeight: 'bold',
              borderRadius: 5,
              border: '2px solid #393a3a',
              margin: '10px 10px 2px 10px',
            }}
            onClick={handleClose}
          >
            Anexar Comprovante
          </MenuItem>
        </StyledMenu>
      </ButtonContainer>
    </>
  );
};

export default AdjustButton;
