import {
  useEffect,
  useState,
  useMemo,
} from 'react';

import { getProperPayHostDashboard } from '../../../services/HostDashboard/request';
import { ProperPayHostDashboard } from '../../../services/HostDashboard/types';

import { initialValuesHostDashboard } from '../../../services/HostDashboard/utils';

import { TagProvider } from '../../../context/HostDashboard/HostGridFinances/TagContext';

import { useUser } from '../../../context/UserContext';
import { useToast } from '../../../context/ToastContext';
import { useGridFinances } from '../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';

import { Finances as FinancesShimmer } from '../LoadingShimmer/Finances/Finances';
import Header from './Header';
import Grid from './Grid';

import { Container, Content } from './styles';
import CountingGridContent from './CountingGridContent';

const FinancesCount = () => {
  const { isLoadingFinances } = useGridFinances();
  return (
    <>
      <div style={{ marginTop: '16px' }} />
      <Container>
        <Header title="Contagem de diárias e limpezas" />

        <Content>
          {isLoadingFinances ? <FinancesShimmer /> : (
            <>
              <TagProvider>
                <Grid>
                  <CountingGridContent />
                </Grid>
              </TagProvider>
            </>
          )}
        </Content>
      </Container>
    </>
  );
};

export default FinancesCount;
