import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { UseFormik } from '../../utils/Formik/types';

import { COUNTRY_BRAZIL, getAddressByCep } from '../../services/Address/request';
import { AddressDetail } from '../../services/Address/types';

import { useToast } from '../../context/ToastContext';

import { onlyNumber } from '../../utils/Formatter';
import { ErrorMessage } from '../../utils/Messages';

import FormGridRow from '../FormGridRow';
import TextField from '../TextField';
import StateSelect from '../SimpleSelect/StateSelect';
import CountrySelect from '../SimpleSelect/CountrySelect';

import {
  Container,
  TextBold,
  Separator,
} from './styles';

type Props = {
  formik?: UseFormik<any>,
  suffix?: string,
  required?: boolean,
  addressProp?: AddressDetail;
};

const FormAddress: React.FC<Props> = ({
  formik,
  suffix = '',
  required = true,
  addressProp,
}) => {
  const [lastCep, setLastCep] = useState<string>('');
  const toast = useToast();
  const getAddress = async () => {
    if (formik && formik?.values) {
      const country = formik?.values[`country${suffix}`] || addressProp?.country;
      const zipCode: string = onlyNumber(formik?.values[`zipCode${suffix}`]);

      if (country === COUNTRY_BRAZIL.acronym && zipCode?.length === 8 && lastCep !== zipCode) {
        try {
          const address = await getAddressByCep(zipCode);

          formik.setFieldValue(`state${suffix}`, address.uf);
          formik.setFieldValue(`street${suffix}`, address.logradouro);
          formik.setFieldValue('streetName', address.logradouro);
          formik.setFieldValue(`neighborhood${suffix}`, address.bairro);
          formik.setFieldValue('neighborhoodName', address.bairro);
          formik.setFieldValue(`city${suffix}`, address.localidade);
          formik.setFieldValue(`complement${suffix}`, address.complemento);

          formik.setFieldTouched(
            `state${suffix}`, true, false,
          );
          formik.setFieldTouched(
            `street${suffix}`, true, false,
          );
          formik.setFieldTouched(
            'strretName', true, false,
          );
          formik.setFieldTouched(
            `neighborhood${suffix}`, true, false,
          );
          formik.setFieldTouched(
            `city${suffix}`, true, false,
          );
          formik.setFieldTouched(
            `complement${suffix}`, true, false,
          );
        } catch {
          toast.error(ErrorMessage.addressDefaultErro());
        }
      }
      setLastCep(zipCode);
    }
  };

  useEffect(() => {
    getAddress();
    return () => {};
  }, [formik?.values, addressProp?.postal_code]);

  const getLabel = (label: string) => {
    let aux = `${label}`;
    if (required) {
      aux = `*${label}`;
    }
    return aux;
  };

  return (
    <Container>
      <TextBold>Endereço</TextBold>
      <Separator />

      <FormGridRow>
        <CountrySelect
          id={`country${suffix}`}
          formik={formik}
          label={getLabel('País')}
        />
      </FormGridRow>

      <FormGridRow grid>
        <Grid item xs={12} sm={9}>
          <TextField
            id={`zipCode${suffix}`}
            label={getLabel('Cep')}
            formik={formik}
            mask="cep"
            value={addressProp?.postal_code}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StateSelect
            id={`state${suffix}`}
            formik={formik}
          />
        </Grid>
      </FormGridRow>

      <FormGridRow grid>
        <Grid item xs={12} sm={10}>
          <TextField
            id={`street${suffix}`}
            label={getLabel('Rua')}
            formik={formik}
            value={formik?.values?.street}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id={`number${suffix}`}
            label="Número"
            formik={formik}
            value={addressProp?.number}
          />
        </Grid>
      </FormGridRow>
      <FormGridRow>
        <TextField
          id={`complement${suffix}`}
          label={'Complemento'}
          formik={formik}
          value={formik?.values?.complement}
        />
      </FormGridRow>

      <FormGridRow grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id={`neighborhood${suffix}`}
            label={getLabel('Bairro')}
            formik={formik}
            value={formik?.values?.neighborhood}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={`city${suffix}`}
            label={getLabel('Cidade')}
            formik={formik}
            value={formik?.values?.city}
          />
        </Grid>
      </FormGridRow>
    </Container>
  );
};

export default FormAddress;
