import React, { FC } from 'react';

import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';

import { GRIDITEMS, translateTedLabel } from '../../utils';

import { useHostTedList } from '../../../../hooks/HostTedListHook/useHostTedList';

import {
  Container,
  GridTitle,
  GridHeader,
  GridValue,
  ContainerArrows,
  Image,
} from './styles';

import Tooltip from '../../../Tooltip';

interface IGridValueComponent {
  label: string;
}

const GridValueComponent: FC<IGridValueComponent> = ({ label }) => {
  const { orderGridBy, setOrderGridBy } = useHostTedList();

  const handleSortGrid = () => {
    setOrderGridBy({
      order_by: translateTedLabel[label],
      order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <GridValue>
      <GridTitle>
        {label !== 'Ajustar' && (
          <div className="titleContainer">
            <h1>{label}</h1>
            <Tooltip text={`Ordenar ${label}`}>
              <ContainerArrows
                onClick={() => handleSortGrid()}
              >
                <Image src={arrowTop} alt="Seta para cima" />
                <Image src={arrowBottom} alt="Seta para baixo" />
              </ContainerArrows>
            </Tooltip>
          </div>
        )}
      </GridTitle>
    </GridValue>
  );
};

const Header = () => {
  const items = GRIDITEMS;

  return (
    <Container>
      <GridHeader>
        {items.map((item) => (
          <GridValueComponent label={item} />
        ))}
      </GridHeader>
    </Container>
  );
};

export default Header;
