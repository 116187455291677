import Card from './Card';
import {
  Container, Title, Divider,
} from './styles';

const insertOptions = [
  // {
  //   name: 'Proprietário',
  //   route: '/inserirdados/proprietario',
  // },
  {
    name: 'Anfitrião',
    route: '/inserirdados/anfitriao',
  },
  // {
  //   name: 'Propriedade',
  //   route: '/inserirdados/propriedade',
  // },
  {
    name: 'Comodidades das Propriedades',
    route: '/inserirdados/comodidades',
  },
  {
    name: 'Regras das Propriedades',
    route: '/inserirdados/regras',
  },
  {
    name: 'Novo listing',
    route: '/inserirdados/novo-listing',
  },
  {
    name: 'Mudança de Status',
    route: '/inserirdados/mudar-status-imovel',
  },
  {
    name: 'Parceiro',
    route: '/inserirdados/parceiro',
  },
  {
    name: 'Condomínio B2B',
    route: '/inserirdados/condominio-b2b',
  },
  {
    name: 'Inserir Spot para indicação',
    route: '/inserirdados/spot',
  },
];

const InsertData = () => (
  <Container>
    <div className="content">
      <Title>Novos dados</Title>
      <Divider />
      <div className="cards">
        {insertOptions.map((option) => (<Card name={option.name} route={option.route} />))}
      </div>
    </div>
  </Container>
);

export default InsertData;
