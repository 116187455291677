import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import { motion } from 'framer-motion';

export const OpenFinancialUpdateModalBackdrop = styled.div<{ isModalOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999 !important;
  background-color: rgba(0,0,0, 0.2);
  display: ${({ isModalOpen }) => (isModalOpen ? 'block' : 'none')};
  overflow: none;
`;

export const OpenFinancialUpdateModalCard = styled.div`
  position: absolute;
  padding: 1rem;
  background-color: #F9F9F9;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${({ theme }) => theme.fonts.familys._6};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 900px;
  max-height: 90%;

  @media (max-width: 1000px) {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
`;

export const OpenFinancialUpdateModalStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
  height: 100%;
  
  @media (max-width: 800px) {
    height: initial;
  }
`;

export const OpenFinancialUpdateModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0 0;
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const OpenFinancialUpdateModalCloseButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  align-self: baseline;
  z-index: 9999999;
  background-color: transparent;
  svg {
    color: #969696;
  }
`;

export const OpenFinancialUpdateModalHeaderTitle = styled.h2`
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #001840;
`;

export const OpenFinancialUpdateModalCloseIcon = styled(Close)`
  color: ${({ theme }) => theme.palette.grey._900.hex()} !important;
`;

export const OpenFinancialUpdateModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 2rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
  
  @media (max-width: 400px) {
    gap: 1rem;
    height: 90%;
  }
`;

export const OpenFinancialUpdateModalTextContainer = styled.div`
  text-align: center;
  margin-top: -1.5rem;
  @media (max-width: 400px) {
    &::-webkit-scrollbar {
      border-radius: 10px;
      height: 0.5rem;
      width: 0.5rem;
      background-color: ${({ theme }) => theme.palette.grey._400.hex()};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.blue._400.hex()};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      background-color: ${({ theme }) => theme.palette.blue._50.hex()};
    }
  }
`;

export const OpenFinancialUpdateModalTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  color: #042675;
  margin-top: 2rem;
  margin-bottom: 1.75rem;

  @media (max-width: 400px) {
    font-size: 18px;
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
  }
`;

export const OpenFinancialUpdateModalText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #969696;
  margin-bottom: 1rem;
    
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const OpenFinancialUpdateModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;

export const Divider = styled.div`
  width: 98%;
  align-self: center;
  border-bottom: 1px solid #D9D9D9;
`;
