import {
  FC,
  useEffect,
  useState,
} from 'react';

import { Grid } from '@mui/material';

import { useFormik } from 'formik';
import useKeys from '../../../../hooks/GlobalHook/useKeys';

import {
  DatePicker,
  FormButton,
  RadioButton,
  SimpleSelect,
  TextField,
} from '../../..';

import StarRating from '../../../StarRating';

import newExpense from '../../../../assets/icons/expense/newExpense.svg';
import iconClose from '../../../../assets/icons/generals/iconClose.svg';

import {
  Backdrop,
  ButtonsContainer,
  CloseButton,
  Container,
  ContainerSupplier,
  Content,
  ErrorIcon,
  FieldCodeProperty,
  FormContainer,
  FormContent,
  HeaderContainer,
  LeftSideContainer,
  NoteContainer,
  PendingReasonContainer,
  PhotoContainer,
  RadiosButtonContainer,
  RightSideContainer,
} from './styles';

import {
  checkEmptyString,
  currencyToNumber,
  formatedDateOfRequest,
  numberToCurrency,
} from '../../../../utils/Formatter';

import {
  FileProps,
} from '../../../../context/FileContext/types';

import {
  AllInformationExpenseProps,
  ExpenseParams,
  ExpenseProps,
  ExpenseReason,
  ExpenseReasonLabel,
  ExpenseReasonValue,
  ExpenseResponseProps,
} from '../../../../services/Expenses/types';

import { Property } from '../../../../services/Property/types';

import { patchExpenses, postExpenseFile, postExpenses } from '../../../../services/Expenses/request';
import { getPropertiesHost } from '../../../../services/Property/request';

import { initialValuesFile } from '../../../../context/FileContext/FileContext';
import { useLoader } from '../../../../context/LoaderContext';
import { useToast } from '../../../../context/ToastContext';
import { useUser } from '../../../../context/UserContext';
import { useExpense } from '../../../../hooks/ExpenseHook/useExpense';
import { useStarRating } from '../../../../hooks/StarRatingHook/useStarRating';

import { ResponsiblesForExpenseProps, getParamsResponsibleUserAndRegisterBy } from '../../../../services/Expenses/utils';
import { currency } from '../../../../utils/InputMask/Number';
import { ErrorMessage, useToastErrorMessage } from '../../../../utils/Messages';
import { compareList } from '../../../../utils/Sorting';

import DropdownAutocomplete, { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';
import FormGridRow from '../../../FormGridRow';
import UploadButton from './UploadButtons/UploadButton';
import DuplicateAlert from './DuplicateAlert';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import { getHosts } from '../../../../services/Host/request';
import { RequestHost } from '../../../../services/Host/types';

type ValuesFormikProps = {
  supplier: string,
  supplier_phonenumber: string,
  supplier_rating: number,
  value: string,
  reason: string,
  description: string,
  expense_status: string,
  refund: string | null,
  expense_date: Date,
  owner_approval: boolean,
  owner_approval_status: string,
  property_id: number,
  host_id: number | undefined,
  paid_by: string,
};

type AllFormikType = {
  supplier: string,
  supplier_phonenumber: string,
  supplier_rating: number,
  value: string,
  reason: string,
  description: string,
  expense_status: string,
  refund: string | null,
  expense_date: Date,
  owner_approval: boolean,
  owner_approval_status: string,
  property_id: number,
  host_id: number | undefined,
  paid_by: string,
};

type FileReferenceResponse = {
  id?: string;
  uid: string,
  name: string,
  category?: 'Maintenance' | 'Statement',
  content_type: string,
  storage: {
    url: string,
    fields: {
      acl: string,
      content_type?: string,
      key: string,
      AWSAccessKeyId: string,
      policy: string,
      signature: string
    }
  }
};

const NewExpensesModal: FC = () => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const NAME_PAGE = userInformation?.main_role === 'Host' ? 'reembolso' : 'despesa';

  const {
    openModalExpenses,
    handleOpenModalExpenses,
    isEditingModal,
    indexExpenseSelected,
    hasFiltered,
    filterParams,
    expensesList,
    properties,
  } = useExpense();

  const getExpensesList = (x?: ExpenseParams) => x;

  const { rating, handleChangeRating } = useStarRating();

  const esc = useKeys('Escape');
  if (esc) handleOpenModalExpenses(false);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { setLoad } = useLoader();

  const [hosts, setHosts] = useState<RequestHost[]>();

  const expense: ExpenseResponseProps = expensesList?.results[indexExpenseSelected];

  const [isNotExpenseDuplicate, setIsNotExpenseDuplicate] = useState<boolean>(false);

  const [
    showErrorOwnerApprovalNotFound,
    setShowErrorOwnerApprovalNotFound] = useState<boolean>(false);
  const [showErrorReason, setShowErrorReason] = useState<boolean>(false);
  const [showErrorPaidBy, setShowErrorPaidBy] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [showErrorPropertyNotFound, setShowErrorPropertyNotFound] = useState<boolean>(false);
  const [showErrorRatingNotFound, setShowErrorRatingNotFound] = useState<boolean>(false);
  const [showErrorDescrptionVoid, setShowErrorDescrptionVoid] = useState<boolean>(false);
  const [showErrorSupplierPhoneNumberNotFound,
    setShowErrorSupplierPhoneNumberNotFound] = useState<boolean>(false);
  const [showErrorMinSupplierPhoneNumber,
    setShowErrorMinSupplierPhoneNumber] = useState<boolean>(false);
  const [showErrorFilesRequired, setShowErrorFilesRequired] = useState<boolean>(false);

  const [statementFiles, setStatementFiles] = useState<FileProps[]>([]);
  const [maintenanceFiles, setMaintenanceFiles] = useState<FileProps[]>([]);
  const [uploadStatementFile, setUploadStatementFile] = useState<FileProps>(initialValuesFile);
  const [uploadMaintenanceFile, setUploadMaintenanceFile] = useState<FileProps>(initialValuesFile);
  const [sendingFileArray, setSendingFileArray] = useState<FileReferenceResponse[]>([]);
  const [allInformationExpenseFiles,
    setAllInformationExpenseFiles] = useState<AllInformationExpenseProps>({
  } as AllInformationExpenseProps);

  const getOwnerApprovalStatus: Record<string, string> = {
    true: 'approved',
    false: 'notApproved',
    default: '',
  };

  const isSeazoneUser = () => (userInformation?.main_role === 'Seazone' || userInformation?.main_role === 'Admin')
  && (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding')
  || roles.includes('Admin'));

  const getInitialExpenseDate = () => {
    if (!isEditingModal) return new Date();

    const today = new Date().toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const dateString = expense?.expense_date || today;
    const expenseDate = new Date(dateString);
    expenseDate.setDate(expenseDate.getDate() + 1);

    return expenseDate;
  };

  const handleValidationsForm = (values: ValuesFormikProps) => {
    const hasError = false;

    if (values.property_id === -1) {
      setShowErrorPropertyNotFound(true);
      return true;
    }

    if (values.supplier_rating === 0) {
      setShowErrorRatingNotFound(true);
      return true;
    }

    if (values.supplier_phonenumber === '') {
      setShowErrorSupplierPhoneNumberNotFound(true);
      return true;
    }

    if (values.supplier_phonenumber.length < 19) {
      setShowErrorMinSupplierPhoneNumber(true);
      return true;
    }

    if (['Selecione', ''].includes(values.reason)) {
      setShowErrorReason(true);
      return true;
    }

    if (isSeazoneUser()) {
      if (['Selecione', ''].includes(values.paid_by)) {
        setShowErrorPaidBy(true);
        return true;
      }
    }

    if (values.owner_approval_status === '') {
      setShowErrorOwnerApprovalNotFound(true);
      return true;
    }

    if (values.description.length <= 0) {
      setShowErrorDescrptionVoid(true);
      return true;
    }

    if (statementFiles.length === 0) {
      setShowErrorFilesRequired(true);
      return true;
    }
    return hasError;
  };

  const handleResetStates = () => {
    setLoad(false);
    handleOpenModalExpenses(false);
    setSendingFileArray([]);
    setMaintenanceFiles({} as FileProps[]);
    setStatementFiles({} as FileProps[]);
  };

  const formik = useFormik<AllFormikType>({
    initialValues: {
      supplier: '',
      supplier_phonenumber: '',
      supplier_rating: 0,
      value: 'R$ 0,00',
      reason: '',
      description: '',
      expense_status: 'Analyzing',
      refund: null,
      expense_date: new Date(getInitialExpenseDate()),
      owner_approval: false,
      owner_approval_status: '',
      property_id: -1,
      host_id: undefined,
      paid_by: 'Selecione',
    },
    onSubmit: async (values) => {
      try {
        const isInvalid = handleValidationsForm(values as ValuesFormikProps);
        if (isInvalid) return;
        setLoad(true);

        if (!isEditingModal) {
          const responsibles: ResponsiblesForExpenseProps = {
            main_role: userInformation?.main_role || '',
            roles: userInformation?.roles || [],
            host_id: Number(values.host_id),
            user_id: Number(userInformation?.user_id || -1),
          };

          let expenseParamsPost: ExpenseProps = {
            refund: values.refund,
            expense_date: formatedDateOfRequest(values.expense_date.toString()),
            expense_status: values.expense_status,
            reason: values.reason as ExpenseReason,
            description: values.description,
            supplier: values.supplier,
            supplier_phonenumber: values.supplier_phonenumber,
            supplier_rating: values.supplier_rating,
            value: currencyToNumber(currency(formik.values.value)),
            owner_approval: values.owner_approval,
            property: Number(values.property_id),
            ...getParamsResponsibleUserAndRegisterBy(responsibles),
          };
          if (isSeazoneUser()) {
            expenseParamsPost = {
              ...expenseParamsPost,
              paid_by: values.paid_by,
            };
          }

          const createdExpense = await postExpenses(expenseParamsPost);
          const formattedStatementFileArray = sendingFileArray
            .filter((item) => item.category === 'Statement')
            .map((item) => item.uid);

          const formattedMaintenanceFileArray = sendingFileArray
            .filter((item) => item.category === 'Maintenance')
            .map((item) => item.uid);

          await postExpenseFile({
            expense: createdExpense.id,
            files: formattedStatementFileArray,
            category: 'Statement',
          });
          await postExpenseFile({
            expense: createdExpense.id,
            files: formattedMaintenanceFileArray,
            category: 'Maintenance',
          });
          setTimeout(() => {
            handleResetStates();
            if (userInformation?.main_role !== 'Host') {
              toast.success('Despesa inserida com sucesso!');
            } else {
              toast.success('Reembolso inserido com sucesso!');
            }
          }, 1000);
        } else {
          let expenseParamsPatch: ExpenseProps = {
            refund: values.refund,
            expense_date: formatedDateOfRequest(values.expense_date.toString()),
            expense_status: 'Analyzing',
            reason: values.reason as ExpenseReason,
            description: values.description,
            supplier: values.supplier,
            supplier_phonenumber: values.supplier_phonenumber,
            supplier_rating: values.supplier_rating,
            value: currencyToNumber(currency(formik.values.value)),
            owner_approval: values.owner_approval,
            maintenance_files: allInformationExpenseFiles.maintenanceExpenseFiles,
            statement_files: allInformationExpenseFiles.statementExpenseFiles,
          };

          if (isSeazoneUser()) {
            expenseParamsPatch = {
              ...expenseParamsPatch,
              paid_by: values.paid_by,
            };
          }

          if (expense?.id) {
            await patchExpenses(expense.id, expenseParamsPatch);
            if (sendingFileArray.length > 0) {
              const formattedStatementFileArray = sendingFileArray
                .filter((item) => item.category === 'Statement')
                .map((item) => item.uid);

              const formattedMaintenanceFileArray = sendingFileArray
                .filter((item) => item.category === 'Maintenance')
                .map((item) => item.uid);

              await postExpenseFile({
                expense: expense.id,
                files: formattedStatementFileArray,
                category: 'Statement',
              });
              await postExpenseFile({
                expense: expense.id,
                files: formattedMaintenanceFileArray,
                category: 'Maintenance',
              });
            }
            setTimeout(() => {
              handleResetStates();
              if (userInformation?.main_role !== 'Host') {
                toast.success('Despesa inserida com sucesso!');
              } else {
                toast.success('Reembolso inserido com sucesso!');
              }
            }, 1000);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoad(false);
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
        setSendingFileArray([]);
        setMaintenanceFiles({} as FileProps[]);
        setStatementFiles({} as FileProps[]);
      } finally {
        if (hasFiltered) {
          await getExpensesList(filterParams);
        } else {
          await getExpensesList();
        }
      }
    },
  });

  useEffect(() => {
    if (!isSeazoneUser()) return;

    const fetchData = async () => {
      setLoading(true);
      const response = await getHosts();
      setHosts(response);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (openModalExpenses && isEditingModal) {
      if (expense) {
        formik.setValues({
          ...formik.initialValues,
          supplier: expense?.supplier || '',
          supplier_phonenumber: expense?.supplier_phonenumber || '',
          supplier_rating: expense?.supplier_rating || 0,
          value: expense?.value?.toString() || 'R$ 0,00',
          reason: expense?.reason || '',
          description: expense?.description || '',
          expense_status: expense?.expense_status || 'Analyzing',
          refund: null,
          expense_date: getInitialExpenseDate(),
          owner_approval: expense?.owner_approval || false,
          owner_approval_status: getOwnerApprovalStatus[expense?.owner_approval?.toString() || 'default'],
          property_id: expense.property.id as any,
          host_id: expense?.responsible_user?.id as any || undefined,
          paid_by: expense?.paid_by || 'Selecione',
        });
        handleChangeRating(expense?.supplier_rating);
      }
    }

    return () => handleResetStates();
  }, [openModalExpenses, isEditingModal, indexExpenseSelected]);

  useEffect(() => {
    if (!(['Selecione', ''].includes(formik.values.reason))) {
      setShowErrorReason(false);
    }
  }, [formik.values.reason]);

  useEffect(() => {
    if (!(['Selecione', ''].includes(formik.values.paid_by))) {
      setShowErrorPaidBy(false);
    }
  }, [formik.values.paid_by]);

  useEffect(() => {
    if (formik.values.owner_approval_status === 'approved') {
      formik?.setFieldValue('owner_approval', true);
      formik?.setFieldTouched(
        'owner_approval', true, false,
      );
      setShowErrorOwnerApprovalNotFound(false);
    } else if (formik.values.owner_approval_status === 'notApproved') {
      formik?.setFieldValue('owner_approval', false);
      formik?.setFieldTouched(
        'owner_approval', true, false,
      );
      setShowErrorOwnerApprovalNotFound(false);
    }
  }, [formik.values.owner_approval_status]);

  useEffect(() => {
    formik?.setFieldValue('supplier_rating', rating);
    formik?.setFieldTouched(
      'supplier_rating', true, false,
    );
  }, [rating]);

  useEffect(() => {
    handleChangeRating(isEditingModal ? Number(expense?.supplier_rating || 0) : 0);
  }, [expense]);

  useEffect(() => {
    if (formik.values.supplier_rating !== 0) {
      setShowErrorRatingNotFound(false);
    }
  }, [formik.values.supplier_rating]);

  useEffect(() => {
    if (formik.values.property_id !== -1) {
      setShowErrorPropertyNotFound(false);
    }
  }, [formik.values.supplier_rating]);

  useEffect(() => {
    if (formik.values.supplier_phonenumber.length === 19) {
      setShowErrorMinSupplierPhoneNumber(false);
    }
  }, [formik.values.supplier_phonenumber]);

  useEffect(() => {
    if (statementFiles.length === 0) {
      setShowErrorFilesRequired(false);
    }
  }, [statementFiles]);

  const handleGetProperty = (): SelectProps[] => properties.map((property) => (
    { optionText: `${property.code}`, optionValue: `${property.id}` }
  )).sort((a, b) => compareList(a.optionText, b.optionText));

  const handleGetHost = (): SelectProps[] => (hosts || []).map((item: any) => ({
    optionText: `${item?.user?.first_name} ${item?.user?.last_name}`,
    optionValue: item?.user.id,
  })).sort((a: SelectProps, b: SelectProps) => compareList(a.optionText || '', b.optionText || ''));

  let options: SelectOption[] = [
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_CONDOMINIUM,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_CONDOMINIUM,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_ENERGY,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_ENERGY,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_GARDEN,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GARDEN,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_INTERNET,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_INTERNET,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_IPTU,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_IPTU,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_POOL,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_POOL,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_TV,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_TV,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_WATER,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_WATER,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_SUBSCRIPTION,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_SUBSCRIPTION,
    },
    {
      value: ExpenseReasonValue.MAINTENANCE,
      valueLabel: ExpenseReasonLabel.MAINTENANCE,
    },
    {
      value: ExpenseReasonValue.PURCHASE_REQUIRED_ITEMS,
      valueLabel: ExpenseReasonLabel.PURCHASE_REQUIRED_ITEMS,
    },
    {
      value: ExpenseReasonValue.THIRD_PARTY_SERVICES,
      valueLabel: ExpenseReasonLabel.THIRD_PARTY_SERVICES,
    },
    {
      value: ExpenseReasonValue.ONBOARDING_KEYS_CLEANING,
      valueLabel: ExpenseReasonLabel.ONBOARDING_KEYS_CLEANING,
    },
    {
      value: ExpenseReasonValue.ONBOARDING_LAUNDRY,
      valueLabel: ExpenseReasonLabel.ONBOARDING_LAUNDRY,
    },
    {
      value: ExpenseReasonValue.RECCURENT_EXPENSE,
      valueLabel: ExpenseReasonLabel.RECCURENT_EXPENSE,
    },
    {
      value: ExpenseReasonValue.GUEST_DAMAGE,
      valueLabel: ExpenseReasonLabel.GUEST_DAMAGE,
    },
    {
      value: ExpenseReasonValue.SEAZONE_CHARGES,
      valueLabel: ExpenseReasonLabel.SEAZONE_CHARGES,
    },
    // {
    //   value: ExpenseReasonValue.RESERVATION_COMMISSION,
    //   valueLabel: ExpenseReasonLabel.RESERVATION_COMMISSION,
    // },
    // {
    //   value: ExpenseReasonValue.OWNER_CLEANING,
    //   valueLabel: ExpenseReasonLabel.OWNER_CLEANING,
    // },
    {
      value: ExpenseReasonValue.OWNER_RESERVATION,
      valueLabel: ExpenseReasonLabel.OWNER_RESERVATION,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_GAS,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GAS,
    },
    {
      value: ExpenseReasonValue.MEMBERSHIP_FEE_MADEGO,
      valueLabel: ExpenseReasonLabel.MEMBERSHIP_FEE_MADEGO,
    },
    {
      value: ExpenseReasonValue.LAYETTE_PARCEL_MADEGO,
      valueLabel: ExpenseReasonLabel.LAYETTE_PARCEL_MADEGO,
    },
    {
      value: ExpenseReasonValue.BUYING_LAYETTE,
      valueLabel: ExpenseReasonLabel.BUYING_LAYETTE,
    },
    {
      value: ExpenseReasonValue.LAYETTE_DAMAGE,
      valueLabel: ExpenseReasonLabel.LAYETTE_DAMAGE,
    },
    {
      value: ExpenseReasonValue.PRO_PHOTO_REVIEW,
      valueLabel: ExpenseReasonLabel.PRO_PHOTO_REVIEW,
    },
  ].sort((firstOption,
    nextOption) => compareList(firstOption.valueLabel, nextOption.valueLabel));

  if (roles.includes('Host')) {
    options = [
      {
        value: ExpenseReasonValue.MAINTENANCE,
        valueLabel: ExpenseReasonLabel.MAINTENANCE,
      },
      {
        value: ExpenseReasonValue.PURCHASE_REQUIRED_ITEMS,
        valueLabel: ExpenseReasonLabel.PURCHASE_REQUIRED_ITEMS,
      },
      // {
      //   value: ExpenseReasonValue.THIRD_PARTY_SERVICES,
      //   valueLabel: ExpenseReasonLabel.THIRD_PARTY_SERVICES,
      // },
      {
        value: ExpenseReasonValue.ONBOARDING_KEYS_CLEANING,
        valueLabel: ExpenseReasonLabel.ONBOARDING_KEYS_CLEANING,
      },
      {
        value: ExpenseReasonValue.ONBOARDING_LAUNDRY,
        valueLabel: ExpenseReasonLabel.ONBOARDING_LAUNDRY,
      },
      {
        value: ExpenseReasonValue.RECCURENT_EXPENSE,
        valueLabel: ExpenseReasonLabel.RECCURENT_EXPENSE,
      },
      {
        value: ExpenseReasonValue.GUEST_DAMAGE,
        valueLabel: ExpenseReasonLabel.GUEST_DAMAGE,
      },
      // {
      //   value: ExpenseReasonValue.SEAZONE_CHARGES,
      //   valueLabel: ExpenseReasonLabel.SEAZONE_CHARGES,
      // },
      // {
      //   value: ExpenseReasonValue.RESERVATION_COMMISSION,
      //   valueLabel: ExpenseReasonLabel.RESERVATION_COMMISSION,
      // },
      // {
      //   value: ExpenseReasonValue.OWNER_CLEANING,
      //   valueLabel: ExpenseReasonLabel.OWNER_CLEANING,
      // },
      {
        value: ExpenseReasonValue.OWNER_RESERVATION,
        valueLabel: ExpenseReasonLabel.OWNER_RESERVATION,
      },
      // {
      //   value: ExpenseReasonValue.MEMBERSHIP_FEE_MADEGO,
      //   valueLabel: ExpenseReasonLabel.MEMBERSHIP_FEE_MADEGO,
      // },
      // {
      //   value: ExpenseReasonValue.LAYETTE_PARCEL_MADEGO,
      //   valueLabel: ExpenseReasonLabel.LAYETTE_PARCEL_MADEGO,
      // },
      {
        value: ExpenseReasonValue.BUYING_LAYETTE,
        valueLabel: ExpenseReasonLabel.BUYING_LAYETTE,
      },
      {
        value: ExpenseReasonValue.LAYETTE_DAMAGE,
        valueLabel: ExpenseReasonLabel.LAYETTE_DAMAGE,
      },
      {
        value: ExpenseReasonValue.PRO_PHOTO_REVIEW,
        valueLabel: ExpenseReasonLabel.PRO_PHOTO_REVIEW,
      },
    ].sort((firstOption,
      nextOption) => compareList(firstOption.valueLabel, nextOption.valueLabel));
  }

  if (roles.includes('Host') === false) {
    options = [
      ...options,
      {
        value: ExpenseReasonValue.ONBOARDING_ADMINISTRATIVE,
        valueLabel: ExpenseReasonLabel.ONBOARDING_ADMINISTRATIVE,
      },
    ];

    options = options.sort((firstOption: SelectOption, nextOption: SelectOption) => {
      if (firstOption?.valueLabel && nextOption?.valueLabel) {
        return compareList(`${firstOption.valueLabel}`, `${nextOption.valueLabel}`);
      }
      return 0;
    });
  }

  return (
    <>
      <Backdrop
        onClick={() => handleOpenModalExpenses(false)}
      >
        <Container onClick={(e) => e.stopPropagation()}>
          <Content>
            <HeaderContainer>
              <div>
                <img src={newExpense} alt="newExpenseIcon" />
                {userInformation?.main_role === 'Host' ? (
                  <h1>{`${isEditingModal ? `Editar  ${NAME_PAGE}` : `Novo  ${NAME_PAGE}`}`}</h1>
                ) : (
                  <h1>{`${isEditingModal ? `Editar  ${NAME_PAGE}` : `Nova  ${NAME_PAGE}`}`}</h1>
                )}

              </div>
              <CloseButton onClick={() => handleOpenModalExpenses(false)}>
                <img src={iconClose} alt="close" />
              </CloseButton>
            </HeaderContainer>
            <FormContainer onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
              <FormContent>
                <LeftSideContainer>
                  <div className="row">
                    {!isEditingModal ? (
                      <>
                        <DropdownAutocomplete
                          dataCy="select-expense-properties-list"
                          label="Imóvel: (Pesquise pelo código do imóvel)"
                          id="property_id"
                          required
                          options={handleGetProperty()}
                          formik={formik}
                        />
                        { showErrorPropertyNotFound && (
                        <p data-cy="msg-supplier-rating-required" className="messageError">
                          <ErrorIcon />
                          Avalie o fornecedor
                        </p>
                        )}
                      </>
                    ) : (
                      <FieldCodeProperty>
                        <h1 id="property_id" data-cy="input-property-selected">
                          {expensesList?.results[indexExpenseSelected]?.property?.code
                            ? `Imóvel - ${expensesList?.results[indexExpenseSelected]?.property?.code}`
                            : 'Não pertence a um imóvel'}
                        </h1>
                      </FieldCodeProperty>
                    )}
                  </div>

                  {isSeazoneUser() && (
                    !isEditingModal ? (
                      <div className="autocomplete-host">
                        <DropdownAutocomplete
                          dataCy="select-host-list"
                          label="Anfitrião responsável: (Pesquise pelo nome do anfitrião)"
                          id="host_id"
                          required
                          options={handleGetHost()}
                          formik={formik}
                          loading={loading}
                        />
                      </div>
                    ) : (
                      <FieldCodeProperty>
                        <h1 id="property_id" data-cy="input-property-selected">
                          {`Anfitrião - ${checkEmptyString(`${expensesList?.results[indexExpenseSelected]?.responsible_user?.first_name} ${expensesList?.results[indexExpenseSelected]?.responsible_user?.last_name}`) || 'Não informado'}`}
                        </h1>
                      </FieldCodeProperty>
                    ))}

                  <ContainerSupplier>
                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        {!isEditingModal ? (
                          <TextField
                            dataCy="input-supplier"
                            className="styledTextFieldLabel"
                            id="supplier"
                            required
                            label="Fornecedor"
                            placeholder="Nome do fornecedor"
                            formik={formik}
                          />
                        ) : (
                          <TextField
                            dataCy="input-supplier"
                            className="styledTextFieldLabel"
                            id="supplier"
                            required
                            label="Fornecedor"
                            placeholder="Nome do fornecedor"
                            value={formik.values.supplier}
                          />
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <StarRating
                          label="Avaliação do fornecedor"
                          required
                        />
                        { showErrorRatingNotFound && (
                        <p data-cy="msg-supplier-rating-required" className="messageError">
                          <ErrorIcon />
                          Avalie o fornecedor
                        </p>
                        )}
                      </Grid>
                    </FormGridRow>
                  </ContainerSupplier>
                  {!isEditingModal ? (
                    <>
                      <TextField
                        className="styledMoney"
                        required
                        label="Telefone do fornecedor"
                        id="supplier_phonenumber"
                        mask="phone"
                        placeholder="+55 (48) 99999-9999"
                      />
                      { showErrorSupplierPhoneNumberNotFound && (
                      <p data-cy="msg-supplier-phone-number-required" className="messageError">
                        <ErrorIcon />
                        Digite o numero do fornecedor.
                      </p>
                      )}
                      { showErrorMinSupplierPhoneNumber && (
                        <p data-cy="msg-supplier-min-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Telefone inválido. Ex. +55 (48) 99999-9999
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <TextField
                        className="styledMoney"
                        required
                        label="Telefone do fornecedor"
                        id="supplier_phonenumber"
                        value={formik.values.supplier_phonenumber}
                        mask="phone"
                        placeholder={formik.values.supplier_phonenumber || '+00 (00) 00000-0000'}
                        formik={formik}
                      />

                      { showErrorSupplierPhoneNumberNotFound && (
                      <p data-cy="msg-supplier-phone-number-required" className="messageError">
                        <ErrorIcon />
                        Digite o numero do fornecedor.
                      </p>
                      )}
                      { showErrorMinSupplierPhoneNumber && (
                        <p data-cy="msg-supplier-min-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Telefone inválido. Ex. +55(48)99999-9999
                        </p>
                      )}
                    </>
                  )}
                  { }
                  {!isEditingModal ? (
                    <TextField
                      dataCy="input-expense-value"
                      className="styledMoney"
                      required
                      label="Valor"
                      id="value"
                      mask="money"
                      placeholder="R$ 0,00"
                    />
                  ) : (
                    <TextField
                      dataCy="input-expense-value"
                      className="styledMoney"
                      required
                      label="Valor"
                      id="value"
                      mask="money"
                      placeholder="R$ 0,00"
                      value={numberToCurrency(Number(formik.values.value))}
                      formik={formik}
                    />
                  )}

                  <SimpleSelect
                    dataCy="input-expense-reason"
                    className="reasonSelect"
                    id="reason"
                    placeholder="Selecione"
                    required
                    label="Motivo"
                    formik={formik}
                    options={options}
                  />
                  { showErrorReason && (
                  <p data-cy="msg-reason-required" className="messageError">
                    <ErrorIcon />
                    {userInformation?.main_role === 'Host' ? 'Selecione o motivo do reembolso' : 'Selecione o motivo da despesa'}
                  </p>
                  )}

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <DatePicker
                        dataCy="datepicker-expense"
                        formik={formik}
                        label="Data de execução"
                        required
                        id="expense_date"
                        minDate={new Date('1850-01-02')}
                        hasInitialDates
                        disableFuture
                        disableInput
                      />
                    </Grid>
                    {isSeazoneUser() && (
                    <Grid item sm={6} xs={12}>
                      <SimpleSelect
                        dataCy="input-paid_by"
                        className="reasonSelect"
                        id="paid_by"
                        placeholder="Selecione"
                        required
                        label="Quem paga"
                        formik={formik}
                        options={[
                          { value: 'Host', valueLabel: 'Anfitrião' },
                          { value: 'Owner', valueLabel: 'Proprietário' },
                          { value: 'Guest', valueLabel: 'Hóspede' },
                          { value: 'Seazone', valueLabel: 'Seazone' },
                        ]}
                      />
                      { showErrorPaidBy && (
                      <p data-cy="msg-reason-required" className="messageError">
                        <ErrorIcon />
                        {userInformation?.main_role === 'Host' ? 'Selecione o pagador do reembolso' : 'Selecione o pagador da despesa'}
                      </p>
                      )}
                    </Grid>
                    )}
                  </FormGridRow>
                </LeftSideContainer>
                <RightSideContainer>
                  <PhotoContainer>
                    <UploadButton
                      maintenanceFiles={maintenanceFiles}
                      setMaintenanceFiles={setMaintenanceFiles}
                      statementFiles={statementFiles}
                      setStatementFiles={setStatementFiles}
                      allInformationExpenseFiles={allInformationExpenseFiles}
                      setAllInformationExpenseFiles={setAllInformationExpenseFiles}
                      uploadStatementFile={uploadStatementFile}
                      setUploadStatementFile={setUploadStatementFile}
                      uploadMaintenanceFile={uploadMaintenanceFile}
                      setUploadMaintenanceFile={setUploadMaintenanceFile}
                      setSendingFileArray={setSendingFileArray}
                    />
                  </PhotoContainer>
                  {showErrorFilesRequired && (
                    <p data-cy="msg-files-required" className="messageError">
                      <ErrorIcon />
                      Upload obrigatório de arquivos do recibo
                    </p>
                  )}
                  <NoteContainer>
                    <div className="row-description">
                      <TextField
                        dataCy="textarea-expense-description"
                        label={userInformation?.main_role === 'Host' ? 'Descrição do reembolso' : 'Descrição da despesa'}
                        id="description"
                        type="textarea"
                        placeholder={userInformation?.main_role === 'Host' ? 'Descrição do reembolso' : 'Descrição da despesa'}
                        value={isEditingModal ? formik.values.description : undefined}
                        required
                      />
                      {showErrorDescrptionVoid && (
                        <p data-cy="msg-supplier-rating-required" className="messageError">
                          <ErrorIcon />
                          {userInformation?.main_role === 'Host' ? 'Descreva o reembolso' : 'Descreva a despesa'}
                        </p>
                      )}
                    </div>
                  </NoteContainer>

                  <RadiosButtonContainer>
                    <RadioButton
                      dataCy="radio-button-owner-approval"
                      id="owner_approval_status"
                      required
                      labelPlacement="end"
                      formik={formik}
                      formLabel="Foi pedido a autorização do proprietário ?"
                      options={[
                        { value: 'approved', label: 'Sim' },
                        { value: 'notApproved', label: 'Não' },
                      ]}
                    />
                  </RadiosButtonContainer>
                  {showErrorOwnerApprovalNotFound && (
                    <p data-cy="msg-reason-required" className="messageError">
                      <ErrorIcon />
                      {'Selecione se foi realizado o pedido de autorização'}
                    </p>
                  )}

                  {isEditingModal && expense?.expense_status === 'Pending' && (
                  <PendingReasonContainer>
                    <h1 data-cy="txt-pending-reason">
                      Motivo da Pendência:
                    </h1>
                    <p>
                      {expense?.pending_reason || ''}
                    </p>
                  </PendingReasonContainer>
                  )}

                </RightSideContainer>
              </FormContent>

              {!isEditingModal && (
                <DuplicateAlert
                  checked={isNotExpenseDuplicate}
                  setChecked={() => setIsNotExpenseDuplicate((state: boolean) => !state)}
                />
              )}

              <ButtonsContainer>
                <FormButton dataCy="btn-cancel" variant="outlined" onClick={() => handleOpenModalExpenses(false)}>
                  Cancelar
                </FormButton>
                <FormButton type="submit" dataCy="btn-save" disable={!isEditingModal && !isNotExpenseDuplicate && statementFiles.length === 0}>Salvar</FormButton>
              </ButtonsContainer>
            </FormContainer>
          </Content>
        </Container>

      </Backdrop>
    </>
  );
};

export default NewExpensesModal;
