import styled from 'styled-components';

interface Props {
  type: string;
}
export const Tag = styled.span`
  margin-left: 5px;
  border-radius: 5px;
  width: 32px;
  height: 16px;
  background-color: ${({ type }:Props) => (type === 'yes' ? ({ theme }) => theme.palette.green.main.hex() : ({ theme }) => theme.palette.orange.main.hex())};
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.palette.white._400.hex()};
  display: flex;
  justify-content: center;
`;
