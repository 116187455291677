/* eslint-disable no-await-in-loop */
import {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { sleep } from '../../../utils/Sleep';

import { generateHostFinancialStatement, getProperPayHostDashboard } from '../../../services/HostDashboard/request';
import { ParamsHostFinancialStatementSpredsheet, ProperPayHostDashboard } from '../../../services/HostDashboard/types';

import { initialValuesHostDashboard } from '../../../services/HostDashboard/utils';

import { TagProvider } from '../../../context/HostDashboard/HostGridFinances/TagContext';

import { useUser } from '../../../context/UserContext';
import { useToast } from '../../../context/ToastContext';
import { useGridFinances } from '../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';

import { Finances as FinancesShimmer } from '../LoadingShimmer/Finances/Finances';
import Header from './Header';
import Grid from './Grid';
import DragDrawerModal from '../../Modal/DragDrawerModal';
import FinancialStatementModal from './Modal/FinancialStatementModal';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';

import { Container, Content, WrapperModal } from './styles';
import { useFinancialStatement } from '../../../hooks/HostDashboardHook/HostGridFinances/useFinancialStatement';
import { useDate } from '../../../hooks/DateHook/useDate';
import { getFilesByFileUid } from '../../../services/Utils/request';
import LoadingStepper from '../../OwnerPage/OwnerReport/LoadingStepper';
import FinancialGridContent from './FinancialGridContent';

interface IFinances {
  propertiesCodes: SelectOption[];
}

const Finances = ({ propertiesCodes }: IFinances) => {
  const { userInformation: user } = useUser();
  const toast = useToast();
  const { isLoadingFinances } = useGridFinances();
  const {
    period,
    setPeriod,
    propertyId,
    setPropertyId,
    openFinancialStatementModal,
    setOpenFinancialStatementModal,
  } = useFinancialStatement();

  const { handleDateSelected } = useDate();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const [downloadProcessed, setDownloadProcessed] = useState<boolean>(false);

  const validateHostId = useMemo(() => (user?.host?.id ? user?.host?.id : undefined), [user]);
  const controller = new AbortController();

  const polling = useCallback(async (fileID: string, signal: AbortSignal) => {
    let counter = 0;
    const TRIES = 10;
    const CONDITIONAL_TRIES = TRIES - 1;

    while (counter !== TRIES) {
      await sleep(2000);

      const { url } = await getFilesByFileUid(
        fileID, signal, setDownloadProgress,
      );

      if (url) {
        setDownloadProcessed(true);
        setIsDownloading(false);
        window.open(url, '_blank');
        break;
      }

      if (counter === CONDITIONAL_TRIES) {
        toast.error('Não foi possível processar o arquivo. Tente novamente mais tarde!');
        break;
      }

      counter += 1;
    }
  }, []);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const params: ParamsHostFinancialStatementSpredsheet = {
        host_id: validateHostId,
        year: period.getFullYear(),
        month: period.getMonth() + 1,
        property_id: propertyId,
      };

      const { status, file_uid: fileUid } = await generateHostFinancialStatement(params);
      switch (status) {
        case 'Processing':
          polling(fileUid, controller.signal as AbortSignal);
          break;
        case undefined:
          toast.error('Não foi possível processar o arquivo. Tente novamente mais tarde!');
          setIsDownloading(false);
          break;
        default:
          toast.error('Não foi possível processar o arquivo. Tente novamente mais tarde!');
          setIsDownloading(false);
          break;
      }
    } catch {
      toast.error('Não foi possível processar o arquivo. Tente novamente mais tarde!');
      setIsDownloading(false);
    }
  };

  const handleCloseModal = () => {
    setPropertyId(undefined);
    setPeriod(new Date());
    handleDateSelected(new Date());
    setOpenFinancialStatementModal(false);
  };

  useEffect(() => {
    if (downloadProcessed) {
      setTimeout(() => {
        setIsDownloading(false);
      }, 1500);
    }
  }, [downloadProcessed]);
  return (
    <>
      <Container>
        <Header propertiesCodes={propertiesCodes} title="Financeiro" />

        <Content>
          {isLoadingFinances ? <FinancesShimmer /> : (
            <TagProvider>
              <Grid>
                <FinancialGridContent />
              </Grid>
            </TagProvider>
          )}
        </Content>
      </Container>

      {openFinancialStatementModal && (
        <DragDrawerModal
          variant="withAppyButton"
          title="Baixar o relatório financeiro"
          applyButtonText="Baixar extrato"
          open={openFinancialStatementModal}
          onClose={() => handleCloseModal()}
          handleClickApplyButton={() => handleDownload()}
        >
          <WrapperModal>
            <FinancialStatementModal propertiesCodes={propertiesCodes} />
          </WrapperModal>
        </DragDrawerModal>
      )}
      {isDownloading && (
        <LoadingStepper
          progress={downloadProgress}
          stepperOptions={[
            'Processando',
            'Preparando extrato',
            'Extrato Baixado',
          ]}
        />
      )}

    </>
  );
};

export default Finances;
