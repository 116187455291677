import styled from 'styled-components';

export const ContainerBox = styled.form`
  width: 350px;
  height: 300px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0 0.5rem;
  }
`;
