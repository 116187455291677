import styled from 'styled-components';

interface Props {
  isMain: boolean;
}

export const Container = styled.div<Props>`
  padding: 30px;
  width: 100%;
  height: 120px;

  background: #F1FFED;
  border: 3px solid ${(props) => (props.isMain ? '#AAE0AC' : '#E8E8E8')};
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media(max-width: 480px){
    flex-direction: column-reverse;
    padding: 15px
  }

  div {
    width: 40%;

    &.new-styled {
      width: 20%;
    }

    @media(max-width: 480px){
      width: 100%;
  }
  }

  h2 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  p {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
`;

export const Divider = styled.div`
  width: 1px !important;
  margin: 0 19px;
  height: 100%;
  background-color: #8F8F8F;
`;

export const Options = styled.section`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 900px){
    width: 100%;
    right: 0;
    justify-content: flex-end;
  }

  svg, img {

    :hover {
      cursor: pointer;
      filter: brightness(0.9);
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);

    }

    &.delete {
      fill: ${({ theme }) => theme.palette.orange.main.hex()}
    }
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const TagMain = styled.div`
  min-width: 70px;
  padding: 5px 10px;


  background: ${({ theme }) => theme.palette.green._300.hex()};
  border-radius: 20px;

  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 9px;

  @media(max-width: 480px) {
    width: 70px;
    max-width: 70px;
  }
`;
