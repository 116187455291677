/* eslint-disable max-len */
import request from '../../request';
import { ProperPayOwnerDashboardResponse } from './types';
import {
  sumExecutedDailys,
  sumPaidDailys,
  sumOutgoing,
  getMonthlyFormattedFinancialData,
} from './utils';

export const getProperPayOwnerDashboard = async (
  year: number,
  propertyId?: number,
  ownerId?: number,
): Promise<ProperPayOwnerDashboardResponse> => {
  // const month = new Date().getMonth() + 1;

  // Dados vindos da api /proper_pay/property/annual-results/
  // if (!propertyId) return { ...initialValuesOwnerDashboard };

  const resp = await request.get<ProperPayOwnerDashboardResponse>('/proper_pay/property/annual-results/', {
    params: {
      year,
      property_id: propertyId,
      owner_id: ownerId,
    },
  });

  const months = Array.from({ length: 12 }, (_, i) => `${i + 1}`);

  const executedDailys: any = {
    total: sumExecutedDailys(Number(resp.data?.to_receive?.total || 0),
      Number(resp.data?.to_receive_following_month?.total || 0)),
  };

  const paidDailys: any = {
    total: sumPaidDailys(Number(resp.data?.revenue?.total || 0),
      Number(resp.data?.revenue_prior_month?.total || 0)),
  };

  const outgoing: any = {
    total: sumOutgoing(Number(resp.data?.commission?.total || 0),
      Number(resp.data?.expenses?.total || 0)),
  };

  const results: any = {
    total: 0,
  };

  months.forEach((monthRef) => {
    executedDailys[monthRef] = sumExecutedDailys(Number(resp.data?.to_receive?.[monthRef] || 0),
      Number(resp.data?.to_receive_following_month?.[monthRef] || 0));

    paidDailys[monthRef] = sumPaidDailys(Number(resp.data?.revenue?.[monthRef] || 0),
      Number(resp.data?.revenue_prior_month?.[monthRef] || 0));

    outgoing[monthRef] = sumOutgoing(Number(resp.data?.commission?.[monthRef] || 0),
      Number(resp.data?.expenses?.[monthRef] || 0));

    results[monthRef] = paidDailys[monthRef] - (outgoing[monthRef] * -1);
  });

  results.total = paidDailys.total - (outgoing.total * -1);

  const properyPayPropertyResponse: ProperPayOwnerDashboardResponse = {
    executed_dailys: getMonthlyFormattedFinancialData(executedDailys),
    paid_dailys: getMonthlyFormattedFinancialData(paidDailys),
    to_receive: getMonthlyFormattedFinancialData(resp.data?.to_receive),
    to_receive_following_month: getMonthlyFormattedFinancialData(resp.data?.to_receive_following_month),
    revenue: getMonthlyFormattedFinancialData(resp.data?.revenue),
    revenue_prior_month: getMonthlyFormattedFinancialData(resp.data?.revenue_prior_month),
    outgoing: getMonthlyFormattedFinancialData(outgoing),
    commission: getMonthlyFormattedFinancialData(resp.data?.commission),
    expenses: getMonthlyFormattedFinancialData(resp.data?.expenses),
    results: getMonthlyFormattedFinancialData(results),
    transfer: getMonthlyFormattedFinancialData(resp.data?.transfer),
    final_balance: getMonthlyFormattedFinancialData(resp.data?.final_balance),
  };

  return {
    ...properyPayPropertyResponse,
    executed_dailys: { ...properyPayPropertyResponse.executed_dailys },
    paid_dailys: { ...properyPayPropertyResponse.paid_dailys },
    to_receive: { ...properyPayPropertyResponse.to_receive },
    to_receive_following_month: { ...properyPayPropertyResponse.to_receive_following_month },
    revenue: { ...properyPayPropertyResponse.revenue },
    revenue_prior_month: { ...properyPayPropertyResponse.revenue_prior_month },
    outgoing: { ...properyPayPropertyResponse.outgoing },
    commission: { ...properyPayPropertyResponse.commission },
    expenses: { ...properyPayPropertyResponse.expenses },
    results: { ...properyPayPropertyResponse.results },
    transfer: {
      ...properyPayPropertyResponse.transfer,
      // total: formatMonetaryValue(calculateTotalSum(resp.data?.transfer)),
    },
    final_balance: {
      ...properyPayPropertyResponse.final_balance,
      // total: formatMonetaryValue(calculateTotalSum(resp.data?.final_balance)),
    },
  };
};
