import React from 'react';

import Card from './Card';

import { Container } from './styles';
import { useFinancialFeedback } from '../../../../hooks/FinancialFeedbackContext/FinancialFeedbackContext';

const CardList = () => {
  const { cardListAPI: cards } = useFinancialFeedback();

  return (
    <Container>
      <Card
        filter="closing"
        title="Fechamento"
        status={cards?.closing?.progress}
        description="Reflete o andamento do fechamento pela perspectiva de imóveis"
        table={[
          {
            id: 1,
            indicator: 'Total imoveis',
            value: cards?.closing?.amount_active_property,
          },
          {
            id: 2,
            indicator: 'Imóveis com fechamento concluído',
            value: cards?.closing?.amount_closed_revenues,
          },
        ]}
      />
      <Card
        filter="nf"
        title="Notas Fiscais"
        status={cards?.nf?.progress}
        description="Reflete o andamento das notas fiscais pela perspectiva de imóveis"
        table={[
          {
            id: 1,
            indicator: 'Quantidade de NFs Concluídas',
            value: cards?.nf?.amount_concluded_nfs,
          },
          {
            id: 2,
            indicator: 'Quantidade de NFs Geradas',
            value: cards?.nf?.amount_generated_nfs,
          },
        ]}
      />
      <Card
        filter="nf"
        title="TEDs"
        status={cards?.ted?.progress}
        description="Reflete o andamento das TEDs pela perspectiva de imóveis"
        table={[
          {
            id: 1,
            indicator: 'Quantidade de TEDs Concluídas',
            value: cards?.ted?.amount_concluded_teds,
          },
          {
            id: 2,
            indicator: 'Quantidade de TEDs Geradas',
            value: cards?.ted?.amount_generated_teds,
          },
        ]}
      />
      <Card
        onlyStatus
        filter="host"
        title="Taxa cérebro"
        status={cards?.brain_fee?.is_aligned ? 100 : 0}
        description="Verificar as divergências de taxas comparadas ao padrão estabelecido"
        table={[
          {
            id: 1,
            indicator: 'Taxa cérebro diferentes de 35%',
            value: cards?.brain_fee?.amount_brain_fee,
          },
          {
            id: 2,
            indicator: 'Taxa cérebro de limpeza diferentes de 35% ou 15%',
            value: cards?.brain_fee?.amount_cleaning_brain_fee,
          },
        ]}
      />
      <Card
        onlyStatus
        filter="expenses"
        title="Despesas lançadas"
        status={cards?.expenses_for_month?.is_aligned ? 100 : 0}
        description="Despesas registradas no mês atual e que são referentes ao mesmo mês"
        table={[
          {
            id: 1,
            indicator: 'Despesas para o mês',
            value: cards?.expenses_for_month?.amount_expenses_for_month,
          },
          {
            id: 2,
            indicator: 'Despesas concluídas',
            value: cards?.expenses_for_month?.amount_expenses_concluded,
          },
          {
            id: 3,
            indicator: 'Despesas em análise',
            value: cards?.expenses_for_month?.amount_expenses_analysis,
          },
          {
            id: 4,
            indicator: 'Despesas pendentes',
            value: cards?.expenses_for_month?.amount_expenses_pending,
          },
          {
            id: 5,
            indicator: 'Despesas pagas',
            value: cards?.expenses_for_month?.amount_expenses_paid,
          },
        ]}
      />
      <Card
        onlyStatus
        filter="expenses"
        title="Despesas lançadas com atraso"
        status={cards?.expenses_overdue?.is_aligned ? 100 : 0}
        description="Despesas registradas no mês atual e que são referentes ao mês anterior"
        table={[
          {
            id: 1,
            indicator: 'Despesas lançadas com atraso',
            value: cards?.expenses_overdue?.amount_expenses_overdue,
          },
          {
            id: 2,
            indicator: 'Despesas concluídas',
            value: cards?.expenses_overdue?.amount_expenses_overdue_concluded,
          },
          {
            id: 3,
            indicator: 'Despesas em análise',
            value: cards?.expenses_overdue?.amount_expenses_overdue_analysis,
          },
          {
            id: 4,
            indicator: 'Despesas pendentes',
            value: cards?.expenses_overdue?.amount_expenses_overdue_pending,
          },
          {
            id: 5,
            indicator: 'Despesas pagas',
            value: cards?.expenses_overdue?.amount_expenses_overdue_paid,
          },
        ]}
      />
      <Card
        filter="manualFit"
        title="Quantidade de ajustes manuais"
        description="Visibilidade dos ajustes manuais realizados pelo time financeiro para: Proprietários, Anfitriões e Imóveis"
        table={[
          {
            id: 1,
            indicator: 'Quantidade de ajustes de imóvel',
            value: cards?.manual_fit?.property_manual_fit_amount,
          },
          {
            id: 2,
            indicator: 'Quantidade de ajustes de proprietario',
            value: cards?.manual_fit?.owner_manual_fit_amount,
          },
          {
            id: 3,
            indicator: 'Quantidade de ajustes de anfitrião',
            value: cards?.manual_fit?.host_manual_fit_amount,
          },
        ]}
      />
    </Container>
  );
};

export default CardList;
