/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useCreateBlockPropertyHostPageData } from '../../../context/CreateBlockPropertyInHostPage/CreateBlockPropertyInHostpage';
import { useToast } from '../../../context/ToastContext';
import { postHostBlocking } from '../../../services/Reservation/request';
import { BlockHostFormData } from '../../../services/Reservation/types';
import { ErrorMessage, useToastErrorMessage } from '../../../utils/Messages';
import { formatedDateToShow } from '../../../utils/Formatter';
import DatePickerRange from '../../DatePickerRange';
import FormButton from '../../FormButton';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import TextField from '../../TextField';

import {
  BoldText,
  Form,
  InformationDiarias,
  MidlleText,
  NotesContainer,
  ReasonsContainer,
  SaveButtonContainer,
} from './styles';

const validation = Yup.object().shape({
  reason: Yup.string().required(),
  checkInDate: Yup.date().required(),
  checkOutDate: Yup.date()
    .when('checkInDate',
      (checkInDate: any, schema: Yup.DateSchema) => checkInDate
        && schema.min(checkInDate, 'A data deve ser maior que data de check-in'))
    .required(),
});

const dataReasons = [
  {
    id: 0,
    name: 'Proprietário',
    value: 'Owner use',
  },
  {
    id: 1,
    name: 'Manutenção',
    value: 'Maintenance',
  },
  {
    id: 2,
    name: 'Limpeza',
    value: 'Cleaning',
  },
  {
    id: 3,
    name: 'Anfitrião',
    value: 'Host',
  },
];

const HostBlockPropertyForm = () => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const {
    selectedProperty,
    handleCloseModal,
    handleCheckIn,
    handleCheckOut,
    handleOpenSuccessModal,
  } = useCreateBlockPropertyHostPageData();

  const formik = useFormik({
    initialValues: {
      guests: '',
      checkInDate: new Date(),
      checkOutDate: new Date(),
      notes: '',
      reason: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const reason = dataReasons.find((item) => item.id === Number(values.reason));
        await postHostBlocking({
          ...values,
          property: selectedProperty,
          blockingReason: reason,
        } as unknown as BlockHostFormData);
        const successCheckIn = formatedDateToShow(values.checkInDate.toString());
        const successCheckOut = formatedDateToShow(values.checkOutDate.toString());
        handleCheckIn(successCheckIn);
        handleCheckOut(successCheckOut);

        toast.success('Pedido de bloqueio realizado com sucesso!');
        formik.resetForm();
        handleCloseModal();
        handleOpenSuccessModal();
      } catch (e: any) {
        if ([`${e?.error}`, `${e?.message}`].includes('property has extra day preparation')) {
          toast.error('Não é possível inserir um bloqueio neste período, pois o imóvel já está reservado para preparo de reserva.');
        } else if (e instanceof Error) {
          toastErrorRequest(e);
        } else {
          toast.error('Erro ao realizar bloqueio');
        }
      }
    },
  });

  const dailyQuantity = () => {
    const begin = moment(formik.values.checkInDate);
    const end = moment(formik.values.checkOutDate);
    const duration = moment.duration(end.diff(begin));

    return Math.round(duration.asDays());
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <BoldText>*Datas selecionadas</BoldText>
      <DatePickerRange
        hasInitialDates
        formik={formik}
        id1="checkInDate"
        id2="checkOutDate"
        minDate={new Date('2000-01-02')}
        showingLabel={false}
      />
      <InformationDiarias>
        <MidlleText>
          {'1 anúncio selecionado'}
        </MidlleText>
        <MidlleText>
          {`${dailyQuantity()} `}
          {dailyQuantity() === 1
            ? 'diária selecionada'
            : 'diárias selecionadas'}
        </MidlleText>
      </InformationDiarias>
      <ReasonsContainer>
        <SimpleSelect
          id="reason"
          dataCy="reason"
          placeholder="Selecione"
          label="Motivo do bloqueio"
          formik={formik}
          options={(dataReasons || []).map<SelectOption>(({ id, name }) => ({
            value: id,
            valueLabel: name,
          }))}
        />
      </ReasonsContainer>
      <NotesContainer>
        <TextField
          label="Notas"
          formik={formik}
          id="notes"
          dataCy="notes"
          type="textarea"
          placeholder="Adicione alguma observação sobre o bloqueio dessas datas"
        />
      </NotesContainer>
      <SaveButtonContainer>
        <FormButton type="submit" dataCy="btn-save">
          Salvar
        </FormButton>
      </SaveButtonContainer>
    </Form>
  );
};

export default HostBlockPropertyForm;
