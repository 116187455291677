import request from '../request';
import { ChangePropertyHostProps } from '../../context/ChangePropertyHostContext/types';

import {
  GetCategoryLocationData,
  GetHostDetailsById,
  GetOwnerBankDetailsById,
  GetOwnerDetailsById,
  GetOwnerInvoiceDetailsById,
  GetOwnersData,
  GetPartnerDetailsById,
  GetPropertiesResponse,
  GetPropertyChangeHostProps,
  GetPropertyHandoverDetails,
  GetUserDetailsById,
  PartnerExecutive,
  PatchEditAddress,
  PatchEditBankDetails,
  PatchEditHost,
  PatchEditInvoiceDetails,
  PatchEditUser,
  PatchOwner,
  PatchOwnerObject,
  PatchPartner,
  PatchPropertyChangeHostObject,
  PlanOptions,
  PostB2BCondominium,
  PostBankDetailsOwnerObject,
  PostCategoryLocationObject,
  PostHostObject,
  PostInvoiceDetailsOwnerObject,
  PostOwnerObject,
  PostPropertyAmenitiesObject,
  PostPropertyChangeHostObject,
  PostPropertyObject,
  PostPropertyRulesObject,
  PostPropertyStatusObject,
  PostUserObject,
  PropertyAmenitiesResponse,
  PropertyDetailsResponse,
  PropertyRulesResponse,
} from './types';
import { formatPropertyChangeHostData } from './utils';

export const postUser = async (user: PostUserObject) => {
  const response = await request.post('/account/user/', {
    ...user,
  });

  return response.data.id;
};

export const postOwner = async (owner: PostOwnerObject) => {
  const response = await request.post('/account/owner/', {
    ...owner,
  });

  return response.data.id;
};

export const postBankDetails = async (bankDetails: PostBankDetailsOwnerObject) => {
  const response = await request.post('/financial/owner/bank_details/', {
    is_default: bankDetails.is_default,
    entity_name: bankDetails.entity_name,
    branch_number: bankDetails.branch_number,
    account_number: bankDetails.account_number,
    account_type: bankDetails.account_type,
    cpf: bankDetails.cpf,
    cnpj: bankDetails.cnpj,
    pix_key: bankDetails.pix_key,
    pix_key_type: bankDetails.pix_key_type,
    bank: bankDetails.bank,
    user: bankDetails.user,
  });

  return response.data.id;
};

export const postInvoiceDetails = async (invoiceDetails: PostInvoiceDetailsOwnerObject) => {
  const response = await request.post('/financial/owner/invoice_details/', {
    is_default: invoiceDetails.is_default,
    invoice_entity_name: invoiceDetails.invoice_entity_name,
    cpf: invoiceDetails.cpf,
    email: invoiceDetails.email,
    phone_number: invoiceDetails.phone_number,
    postal_code: invoiceDetails.postal_code,
    address: invoiceDetails.address,
    address_number: invoiceDetails.address_number,
    complement: invoiceDetails.complement,
    district: invoiceDetails.district,
    city: invoiceDetails.city,
    state: invoiceDetails.state,
    user: invoiceDetails.user,
  });

  return response.data.id;
};

export const postHost = async (host: PostHostObject) => {
  const { data } = await request.post('/account/host/', {
    reservation_royalties: host.reservation_royalties,
    cleaning_royalties: host.cleaning_royalties,
    is_host_ops: host.is_host_ops,
    user: host.user,
    location: host.location,
  });
  return data.id;
};
export const deleteHost = async (id: number) => {
  await request.delete(`/account/host/${id}/`);
};
export const postPropertyAmenities = async ({
  basic,
  air_conditioning,
  baby_bath,
  baby_crib,
  baby_security_gate,
  babysitter_recommendation,
  barbecue,
  barbecue_utensils,
  bath_soap,
  bathtube,
  beach_basics,
  bedding,
  bidet,
  bikes,
  blackout_curtains,
  board_games,
  boat_ramp,
  breading_machine,
  breakfest,
  car_eletric_charger,
  carbon_monoxide_alarm,
  ceiling_fan, central_heating,
  changing_mat,
  children_books_and_toys,
  children_dishware,
  cleaning_before_checkout,
  cleaning_products,
  clothes_dryer,
  clothes_line,
  coffee_machine,
  compactor_trash_can,
  conditioner,
  confectionery_sheet,
  courtyard_or_balcony,
  dining_table, dishware,
  dishwasher,
  eletric_pot,
  eletronic_sitter,
  espresso_machine,
  exclusive_work_space,
  extra_blankets_and_pillows,
  filter_coffee_maker,
  fire_alarm,
  fire_extinguisher,
  fire_pit,
  fire_screen,
  first_aid_kit,
  free_street_parking_lot,
  freezer,
  garden_or_yard,
  ground_floor,
  gym,
  hairdryer,
  hangers,
  high_chair,
  hot_water,
  indoor_fireplace,
  insurance,
  internet_access,
  iron,
  jacuzzi,
  kayak,
  kettle,
  keurig_coffee_machine,
  kitchen,
  kitchen_basics,
  lake_access,
  leave_bags_allowed,
  microwave,
  minibar,
  mosquito_net,
  outdoor_dining_area,
  outdoor_furniture,
  outdoor_shower,
  oven,
  paid_inside_parking_lot,
  paid_street_parking_lot,
  parking_lot,
  piano,
  place_to_store_clothes,
  portable_fan,
  portable_playpen,
  portable_wifi,
  private_entrance,
  property,
  recorder,
  refrigerator,
  sea_view,
  shampoo,
  shower_gel,
  ski_entrance_and_exit,
  snooker_table,
  socket_protectors,
  sound_system,
  steam_room,
  stove,
  swimming_pool,
  table_croner_protector,
  table_tennis_table,
  toaster,
  tv,
  tv_subscription,
  video_game,
  washhouse_around,
  waters_view,
  wifi,
  window_net_protector,
  wine_glasses,
  washing_machine,
}: PostPropertyAmenitiesObject) => {
  await request.post('/property/amenities/', {
    basic,
    air_conditioning,
    cleaning_products,
    kitchen_basics,
    exclusive_work_space,
    dishware,
    clothes_dryer,
    hairdryer,
    central_heating,
    jacuzzi,
    kitchen,
    swimming_pool,
    tv,
    wifi,
    bathtube,
    bidet,
    bath_soap,
    conditioner,
    hot_water,
    outdoor_shower,
    shampoo,
    shower_gel,
    bedding,
    place_to_store_clothes,
    clothes_line,
    extra_blankets_and_pillows,
    hangers,
    iron,
    mosquito_net,
    blackout_curtains,
    insurance,
    tv_subscription,
    internet_access,
    video_game,
    piano,
    table_tennis_table,
    snooker_table,
    recorder,
    sound_system,
    baby_bath,
    eletronic_sitter,
    baby_security_gate,
    babysitter_recommendation,
    board_games,
    changing_mat,
    children_books_and_toys,
    children_dishware,
    baby_crib,
    fire_screen,
    high_chair,
    socket_protectors,
    portable_playpen,
    table_croner_protector,
    window_net_protector,
    ceiling_fan,
    indoor_fireplace,
    portable_fan,
    carbon_monoxide_alarm,
    fire_extinguisher,
    first_aid_kit,
    fire_alarm,
    portable_wifi,
    confectionery_sheet,
    barbecue_utensils,
    breading_machine,
    coffee_machine,
    dining_table,
    dishwasher,
    freezer,
    kettle,
    keurig_coffee_machine,
    microwave,
    minibar,
    espresso_machine,
    oven,
    filter_coffee_maker,
    refrigerator,
    eletric_pot,
    stove,
    toaster,
    compactor_trash_can,
    wine_glasses,
    sea_view,
    lake_access,
    washhouse_around,
    private_entrance,
    ski_entrance_and_exit,
    waters_view,
    barbecue,
    beach_basics,
    bikes,
    boat_ramp,
    fire_pit,
    garden_or_yard,
    kayak,
    outdoor_dining_area,
    outdoor_furniture,
    courtyard_or_balcony,
    car_eletric_charger,
    parking_lot,
    free_street_parking_lot,
    paid_street_parking_lot,
    paid_inside_parking_lot,
    gym,
    steam_room,
    ground_floor,
    breakfest,
    cleaning_before_checkout,
    leave_bags_allowed,
    washing_machine,
    property,
  });
};

export const postPropertyRules = async ({
  allow_pet,
  check_in_time,
  check_out_time,
  events_permitted,
  property,
  smoking_permitted,
  suitable_for_babies,
  suitable_for_children,
}: PostPropertyRulesObject) => {
  await request.post('/property/rules/', {
    allow_pet,
    check_in_time,
    check_out_time,
    events_permitted,
    property,
    smoking_permitted,
    suitable_for_babies,
    suitable_for_children,
  });
};

export const getPropertyChangeHost = async () => {
  const { data } = await request.get<GetPropertyChangeHostProps[]>('/property/change_property_host/');
  const dataFormatted = formatPropertyChangeHostData(data);
  return dataFormatted as ChangePropertyHostProps[];
};

export const postPropertyChangeHost = async ({
  new_host,
  old_host,
  property,
  replacement_date,
}: PostPropertyChangeHostObject) => {
  const { data } = await request.post<GetPropertyChangeHostProps>('/property/change_property_host/', {
    new_host,
    replacement_date,
    old_host,
    property,
  });

  const dataFormatted = formatPropertyChangeHostData([{ ...data }]);
  return dataFormatted?.[0] || {};
};

export const patchPropertyChangeHost = async (id: number,
  payload: PatchPropertyChangeHostObject) => {
  const { data } = await request.patch<GetPropertyChangeHostProps>(`/property/change_property_host/${id}/`, {
    ...payload,
  });

  const dataFormatted = formatPropertyChangeHostData([{ ...data }]);
  return dataFormatted?.[0] || {};
};

export const deletePropertyChangeHost = async (id: number) => request.delete<GetPropertyChangeHostProps>(`/property/change_property_host/${id}/`);

export const patchPropertyAmenities = async (id: number, {
  basic,
  air_conditioning,
  baby_bath,
  baby_crib,
  baby_security_gate,
  babysitter_recommendation,
  barbecue,
  barbecue_utensils,
  bath_soap,
  bathtube,
  beach_basics,
  bedding,
  bidet,
  bikes,
  blackout_curtains,
  board_games,
  boat_ramp,
  breading_machine,
  breakfest,
  car_eletric_charger,
  carbon_monoxide_alarm,
  ceiling_fan, central_heating,
  changing_mat,
  children_books_and_toys,
  children_dishware,
  cleaning_before_checkout,
  cleaning_products,
  clothes_dryer,
  clothes_line,
  coffee_machine,
  compactor_trash_can,
  conditioner,
  confectionery_sheet,
  courtyard_or_balcony,
  dining_table, dishware,
  dishwasher,
  eletric_pot,
  eletronic_sitter,
  espresso_machine,
  exclusive_work_space,
  extra_blankets_and_pillows,
  filter_coffee_maker,
  fire_alarm,
  fire_extinguisher,
  fire_pit,
  fire_screen,
  first_aid_kit,
  free_street_parking_lot,
  freezer,
  garden_or_yard,
  ground_floor,
  gym,
  hairdryer,
  hangers,
  high_chair,
  hot_water,
  indoor_fireplace,
  insurance,
  internet_access,
  iron,
  jacuzzi,
  kayak,
  kettle,
  keurig_coffee_machine,
  kitchen,
  kitchen_basics,
  lake_access,
  leave_bags_allowed,
  microwave,
  minibar,
  mosquito_net,
  outdoor_dining_area,
  outdoor_furniture,
  outdoor_shower,
  oven,
  paid_inside_parking_lot,
  paid_street_parking_lot,
  parking_lot,
  piano,
  place_to_store_clothes,
  portable_fan,
  portable_playpen,
  portable_wifi,
  private_entrance,
  property,
  recorder,
  refrigerator,
  sea_view,
  shampoo,
  shower_gel,
  ski_entrance_and_exit,
  snooker_table,
  socket_protectors,
  sound_system,
  steam_room,
  stove,
  swimming_pool,
  table_croner_protector,
  table_tennis_table,
  toaster,
  tv,
  tv_subscription,
  video_game,
  washhouse_around,
  waters_view,
  wifi,
  window_net_protector,
  wine_glasses,
  washing_machine,
}: PostPropertyAmenitiesObject) => {
  await request.patch(`/property/amenities/${id}/`, {
    basic,
    air_conditioning,
    cleaning_products,
    kitchen_basics,
    exclusive_work_space,
    dishware,
    clothes_dryer,
    hairdryer,
    central_heating,
    jacuzzi,
    kitchen,
    swimming_pool,
    tv,
    wifi,
    bathtube,
    bidet,
    bath_soap,
    conditioner,
    hot_water,
    outdoor_shower,
    shampoo,
    shower_gel,
    bedding,
    place_to_store_clothes,
    clothes_line,
    extra_blankets_and_pillows,
    hangers,
    iron,
    mosquito_net,
    blackout_curtains,
    insurance,
    tv_subscription,
    internet_access,
    video_game,
    piano,
    table_tennis_table,
    snooker_table,
    recorder,
    sound_system,
    baby_bath,
    eletronic_sitter,
    baby_security_gate,
    babysitter_recommendation,
    board_games,
    changing_mat,
    children_books_and_toys,
    children_dishware,
    baby_crib,
    fire_screen,
    high_chair,
    socket_protectors,
    portable_playpen,
    table_croner_protector,
    window_net_protector,
    ceiling_fan,
    indoor_fireplace,
    portable_fan,
    carbon_monoxide_alarm,
    fire_extinguisher,
    first_aid_kit,
    fire_alarm,
    portable_wifi,
    confectionery_sheet,
    barbecue_utensils,
    breading_machine,
    coffee_machine,
    dining_table,
    dishwasher,
    freezer,
    kettle,
    keurig_coffee_machine,
    microwave,
    minibar,
    espresso_machine,
    oven,
    filter_coffee_maker,
    refrigerator,
    eletric_pot,
    stove,
    toaster,
    compactor_trash_can,
    wine_glasses,
    sea_view,
    lake_access,
    washhouse_around,
    private_entrance,
    ski_entrance_and_exit,
    waters_view,
    barbecue,
    beach_basics,
    bikes,
    boat_ramp,
    fire_pit,
    garden_or_yard,
    kayak,
    outdoor_dining_area,
    outdoor_furniture,
    courtyard_or_balcony,
    car_eletric_charger,
    parking_lot,
    free_street_parking_lot,
    paid_street_parking_lot,
    paid_inside_parking_lot,
    gym,
    steam_room,
    ground_floor,
    breakfest,
    cleaning_before_checkout,
    leave_bags_allowed,
    washing_machine,
    property,
  });
};

export const patchPropertyRules = async (id: number, {
  allow_pet,
  check_in_time,
  check_out_time,
  events_permitted,
  property,
  smoking_permitted,
  suitable_for_babies,
  suitable_for_children,
}: PostPropertyRulesObject) => {
  await request.patch(`/property/rules/${id}/`, {
    allow_pet,
    check_in_time,
    check_out_time,
    events_permitted,
    property,
    smoking_permitted,
    suitable_for_babies,
    suitable_for_children,
  });
};

export const postProperty = async (property: PostPropertyObject) => {
  await request.post('property/manager/', {
    ...property,
    owners: [property.owners],
  });
};

export const patchProperty = async (id: number, property: PostPropertyObject) => {
  await request.patch(`property/manager/${id}/`, {
    ...property,
    owners: [property.owners],
  });
};

export const getPropertiesManager = async () => {
  const { data } = await request.get('property/manager/');
  return data as PropertyDetailsResponse[];
};

export const getPropertyMaganer = async (id: number) => {
  const { data } = await request.get(`property/manager/${id}`);
  return data as PropertyDetailsResponse;
};

export const postCategoryLocation = async (categoryLocation: PostCategoryLocationObject) => {
  const response = await request.post('/property/categorylocation/', {
    category: categoryLocation.category,
    location: categoryLocation.location,
  });
  return response.data.id;
};

export const postChangePropertyStatus = async (propertyStatus: PostPropertyStatusObject) => {
  const response = await request.post('/property/status_log/', {
    ...propertyStatus,
  });

  return response.data.id;
};

export const patchOwner = async (owner: PatchOwnerObject, ownerId: number) => {
  await request.patch(`/account/owner/${ownerId}/`, {
    default_bank_details: owner.default_bank_details,
    default_invoice_details: owner.default_invoice_details,
  });
};

export const deleteUser = async (id: number) => {
  await request.delete(`/account/user/${id}/`);
};

export const deleteOwner = async (id: number) => {
  await request.delete(`/account/owner/${id}/`);
};

export const deleteBankDetails = async (id: number) => {
  await request.delete(`/financial/owner/bank_details/${id}/`);
};

export const deleteInvoceDetails = async (id: number) => {
  await request.delete(`/financial/owner/invoice_details/${id}/`);
};

export const getPropertyAmenitiesById = async (id: number) => {
  const response = await request.get<PropertyAmenitiesResponse[]>('/property/amenities/', {
    params: {
      property: id,
    },
  });
  return response.data;
};

export const getPropertyRulesById = async (id: number) => {
  const response = await request.get<PropertyRulesResponse[]>('/property/rules/', {
    params: {
      property: id,
    },
  });
  return response.data;
};

export const deleteCategoryLocation = async (id: number) => {
  await request.delete(`/property/categorylocation/${id}/`);
};

export const getOwners = async () => {
  const { data } = await request.get<GetOwnersData[]>('/account/owner/');
  return data;
};

export const getOwnerDetailsById = async (id: number) => {
  const { data } = await request.get<GetOwnerDetailsById>(`/account/owner/${id}`);
  return data;
};

export const getUserDetailsById = async (id: number) => {
  const { data } = await request.get<GetUserDetailsById>(`/account/user/${id}`);
  return data;
};

export const getPartnerDetailsById = async (id: number) => {
  const { data } = await request.get<GetPartnerDetailsById>(`/account/partner/${id}`);
  return data;
};

export const getOwnerBankDetailsById = async (id: number) => {
  const { data } = await request.get<GetOwnerBankDetailsById>(`/financial/owner/bank_details/${id}/`);
  return data;
};

export const getOwnerInvoiceDetailsById = async (id: number) => {
  const { data } = await request.get<GetOwnerInvoiceDetailsById>(`/financial/owner/invoice_details/${id}/`);
  return data;
};

export const getCategoryLocationForSelect = async () => {
  const { data } = await request.get<GetCategoryLocationData[]>('/property/categorylocation/');
  return data;
};

export const patchEditAddress = async (id: number, address: PatchEditAddress) => {
  await request.patch<PatchEditAddress>(`/account/address/${id}/`, {
    ...address,
  });
};

export const patchEditOwner = async (id: number, owner: PatchOwner) => {
  await request.patch<PatchOwner>(`/account/owner/${id}/`, {
    ...owner,
  });
};

export const patchEditPartner = async (id: number, partner: PatchPartner) => {
  await request.patch<PatchPartner>(`/account/partner/${id}/`, {
    ...partner,
  });
};

export const postEditPartner = async (partner: PatchPartner) => {
  await request.post<PatchPartner>('/account/partner/', {
    ...partner,
  });
};

export const patchEditUser = async (id: number, user: PatchEditUser) => {
  await request.patch<PatchEditUser>(`/account/user/${id}/`, {
    ...user,
  });
};

export const patchEditBankDetails = async (id: number, bankDetails: PatchEditBankDetails) => {
  await request.patch<PatchEditBankDetails>(`/financial/owner/bank_details/${id}/`, {
    ...bankDetails,
  });
};

export const patchEditInvoiceDetails = async (id: number,
  invoiceDetails: PatchEditInvoiceDetails) => {
  await request.patch<PatchEditInvoiceDetails>(`/financial/owner/invoice_details/${id}/`, {
    ...invoiceDetails,
  });
};

export const getHostDetailsById = async (id: number) => {
  const { data } = await request.get<GetHostDetailsById>(`/account/host/${id}/`);
  return data;
};

export const patchEditHost = async (id: number, host: PatchEditHost) => {
  await request.patch<PatchEditInvoiceDetails>(`/account/host/${id}/`, {
    ...host,
  });
};

export const getProperties = async () => {
  const { data } = await request.get<GetPropertiesResponse[]>('/properties/properties_list/');
  return data;
};

export const getPropertyDetails = async () => {
  const { data } = await request.get<PropertyDetailsResponse[]>('/properties/details/');
  return data;
};

export const getPartnerExecutivesRequest = async () => {
  const { data } = await request.get<PartnerExecutive[]>('/partners/executive/');
  return data;
};

export const getPropertyHandoverDetails = async (property_id: number) => {
  const { data } = await request.get<GetPropertyHandoverDetails[]>('/property/handover_details/',
    {
      params: {
        property_id,
      },
    });
  return data;
};

export const patchPropertyHandoverDetails = async (handover_id: number, plan: PlanOptions) => {
  const { data } = await request.patch<GetPropertyHandoverDetails[]>(`/property/handover_details/${handover_id}/`,
    {
      plan,
    });
  return data;
};

export const postB2BCondominium = async (b2bCondominium: PostB2BCondominium) => {
  const response = await request.post('/partners/b2b_condominium/', {
    ...b2bCondominium,
  });

  return response.data;
};
