/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { FC } from 'react';

import Tag from '../Tag';

import { ManualFitResponseProps, ReservationProps } from '../../../../context/OwnerPage/OwnerExtractContext/types';
import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';

import {
  Container,
  Content,
  TitleContainer,
  SummaryContainer,
  SummaryContent,
  Divider,
  WrapperBox,
} from './styles';

const IncomeSection: FC = () => {
  const { extractList } = useOwnerExtract();
  const incomesPlatforms: string[] = [];
  const incomesFuturePlatforms: string[] = [];

  extractList?.[0]?.incomes?.reservations?.forEach((item) => {
    if (item?.platform !== '' && !incomesPlatforms.includes(item?.platform)) {
      incomesPlatforms.push(item.platform);
    }
  });

  extractList?.[0]?.incomes_future?.reservations?.forEach((item) => {
    if (item?.platform !== '' && !incomesFuturePlatforms.includes(item?.platform)) {
      incomesFuturePlatforms.push(item.platform);
    }
  });

  return (
    <Container>

      <Tag
        label="Entradas"
        disableDropIcon
        description="Total de receitas do mês"
        stickTag
      />

      <Content className="content-income">
        <TitleContainer>
          <span>Receitas</span>
          <Divider />
          <span>{`${extractList?.[0]?.incomes?.total}`}</span>
        </TitleContainer>

        {incomesPlatforms.map((platform) => (
          <SummaryContainer>
            <div key={platform} className="origin-platform">
              <h4>{platform}</h4>
            </div>

            {extractList?.[0]?.incomes?.reservations
              ?.map((item: ReservationProps) => item.platform === platform && (
                <SummaryContent key={item.id}>
                  <div className="range-dates">
                    <strong>{'Check-in '}</strong>
                    <span>{`${item.check_in_date} | `}</span>
                    <strong>{'Check-out '}</strong>
                    <span>{`${item.check_out_date}`}</span>
                  </div>
                  <Divider isDashed />
                  <div className="total-value">
                    <span>{`${item.dailys_value}`}</span>
                  </div>
                </SummaryContent>
              ))}
          </SummaryContainer>
        ))}

        {extractList?.[0]?.others_incomes?.manual_fits?.length > 0 && (
          <SummaryContainer>
            <div className="origin-platform">
              <h4>Outras receitas</h4>
            </div>

            {extractList?.[0]?.others_incomes?.manual_fits?.map((item: ManualFitResponseProps) => (
              <SummaryContent key={item.id}>
                <div className="range-dates">
                  <strong>{`${item.description} `}</strong>
                </div>
                <Divider isDashed />
                <div className="total-value">
                  <span>{`${item.value}`}</span>
                </div>
              </SummaryContent>
            ))}
          </SummaryContainer>
        )}
      </Content>

      {/* <Content className="content-income-future">
        <TitleContainer>
          <span>Receitas futuras</span>
          <Divider />
          <span>{`${numberToCurrency(extractList?.[0]?.incomes_future?.total || 0)}`}</span>
        </TitleContainer>

        {incomesFuturePlatforms.map((platform) => (
          <SummaryContainer>
            <div key={platform} className="origin-platform">
              <h4>{platform}</h4>
            </div>

            {extractList?.[0]?.incomes_future?.reservations
              ?.map((item: ReservationProps) => item.platform === platform && (
                <SummaryContent key={item.id}>
                  <div className="range-dates">
                    <strong>{'Check-in '}</strong>
                    <span>{`${item.check_in_date} | `}</span>
                    <strong>{'Check-out '}</strong>
                    <span>{`${item.check_out_date}`}</span>
                  </div>
                  <Divider isDashed />
                  <div className="total-value">
                    <span>{`${numberToCurrency(item.dailys_value)}`}</span>
                  </div>
                </SummaryContent>
              ))}
          </SummaryContainer>
        ))}

        <WrapperBox>
          <strong>{'Valores das plataformas Booking e Expedia, geralmente são pagos 30 dias corridos após a data do check-out.'}</strong>
        </WrapperBox>
      </Content> */}
    </Container>
  );
};

export default IncomeSection;
