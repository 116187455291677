import GreenCheckIcon from '../../../assets/icons/Onboarding/greenCheckIcon.svg';
import GrayCheckIcon from '../../../assets/icons/Onboarding/grayCheckIcon.svg';

import { useOnboardingPage } from '../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';
import { useToast } from '../../../context/ToastContext';

import {
  CheckIcon,
  Content,
  Phase,
  PhaseBar,
  PhaseContent,
  PhaseFinal,
  PhaseImageContainer,
  Phases,
  PhasesBar,
  PhasesContent,
  PhasesTitle,
} from './styles';

export default function OnboardingPhases() {
  const toast = useToast();
  const { phases, handleClickPhase } = useOnboardingPage();
  const { owner, propertyDetails, values } = useOnboardingHandover();

  const handleClick = (index: number) => {
    if (index === 1) {
      if (owner?.id && owner?.id !== 0) {
        handleClickPhase(index);
        return;
      }
      toast.error('Selecione um proprietário!');
      return;
    }
    if (index === 2) {
      if (propertyDetails && propertyDetails?.propertycode) {
        handleClickPhase(index);
        return;
      }
      toast.error('Preencha a seção Informações do imóvel');
      return;
    }
    if (index === 3) {
      if (values && values?.host?.id) {
        handleClickPhase(index);
        return;
      }
      toast.error('Preencha a seção Taxas e comissões');
      return;
    }
    handleClickPhase(index);
  };

  return (
    <Content>
      <PhasesTitle>
        <h1>Etapas do Onboarding</h1>
      </PhasesTitle>
      <PhasesContent>
        <Phases>
          {phases.map((phase, index) => (
            <>
              {phase.name !== 'Final' && (
              <PhaseContent length={(100 / phases.length + 1)}>
                <Phase
                  isActive={phase.isActive}
                  onClick={() => handleClick(index)}
                  isLong={phase.name === 'Taxas e comissões'}
                >
                  {phase.name}
                </Phase>
                <PhaseImageContainer>
                  {phase.isActive ? <CheckIcon src={GreenCheckIcon} alt="active icon" /> : <CheckIcon src={GrayCheckIcon} alt="inactive icon" />}
                </PhaseImageContainer>
              </PhaseContent>
              )}
            </>
          ))}
          <PhaseFinal
            length={(100 / phases.length + 1)}
            isActive
          >
            Final
          </PhaseFinal>
        </Phases>
        <PhasesBar>
          {phases.map((phase) => (
            <PhaseBar length={(100 / phases.length + 1)} isConcluded={phase.isConcluded} />
          ))}
          <PhaseBar length={(100 / phases.length + 1)} isConcluded={false} />
        </PhasesBar>
      </PhasesContent>
    </Content>
  );
}
