import { useEffect } from 'react';
import Upload from '../../../../../../../Upload';
import { ICardContent } from '../../types';
import photoSketch from '../../../../../../../../assets/icons/expense/photoSketch.svg';

import {
  Content, UploadFilesContainer, UploadedFilesContainer,
} from './style';
import { initialValuesFile } from '../../../../../../../../context/FileContext/FileContext';
import FilesList from './FilesList/FilesList';
import { ICategoryes } from '../../../types';
import {
  FileAWSFormat, FileProps, FileReference,
} from '../../../../../../../../context/FileContext/types';
import { useFile } from '../../../../../../../../hooks/FileHook/useFile';
import { useToast } from '../../../../../../../../context/ToastContext';
import { deleteExpenseFile } from '../../../../../../../../services/Expenses/request';
import { ExpenseFile } from '../../../../../../../../services/Expenses/types';
import { useExpense } from '../../../../../../../../hooks/ExpenseHook/useExpense';
import { useToastErrorMessage } from '../../../../../../../../utils/Messages';

type FileReferenceResponse = {
  id?: string;
  uid: string,
  name: string,
  category?: 'Maintenance' | 'Statement',
  content_type: string,
  storage: {
    url: string,
    fields: {
      acl: string,
      content_type?: string,
      key: string,
      AWSAccessKeyId: string,
      policy: string,
      signature: string
    }
  }
};

type FilesReferenceResponse = {
  id?: string;
  uid: string,
  name: string,
  category?: string,
  content_type: string,
  storage: {
    url: string,
    fields: {
      acl: string,
      content_type?: string,
      key: string,
      AWSAccessKeyId: string,
      policy: string,
      signature: string
    }
  }
};

function CardContent({
  isOpen,
  category,
  maintenanceFiles,
  setMaintenanceFiles,
  statementFiles,
  setStatementFiles,
  setUploadMaintenanceFile,
  setUploadStatementFile,
  uploadMaintenanceFile,
  uploadStatementFile,
  setSendingFileArray,
  allInformationExpenseFiles,
  setAllInformationExpenseFiles,
}: ICardContent) {
  const { uploadFileToS3, createFileReference } = useFile();
  const toast = useToast();

  const {
    handleExpenseData,
    expenseData,
  } = useExpense();

  const toastErrorRequest = useToastErrorMessage();

  const handleUploadFile = async (fileItem: FileProps) => {
    let fileResponse: FileReferenceResponse = {} as FileReferenceResponse;
    try {
      const fileReference: FileReference = {
        name: fileItem.name,
        category,
        content_type: fileItem.MIMEtype,
      };
      const responseFile: FilesReferenceResponse = await createFileReference(fileReference);
      const params: FileAWSFormat = {
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        key: responseFile.storage.fields.key,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        file: fileItem.file,
        fileId: responseFile.uid,
      };
      await uploadFileToS3(fileItem, params);
      let fileCategory:'Maintenance' | 'Statement' | undefined;
      if (responseFile.category === 'Statement') {
        fileCategory = 'Statement';
      }
      if (responseFile.category === 'Maintenance') {
        fileCategory = 'Maintenance';
      }
      fileResponse = {
        id: fileItem.id,
        ...responseFile,
        category: fileCategory,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
      setSendingFileArray((oldState) => [...oldState, fileResponse]);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error('Não foi possível fazer o upload da imagem');
      }
    }
  };

  function handleSetFileInFileArray(categoryFile: ICategoryes) {
    if (categoryFile === 'Maintenance') {
      setMaintenanceFiles((oldState) => [...oldState, uploadMaintenanceFile]);
    }

    if (categoryFile === 'Statement') {
      setStatementFiles((oldState) => [...oldState, uploadStatementFile]);
    }
  }

  async function handleDeleteFile(fileId: string) {
    try {
      if (category === 'Maintenance') {
        const newFileArray = maintenanceFiles.filter((file) => file.id !== fileId);
        setMaintenanceFiles(newFileArray);
        const filesToDelete = allInformationExpenseFiles.maintenanceExpenseFiles
          .find((file) => file.file.id === fileId);

        if (filesToDelete) {
          const filteredArray = allInformationExpenseFiles.maintenanceExpenseFiles
            .filter((expenseFile) => expenseFile.id !== filesToDelete.id);
          handleExpenseData((oldState) => ({
            ...oldState,
            maintenance_files: filteredArray,

          }));
          await deleteExpenseFile(filesToDelete.id);
        }
      }

      if (category === 'Statement') {
        const newFileArray = statementFiles.filter((file) => file.id !== fileId);
        setStatementFiles(newFileArray);
        const filesToDelete = allInformationExpenseFiles.statementExpenseFiles
          .find((file) => file.file.id === fileId);

        if (filesToDelete) {
          const filteredArray = allInformationExpenseFiles.statementExpenseFiles
            .filter((expenseFile) => expenseFile.id !== filesToDelete.id);
          handleExpenseData((oldState) => ({
            ...oldState,
            statement_files: filteredArray,

          }));
          await deleteExpenseFile(filesToDelete.id);
        }
      }
      setSendingFileArray((oldState) => oldState.filter((file) => file.id !== fileId));
    } catch (err) {
      toastErrorRequest(err);
    }
  }

  useEffect(() => {
    if (category === 'Maintenance' && uploadMaintenanceFile !== initialValuesFile) {
      handleSetFileInFileArray('Maintenance');
    }
    if (category === 'Statement' && uploadStatementFile !== initialValuesFile) {
      handleSetFileInFileArray('Statement');
    }
  }, [uploadMaintenanceFile, uploadStatementFile]);

  useEffect(() => {
    setUploadMaintenanceFile(initialValuesFile);
    if (category === 'Maintenance' && uploadMaintenanceFile !== initialValuesFile) {
      handleUploadFile(uploadMaintenanceFile);
    }
  }, [uploadMaintenanceFile]);

  useEffect(() => {
    setUploadStatementFile(initialValuesFile);
    if (category === 'Statement' && uploadStatementFile !== initialValuesFile) {
      handleUploadFile(uploadStatementFile);
    }
  }, [uploadStatementFile]);

  useEffect(() => {
    let statementExpenseFiles: ExpenseFile[] = [];
    let maintenanceExpenseFiles: ExpenseFile[] = [];
    if (expenseData?.maintenance_files && expenseData?.maintenance_files.length > 0) {
      maintenanceExpenseFiles = expenseData.maintenance_files;
      const newMaintenanceFiles = expenseData.maintenance_files.map((file) => file.file);
      setMaintenanceFiles(newMaintenanceFiles);
    } else {
      setMaintenanceFiles([]);
    }

    if (expenseData?.statement_files && expenseData?.statement_files.length > 0) {
      statementExpenseFiles = expenseData.statement_files;
      const newMaintenanceFiles = expenseData.statement_files.map((file) => file.file);
      setStatementFiles(newMaintenanceFiles);
    } else {
      setMaintenanceFiles([]);
    }

    setAllInformationExpenseFiles({
      statementExpenseFiles,
      maintenanceExpenseFiles,
    });
  }, [expenseData]);

  return (
    <Content
      layout
      exit={{ opacity: 0, y: 0 }}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      isOpen={isOpen}
    >
      {category === 'Maintenance' && (
        <>
          <UploadFilesContainer>
            <Upload
              file={uploadMaintenanceFile}
              setFile={setUploadMaintenanceFile}
              dataCy="input-maintenance-file"
              accept={['image/*', 'application/*', '.csv']}
              defaultPreview={photoSketch}
            />
          </UploadFilesContainer>
          <UploadedFilesContainer>
            {maintenanceFiles.length > 0 && maintenanceFiles.map((file, index) => (
              <FilesList
                index={index}
                MIMEtype={file.MIMEtype}
                preview={file?.previewURL || file?.url}
                id={file.id}
                text={file.name}
                onDelete={() => handleDeleteFile(file.id)}
              />
            ))}
          </UploadedFilesContainer>
        </>
      )}

      {category === 'Statement' && (
        <>
          <UploadFilesContainer>
            <Upload
              file={uploadStatementFile}
              setFile={setUploadStatementFile}
              dataCy="input-statement-file"
              accept={['image/*', 'application/*', '.csv']}
              defaultPreview={photoSketch}
            />
          </UploadFilesContainer>
          <UploadedFilesContainer>
            {statementFiles.length > 0 && statementFiles.map((file, index) => (
              <FilesList
                index={index}
                MIMEtype={file.MIMEtype}
                preview={file?.previewURL || file?.url}
                id={file.id}
                text={file.name}
                onDelete={() => handleDeleteFile(file.id)}
              />
            ))}
          </UploadedFilesContainer>
        </>
      )}
    </Content>
  );
}

export default CardContent;
