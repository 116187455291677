import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 575px;
  padding: 15px 30px;
  margin-top: 23px;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.palette.grey._240.hex()};
  border-radius: 10px;

  >p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.palette.grey._750.hex()};
    margin-top: 27px;
  }

  > span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  > h2 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  @media(max-width: 470px){
    height: auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 10px 0;

  &.column {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  section {
    overflow-y: scroll;
    margin-bottom: 10px;
    width: 100%;
    height: 280px;
  }

  @media(max-width: 470px){
    margin: 0;
  }
`;
