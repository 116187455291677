import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { v4 as uuid } from 'uuid';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from './Modal/Modal';
import GridCards from './GridContent/Cards/GridCards';
import HeaderConfig from './HeaderConfig/HeaderConfig';
import DatesGridHeader from './GridContent/Header/DatesGridHeader';
import { PendencyModal } from './PendencyModal';
import { getUserMe } from '../../services/User/request';
import { HeaderConfigContext } from '../../context/ControllerPage/HeaderConfigContext';

import {
  WrapperCards,
  ControllerContainer,
  Content,
  ContentContainer,
  ConcludedCardsTitle,
  NoTasksToday,
  NoMoreTasksToday,
} from './style';

import { RequestEvent, TypeProps } from '../../services/Control/types';
import BeachUmbrela from '../../assets/icons/controll/BeachUmbrella.svg';
import GridCardsShimmer from './LoadingShimmer/GridCardsShimmer/GridCardsShimmer';
import {
  useEventCheckin, useEventCheckout, useEventCleaning, useEventList,
} from '../../hooks/useEvents/useEvents';

enum ModalKeyEnum {
  checkin = 1,
  checkout = 2,
  cleaning = 3,
}

const ControllerComponent = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalKey, setModalKey] = useState<ModalKeyEnum>(ModalKeyEnum.checkin);
  const [openLateralModal, setOpenLateralModal] = useState<boolean>(false);
  const [dates, setDates] = useState<Moment[]>([]);
  const [checkList, setCheckList] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);

  const [reservationOpen, setReservationOpen] = useState<RequestEvent>();

  const [items, setItems] = useState<Array<number>>([0, 1, 2, 3, 4, 5, 6]);
  const [previneDefault, setPrevineDefault] = useState<boolean>(true);
  const [statusContact, setStatusContact] = useState<boolean>(false);

  const {
    showCheckin,
    showCheckout,
    showConcluded,
  } = useContext(HeaderConfigContext);

  const handleClick = (
    event: any,
    modalIndex: ModalKeyEnum,
    type: TypeProps,
    reservation?: RequestEvent,
  ) => {
    if (reservation) {
      setReservationOpen({ ...reservation, type });
    }

    setModal(true);
    setModalKey(modalIndex);

    if (previneDefault) {
      event.stopPropagation();
    }
  };

  function useWindowSize() {
    const { innerWidth: width } = window;
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  const handleNextWeek = () => {
    const nextDates = Array.from({ length: mobile ? 2 : 8 },
      (_, days: number) => moment(dates[dates.length - 1]).add(days, 'day'));
    nextDates.shift();
    setDates(nextDates);
  };

  const handlePrevWeek = () => {
    const nextDates = Array.from({ length: mobile ? 2 : 8 },
      (_, days: number) => moment(dates[0]).add(-days, 'day'));
    nextDates.shift();
    setDates(nextDates.reverse());
  };

  const genRandomDayArray = () => {
    const randomDayCardNumber = () => Math.floor(Math.random() * (4 - 1 + 1));

    const randomArray = Array(7)
      .fill(0)
      .map(() => randomDayCardNumber());
    const weekArray = Array.from({ length: 7 }, () => randomArray);

    return weekArray;
  };

  const {
    data, isFetching, isLoading, isSuccess, isFetched,
  } = useEventList(dates, mobile);

  const [haveDataInCache, setHaveDataInCache] = useState(true);

  useEffect(() => {
    if (isFetched === false) {
      setHaveDataInCache(false);
    }
  }, [isFetched]);

  useEffect(() => {
    if (isFetched === true) {
      setHaveDataInCache(true);
    }
  }, [dates]);

  useEffect(() => {
    setPrevineDefault(false);
  }, [checkList]);

  useEffect(() => {
    setItems(mobile ? [0] : [0, 1, 2, 3, 4, 5, 6]);
  }, [mobile]);

  useEffect(() => {
    window.addEventListener('resize', useWindowSize);
    const nextDates = Array.from({ length: mobile ? 1 : 7 },
      (_, days: number) => moment(moment()).add(days, 'day'));

    return setDates(nextDates);
  }, [mobile]);

  useEffect(() => {
    useWindowSize();
  }, []);

  function sortByHour(a: RequestEvent, b: RequestEvent): number {
    if (b.hour === '') {
      return -1;
    }
    if (a.hour === '') {
      return 1;
    }
    if (a.hour > b.hour) {
      return 1;
    }
    if (a.hour < b.hour) {
      return -1;
    }
    return 0;
  }
  const fecthLoadingStyles = createTheme({
    palette: {
      secondary: {
        main: '#0D4BD0',
      },
    },
  });

  const [isOpenPendencyModal, setIsOpenPendencyModal] = useState(false);

  useEffect(() => {
    const handleHaveGuestDamagePendency = async () => {
      const response = await getUserMe();
      const requiredActions = response?.required_actions?.Host;
      if (requiredActions) {
        if (requiredActions.includes('INVALID_GUEST_DAMAGE_INFO')) {
          setIsOpenPendencyModal(true);
        } else {
          setIsOpenPendencyModal(false);
        }
      }
    };
    handleHaveGuestDamagePendency();
  }, []);

  function handleClosePendencyModal() {
    setIsOpenPendencyModal(false);
  }

  const taskFilteredData = (
    dataFilter: any, dateField: any, day: any,
  ) => (dataFilter || []).filter((item: any) => (!mobile
    ? moment(item[dateField], 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'), dates[6])
    : true)
      && moment(item[dateField], 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
      && !(item?.checklist?.concluded && (item.was_contacted || item.status === 'COMPLETE')));

  const taskConcludedData = (
    dataFilter: any, dateField: any, day: any) => (dataFilter || []).filter((item: any) => (!mobile
    ? moment(item[dateField], 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'), dates[6])
    : true)
      && moment(item[dateField], 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
      && item?.checklist?.concluded && (item.was_contacted || item.status === 'COMPLETE'));

  const noMoreTasksToday = (day: any) => {
    const checkinListFilter = (data || []).filter((item) => item.type === 'checkin');
    const checkoutListFilter = (data || []).filter((item) => item.type === 'checkout');
    const cleaningListFilter = (data || []).filter((item) => item.type === 'cleaning');

    const noCheckins = taskFilteredData(checkinListFilter, 'check_in_date', day).length === 0;
    const noCheckouts = taskFilteredData(checkoutListFilter, 'check_out_date', day).length === 0;
    const noCleanings = taskFilteredData(cleaningListFilter, 'check_out_date', day).length === 0;

    const concludedCheckins = taskConcludedData(checkinListFilter, 'check_in_date', day).length !== 0;
    const concludedCheckouts = taskConcludedData(checkoutListFilter, 'check_out_date', day).length !== 0;
    const concludedCleanings = taskConcludedData(cleaningListFilter, 'check_out_date', day).length !== 0;

    return noCheckins && noCheckouts && noCleanings
      && (concludedCheckins || concludedCheckouts || concludedCleanings);
  };

  const filteredData = (
    dataFilter: any, dateField: any, day: any) => dataFilter.filter((item: any) => (!mobile
    ? moment(item[dateField], 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'), dates[6])
    : true)
      && moment(item[dateField], 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
      && !(item?.checklist?.concluded && (item.was_contacted || item.status === 'COMPLETE')));

  const concludedData = (
    dataFilter: any, dateField: any, day: any) => dataFilter.filter((item: any) => (!mobile
    ? moment(item[dateField], 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'), dates[6])
    : true)
      && moment(item[dateField], 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
      && item?.checklist?.concluded && (item.was_contacted || item.status === 'COMPLETE'));

  const noReservationToday = (day: any) => {
    const checkinListFilter = (data || []).filter((item) => item.type === 'checkin');
    const checkoutListFilter = (data || []).filter((item) => item.type === 'checkout');
    const cleaningListFilter = (data || []).filter((item) => item.type === 'cleaning');

    const noCheckins = filteredData(checkinListFilter, 'check_in_date', day).length === 0;
    const noConcludedCheckins = concludedData(checkinListFilter, 'check_in_date', day).length === 0;
    const noCheckouts = filteredData(checkoutListFilter, 'check_out_date', day).length === 0;
    const noConcludedCheckouts = concludedData(checkoutListFilter, 'check_out_date', day).length === 0;
    const noCleanings = filteredData(cleaningListFilter, 'check_out_date', day).length === 0;
    const noConcludedCleanings = concludedData(cleaningListFilter, 'check_out_date', day).length === 0;

    return noCheckins && noConcludedCheckins && noCheckouts
      && noConcludedCheckouts && noCleanings && noConcludedCleanings;
  };

  return (
    <>
      {modal && modalKey === 1 && (
      <Modal
        key={'modalCheckin'}
        recive
        checkin
        mobile={mobile}
        onClose={handleClick}
        checkList={checkList}
        setCheckList={setCheckList}
        reservationId={reservationOpen?.id}
        reservation={reservationOpen}
        setReservation={setReservationOpen}
        lateralModal={openLateralModal}
        onOpenLateralModal={() => setOpenLateralModal(true)}
        onCloseLateralModal={() => setOpenLateralModal(false)}
        statusContact={statusContact}
        setStatusContact={setStatusContact}
      />
      )}
      {modal && modalKey === 2 && (
      <Modal
        key={'modalCheckout'}
        hot
        checkout
        mobile={mobile}
        onClose={handleClick}
        setReservation={setReservationOpen}
        checkList={checkList}
        setCheckList={setCheckList}
        reservation={reservationOpen}
        reservationId={reservationOpen?.id}
        lateralModal={openLateralModal}
        onOpenLateralModal={() => setOpenLateralModal(true)}
        onCloseLateralModal={() => setOpenLateralModal(false)}
        statusContact={statusContact}
        setStatusContact={setStatusContact}
      />
      )}
      {isOpenPendencyModal === true && (
        <PendencyModal handleClosePendencyModal={handleClosePendencyModal} />
      )}
      <ControllerContainer checkList={checkList}>
        <HeaderConfig
          modalIsOpened={modal}
          mobile={mobile}
          setDates={setDates}
          dates={dates}
          checkList={checkList}
        />
        {showConcluded && (
          <div>
            <ConcludedCardsTitle backgroundColor={false}>
              Concluídos
            </ConcludedCardsTitle>
          </div>
        )}

        {isFetching && !isLoading && (
          <ThemeProvider theme={fecthLoadingStyles}>
            <LinearProgress color="secondary" />
          </ThemeProvider>
        )}
        <Content mobile={mobile}>
          <DatesGridHeader
            data={data}
            handleNextWeek={handleNextWeek}
            handlePrevWeek={handlePrevWeek}
            mobile={mobile}
            key={'datagrid2'}
            dates={dates}
          />
          {showConcluded ? (
            <>
              {items.map((day, index) => (
                <WrapperCards key={`${day}cards`}>
                  {isLoading && (
                    <>
                      {genRandomDayArray().map((week) => (
                        <>
                          {week.map(() => (
                            <GridCardsShimmer key={uuid()} />
                          ))}
                        </>
                      ))}
                    </>
                  )}
                  <ContentContainer
                    checkList={checkList}
                    hidden={!showConcluded}
                    key={`${day}concludedcards`}
                    backgroundColor={index % 2 === 0}
                  >
                    {isSuccess && data !== undefined && (
                      data.filter((item) => (!mobile
                        ? moment(item.date, 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'),
                          dates[6])
                        : true)
                            && moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
                            && item?.checklist?.concluded
                            && item.was_contacted)
                        && data.filter((item) => (!mobile
                          ? moment(item.date, 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'),
                            dates[6])
                          : true)
                            && moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
                            && item?.checklist?.concluded
                            && item.status === 'COMPLETE')
                    )
                      .sort(sortByHour)
                      .map((item) => (
                        <>
                          {item.type === 'checkin' && (
                            <GridCards
                              className="data-cy-card-concluded"
                              dataCy={`card_${item.id}_checkin_concluded`}
                              key={item.id}
                              checkin
                              talked={item.was_contacted}
                              hot={item.warm_bed}
                              checkout={false}
                              guest={
                                  item.ota !== 'Blocking'
                                    ? item.name
                                    : 'Bloqueio'
                                }
                              hidden={!showCheckin}
                              setOpen={(e: Event) => handleClick(
                                e,
                                ModalKeyEnum.checkin,
                                'checkin',
                                item,
                              )}
                              property={item.property_code}
                              hour={item.check_in_time}
                              haveDataInCache={haveDataInCache}
                            />
                          )}
                          {item.type === 'checkout' && (
                            <GridCards
                              className="data-cy-card-concluded"
                              dataCy={`card_${item.id}_checkout_concluded`}
                              key={item.id}
                              checkout
                              talked={item.was_contacted}
                              hot={item.warm_bed}
                              checkin={false}
                              guest={
                                  item.ota !== 'Blocking'
                                    ? item.name
                                    : 'Bloqueio'
                                }
                              hidden={!showCheckout}
                              setOpen={(e: Event) => handleClick(
                                e,
                                ModalKeyEnum.checkout,
                                'checkout',
                                item,
                              )}
                              property={item.property_code}
                              hour={item.check_out_time}
                              haveDataInCache={haveDataInCache}
                            />
                          )}
                        </>
                      ))}
                  </ContentContainer>
                </WrapperCards>

              ))}
            </>
          ) : (
            <>
              {items.map((day, index) => (
                <WrapperCards key={`${day}cards`}>
                  {isLoading && (
                    <>
                      {genRandomDayArray().map((week) => (
                        <>
                          {week.map(() => (
                            <GridCardsShimmer key={uuid()} />
                          ))}
                        </>
                      ))}
                    </>
                  )}
                  <ContentContainer
                    key={`${day}cards`}
                    checkList={checkList}
                    className="content grid"
                    backgroundColor={index % 2 === 0}
                  >
                    {isSuccess
                    && data !== undefined && (
                      data.filter((item) => (!mobile
                        ? moment(item.date, 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'),
                          dates[6])
                        : true)
                          && moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
                          && !(item?.checklist?.concluded && item.was_contacted))
                      && data.filter((item) => (!mobile
                        ? moment(item.date, 'YYYY-MM-DD').isBetween(dates[0].diff(1, 'days'),
                          dates[6])
                        : true)
                          && moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === dates[day].format('YYYY-MM-DD')
                          && !(
                            item?.checklist?.concluded
                            && item.status === 'COMPLETE'
                          ))
                    )
                      .sort(sortByHour)
                      .map((item) => (
                        <>
                          {item.type === 'checkin' && (
                            <GridCards
                              key={item.id}
                              className="data-cy-card-checkin"
                              dataCy={`card_${item.id}_checkin`}
                              checkin
                              talked={item.was_contacted}
                              hot={item.warm_bed}
                              checkout={false}
                              guest={
                                item.ota !== 'Blocking' ? item.name : 'Bloqueio'
                              }
                              hidden={!showCheckin}
                              setOpen={(e: Event) => handleClick(
                                e, 1, 'checkin', item,
                              )}
                              property={item.property_code}
                              hour={item.check_in_time}
                              haveDataInCache={haveDataInCache}
                            />
                          )}

                          {item.type === 'checkout' && (
                            <GridCards
                              key={item.id}
                              className="data-cy-card-checkout"
                              dataCy={`card_${item.id}_checkout`}
                              setOpen={(e: Event) => handleClick(
                                e, 2, 'checkout', item,
                              )}
                              checkout
                              talked={item.was_contacted}
                              hot={item.warm_bed}
                              checkin={false}
                              guest={
                                item.ota !== 'Blocking' ? item.name : 'Bloqueio'
                              }
                              hidden={!showCheckout}
                              property={item.property_code}
                              hour={item.check_out_time}
                              haveDataInCache={haveDataInCache}
                            />
                          )}
                        </>
                      ))}

                    {isSuccess && noMoreTasksToday(day) && (
                    <NoMoreTasksToday>
                      <p>Todas as atividades concluídas</p>
                      <CheckCircleOutlineIcon />
                    </NoMoreTasksToday>
                    )}

                    {isSuccess && noReservationToday(day) && (
                    <NoTasksToday>
                      <p>Nenhuma reserva para este dia</p>
                      <img src={BeachUmbrela} alt="Imagem guarda sol" />
                    </NoTasksToday>
                    )}

                  </ContentContainer>
                </WrapperCards>
              ))}
            </>
          )}
        </Content>
      </ControllerContainer>
    </>
  );
};

export default ControllerComponent;
