import React from 'react';

import { Container } from './style';

import {
  variantList,
} from '../../../../utils/animations';

import { IIndications } from '../../../../types';
import { usePartners } from '../../../../../../context/Partners/Partner/PartnerContext';

import NotFound from './NotFound';
import Indications from './Indications';

const validateIndication = (singleIndications: IIndications | undefined) => {
  if (singleIndications?.allotments && singleIndications?.allotments.length > 0) {
    return true;
  }

  if (singleIndications?.propretys && singleIndications?.propretys.length > 0) {
    return true;
  }

  if (singleIndications?.spots && singleIndications?.spots.length > 0) {
    return true;
  }

  return false;
};

const Content = () => {
  const { indications } = usePartners();

  return (
    <Container
      initial="hidden"
      animate="visible"
      variants={variantList(0.1)}
    >
      {validateIndication(indications) ? <Indications /> : <NotFound />}
    </Container>
  );
};

export default Content;
