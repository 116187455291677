import styled from 'styled-components';

export const ContentClose = styled.div`
  width: 100%;

  margin-top: 5px;
  display: none;
  justify-content: flex-end;
  padding-right: 10px;

  svg {
    fill: ${({ theme }) => theme.palette.orange.main.hex()};
    right: 0;
    left: auto;
  }

  @media(max-width: 900px) {
    display: flex;
  }
`;

export const Container = styled.form`
  div {
    &.contentPhone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
}
  @media (max-width: 900px) {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin-bottom: 20px;
    div {
      &.contentPhone {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 15px;


      }
    }
  }

  @media (max-width: 463px) {
    margin-bottom: 20px;
    div {
      &.contentPhone {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      }
    }
  }
`;

export const ColInput = styled.div`
  width: 19%;
  height: 100%;

  &:last-child {
    margin-right: 10px;
  }

  &.delete {
    margin-top: 20px;
    width: 15%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  svg, img {
    fill: ${({ theme }) => theme.palette.orange.main.hex()};
    :hover {
      cursor: pointer;
      filter: brightness(0.9);
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);

    }

  }

  @media (max-width: 900px) {
     width: 95%;

     &.delete {
      width: 95%;
  }

  img {
    display: none;
  }
  }
`;

export const ButtonDiscard = styled.button`
  background: #EBEBF5;
  width: 49%;
  height: 35px;
  margin-right: 10px;

  border-radius: 10px;
  border: 1px solid #D9DCDF;

  color: #B5B5B5;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 28px;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};

  width: 49%;
  height: 35px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 28px;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
`;
