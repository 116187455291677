/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { BodyShimmer } from '../LoadingShimmer';
import { useOwnerTedList } from '../../../hooks/OwnerTedListHook/useOwnerTedList';

import {
  convertStringToStatusProps,
} from '../utils';

import Body from './Body';
import Header from './Header';
import NoDataFound from './NoDataFound';

import {
  Container,
  ShimmerContainer,
  ContainerSpinner,
  Spinner,
} from './styles';

import Modal from './Modal';
import ModalConfirmDeleteVoucher from './Modal/ModalConfirmDeleteVoucher';
import ModalUpdateTedStatus from './Modal/ModalUpdateTedStatus';

const Grid = () => {
  const { listItems, loading } = useOwnerTedList();
  const { ref, inView } = useInView();

  const DEFAULT_LIMIT = 15;
  const [limitData, setLimitData] = useState<number>(listItems?.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : listItems?.length);

  const [isLoadMoreDatas, setIsLoadMoreDatas] = useState<boolean>(false);

  const handleLoadMoreDatas = () => {
    setLimitData(listItems?.length > 0
      && limitData >= listItems?.length
      ? listItems?.length : limitData + DEFAULT_LIMIT);
  };

  useEffect(() => {
    setLimitData(listItems?.length > DEFAULT_LIMIT ? DEFAULT_LIMIT : listItems?.length);
  }, [listItems]);

  useEffect(() => {
    if (inView) {
      setIsLoadMoreDatas(true);

      setTimeout(() => {
        handleLoadMoreDatas();
        setIsLoadMoreDatas(false);
      }, 300);
    }
  }, [inView]);

  return (
    loading ? (
      <ShimmerContainer>
        <BodyShimmer />
      </ShimmerContainer>
    ) : listItems?.length === 0 ? (
      <Container>
        <NoDataFound />
      </Container>
    ) : (
      <Container>
        <Modal />
        <ModalConfirmDeleteVoucher />
        <ModalUpdateTedStatus />

        <Header />
        {listItems?.length > 0 && listItems?.slice(0, limitData)?.map((item) => (
          <Body
            id={item.id}
            key={item.id}
            cpf={item.cpf}
            name={item.name}
            bank={item.bank}
            cnpj={item.cnpj}
            pixKey={item.pixKey}
            pixKeyType={item.pixKeyType}
            agency={item.agency}
            account={item.account}
            transfer={item.transfer}
            nameToTed={item.nameToTed}
            bankNumber={item.bankNumber}
            accountType={item.accountType}
            statmentImage={item.statmentImage}
            status={convertStringToStatusProps(item.status)}
            transferDay={item.transferDay}
            checked={item.checked}
          />
        ))}

        {listItems?.length > DEFAULT_LIMIT && listItems?.length > limitData && (
          <ContainerSpinner ref={ref}>
            <Spinner isLoading={isLoadMoreDatas} />
          </ContainerSpinner>
        )}
      </Container>
    )
  );
};

export default Grid;
