import React from 'react';
import { SearchRounded } from '@mui/icons-material';
import { UseFormik } from '../../../../utils/Formik/types';
import { useToast } from '../../../../context/ToastContext';
import { getOwnerTeds } from '../../../../services/OwnerTedList/request';
import { useOwnerTedList } from '../../../../hooks/OwnerTedListHook/useOwnerTedList';

import {
  Container,
  StyledTextField,
} from './styles';

interface ITedListTextField {
  id: string;
  formik: UseFormik<any>;
  onClick?: Function;
}

type IEventCustomType = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const TedListTextField = ({ id, formik, onClick }: ITedListTextField) => {
  const { handleListItems, handleLoading } = useOwnerTedList();
  const toast = useToast();

  const handleGetTeds = async () => {
    handleLoading(true);
    const formattedPeriod = `${formik.values.period.getFullYear()}-${
      formik.values.period.getMonth() + 1
    }`;

    const params = {
      period: formattedPeriod,
      termToFilter: formik.values[id],
    };

    try {
      const response = await getOwnerTeds(params);
      handleListItems(response.formatDataToListItem);

      if (response.formatDataToListItem.length === 0) {
        toast.alert('Nenhum dado foi encontrado');
        handleLoading(false);
      }

      handleLoading(false);
      return response;
    } catch (err) {
      toast.error('Erro ao buscar os TEDs');
      handleLoading(false);
      return false;
    }
  };

  const handleSetDataInFormikIdValue = (e: IEventCustomType) => {
    const customValue: string = e.currentTarget.value;
    formik.setFieldValue(id, customValue);
  };

  const handleKeyPress = (e: { key: string; }) => {
    if (e.key === 'Enter' && onClick) {
      onClick();
      handleGetTeds();
    }
  };

  return (
    <Container>
      <StyledTextField>
        <SearchRounded
          className="icon"
          onClick={() => handleGetTeds()}
          style={{ fontSize: 22, color: '#2860D8' }}
        />
        <input
          id={id}
          type="text"
          onKeyUp={(e) => handleKeyPress(e)}
          placeholder="Busque por proprietários"
          onChange={(e) => handleSetDataInFormikIdValue(e)}
        />
      </StyledTextField>
    </Container>
  );
};

TedListTextField.defaultProps = {
  onClick: () => {},
};

export default TedListTextField;
