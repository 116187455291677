import React, { useEffect } from 'react';

import { Container } from './styles';
import { useOnboardingPage } from '../../hooks/useOnboarding/useOnboarding';

import OnboardingPage from '../../components/OnboardingPage';
import NewOwnerModal from '../../components/OnboardingPage/NewOwnerModal';

import {
  InsertPropertyCategoryLocationProvider,
} from '../../context/InsertPropertyCategoryLocationContext/InsertPropertyCategoryLocationContext';

const Onboarding: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Onboarding';
  }, []);
  const { isOpenModal } = useOnboardingPage();

  return (
    <>
      <Container>
        <InsertPropertyCategoryLocationProvider>
          <OnboardingPage />
        </InsertPropertyCategoryLocationProvider>
      </Container>
      {isOpenModal && (
        <NewOwnerModal />
      )}
    </>
  );
};

export default Onboarding;
