import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FullCard = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 92%;
  height: 100%;
  margin-top: 27px;
  margin-left: 30px;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border-left: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    border-right: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
  }
`;

export const AuxDiv = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 3%;
  height: 100%;
  margin-top: 27px;
  border-radius: 0 10px 10px 0;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  margin: 50px 27px 0 18px;
  width: 93%;

  form {
    display: flex;
    align-items: center;
    height: 50px;

    .field-deal {
      width: 200px;
      margin-right: 5px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-size: 30px;
  width: 23%;
`;

export const CircleLoading = styled.div<{
  size: string;
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 3px solid ${({ theme }) => theme.palette.blue._910.hex()};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: ${spin} 1s linear infinite;

  ::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color:  ${({ theme }) => theme.palette.blue._400.hex()};
}

`;

export const CardLoading = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  h1 {
    padding-top: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme.palette.grey._800.hex()}
  }
`;

export const ContentShowCards = styled.div`
  .steps {
    display: none;

    &.active {
      display: block;
    }
  }
`;
