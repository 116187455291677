import styled from 'styled-components';

export const PropertyListContainer = styled.div`
  margin: 1rem 0 2rem;
`;

export const ListTitle = styled.div`
  font-family: 'Inter';
  font-size: 1.6rem;
  color: #001840;

  @media (max-width: 900px) {
    font-size: 1.375rem;
  }
`;

export const PropertyCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
