/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  ReactElement,
  useRef,
} from 'react';

import {
  createContext,
  useContextSelector,
} from 'use-context-selector';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../ToastContext';
import { EnumType, IPartnersResume, partnersRoutes } from '../../../pages/Partners/types';

import {
  getAllotmentIndication,
  getPropretyIndication,
  getInvestmentIndication,
} from '../../../services/Partner/request';

import {
  IConversion,
  IIndications,
  IInvestmentIndication,
} from '../../../pages/Partners/types/apis/indications';

import {
  IMonitoringSheet,
  getMonitoringSheet,
} from '../../../pages/Partners/utils/monitoringSheet';

import { useUser } from '../../UserContext';
// import { useMobile } from '../../../hooks/useMobile/useMobile';
import { getPartnersResume } from '../../../services/Partner';
import { getPartnersFinancialWithdrawRequest } from '../../../services/Partner/Financial';
import { IPartinersFinancialWithdrawBase } from '../../../services/Partner/Financial/Withdraw/Request/types';
import { useMobile } from '../../../hooks/useMobile/useMobile';
import { getBanks } from '../../../services/Bank/request';
import { Bank } from '../../../services/Bank/types';
import { GetPartnersBankDetailsBase } from '../../../services/Partner/Financial/BankDetails/types';
import { getPartnersBankDetails } from '../../../services/Partner/Financial/BankDetails/request';

const INITIAL_LOADING_TIME = 1500;

type IHideElements = {
  footer?: {
    is: boolean;
    animate?: boolean;
  };
  header?: {
    is: boolean;
    animate?: boolean;
  };
  whatsapp?: {
    is: boolean;
    animate?: boolean;
  };
};

type Welcome = {
  is: boolean;
};

interface ILoading {
  aux: boolean;
  main: boolean;
  message: string | undefined;
}

export type RequiredBank = Required<Bank>;

interface IPartnersContextProps {
  banks: RequiredBank[]

  successScreen: boolean;
  setSuccessScreen: Function;
  resume: IPartnersResume | undefined;
  setResume: Function;
  indications: IIndications | undefined;
  setIndications: Function;
  showBurgerMenu: boolean;
  setShowBurgerMenu: Function;
  monitoringSheet: () => IMonitoringSheet;
  hideElements: IHideElements;
  setHideElements: React.Dispatch<React.SetStateAction<IHideElements>>;
  financialWithdrawRequest: IPartinersFinancialWithdrawBase[];
  setFinancialWithdrawRequest:
  React.Dispatch<React.SetStateAction<IPartinersFinancialWithdrawBase[]>>;

  setConversion: React.Dispatch<React.SetStateAction<IConversion>>;
  conversion: IConversion;
  questions: Questions;
  setQuestions: React.Dispatch<React.SetStateAction<Questions>>;

  welcome: Welcome;
  setWelcome: React.Dispatch<React.SetStateAction<Welcome>>;
  validateAction: boolean;

  loading: ILoading;
  setLoading: React.Dispatch<React.SetStateAction<ILoading>>;

  bankDetails: GetPartnersBankDetailsBase[];
  setBankDetails: React.Dispatch<React.SetStateAction<GetPartnersBankDetailsBase[]>>;
}

const hideElementsInitial = {
  footer: {
    is: false,
    animate: true,
  },
  header: {
    is: false,
    animate: true,
  },
  whatsapp: {
    is: false,
    animate: true,
  },
};

const initialConversion = {
  progress: 20,
  isMaxBar: true,
};

interface Questions {
  doesThePropertyHaveFurniture: boolean;
  isThePropertyUnderConstruction: boolean;
  isTheOwnerAwareOfTheIndication: boolean;
  hasTheOwnerAlreadyReceivedTheEBook: boolean;
  IsIndicatedWithinSeazoneOperationArea: boolean
}

export type KeyQuestions = keyof Questions;

export const PartnersContext = createContext<IPartnersContextProps>({} as IPartnersContextProps);

export const PartnersContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const timmer = useRef<NodeJS.Timeout>({} as NodeJS.Timeout);
  const banks = useRef<RequiredBank[]>([] as RequiredBank[]);

  const { isMobile } = useMobile();
  const { user, userInformation } = useUser();

  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  const toast = useToast();

  const [bankDetails, setBankDetails] = useState([] as GetPartnersBankDetailsBase[]);
  const [loading, setLoading] = useState<ILoading>({} as ILoading);
  const [successScreen, setSuccessScreen] = useState(false);
  const [indications, setIndications] = useState<IIndications>();
  const [resume, setResume] = useState<IPartnersResume | undefined>();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [hideElements, setHideElements] = useState<IHideElements>(hideElementsInitial);
  const [conversion, setConversion] = useState<IConversion>(initialConversion);
  const [questions, setQuestions] = useState<Questions>({
    doesThePropertyHaveFurniture: false,
    hasTheOwnerAlreadyReceivedTheEBook: false,
    IsIndicatedWithinSeazoneOperationArea: false,
    isTheOwnerAwareOfTheIndication: false,
    isThePropertyUnderConstruction: false,
  } as Questions);
  const [welcome, setWelcome] = useState({ is: true });

  const [
    financialWithdrawRequest,
    setFinancialWithdrawRequest,
  ] = useState<IPartinersFinancialWithdrawBase[]>([] as IPartinersFinancialWithdrawBase[]);

  const handleSuccessScreen = useCallback((newData: boolean) => {
    setSuccessScreen(newData);
  }, []);

  const handleShowBurgerMenu = useCallback((newData: boolean) => {
    setShowBurgerMenu(newData);
  }, []);

  const handleIndication = useCallback((newData: IIndications) => {
    setIndications(newData);
  }, []);

  const handleResume = useCallback((newData: IPartnersResume) => {
    setResume(newData);
  }, []);

  const monitoringSheet = useCallback(() => {
    const response = getMonitoringSheet(user);
    return response;
  }, []);

  const handleValidateAction = () => {
    const validateAction = userInformation?.required_actions?.Partner?.includes('PARTNER_WELCOME_POPUP');
    return validateAction || false;
  };

  const handleLoading = () => {
    setLoading((prev) => ({ ...prev, main: true, aux: true }));
  };

  const handleMessage = (message: string) => {
    setLoading((prevState) => ({ ...prevState, message }));
  };

  const handleStopLoading = () => {
    timmer.current = setTimeout(() => {
      setLoading((prev) => ({
        ...prev,
        aux: false,
        main: true,
        message: undefined,
      }));
      clearTimeout(timmer.current);
    }, INITIAL_LOADING_TIME);
  };

  // valida o popup de boas vindas
  const validateAction = React.useMemo(() => handleValidateAction(), [userInformation]);

  React.useEffect(() => {
    const handleGetResume = async () => {
      try {
        const response = await getPartnersResume();
        setResume(response[0]);
      } catch (e) {
        if (e instanceof AxiosError) {
          toast.error(e.message);
        }
      }
    };

    const handleGetIndications = async () => {
      try {
        const propretyIndications = await getPropretyIndication();
        const allotmentIndications = await getAllotmentIndication();
        const spotsIndications = await getInvestmentIndication();

        const { location, terrain, spot } = EnumType;

        const allotmentsIndicationsWithType = allotmentIndications
          .map((item) => ({ ...item, type: terrain }));

        const properyIndicationsWithType = propretyIndications.map((item) => ({
          ...item,
          type: location,
        }));

        const spotsIndicationsWithType = spotsIndications.map((item) => ({
          ...item,
          type: spot,
        }));

        setIndications({
          allotments: allotmentsIndicationsWithType,
          propretys: properyIndicationsWithType,
          spots: spotsIndicationsWithType,
        });
      } catch (e) {
        if (e instanceof AxiosError) {
          toast.error(e.message);
        }
        toast.error('Um erro inesperado aconteceu');
      }
    };

    const handleValidateWelcomePage = () => {
      // if retorno do back
      if (validateAction) {
        if (isMobile && welcome) {
          const route = `/${partnersRoutes.partners.main}/${partnersRoutes.welcome.main}`;
          navigate(route);
          return false;
        }

        if (!isMobile && welcome) {
          return true;
        }
      }

      return false;
    };

    const handleGetPartnersBankDetails = async () => {
      try {
        const response = await getPartnersBankDetails();
        setBankDetails(response);
      } catch (e) {
        setBankDetails([]);
        if (e instanceof AxiosError) {
          toast.error(e.message);
        }
      }
    };

    const handleGetBanks = async () => {
      try {
        const response = await getBanks();
        banks.current = response as RequiredBank[];
      } catch (e) {
        if (e instanceof AxiosError) {
          toast.error(e.message);
        }
        banks.current = [];
      }
    };

    const handleGetFinancialWithdrawRequest = async () => {
      const response = await getPartnersFinancialWithdrawRequest();
      setFinancialWithdrawRequest(response);
    };

    if (isFirstRender) {
      isFirstRender.current = false;
      try {
        handleLoading();

        Promise.all([
          handleGetResume().then(() => handleMessage('Resumo carregado')),
          handleGetIndications().then(() => handleMessage('Indicações carregadas')),
          handleGetBanks().then(() => handleMessage('Bancos carregados')),
          handleValidateWelcomePage(),
          handleGetPartnersBankDetails(),
          handleGetFinancialWithdrawRequest(),
        ]).then(() => {
          setTimeout(() => {
            handleStopLoading();
          }, 2000);
        });
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    }

    // function checkPosition() {
    //   const scroll = window.scrollY;

    //   if (scroll >= 50) {
    //     setHideElements((old) => ({
    //       ...old,
    //       header: {
    //         is: true,
    //       },
    //     }));
    //   } else {
    //     setHideElements((old) => ({
    //       ...old,
    //       header: {
    //         is: false,
    //         animate: false,
    //       },
    //     }));
    //   }
    // }

    // if (isMobile) {
    //   window.addEventListener('scroll', checkPosition);
    // }

    // return () => window.removeEventListener('scroll', checkPosition);

    return () => {
      clearTimeout(timmer.current);
    };
  }, []);

  const value = useMemo(() => ({
    resume,
    setResume: handleResume,
    indications,
    setIndications: handleIndication,
    successScreen,
    setSuccessScreen: handleSuccessScreen,
    showBurgerMenu,
    setShowBurgerMenu: handleShowBurgerMenu,
    monitoringSheet,
    hideElements,
    setHideElements,
    financialWithdrawRequest,
    setFinancialWithdrawRequest,
    conversion,
    setConversion,
    questions,
    setQuestions,

    welcome,
    setWelcome,
    validateAction,

    loading,
    setLoading,

    banks: banks.current,
    bankDetails,
    setBankDetails,
  }),
  [
    welcome,
    setWelcome,
    resume,
    handleResume,
    indications,
    handleIndication,
    successScreen,
    handleSuccessScreen,
    showBurgerMenu,
    handleShowBurgerMenu,
    monitoringSheet,
    hideElements,
    setHideElements,
    financialWithdrawRequest,
    setFinancialWithdrawRequest,
    conversion,
    setConversion,
    questions,
    setQuestions,
    validateAction,

    loading,
    setLoading,

    banks,
    bankDetails,
    setBankDetails,
  ]);

  return (
    <PartnersContext.Provider value={value}>
      {children}
    </PartnersContext.Provider>
  );
};

export const usePartners = () => {
  const successScreen = useContextSelector(PartnersContext, (state) => state.successScreen);
  const setSuccessScreen = useContextSelector(PartnersContext, (state) => state.setSuccessScreen);

  const questions = useContextSelector(PartnersContext, (state) => state.questions);
  const setQuestions = useContextSelector(PartnersContext, (state) => state.setQuestions);

  const indications = useContextSelector(PartnersContext,
    (state) => state.indications);
  const setIndications = useContextSelector(PartnersContext,
    (state) => state.setIndications);

  const showBurgerMenu = useContextSelector(PartnersContext,
    (state) => state.showBurgerMenu);
  const setShowBurgerMenu = useContextSelector(PartnersContext,
    (state) => state.setShowBurgerMenu);

  const setConversion = useContextSelector(PartnersContext,
    (state) => state.setConversion);
  const conversion = useContextSelector(PartnersContext,
    (state) => state.conversion);

  const monitoringSheet = useContextSelector(PartnersContext,
    (state) => state.monitoringSheet);

  const financialWithdrawRequest = useContextSelector(PartnersContext,
    (state) => state.financialWithdrawRequest);
  const setFinancialWithdrawRequest = useContextSelector(PartnersContext,
    (state) => state.setFinancialWithdrawRequest);

  const resume = useContextSelector(PartnersContext, (state) => state.resume);
  const setResume = useContextSelector(PartnersContext, (state) => state.setResume);
  const resumeIndications = {
    inProgress: resume?.indications_in_progress || 0,
    gains: resume?.indications_won || 0,
    lost: resume?.indications_lost || 0,
  };

  const welcome = useContextSelector(PartnersContext, (state) => state.welcome);
  const setWelcome = useContextSelector(PartnersContext, (state) => state.setWelcome);

  const hideElements = useContextSelector(PartnersContext, (state) => state.hideElements);
  const setHideElements = useContextSelector(PartnersContext, (state) => state.setHideElements);

  const loading = useContextSelector(PartnersContext, (state) => state.loading);
  const setLoading = useContextSelector(PartnersContext, (state) => state.setLoading);

  const banks = useContextSelector(PartnersContext, (state) => state.banks);

  const bankDetails = useContextSelector(PartnersContext, (state) => state.bankDetails);
  const setBankDetails = useContextSelector(PartnersContext, (state) => state.setBankDetails);
  const thereIsBankDetails = React.useMemo(() => {
    if (bankDetails.length > 0) {
      return true;
    }

    return false;
  }, [bankDetails]);

  const validateAction = useContextSelector(PartnersContext, (state) => state.validateAction);

  const handleHideAllElements = () => {
    setHideElements((old) => ({
      ...old,
      whatsapp: {
        is: true,
      },
      header: {
        is: true,
      },
      footer: {
        is: true,
      },
    }));
  };

  const handleShowAllElements = () => {
    setHideElements((old) => ({
      ...old,
      whatsapp: {
        is: false,
      },
      header: {
        is: false,
        animate: false,
      },
      footer: {
        is: false,
        animate: false,
      },
    }));
  };

  return {
    validateAction,
    welcome,
    setWelcome,
    resume,
    setResume,
    resumeIndications,
    indications,
    setIndications,
    successScreen,
    setSuccessScreen,
    showBurgerMenu,
    setShowBurgerMenu,
    monitoringSheet,
    hideElements,
    setHideElements,
    handleHideAllElements,
    handleShowAllElements,
    financialWithdrawRequest,
    setFinancialWithdrawRequest,
    conversion,
    setConversion,
    questions,
    setQuestions,
    loading,
    setLoading,
    banks,
    bankDetails,
    setBankDetails,
    thereIsBankDetails,
  };
};
