import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  background: transparent;
  border-radius: 10px;
  padding: 6px 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: flex-start;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  width: 20%;

  .icons {
    gap: 4px;
    display: grid;
    margin-left: 10px;
    cursor: pointer;
  }

  &.name {
    width: 30%;
  }

  &.qtd-property {
    width: 10%;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.email {
    width: 20%;
    p {
    width: 80%;
    }
  }

  &.address {
    width: 20%;

  }
  
`;
