export type ResponsiblesForExpenseProps ={
  main_role: string,
  roles: string[],
  host_id: number,
  user_id: number,
};

type ResponsiblesForExpenseResponse = {
  responsible_user: number,
  registered_by: number,
};

export const getParamsResponsibleUserAndRegisterBy = (data: ResponsiblesForExpenseProps):
ResponsiblesForExpenseResponse => {
  if (data.main_role === 'Seazone'
    && (data.roles.includes('SeazoneAdministrative') || data.roles.includes('SeazoneOnboarding'))) {
    return {
      responsible_user: data.host_id,
      registered_by: data.user_id,
    };
  }

  return {
    responsible_user: data.user_id,
    registered_by: data.user_id,
  };
};
