import {
  FC,
  useState,
  useMemo,
  memo,
  useContext,
} from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { X } from 'react-feather';

import moment from 'moment';
import { PropertyCloseContext } from '../../../PropertyClose/PropertyClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../../services/FinancialClose/types';

import { postManualFitCleaning } from '../../../../../services/FinancialClose/Cleaning/request';

import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';
import { currency } from '../../../../../utils/InputMask/Number';

import { useFinancialClose } from '../../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../../../hooks/FinancialCloseHook/useFinancialCloseProperty';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../../utils/Messages';

import { formatDate } from '../../../../../context/FinancialClosePage/FinancialCloseContext/utils';

import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import SimpleSelect from '../../../../SimpleSelect';
import CopyText from '../../../CopyText';

import {
  Container,
  Form,
  Header,
  ContainerHeader,
  ContentHeader,
  Body,
  Title,
  SwitchContainer,
  SwitchContent,
  SwitchButton,
  SwitchCircle,
  ButtonsContainer,
  CloseButton,
  Backdrop,
  Spacing,
  StarSymbol,
  SelectContainer,
} from './styles';

const AddManualFitCleaning: FC = () => {
  const {
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    openModalAddManualFitCleaning,
    handleOpenModalAddManualFitCleaning,
  } = useFinancialCloseProperty();

  const {
    refetchSelectedProperties,
  } = useContext(PropertyCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const reservation = useMemo(() => (Object.keys(openModalAddManualFitCleaning.reservation)
    .length !== 0
    ? openModalAddManualFitCleaning.reservation : null), [openModalAddManualFitCleaning]);

  const handlePostManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await postManualFitCleaning(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const validation = Yup.object().shape({
    is_adding: Yup.string().required('Campo obrigatório'),
    description: Yup.string().required('Campo obrigatório'),
    value: Yup.string().required('Campo obrigatório'),
    dateref: Yup.string().required('Campo obrigatório'),
    problemType: Yup.string().required('Campo obrigatório'),
    problemDescription: Yup.string().when('problemType', {
      is: (problemType: string) => `${problemType}` === 'Other',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    }),
    guestDepartureDate: Yup.string().when('problemType', {
      is: (problemType: string) => `${problemType}` === 'Early_Check_Out',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      is_adding: false,
      description: '',
      value: numberToCurrency(0),
      dateref: moment(reservation?.check_in_date,
        'DD/MM/YYYY').toDate(),
      guestDepartureDate: dateSelected,
      problemType: '',
      problemDescription: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const dateRef = values?.dateref as any;
      const guestDepartureDate = values?.problemType === 'Early_Check_Out' ? values?.guestDepartureDate : null as any;
      const value = values?.value;
      const description = values?.description;

      if (reservation?.id && reservation?.property?.id) {
        handleActiveLinearProgress(true);

        await handlePostManualFit({
          date_ref: dateRef ? dateRef.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : new Date(),
          value: typeof value === 'string' ? currencyToNumber(currency(value)) : value,
          is_adding: isAdding,
          reservation: reservation.id,
          description,
          problem_type: values.problemType,
          problem_description: values.problemDescription,
          guest_departure_date: guestDepartureDate ? guestDepartureDate.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : null,
        });

        await refetchSelectedProperties({
          ...params,
          search: reservation.property.code,
        });

        formik.resetForm();

        setTimeout(() => {
          handleOpenModalAddManualFitCleaning(false);
          handleActiveLinearProgress(false);
          toast.success('Ajuste realizado com sucesso!');
        }, 1000);
      }
    },
  });

  if (!reservation) return null;

  return (
    <>
      <Form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Container isOpen={openModalAddManualFitCleaning.open}>
          <Header>
            <ContainerHeader>
              <Title>{`Ajuste Limpeza - Imóvel ${reservation.property.code}`}</Title>
              <CopyText
                text={`${reservation.code}`}
                toastMessage="Código da reserva copiado para área de transferência"
                tooltipText="Copiar código"
              />
              <CloseButton
                type="button"
                onClick={() => handleOpenModalAddManualFitCleaning({
                  open: false,
                  reservation: {},
                })}
              >
                <X size={22} />
              </CloseButton>
            </ContainerHeader>

            <ContainerHeader>
              <ContentHeader>
                <strong>Anfitrião</strong>
                <span>{`${reservation.host.name}`}</span>
              </ContentHeader>
              <ContentHeader>
                <strong>Check-in</strong>
                <span>{reservation.check_in_date}</span>
              </ContentHeader>
              <ContentHeader>
                <strong>Check-out</strong>
                <span>{reservation.check_out_date}</span>
              </ContentHeader>
            </ContainerHeader>
          </Header>

          <Body>
            <SwitchContainer>
              <Grid style={{ display: 'flex' }}>
                <strong>É um ajuste positivo ou negativo?</strong>
                <StarSymbol>*</StarSymbol>
              </Grid>
              <SwitchContent switchIsActive={isAdding}>
                <p>Negativo</p>
                <SwitchButton data-cy={`btn-add-remove-${reservation.id}`} type="button" onClick={() => setIsAdding((prev) => !prev)}>
                  <SwitchCircle switchIsActive={isAdding} />
                </SwitchButton>
                <p>Positivo</p>
              </SwitchContent>
            </SwitchContainer>
            <Spacing />

            <Grid>
              <TextField
                dataCy={`input-value-${reservation.id}`}
                id="value"
                formik={formik}
                label="Valor"
                mask="money"
                placeholder="R$ 0,00"
                required
                requireSymbolPosition="right"
              />
            </Grid>
            <Spacing />

            <Grid>
              <SelectContainer>
                <SimpleSelect
                  dataCy={`problemType_${reservation.id}`}
                  id="problemType"
                  placeholder="Selecione"
                  label="Tipo de problema"
                  formik={formik}
                  required
                  requireSymbolPosition="right"
                  options={[
                    { value: 'Discount', valueLabel: 'Desconto' },
                    { value: 'Cancellation_Fee', valueLabel: 'Taxa de cancelamento' },
                    { value: 'Adjustment', valueLabel: 'Ajuste' },
                    { value: 'Early_Check_Out', valueLabel: 'Check-out antecipado' },
                    { value: 'Other', valueLabel: 'Outros' },
                  ]}
                />
              </SelectContainer>
            </Grid>
            <Spacing />

            {formik.values?.problemType === 'Other' && (
              <>
                <Grid>
                  <TextField
                    dataCy={`input-problem-description_${reservation.id}`}
                    id="problemDescription"
                    formik={formik}
                    label="Qual outro tipo de problema?"
                    placeholder="Digite aqui o outro problema"
                    required
                    requireSymbolPosition="right"
                  />
                </Grid>
                <Spacing />
              </>
            )}

            <Grid>
              <TextField
                dataCy={`textarea-description-${reservation.id}`}
                id="description"
                formik={formik}
                label="Motivos"
                type="textarea"
                placeholder="Motivos"
                required
                requireSymbolPosition="right"
              />
            </Grid>
            <Spacing />

            {formik.values?.problemType === 'Early_Check_Out' && (
              <>
                <Grid>
                  <DatePicker
                    dataCy={`input-date-${reservation.id}`}
                    id="guestDepartureDate"
                    formik={formik}
                    label="Data de saída do hóspede"
                    minDate={new Date('1850-01-02')}
                    hasInitialDates
                    required
                    requireSymbolPosition="right"
                  />
                </Grid>
                <Spacing />
              </>
            )}

            <Grid>
              <DatePicker
                dataCy={`input-date-${reservation.id}`}
                id="dateref"
                formik={formik}
                label="Data de referência"
                hasInitialDates
                required
                requireSymbolPosition="right"
                showDaysOutsideCurrentMonth={false}
                minDate={moment(reservation?.check_in_date,
                  'DD/MM/YYYY').toDate()}
                maxDate={moment(reservation?.check_out_date,
                  'DD/MM/YYYY').subtract(1, 'day').toDate()}
              />
            </Grid>
          </Body>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel" type="button" variant="outlined" onClick={() => handleOpenModalAddManualFitCleaning(false)}>Cancelar</FormButton>
            <FormButton dataCy="btn-confirm" type="submit">Salvar</FormButton>
          </ButtonsContainer>
        </Container>
      </Form>

      { openModalAddManualFitCleaning && (
        <Backdrop onClick={() => handleOpenModalAddManualFitCleaning(false)} />
      )}
    </>
  );
};

export default memo(AddManualFitCleaning);
