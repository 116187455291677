/* eslint-disable react/no-array-index-key */
import {
  useEffect, useState,
} from 'react';
import { PropertyOwner } from '../../../services/Owner/types';
import { Pagination } from './Pagination';
import { ListTitle, PropertyCardsContainer, PropertyListContainer } from './styles';
import { PropertyCard } from './PropertyCard';
import ModalPropertyLock from '../Modal/ModalPropertyLock';
import ModalFinancialStatement from '../Modal/ModalFinancialStatement';
import { CardShimmer } from './CardShimmer';
import { compareList } from '../../../utils/Sorting';
import TooltipInfo from '../TooltipInfo';

interface PropertyListProps {
  ownerProperties: PropertyOwner[];
  isLoading: boolean;
}

type OwnerPropertyStatus =
| 'Active'
| 'Inactive'
| 'Onboarding'
| 'Closed'
| 'Signed_Contract';

type OwnerPropertyCondition = 'Available' | 'Blocked' | 'Rented';
const PER_PAGE = 4;

export default function PropertyList({
  ownerProperties,
  isLoading,
}: PropertyListProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const [paginatedProperties, setPaginateProperties] = useState<PropertyOwner[]>([]);
  const [openModalPropertyLock, setOpenModalPropertyLock] = useState<boolean>(false);
  const [openModalFinancialStatement, setOpenModalFinancialStatement] = useState<boolean>(false);

  useEffect(() => {
    function filterTotalPages(pageIndexSelected: number, itemsPerPage: number) {
      const startIndex = pageIndexSelected * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const filteredProperty = ownerProperties
        .sort((a, b) => compareList(a.code, b.code))
        .slice(startIndex, endIndex);

      setPaginateProperties(filteredProperty);
    }

    filterTotalPages(pageIndex, PER_PAGE);
  }, [pageIndex, ownerProperties]);

  return (
    <>
      <PropertyListContainer>
        <div style={{
          display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '1.5rem',
        }}
        >
          <ListTitle>
            Meus imóveis
          </ListTitle>
          <TooltipInfo size="lg" title="Taxa de ocupação" subtitle="O valor apresentado é referente as diárias executadas." />
        </div>
        {isLoading ? (Array.from({ length: PER_PAGE }).map((_, i) => (<CardShimmer key={`property-owner-shimmer-${i}`} />))) : (
          <>
            <PropertyCardsContainer>
              {paginatedProperties.map((ownerProperty) => (
                <PropertyCard
                  key={ownerProperty.id}
                  propertyCode={ownerProperty.code}
                  propertyId={ownerProperty.id}
                  setOpenModalFinancialStatement={setOpenModalFinancialStatement}
                  setOpenModalPropertyLock={setOpenModalPropertyLock}
                  propertyNeighborhood={ownerProperty.address?.neighborhood}
                  condition={ownerProperty.property_condition as OwnerPropertyCondition}
                  status={ownerProperty.status as OwnerPropertyStatus}
                />
              ))}
            </PropertyCardsContainer>
            <Pagination
              onPageChange={setPageIndex}
              pageIndex={pageIndex}
              perPage={PER_PAGE}
              totalCount={ownerProperties.length}
            />
          </>
        )}
      </PropertyListContainer>
      <ModalPropertyLock
        openModalPropertyLock={openModalPropertyLock}
        onClose={setOpenModalPropertyLock}
        showOnlyHeader
        dataCy="modal-2"
      />

      <ModalFinancialStatement
        open={openModalFinancialStatement}
        handleOpen={setOpenModalFinancialStatement}
      />
    </>
  );
}
