import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 5px;

  p {
    width: 300px;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: ${({ theme }) => theme.palette.grey._720.hex()};
        margin-right: 19px;
      }
  }

  @media(max-width: 1180px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MoreAmenities = styled.span`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  color:  ${({ theme }) => theme.palette.orange.main.hex()};
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;

  img {
    margin-left: 9px;
  }

  .more {
    transform: rotate(0deg);
  }

  .less {
    transform: rotate(180deg);
  }
`;
