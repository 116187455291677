/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect, useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { InputAdornment } from '@mui/material';
import { Eye, EyeOff } from 'react-feather';

import { CreateCompleteUser } from '../../../services/User/types';
import { GetUserDetailsById } from '../../../services/InsertData/types';
import { RequestPartner } from '../../../services/Partner/types';

import { formatCNPJToPost, formatCPFtoSubmit } from '../../../utils/Formatter';
import { useToastErrorMessage } from '../../../utils/Messages';

import { postAddress, deleteAddress } from '../../../services/Address/request';
import { deleteUser } from '../../../services/InsertData/request';
import { DeleteOwnerInvoice, postOwner, postOwnerInvoice } from '../../../services/Owner/request';
import { postUser } from '../../../services/User/request';
import { getPartners } from '../../../services/Partner';

import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';
import { useToast } from '../../../context/ToastContext';
import { useOnboardingPage } from '../../../hooks/useOnboarding/useOnboarding';
import { useMobile } from '../../../hooks/useMobile/useMobile';

import { compareList } from '../../../utils/Sorting';
import {
  numberRegex,
  uppercaseRegex,
  lowercaseRegex,
  commonPasswords,
  specialCharRegex,
} from './utils';

import {
  TextField,
  DatePicker,
  FormButton,
  FormAddress,
  GenderSelect,
  RadioTypePerson,
  SimpleSelect,
} from '../..';

import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import OnboardingSwitch from '../OnboardingSwitch';

import {
  Title,
  ModalCard,
  InputSpace,
  DoubleInput,
  CloseContainer,
  ModalContainer,
  InputsContainer,
  SwitchContainer,
  ButtonsContainer,
  StyledCountrySelector,
} from './styles';

const maritalStatus = [
  {
    value: 'Single',
    valueLabel: 'Solteiro(a)',
  },
  {
    value: 'Married',
    valueLabel: 'Casado(a)',
  },
  {
    value: 'Divorced',
    valueLabel: 'Divorciado(a)',
  },
  {
    value: 'Widowed',
    valueLabel: 'Viúvo(a)',
  },
];

export default function NewOwnerModal() {
  const { isMobile } = useMobile();
  const { setIsOpenModal } = useOnboardingPage();
  const [partners, setPartners] = useState<RequestPartner[]>([]);

  const {
    setIdNewOwner,
    infosPipeDrive,
    setReloadOwners,
  } = useOnboardingHandover();

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const [type, setType] = useState('password');
  const [typeFieldConfirm, setTypeFieldConfirm] = useState('password');

  function selectInitialDates() {
    const pipeData = infosPipeDrive.owner_infos?.birth_date;

    if (pipeData) {
      return moment(pipeData).format('MM-DD-YYYY');
    }
    return '';
  }

  const initialValues = useMemo(() => ({
    rg: '',
    state: '',
    gender: '',
    password: '',
    personID: infosPipeDrive.owner_infos?.owner_person_id.toString() || '',
    passwordConfirm: '',
    born: selectInitialDates(),
    cpf: infosPipeDrive.owner_infos?.cpf || '',
    cnpj: infosPipeDrive.owner_infos?.cnpj || '',
    email: infosPipeDrive.owner_infos?.email || '',
    name: infosPipeDrive.owner_infos?.first_name || '',
    phone: infosPipeDrive.owner_infos?.phone_number || '',
    lastname: infosPipeDrive.owner_infos?.last_name || '',
    profession: infosPipeDrive.owner_infos?.owner_profession || '',
    nationality: infosPipeDrive.owner_infos?.nationality || '',
    marital_status: infosPipeDrive.owner_infos?.marital_status || '',
    city: infosPipeDrive.owner_infos?.owner_address.owner_city || '',
    indicationName: infosPipeDrive.property_infos?.partner_name || '',
    isPartnerIndication: !!infosPipeDrive.property_infos?.partner_name,
    number: infosPipeDrive.owner_infos?.owner_address.owner_numer || '',
    street: infosPipeDrive.owner_infos?.owner_address.owner_street || '',
    zipCode: infosPipeDrive.owner_infos?.owner_address.owner_postal_code || '',
    complement: infosPipeDrive.owner_infos?.owner_address.owner_complement || '',
    neighborhood: infosPipeDrive.owner_infos?.owner_address.owner_neighborhood || '',
    typePerson: infosPipeDrive.owner_infos?.is_individual ? 'individual' : 'legal' || '',
    country: infosPipeDrive.owner_infos?.owner_address.owner_country === 'Brasil' ? 'BR' : 'BR' || 'BR',
  }), [infosPipeDrive?.owner_infos]);

  const validation = Yup.object().shape({
    typePerson: Yup.string(),
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().required().email('E-mail inválido'),
    personID: Yup.string().required('O personID é obrigatório'),
    lastname: Yup.string().required('O Sobrenome é obrigatório'),
    born: Yup.string().required('A data de nascimento é obrigatória'),
    phone: Yup.string(),
    gender: Yup.string().required('É necessário informar um gênero, caso não queria informar um selecione a opção "Não informado"'),
    cpf: Yup.string().when('typePerson', {
      is: 'individual',
      then: Yup.string().required('O CPF é obrigatório caso seja uma pessoa física'),
    }),
    cnpj: Yup.string().when('typePerson', {
      is: 'legal',
      then: Yup.string().required('O CNPJ é obrigatório caso seja uma pessoa jurídica'),
    }),

    street: Yup.string().required('É necessário informar uma rua'),
    city: Yup.string().required('É necessário informar uma cidade'),
    country: Yup.string().required('É necessário informar um país'),
    neighborhood: Yup.string().required('É necessário informar um bairro'),
    state: Yup.string().required('É necessário informar uma unidade federativa'),
    zipCode: Yup.string().required('É necessário informar um código postal'),
    nationality: Yup.string().required('É necessário informar a nacionalidade'),
    marital_status: Yup.string().required('É necessário informar o estado civil'),

    password: Yup.string()
      .required('A senha é obrigatória')
      .matches(specialCharRegex, 'A senha deve conter pelo menos um caracter especial')
      .matches(uppercaseRegex, 'A senha deve conter pelo menos uma letra maiúscula')
      .matches(lowercaseRegex, 'A senha deve conter pelo menos uma letra minúscula')
      .matches(numberRegex, 'A senha deve conter pelo menos um número')
      .notOneOf(commonPasswords, 'A senha não pode ser uma senha comum')
      .min(8, 'A senha deve ter pelo menos 8 caracteres'),

    passwordConfirm: Yup.string().required('É necessário confirmar a senha')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      try {
        const idAddress = await postAddress({
          city: values.city,
          state: values.state,
          street: values.street,
          number: values.number,
          country: values.country,
          postal_code: values.zipCode,
          complement: values.complement,
          neighborhood: values.neighborhood,
        });

        const base: CreateCompleteUser = {
          is_staff: false,
          is_active: true,
          trading_name: '',
          corporate_name: '',
          main_role: 'Owner',
          email: values.email,
          gender: values.gender,
          first_name: values.name,
          password: values.password,
          last_name: values.lastname,
          phone_number1: values.phone,
          main_address: idAddress.id || 0,
          pipedrive_person_id: values.personID,
          password_confirmation: values.passwordConfirm,
          is_individual: values.typePerson === 'individual',
          birth_date: moment(values.born).format('YYYY-MM-DD'),
          cpf: values.typePerson === 'individual' ? formatCPFtoSubmit(values.cpf) : '',
          cnpj: values.typePerson !== 'individual' ? formatCNPJToPost(values.cnpj) : '',
        };

        try {
          let userId: GetUserDetailsById = {} as GetUserDetailsById;
          let invoiceId: number = 0;
          if (infosPipeDrive?.owner_infos?.owner_person_id) {
            userId = await postUser({
              ...base,
              pipedrive_person_id: infosPipeDrive.owner_infos.owner_person_id ? `${infosPipeDrive.owner_infos.owner_person_id}` : undefined,
            });
          } else {
            userId = await postUser({
              ...base,
            });
          }

          try {
            await postOwner({
              user: userId.id,
              invoice_address: idAddress.id || 0,
              profession: `${values.profession}`.trim() === '' ? 'Não informado' : values.profession,
              nationality: `${values.nationality}`.trim() === '' ? 'Não informado' : values.nationality,
              marital_status: values.marital_status,
              is_partner_indication: values.isPartnerIndication,
              referrer_partner: `${values.indicationName}`.trim() === '' ? undefined : values.indicationName,
            });

            const choiseCnpjOrCpfInInvoiceDetails = () => {
              if (userId.cnpj) {
                return {
                  cnpj: userId.cnpj,
                };
              } if (userId.cpf) {
                return {
                  cpf: userId.cpf,
                };
              }
              return {} as Object;
            };

            const invoice = await postOwnerInvoice({
              ...choiseCnpjOrCpfInInvoiceDetails(),
              address: idAddress.street,
              address_number: idAddress.number,
              city: idAddress.city,
              complement: idAddress.complement,
              state: idAddress.state,
              district: idAddress.neighborhood,
              postal_code: idAddress.postal_code,
              phone_number: userId.phone_number1,
              email: userId.email,
              user: userId.id,
              invoice_entity_name: `${userId.first_name} ${userId.last_name}`,
            });

            invoiceId = invoice.id;

            toast.success('Cadastro realizado com sucesso!');
            setIsOpenModal(false);
            setReloadOwners(true);
            setIdNewOwner(userId.id);
            resetForm();
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
            }

            await deleteAddress(idAddress.id || 0);
            await DeleteOwnerInvoice(invoiceId || 0);
            await deleteUser(userId.id);
          }
        } catch (e: unknown) {
          await deleteAddress(idAddress.id || 0);
          if (e instanceof Error) {
            toastErrorRequest(e);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const handleGetPartners = async () => {
    const response = await getPartners();
    const sorted = response.sort((a, b) => compareList(a.user.first_name, b.user.first_name));
    setPartners(sorted);
  };

  useEffect(() => {
    handleGetPartners();
  }, []);

  return (
    <>
      <ModalContainer isModalOpen>
        <ModalCard>
          <CloseContainer onClick={() => setIsOpenModal(false)}>
            <Close />
            <p>Fechar</p>
          </CloseContainer>
          <Title>Dados novo proprietário</Title>
          <InputsContainer onSubmit={formik.handleSubmit}>
            <InputSpace />
            <TextField
              label="*Person ID"
              id="personID"
              formik={formik}
              value={infosPipeDrive.owner_infos?.owner_person_id || ''}
            />
            <InputSpace />
            <TextField
              label="*Nome"
              id="name"
              formik={formik}
              value={infosPipeDrive.owner_infos?.first_name || ''}
            />
            <InputSpace />
            <TextField
              label="*Sobrenome"
              id="lastname"
              formik={formik}
              dataCy="lastname"
              value={infosPipeDrive.owner_infos?.last_name || ''}
            />
            <InputSpace />
            <TextField
              label="*E-mail"
              id="email"
              dataCy="email"
              type="email"
              formik={formik}
              value={infosPipeDrive.owner_infos?.email || ''}
            />
            <InputSpace />
            <StyledCountrySelector
              country={'br'}
              label="*Telefone/Celular"
              enableLongNumbers
              autoFormat
              enableTerritories
              value={formik.values.phone}
              enableClickOutside={!isMobile}
              onChange={(value) => formik.setFieldValue('phone', value)}
              disableDropdown
              placeholder="+xx (xx) xxxxx-xxxx"
            />
            <InputSpace />
            <TextField
              label="Profissão"
              id="profession"
              formik={formik}
              value={infosPipeDrive.owner_infos?.owner_profession || ''}
            />
            <InputSpace />
            <TextField
              label="Nacionalidade"
              id="nationality"
              formik={formik}
              value={infosPipeDrive.owner_infos?.nationality || ''}
            />
            <InputSpace />
            <SimpleSelect
              id="marital_status"
              label="Estado civil"
              className="selector"
              defaultSelected={infosPipeDrive.owner_infos?.marital_status || undefined}
              options={maritalStatus}
              formik={formik}
              placeholder="Selecione..."
              labelClassName="labelClass"
            />
            <InputSpace />
            <DoubleInput>
              <GenderSelect label="*Gênero" formik={formik} />
              <DatePicker
                formik={formik}
                label="*Data de nasc."
                id="born"
                dataCy="born"
                minDate={new Date('1850-01-02')}
                viewsCustom={['day', 'month', 'year']}
                disableCloseOnSelect={false}
                disableFuture
                hasInitialDates={false}
                onlyAlertErrorWhenTouched
              />
            </DoubleInput>
            <InputSpace />
            <RadioTypePerson
              labelPlacement="end"
              id="typePerson"
              formLabel="Pessoa"
              formik={formik}
            />
            <InputSpace />

            {formik.values.typePerson === 'individual' && (
            <TextField
              label="*CPF"
              id="cpf"
              dataCy="cpf"
              mask="cpf"
              formik={formik}
              value={infosPipeDrive.owner_infos?.cpf || ''}
            />
            )}
            <InputSpace />
            {formik.values.typePerson === 'legal' && (
            <TextField
              label="*CNPJ"
              id="cnpj"
              dataCy="cnpj"
              mask="cnpj"
              formik={formik}
              value={infosPipeDrive.owner_infos?.cnpj || ''}
            />
            )}
            <InputSpace />
            <TextField
              label="Número de identidade"
              id="rg"
              dataCy="rg"
              formik={formik}
            />
            <InputSpace />

            <SwitchContainer flexDirection="column">
              <label htmlFor="isPartnerIndication">Foi indicado por algum parceiro ?</label>
              <OnboardingSwitch
                id="isPartnerIndication"
                formik={formik}
                value={formik.values.isPartnerIndication}
                alignment="center"
              />
            </SwitchContainer>
            <InputSpace />
            <SimpleSelect
              id="indicationName"
              dataCy="indicationName"
              placeholder="Selecione o parceiro"
              disabled={!formik.values.isPartnerIndication}
              required={formik.values.isPartnerIndication}
              disableRequireSymbol
              formik={formik}
              defaultSelected={infosPipeDrive.property_infos?.partner_name || undefined}
              options={
                (partners || []).map<SelectOption>(({
                  id,
                  user: partner,
                }) => ({ value: id, valueLabel: `${partner.first_name} ${partner.last_name}` }))
              }
            />
            <InputSpace />
            <TextField
              label="*Senha"
              id="password"
              formik={formik}
              type={type}
              endAdornment={(
                <InputAdornment position="start">
                  {type === 'password'
                    ? <EyeOff onClick={() => setType('text')} strokeWidth={'1'} cursor="pointer" size={20} />
                    : <Eye onClick={() => setType('password')} strokeWidth={'1'} cursor="pointer" size={20} />}
                </InputAdornment>
              )}
            />
            <InputSpace />
            <TextField
              label="*Confirmar senha"
              id="passwordConfirm"
              formik={formik}
              type={typeFieldConfirm}
              endAdornment={(
                <InputAdornment position="start">
                  {typeFieldConfirm === 'password'
                    ? <EyeOff onClick={() => setTypeFieldConfirm('text')} strokeWidth={'1'} cursor="pointer" size={20} />
                    : <Eye onClick={() => setTypeFieldConfirm('password')} strokeWidth={'1'} cursor="pointer" size={20} />}
                </InputAdornment>
              )}
            />
            <InputSpace />
            <FormAddress
              formik={formik}
              addressProp={{
                city: infosPipeDrive.owner_infos?.owner_address.owner_city,
                country: infosPipeDrive.owner_infos?.owner_address.owner_country === 'Brasil' ? 'BR' : 'BR',
                neighborhood: infosPipeDrive.owner_infos?.owner_address.owner_neighborhood,
                number: infosPipeDrive.owner_infos?.owner_address.owner_numer,
                postal_code: infosPipeDrive.owner_infos?.owner_address.owner_postal_code,
                street: infosPipeDrive.owner_infos?.owner_address.owner_street,
                complement: infosPipeDrive.owner_infos?.owner_address.owner_complement,
                state: '',
              }}
            />
            <InputSpace />

            <ButtonsContainer>
              <FormButton
                dataCy="btn-cancel"
                type="button"
                customColor="grey"
                onClick={() => setIsOpenModal(false)}
              >
                Cancelar
              </FormButton>
              <FormButton
                dataCy="btn-confirm-block"
                type="submit"
              >
                Confirmar
              </FormButton>
            </ButtonsContainer>
            <InputSpace />
          </InputsContainer>
        </ModalCard>
      </ModalContainer>
    </>
  );
}
