import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const ColoredBar = styled.div`
  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
  display: flex;
  height: 95px;
  width: 100%;
`;

export const LogoImage = styled.div`
  max-width: 200px;
  margin: auto;
`;

export const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white._250.hex()};

  gap: 50px;
  display: flex;
  flex-direction: column;
`;

export const LoginContainer = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  max-width: 560px;
  max-height: 420px;
  
  padding: 32px;

  height: 100%;
  width: 100%;
  
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  
  box-sizing: border-box;
  border-radius: 18px;
  margin: auto;

  @media (max-width: 1000px) {
    align-items: normal;
    justify-content: normal;
  }
`;

export const ButtonsContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled(Typography)`
  && {
    &.loginButton {
      font-size: 16px;
    }

    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    margin: 0 auto;
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  min-width: 377px;

  .MuiFormControl-root {
    margin: 0.1rem 0 !important;
  }

  @media (max-width: 800px) {
    min-width: 300px;
  }

  @media (max-width: 568px) {
    min-width: 150px;
  }
`;

export const LoginButton = styled(Button)`
  && {
    height: 40px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.blue._850.hex()};
    text-transform: capitalize;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background: ${({ theme }) => `linear-gradient(180deg, ${theme.palette.blue._200.hex()} 0%, ${theme.palette.blue._850.hex()} 100%)`};
    box-shadow: 0px 3px 5px 1px ${({ theme }) => theme.palette.blue._850.alpha(0.5).toString()};
    transition: all 0.3s linear;

    &:disabled {
      background-color: ${({ theme }) => theme.palette.grey._430.hex()};
      color: ${({ theme }) => theme.palette.grey._650.hex()};
      box-shadow: none;
    }

    &:hover {
      box-shadow: 1px 1px 20px 1px ${({ theme }) => theme.palette.blue._850.hex()};
    }
  }
`;

export const ForgetPasswordText = styled(Link)`
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  text-decoration: underline;
  a:link, a:visited, a:hover, a:active {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }
`;
