/* eslint-disable no-restricted-syntax */
import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import { useFormik } from 'formik';

import { ErrorMessage } from '../../../../utils/Messages';
import { getPropertiesOwner } from '../../../../services/Owner/request';
import { PropertyOwner, QueryParamsFilter } from '../../../../services/Owner/types';

import { useViewMode } from '../../../../context/ViewMode/ViewMode';
import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';
import { useLoader } from '../../../../context/LoaderContext';
import { useToast } from '../../../../context/ToastContext';

import ModalHeader from './ModalHeader';
import FormButton from '../../../FormButton';
import ModalTemplate from '../ModalTemplate';
import ModalConfirmLock from '../ModalConfirmLock';

import {
  ContainerBox,
  BodyBox,
  HorizontalRow,
  BodyBoxContainer,
  PropertyContainer,
  StatusContainer,
  StatusContent,
  ButtonContainer,
  PropertyContent,
  PropertyImage,
  PropertyCode,
  PropertyAlias,
  Circle,
  Status,
} from './styles';

type ExistingReservationProps = {
  checkin: Moment;
  checkout: Moment;
};

interface Props {
  onClose: Function;
  openModalPropertyLock?: boolean;
  showOnlyHeader?: boolean;
  propertyCode?: string;
  propertyId?: number;
  dataCy?: string;
  propertyInfos?: {
    existingBookings: ExistingReservationProps[],
    hasExtraDayPreparation: boolean,
  }
}

const ModalPropertyLock: FC<Props> = ({
  openModalPropertyLock = false,
  onClose,
  showOnlyHeader = false,
  propertyCode = '',
  propertyId,
  dataCy = 'modal-1',
  propertyInfos = {
    existingBookings: [],
    hasExtraDayPreparation: false,
  },
}) => {
  const toast = useToast();
  const {
    property: extractProperty,
  } = useOwnerExtract();
  const { setLoad } = useLoader();
  const [properties, setProperties] = useState<PropertyOwner[]>([]);
  const [propertySelected, setPropertySelected] = useState<PropertyOwner>();
  const [openModalConfirmLock, setOpenModalConfirmLock] = useState<boolean>(false);
  const { mode } = useViewMode();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  function checkAvailability(
    desiredCheckin: Moment,
    desiredCheckout: Moment,
    existingReservations: ExistingReservationProps[],
  ) {
    for (const reservation of existingReservations) {
      const { checkin, checkout } = reservation;
      // Check if there is an overlap of dates
      if (desiredCheckout.isAfter(checkin) && desiredCheckin.isBefore(checkout)) {
        return false; // If there is an overlap, the reservation is not available
      }
    }
    return true; // If there is no overlap, the reservation is available
  }

  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
    },

    onSubmit: async (values) => {
      const isAvailable = checkAvailability(
        moment(values.startDate),
        moment(values.endDate),
        propertyInfos.existingBookings,
      );

      if (!isAvailable) {
        toast.error(`Não é possível inserir um bloqueio neste período, pois o imóvel já está reservado${propertyInfos.hasExtraDayPreparation ? ' para preparo de reserva.' : '.'}`);
        return;
      }

      const startDate = moment(values.startDate).format('YYYY-MM-DD');
      const endDate = moment(values.endDate).format('YYYY-MM-DD');
      const params: QueryParamsFilter = {
        start_date: startDate,
        end_date: endDate,
      };

      if (!showOnlyHeader) {
        setLoad(true);
        try {
          const validateMode = (mode && mode) || undefined;
          const response: PropertyOwner[] = await getPropertiesOwner(params, validateMode);

          setProperties(response);
          setLoad(false);
        } catch (e: unknown) {
          if (e instanceof Error) {
            toast.error(e.message || ErrorMessage.default());
            setLoad(false);
          }
        }
      }
    },
  });

  const handleOpenModalConfirmLock = () => {
    const isAvailable = checkAvailability(
      moment(formik.values.startDate),
      moment(formik.values.endDate),
      propertyInfos.existingBookings,
    );

    if (isAvailable) {
      setOpenModalConfirmLock(true);
    }
  };

  const handleRequestBlock = (propertie: PropertyOwner) => {
    setPropertySelected(propertie);
    handleOpenModalConfirmLock();
  };

  useEffect(() => {
    if (openModalPropertyLock) {
      setProperties([]);
    }
    return () => {
      setProperties([]);
      formik.setFieldValue('startDate', null);
      formik.setFieldValue('endDate', null);
    };
  }, [openModalPropertyLock]);

  return (
    <>
      <ModalTemplate
        open={openModalPropertyLock}
        handleClose={onClose}
      >
        <ContainerBox
          onChange={formik.handleChange}
          onSubmit={formik.handleSubmit}
        >
          <ModalHeader
            handleClick={!showOnlyHeader ? () => {} : () => handleOpenModalConfirmLock()}
            formik={formik}
            onClose={onClose}
            title="Solicitação de bloqueio do imóvel"
            subtitle="Selecione a data e veja os imóveis disponíveis"
            titleButton={!showOnlyHeader ? 'Buscar' : 'Bloquear'}
            customColorButton={!showOnlyHeader ? 'black' : 'red'}
            propertyId={propertyId || extractProperty.id}
            calendarHasMarkers={showOnlyHeader}
            calendars={showOnlyHeader ? 1 : 2}
            dataCy={dataCy}
          />

          {!showOnlyHeader && (
            <BodyBox>
              <BodyBoxContainer>
                <div>
                  <p>Imóveis disponíveis</p>
                </div>
                <div>
                  <p>Status</p>
                </div>
              </BodyBoxContainer>
              <HorizontalRow />

              {properties.map((property) => (
                <React.Fragment key={`${property.code}`}>
                  <BodyBoxContainer data-cy={`${dataCy}-property`}>
                    <PropertyContainer>
                      <PropertyImage status={property?.property_condition?.toLowerCase() === 'available' ? 'available' : 'unavailable'} />
                      <PropertyContent>
                        <PropertyCode status={property?.property_condition?.toLowerCase() === 'available' ? 'available' : 'unavailable'}>
                          {property.code}
                        </PropertyCode>
                        <PropertyAlias status={property?.property_condition?.toLowerCase() === 'available' ? 'available' : 'unavailable'}>
                          {property.code}
                        </PropertyAlias>
                      </PropertyContent>
                    </PropertyContainer>

                    <StatusContainer>
                      <StatusContent>
                        <Circle status={property?.property_condition?.toLowerCase() === 'available' ? 'available' : 'unavailable'} />
                        <Status data-cy={`${dataCy}-status-property-${property.id}-${property?.property_condition}`}>{property?.property_condition?.toLowerCase() === 'available' ? 'Disponível' : 'Indisponível'}</Status>
                      </StatusContent>
                    </StatusContainer>

                    <ButtonContainer>
                      {property?.property_condition?.toLowerCase() === 'available' ? (
                        <FormButton
                          dataCy={`${dataCy}-btn-block-property-${property.id}`}
                          type="button"
                          onClick={() => handleRequestBlock(property)}
                        >
                          Solicitar bloqueio
                        </FormButton>
                      ) : (
                        <FormButton
                          type="button"
                          customColor="grey"
                          disable
                        >
                          Solicitar bloqueio
                        </FormButton>
                      )}
                    </ButtonContainer>
                  </BodyBoxContainer>
                  <HorizontalRow />
                </React.Fragment>
              ))}
            </BodyBox>
          )}
        </ContainerBox>
      </ModalTemplate>

      <ModalConfirmLock
        open={openModalConfirmLock}
        onClose={setOpenModalConfirmLock}
        onClosePropertyModal={onClose}
        startDate={moment(formik.values.startDate).format('DD/MM/YYYY')}
        endDate={moment(formik.values.endDate).format('DD/MM/YYYY')}
        propertyCode={propertyCode !== '' ? propertyCode : (propertySelected && propertySelected.code) || extractProperty.code}
        propertyId={propertyId || (propertySelected && propertySelected.id) || extractProperty.id}
        dataCy={dataCy}
      />
    </>
  );
};

export default ModalPropertyLock;
