import axios from 'axios';
import request from '../request';

import {
  FinancialCloseParams,
  FinancialCloseOwnerResponse,
  ManualFitParams,
  ManualFitProps,
  ConsolidationTaskProps,
  RevenuesOwnerProps,
  ReservationReportProps,
  ControllerProperPayStatus,
} from './types';

import {
  formatFinancialDataOwner,
} from './utils';

import { formatDate } from '../../context/FinancialClosePage/FinancialCloseContext/utils';
import { formatedDateOfRequest } from '../../utils/Formatter';

export const getFinancialCloseOwner = async (params?: FinancialCloseParams,
  abortController?: any) => {
  try {
    let allParams: FinancialCloseParams = {
      start_date: params?.start_date || formatDate(new Date()).start_date,
    };

    if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
    if (params?.search) allParams = { ...allParams, search: params.search };

    let paramOwners: string = '';
    const length = params?.owners?.length || 0;

    if (params?.owners) {
      params.owners.forEach((id, index) => {
        paramOwners += index !== (length - 1) ? `owners[]=${id}&` : `owners[]=${id}`;
      });
    }

    const url = params?.owners ? `/admin_financial_owners/?${paramOwners}` : '/admin_financial_owners/';

    const payload = abortController ? {
      params: {
        ...allParams,
      },
      signal: abortController.signal,
    } : {
      params: {
        ...allParams,
      },
    };

    const { data } = await request.get<FinancialCloseOwnerResponse>(url, payload);

    const [year, month] = allParams.start_date.split('-');
    const financialData = formatFinancialDataOwner(
      data, year, month,
    );

    return financialData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'Request Canceled!';
    }
    return error;
  }
};

export const patchRevenuesOwner = async (revenues: RevenuesOwnerProps) => {
  await request({
    method: 'PATCH',
    url: '/admin_financial_owners/',
    data: { ...revenues },
  });
};

export const getManualFitOwner = async (params?: ManualFitParams) => {
  let allParams: ManualFitParams = {
    start_date: params?.start_date || formatDate(new Date()).start_date,
  };

  if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
  if (params?.is_adding) allParams = { ...allParams, is_adding: params.is_adding };
  if (params?.owner_id) allParams = { ...allParams, owner_id: params.owner_id };

  const { data } = await request.get<ManualFitProps[]>('/owner_manual_fit/', {
    params: allParams,
  });

  const dataFormatted = data.map((item) => ({
    ...item,
    value: Number(item.value),
  }));

  return dataFormatted;
};

export const deleteManualFitOwner = async (id: number) => request.delete(`/owner_manual_fit/${id}/`);

export const postManualFitOwner = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    owner: data.owner,
    description: data.description,
  };

  return request.post<ManualFitProps>('/owner_manual_fit/', {
    ...body,
  });
};

export const patchManualFitOwner = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    owner: data.owner,
    description: data.description,
  };

  return request.patch<ManualFitProps>(`/owner_manual_fit/${data.id}/`, {
    ...body,
  });
};

export const postConsolidationTask = async (data: ConsolidationTaskProps) => {
  let body: ConsolidationTaskProps = {
    task: data.task,
    date: data?.date || formatDate(new Date()).start_date,
  };

  if (data?.properties) {
    body = { ...body, properties: data.properties };
  } else if (data?.hosts) {
    body = { ...body, hosts: data.hosts };
  } else if (data?.owners) {
    body = { ...body, owners: data.owners };
  }

  return request.post<ConsolidationTaskProps>('/consolidation_tasks/', {
    ...body,
  });
};

export const getReservationsReport = async (params: ReservationReportProps) => {
  await request.get('/reservations_report/', { params, responseType: 'blob' }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `reservas_${params.start_date}_${params.end_date}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

export const postFinancialAudit = async () => request.post('/reports/property_financial_audit/');

export const getControllerProperPayStatus = async () => {
  const { data } = await request.get<ControllerProperPayStatus>('/proper_pay/controller/status/');
  return data;
};

export const putTriggerUpdateFinancialCloseTables = async () => {
  await request.put('/channel_manager/trigger_financial_closing/');
};

export const putTriggerUpdateFinancialCloseTable = async (tableNames: string[]) => {
  await request.put('/channel_manager/import_financial_closing_table_data/', {
    table_names: tableNames,
  });
};
