/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import deleteIcon from '../../assets/icons/generals/bashRed.svg';
import { useUser } from '../../context/UserContext';
import { OwnerInvoiceDetails } from '../../services/Owner/types';
import { cnpj, cpf } from '../../utils/InputMask/Number';
import ModalConfirmDeleteDataFiscalNote from '../PersonalDataOwner/Modal/ModalConfirmDeleteDataFiscalNote';
import {
  Container, Divider, Options, TagMain,
} from './styles';
import { useViewMode } from '../../context/ViewMode/ViewMode';

interface Props {
  dataInvoice: OwnerInvoiceDetails;
  handleOpenEditModalFiscalNote: (a: OwnerInvoiceDetails) => void;
  getListOwnerInvoices: () => void;
}

const CardsDataFiscalNote = ({
  dataInvoice,
  handleOpenEditModalFiscalNote,
  getListOwnerInvoices,
}: Props): JSX.Element => {
  const [
    openModalDeleteDataFiscalNote,
    setOpenModalDeleteDataFiscalNote,
  ] = useState<boolean>(false);

  const { userInformation } = useUser();
  const { isViewMode } = useViewMode();

  return (
    <>
      <Container isMain={dataInvoice.is_default || false}>
        <div>
          <h2>Nome</h2>
          <p>{dataInvoice.invoice_entity_name}</p>
        </div>
        <Divider />
        {dataInvoice.cpf && dataInvoice.cpf.length > 1 && (
          <div>
            <h2>CPF</h2>
            <p>{cpf(dataInvoice.cpf)}</p>
          </div>
        )}
        {dataInvoice.cnpj && dataInvoice.cnpj.length > 1 && (
          <div>
            <h2>CNPJ</h2>
            <p>{cnpj(dataInvoice.cnpj)}</p>
          </div>
        )}
        <Options>
          {dataInvoice.is_default && <TagMain>Principal</TagMain>}
          {!isViewMode && (
            <>
              {!userInformation?.roles.includes('Owner') && (
                <>
                  <Edit
                    onClick={() => handleOpenEditModalFiscalNote(dataInvoice)}
                  />
                  {!dataInvoice.is_default && (
                    <img
                      src={deleteIcon}
                      alt="deletar"
                      onKeyDown={() => setOpenModalDeleteDataFiscalNote(true)}
                      onClick={() => setOpenModalDeleteDataFiscalNote(true)}
                      className="delete"
                    />
                  )}
                </>
              )}
            </>
          )}
        </Options>
      </Container>
      <ModalConfirmDeleteDataFiscalNote
        openModal={openModalDeleteDataFiscalNote}
        setOpenModal={setOpenModalDeleteDataFiscalNote}
        idInvoice={dataInvoice.id || 0}
        getListOwnerInvoices={getListOwnerInvoices}
      />
    </>
  );
};

export default CardsDataFiscalNote;
