import React from 'react';

import { Copy } from 'react-feather';

import AdjustButton from './AdjustButton';

import Tooltip from '../../../Tooltip';

import { IStatementImage, StatusProps } from '../../types';

import { convertStatusPropsToStatusToShow } from '../../utils';

import {
  Item,
  Title,
  Circle,
  Container,
  ItemContainer,
  LeftItemContainer,
  RadioButton,
} from './styles';

import { numberToCurrency } from '../../../../utils/Formatter';
import { Number as formaterNumber } from '../../../../utils/InputMask';
import { useCustomToast } from '../../../../context/CustomToastContext';
import { useOwnerTedList } from '../../../../hooks/OwnerTedListHook/useOwnerTedList';
import { translatePixType } from '../../../../utils/Translator';
import { PixType } from '../../../../services/Owner/types';

interface BodyProps {
  id: number;
  name: string;
  nameToTed: string;
  transfer: string;
  cpf: string;
  bank: string;
  agency: string;
  account: string;
  pixKey: string;
  pixKeyType: PixType;
  status: StatusProps;
  cnpj: string;
  accountType: string;
  bankNumber: string;
  statmentImage: IStatementImage;
  transferDay: number;
  checked: boolean;
}

interface ICopyItem {
  text: string;
  size?: number;
  reduce?: number;
}

interface ITooltipText {
  text: string;
  children: React.ReactNode;
}

const reduceLetter = (reduceText: string, max?: number) => {
  const defaultMax = 12;
  if (reduceText) {
    if (max) {
      if (reduceText?.length > max) {
        return `${reduceText?.substring(0, max)}...`;
      }
      return reduceText?.substring(0, max);
    }
    if (reduceText?.length > defaultMax) {
      return `${reduceText?.substring(0, defaultMax)}...`;
    }
    return reduceText;
  }

  return '';
};

const CopyItem = ({ text, size = 17, reduce = 0 }: ICopyItem) => {
  const customToast = useCustomToast();
  const copyString = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
    customToast.addToast({
      title: 'texto copiado para a área de transferência',
      description: '',
      size: 'small',
      type: 'success',
      customMaxTime: 1000,
      hideTimeLine: true,
    });
    return text;
  };

  return (
    <Item>
      <Tooltip text={text}>
        <Title onClick={() => copyString(text)} pointer>
          {reduce !== 0
            ? reduceLetter(text, Number(reduce))
            : reduceLetter(text)}
          <Copy className="copy-icon" size={size} />
        </Title>
      </Tooltip>
    </Item>
  );
};

const TooltipTitle = ({
  text,
  children,
}: ITooltipText) => (
  <Tooltip text={text}>
    <Title pointer>{children}</Title>
  </Tooltip>
);

function validadeTransfer(value: string) {
  const numberTransfer = Number(value);
  if (!numberTransfer) {
    return 0;
  }
  return numberTransfer;
}

function unformattedCpf(cpfUnformatted: string) {
  return cpfUnformatted.replace(/\D/g, '');
}

const Body = ({
  id,
  cpf,
  bank,
  cnpj,
  name,
  agency,
  status,
  pixKey,
  account,
  checked,
  transfer,
  nameToTed,
  pixKeyType,
  bankNumber,
  transferDay,
  accountType,
  statmentImage,
}: BodyProps) => {
  const statusToShow = convertStatusPropsToStatusToShow(status);
  const { handleUpdateCheckedOfTedSelected } = useOwnerTedList();

  const handleUpdateChecked = (tedId: number) => {
    handleUpdateCheckedOfTedSelected(tedId);
  };

  return (
    <Container>
      <ItemContainer status={status}>
        <LeftItemContainer>
          <RadioButton
            data-cy={`radio-button-select-ted-${id}`}
            type="radio"
            id={`radio_${id}`}
            name={`radio_${id}`}
            checked={checked}
            onClick={() => handleUpdateChecked(id)}
          />
          <AdjustButton statmentImage={statmentImage} tedId={id} />
          <Item>
            <Circle status={status || 'Not done'} />
            <Title>{statusToShow}</Title>
          </Item>

          <Item>
            <TooltipTitle text={name}>{reduceLetter(name, 30)}</TooltipTitle>
          </Item>

          <Item>
            <TooltipTitle text={nameToTed}>
              {reduceLetter(nameToTed, 30)}
            </TooltipTitle>
          </Item>

          <Item>
            <Title>{numberToCurrency(validadeTransfer(transfer))}</Title>
          </Item>

          <Item>
            <Title>{formaterNumber.cpf(unformattedCpf(cpf)) || cpf}</Title>
          </Item>

          <CopyItem text={formaterNumber.cnpj(cnpj) || cnpj} />

          <CopyItem text={bankNumber} />

          <CopyItem text={bank} />

          <CopyItem text={agency} />

          <CopyItem text={accountType} />

          <CopyItem text={account} reduce={7} />

          <Item>
            <Title>{translatePixType(pixKeyType)}</Title>
          </Item>

          <CopyItem text={pixKey} />

          <Item>
            <Title>
              {transferDay === -1 ? '-' : transferDay}
            </Title>
          </Item>
        </LeftItemContainer>
      </ItemContainer>
    </Container>
  );
};

CopyItem.defaultProps = {
  size: 17,
  reduce: 0,
};

export default Body;
