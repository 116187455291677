import { useEffect, useMemo, useState } from 'react';

import { Property } from '../../services/Property/types';
import { getPropertiesHost } from '../../services/Property/request';

import { compareList } from '../../utils/Sorting';

import { SelectOption } from '../SimpleSelect/SimpleSelect';
import Finances from './Finances';

import { Container, Content } from './styles';
import FinancesCount from './Finances/FinancesCount';
import { useGridFinances } from '../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';
import { getProperPayHostDashboard } from '../../services/HostDashboard/request';
import { useUser } from '../../context/UserContext';
import { useToast } from '../../context/ToastContext';

const Dashboard = () => {
  const toast = useToast();
  const { userInformation: user } = useUser();

  const {
    setFinancesData,
    setIsLoadingFinances,
    year: yearSelectedInGrid,
    propertyId: idSelectedInGrid,
  } = useGridFinances();

  const [propertiesHost, setPropertiesHost] = useState<Property[]>([]);

  const validateHostId = useMemo(() => (user?.host?.id ? user?.host?.id : undefined), [user]);

  useEffect(() => {
    async function getProperty() {
      const { results } = await getPropertiesHost();
      const filterResultsByActiveStatus = results.filter((item) => item.status !== 'Inactive');
      setPropertiesHost(filterResultsByActiveStatus);
    }
    getProperty();
  }, []);

  const propertiesCodes: SelectOption[] = useMemo(() => {
    if (propertiesHost) {
      const codes = propertiesHost.map((prop) => ({
        id: `${prop.id}`,
        value: `${prop.id}`,
        valueLabel: `${prop.code}`,
      }));

      return codes.sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));
    }

    return [];
  }, [propertiesHost]);

  const handleGetValues = async () => {
    try {
      setIsLoadingFinances(true);
      if (idSelectedInGrid) {
        const data = await getProperPayHostDashboard(
          yearSelectedInGrid, validateHostId, idSelectedInGrid,
        );
        setFinancesData(data);
      } else {
        const data = await getProperPayHostDashboard(yearSelectedInGrid, validateHostId);
        setFinancesData(data);
      }
      setIsLoadingFinances(false);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      } else {
        toast.error('Ocorreu um erro ao recuperar os dados');
      }
    }
  };

  useEffect(() => {
    handleGetValues();
  }, [yearSelectedInGrid, idSelectedInGrid]);

  return (
    <Container>
      <Content>
        <Finances propertiesCodes={propertiesCodes} />
        <FinancesCount />
      </Content>
    </Container>
  );
};

export default Dashboard;
