import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { useOnboardingPage } from '../../../hooks/useOnboarding/useOnboarding';

import CardOwner from './CardOwner';
import CardPropertyInformation from './CardPropertyInformation';
import CardDeploymentFeeValues from './CardDeploymentFeeValues';
import CardFinish from './CardFinish';

import {
  CardContainer,
  Content,
  Title,
  AuxDiv,
  FullCard,
  Header,
  CircleLoading,
  CardLoading,
  ContentShowCards,
} from './styles';
import { getInfosPipedrive } from '../../../services/Onboarding/request';
import { useToast } from '../../../context/ToastContext';
import { ErrorMessage } from '../../../utils/Messages';
import TextField from '../../TextField';
import { ButtonPipedrive } from '../NewOwnerModal/styles';
import FormButton from '../../FormButton';
import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';

export default function OnboardingCard() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [showSteps, setShowSteps] = useState(true);

  const {
    isOwnerCard,
    isPropertyInformationCard,
    isDeploymentFeeValuesCard,
    isFinishCard,
  } = useOnboardingPage();

  const {
    setInfosPipeDrive, setDealID,
  } = useOnboardingHandover();

  const validation = Yup.object().shape({
    dealID: Yup.number().required(),

  });

  const formik = useFormik({
    initialValues: { dealID: 0 },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const result = await getInfosPipedrive(values.dealID);
        setInfosPipeDrive(result);
        setDealID(values.dealID);
        toast.success('Dados carregados com sucesso!');
        setLoading(false);
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          if (e.response) {
            toast.error(e.response.data || ErrorMessage.default());
          }
        } else {
          toast.error(ErrorMessage.default());
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FullCard>
      <CardContainer>
        <Content>
          <Header>
            <Title>Onboarding</Title>
            {isOwnerCard && (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                formik={formik}
                id="dealID"
                placeholder="Deal_ID"
                type="number"
                className="field-deal"
                disabled={loading}
              />
              <ButtonPipedrive>
                <FormButton
                  dataCy="btn-pull-pipedrive"
                  type="submit"
                  customColor="blue-dark"
                  variant="outlined"
                  customClassName="button-deal"
                  disable={loading}
                >
                  {loading ? 'Carregando' : 'Puxar do Pipedrive' }
                </FormButton>
              </ButtonPipedrive>
            </form>
            )}
          </Header>
          {loading ? (
            <CardLoading>
              <CircleLoading size="120px" />
              <h1>Estamos carregando os dados do pipedrive, aguarde um instante.</h1>
            </CardLoading>
          ) : (
            <ContentShowCards>
              {showSteps && (
                <>
                  <div className={`steps ${isOwnerCard ? 'active' : ''}`}><CardOwner /></div>
                  <div className={`steps ${isPropertyInformationCard ? 'active' : ''}`}><CardPropertyInformation /></div>
                  <div className={`steps ${isDeploymentFeeValuesCard ? 'active' : ''}`}><CardDeploymentFeeValues endCallback={() => { setShowSteps(false); setShowSteps(true); }} /></div>
                  {isFinishCard && (
                  <CardFinish />
                  ) }
                </>
              )}
            </ContentShowCards>
          )}

        </Content>
      </CardContainer>
      <AuxDiv />
    </FullCard>
  );
}
