import axios from 'axios';
import request from '../request';
import {
  Handover, PipedriveOwnerInfo, PropertyManager, PropertyRules,
} from './types';

export const postPropertyHandover = async (params: Handover) => {
  const { data } = await request.post('/property/handover_details/', params);
  return data;
};

export const postPropertyManager = async (params: PropertyManager) => {
  const { data } = await request.post('/property/manager/', params);
  return data;
};

export const deletePropertyManager = async (id: number) => {
  await request.delete(`/property/manager/${id}/`);
};

export const postPropertyRules = async (params: PropertyRules) => {
  const { data } = await request.post('/property/rules/', params);
  return data;
};

export const deletePropertyRules = async (id: number) => {
  await request.delete(`/property/rules/${id}/`);
};

const userData = localStorage.getItem('sapron-pms-user');

const handover = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    Authorization: userData ? `Bearer ${JSON.parse(userData).access}` : '',
  },
});

export const getInfosPipedrive = async (id: number): Promise<PipedriveOwnerInfo> => {
  const { data } = await handover.get(`/pipedrive/handover_info/${id}/`);
  return data as PipedriveOwnerInfo;
};
