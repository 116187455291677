/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactElement } from 'react';

import { Lock } from 'react-feather';
import {
  Pets as PetsIcon,
} from '@mui/icons-material';

import moment from 'moment';
import { RequestOta } from '../../../../services/Property/types';
import { BlockingReason, Reservation } from '../../../../services/Reservation/types';
import { formatedDateToShow, numberToCurrency } from '../../../../utils/Formatter';

import {
  ContainerBox,
  HeaderContainer,
  Title,
} from './styles';

import ModalTemplate from '../ModalTemplate';

import airbnbLogo from '../../../../assets/icons/otas/airbnbLogo.svg';
import bookingLogo from '../../../../assets/icons/otas/bookingLogo.svg';
import expediaLogo from '../../../../assets/icons/otas/expediaLogo.svg';
import homeawayLogo from '../../../../assets/icons/otas/homeawayLogo.svg';
import temporadalivreLogo from '../../../../assets/icons/otas/temporadalivreLogo.svg';
import testadorLogo from '../../../../assets/icons/otas/testadorLogo.svg';
import staysLogo from '../../../../assets/icons/otas/staysLogo.svg';
import websiteLogo from '../../../../assets/icons/otas/websiteLogo.svg';
import seazoneLogo from '../../../../assets/icons/otas/seazoneLogo.svg';
import decolarLogo from '../../../../assets/icons/otas/decolarLogo.svg';

import CloseSVG from '../../../IconsComponents/Close';
import {
  NewContainer,
  NewContentBox,
  NewHorizontalRow,
  NewItem, NewItemDescription, NewItemHeader, NewItemPersonContainer, NewItemTitle,
  NewItemWithLine,
  NewVerticalLine,
} from './newStyle';
import PersonSVG from '../../../IconsComponents/Person';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';
import ModalReservationBlocked from './Blocked';
import MobileTooltip from '../../../Tooltip/MobileTooltip';

interface Props {
  id: number;
  onClose: Function;
  open?: boolean;
  guestName?: string;
  petIndicator?: boolean;
  adultGuests?: number;
  childrenGuests?: number;
  blocked?: boolean;
  startDate?: string;
  endDate?: string;
  reason: BlockingReason | string;
  ota: RequestOta;
  totalPrice?: number;
  notes?: string;
  reservation: Reservation;
  dailyNetValue: number;
}

const ImageOta: Record<string, ReactElement> = {
  BLK: <Lock className="lockLogo" />,
  AIR: <img src={airbnbLogo} alt="Airbnb Logo" />,
  BOO: <img src={bookingLogo} alt="Booking Logo" />,
  EXP: <img src={expediaLogo} alt="Expedia Logo" />,
  HOM: <img src={homeawayLogo} alt="HomeAway Logo" />,
  STA: <img src={staysLogo} alt="Stays Logo" />,
  TEM: <img src={temporadalivreLogo} alt="TemporadaLivre Logo" />,
  CON: <img src={seazoneLogo} alt="Seazone Logo" />,
  WEB: <img src={websiteLogo} alt="Website Logo" />,
  TES: <img src={testadorLogo} alt="Testador Logo" />,
  DEC: <img src={decolarLogo} alt="Decolar Logo" />,
};

const ModalReservation: FC<Props> = ({
  id,
  open = false,
  onClose,
  guestName,
  petIndicator,
  adultGuests,
  childrenGuests,
  blocked = false,
  startDate = 'dd/mm/yyyy',
  endDate = 'dd/mm/yyyy',
  reason,
  ota,
  totalPrice,
  notes,
  reservation,
  dailyNetValue,
}) => {
  const { isMobile } = useScreenResize();

  function numberOfDaily(checkin: string, checkout: string) {
    const total = moment(checkout, 'DD/MM/YYYY').diff(moment(checkin, 'DD/MM/YYYY'), 'day');
    return total;
  }

  if (blocked) {
    return (
      <ModalReservationBlocked
        id={id}
        open={open}
        onClose={onClose}
        guestName={guestName}
        petIndicator={petIndicator}
        adultGuests={adultGuests}
        childrenGuests={childrenGuests}
        blocked={blocked}
        startDate={startDate}
        endDate={endDate}
        reason={reason}
        ota={ota}
        totalPrice={totalPrice}
        notes={notes}
        reservation={reservation}
        dailyNetValue={dailyNetValue}
      />
    );
  }

  return (
    <ModalTemplate
      newStyles
      open={open}
      handleClose={onClose}
    >
      <ContainerBox>
        <HeaderContainer isBlocked={blocked}>
          <Title blocked={blocked}>
            Detalhes da reserva
          </Title>
          <CloseSVG
            size={20}
            id="close-btn"
            onClick={() => onClose(false)}
          />
        </HeaderContainer>

        <NewContainer>
          <NewContentBox>
            <NewItemWithLine>
              <NewItem>
                <NewItemTitle>Hóspede</NewItemTitle>
                <NewItemDescription>{guestName}</NewItemDescription>
                <NewItemPersonContainer>
                  <div>
                    <PersonSVG />
                    <span>{adultGuests}</span>
                  </div>
                  <div>
                    <PersonSVG width="14" height="14" />
                    <span>{childrenGuests}</span>
                  </div>
                  {petIndicator && <PetsIcon className="petsIcon" /> }
                </NewItemPersonContainer>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemHeader>
                  <NewItemTitle>Valor bruto da reserva</NewItemTitle>
                  <MobileTooltip
                    text={(
                      <p>
                        Valor pago pelo hóspede, incluindo as taxas de OTA e taxa de Limpeza
                      </p>
                    )}
                  />
                </NewItemHeader>
                <NewItemDescription>
                  {numberToCurrency(reservation?.total_price || 0)}
                </NewItemDescription>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemTitle>Taxa de Limpeza</NewItemTitle>
                <NewItemDescription>
                  {numberToCurrency(reservation?.net_cleaning_fee || 0)}
                </NewItemDescription>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemHeader>
                  <NewItemTitle>Valor Líquido das diárias</NewItemTitle>
                  <MobileTooltip
                    text={(
                      <p>
                        Valor da reserva com descontos.
                      </p>
                    )}
                  />
                </NewItemHeader>
                <NewItemDescription>
                  {numberToCurrency(reservation?.daily_net_value || 0)}
                </NewItemDescription>
              </NewItem>
            </NewItemWithLine>

            {isMobile ? <NewHorizontalRow /> : <NewVerticalLine />}

            <NewItemWithLine>
              <NewItem>
                <NewItemTitle>Data da reserva </NewItemTitle>
                <NewItemDescription>
                  {formatedDateToShow(startDate)}
                  {' '}
                  -
                  {' '}
                  {formatedDateToShow(endDate)}
                </NewItemDescription>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemTitle>
                  Quantidade de diárias
                </NewItemTitle>
                <NewItemDescription>
                  {numberOfDaily(moment(startDate).format('DD/MM/YYYY'), moment(endDate).format('DD/MM/YYYY'))}
                </NewItemDescription>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemTitle>Plataforma</NewItemTitle>
                <NewItemDescription>
                  {ImageOta[ota.initials.toUpperCase().trim()]}
                </NewItemDescription>
              </NewItem>

              <NewHorizontalRow />

              <NewItem>
                <NewItemTitle>Observação</NewItemTitle>
                <NewItemDescription>{notes && notes?.length > 2 ? notes : 'Nenhuma observação'}</NewItemDescription>
              </NewItem>
            </NewItemWithLine>
          </NewContentBox>
        </NewContainer>
      </ContainerBox>
    </ModalTemplate>
  );
};

export default ModalReservation;
