/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  GetOwnerBankDetailsById,
  GetOwnerInvoiceDetailsById,
  PatchEditAddress,
  PatchEditBankDetails,
  PatchEditInvoiceDetails,
  PatchEditUser,
  PatchOwner,
  PixKeyType,
  PostAddressObject,
  PostBankDetailsOwnerObject,
  PostInvoiceDetailsOwnerObject,
  PostOwnerObject,
  PostUserObject,
} from '../../../services/InsertData/types';
import { Gender } from '../../../services/types';

import {
  postOwner,
  postUser,
  postBankDetails,
  postInvoiceDetails,
  patchOwner,
  deleteOwner,
  deleteBankDetails,
  deleteInvoceDetails,
  deleteUser,
  getOwnerDetailsById,
  getUserDetailsById,
  getOwnerBankDetailsById,
  getOwnerInvoiceDetailsById,
  patchEditOwner,
  patchEditUser,
  patchEditAddress,
  patchEditBankDetails,
  patchEditInvoiceDetails,
} from '../../../services/InsertData/request';

import {
  postAddress,
  deleteAddress,
} from '../../../services/Address/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import FormAddUser from './Forms/FormAddUser';
import FormAddOwner from './Forms/FormAddOwner';
import FormButton from '../../FormButton';

import { onlyNumbers } from '../../../utils/Formatter';
import { Number as Mask } from '../../../utils/InputMask';

import {
  Container,
  Title,
  Divider,
  Form,
  ButtonsContainer,
} from './styles';

type FormState = 'user' | 'owner';
type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;

interface AddOwnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate?: Date | null;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  transferDay: number;
  bank: number;
  branchNumber: string;
  accountNumber: string;
  accountType: string;
  pixKey: string;
  pixKeyType?: PixKeyType | 'Dont_Have';
  entityName: string;
  bankCpfOrCnpj: string;
  invoiceEntityName: string;
  invoiceCpfOrCnpj: string;
  invoiceEmail: string;
  invoicePhoneNumber1: string;
  invoiceStreet: string;
  invoiceAddressNumber: string;
  invoiceAddressComplement: string;
  invoiceDistrict: string;
  invoiceCity: string;
  invoiceState: string;
  invoiceCountry: string;
  invoicePostalCode: string;
  zipCode: string;
  ownerId?: number;
  isIndividual: boolean;
  personID?: string | undefined;
}

interface OwnerDetailsProps {
  mainAddress: number; // nu
  postalAddress: number; // nu
  firstName: string; // user
  lastName: string; // user
  email?: string; // user
  phoneNumber1?: string; // user
  phoneNumber2?: string; // user
  gender: string; // user
  birthDate?: string; // user
  cpf?: string // user
  cnpj?: string; // user
  corporateName?: string; // user
  tradingName?: string; // user
  nickname?: string; // user
  profession: string; // owner
  nationality?: string; // owner
  maritalStatus?: MaritalStatus; // owner
  emailForOperation?: string; // owner
  user: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    trading_name: string;
    is_individual: boolean;
    phone_number1: string;
  };
  pipedrive_person_id?: string,
  invoiceAddressId: number; // nu
  defaultBankDetails?: number; // nu
  defaultInvoiceDetails?: number; // nu
  neighborhood: string; // address
  country: string; // address
  street: string; // address
  number?: string; // address
  complement?: string; // address
  city: string; // address
  state: string; // address
  postalCode: string; // address
  condominium?: string; // address
  isDefault?: boolean;
  transfer_day?: number;
  entityName?: string; // bank
  branchNumber?: string; // bank
  accountNumber?: string; // bank
  accountType?: string; // bank
  bankCpf?: string; // bank
  bankCnpj?: string; // bank
  pixKey?: string; // bank
  pixKeyType?: PixKeyType | 'Dont_Have';
  bank?: number; // bank
  bankUser: number; // bank
  invoiceEntityName?: string;
  invoiceCpf?: string;
  invoiceCnpj?: string;
  invoiceEmail?: string;
  invoicePhoneNumber?: string;
  invoicePostalCode?: string;
  invoiceAddress?: string;
  invoiceAddressNumber?: string;
  invoiceComplement?: string;
  invoiceDistrict?: string;
  invoiceCity?: string;
  invoiceState?: string;
  invoiceUser?: number;
  ownerId: number;
  userId: number;
  addressId: number;
  ownerBankId:number;
  invoiceId: number;
  mainRole: string;
  isIndividual?: boolean;
}

const InsertAddOwnerData = () => {
  const isEditingForm = [
    '/editardados/proprietario',
    'editardados/proprietario',
    '/editardados/proprietario/',
    'editardados/proprietario/',
  ].includes(window.location.pathname);

  const [passwordFirstNameRegexp, setPasswordFirstNameRegexp] = useState<RegExp>();
  const [passwordLastNameRegexp, setPasswordLastNameRegexp] = useState<RegExp>();
  const [formState, setFormState] = useState<FormState>('user');
  const [disable, setDisable] = useState(true);
  const [isSameAddress, setIsSameAddres] = useState(false);
  const [
    allOwnerDetails,
    setAllOwnerDetails,
  ] = useState<OwnerDetailsProps>({} as OwnerDetailsProps);

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const navigate = useNavigate();

  function padTo2Digits(number: number) {
    return number.toString().padStart(2, '0');
  }

  function formatDate(date?: Date | string) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return [
          current.getFullYear(),
          padTo2Digits(current.getMonth() + 1),
          padTo2Digits(current.getDate()),
        ].join('-');
      }
    }
    return undefined;
  }

  function handleAge(date?: Date) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return new Date().getFullYear() - date.getFullYear();
      }
    }
    return 0;
  }

  const initialValues = {
    personID: !isEditingForm ? '' : (allOwnerDetails.pipedrive_person_id || ''),
    formType: 'user' as FormState,
    firstName: !isEditingForm ? '' : (allOwnerDetails.firstName || ''), // User
    lastName: !isEditingForm ? '' : (allOwnerDetails.lastName || ''), // User
    nickname: !isEditingForm ? '' : (allOwnerDetails.nickname || ''), // User
    email: !isEditingForm ? '' : (allOwnerDetails.email || ''), // User
    password: '', // User
    passwordConfirmation: '', // User
    phoneNumber1: !isEditingForm ? '' : Mask.phone(allOwnerDetails.phoneNumber1 || ''), // User
    phoneNumber2: !isEditingForm ? '' : Mask.phone(allOwnerDetails.phoneNumber2 || ''), // User
    gender: !isEditingForm ? 'Not informed' as Gender : (allOwnerDetails.gender || 'Not informed' as Gender), // User
    birthDate: !isEditingForm ? null
      : moment(allOwnerDetails?.birthDate || formatDate(new Date())).toDate(), // User
    cpf: !isEditingForm ? '' : Mask.cpf(allOwnerDetails.cpf || ''), // User
    cnpj: !isEditingForm ? '' : Mask.cnpj(allOwnerDetails.cnpj || ''), // User
    corporateName: !isEditingForm ? '' : (allOwnerDetails?.corporateName || ''), // User
    tradingName: !isEditingForm ? '' : (allOwnerDetails?.tradingName || ''), // User
    street: !isEditingForm ? '' : (allOwnerDetails.street || ''), // Address
    number: !isEditingForm ? '' : (allOwnerDetails.number || ''), // Address
    complement: !isEditingForm ? '' : (allOwnerDetails.complement || ''), // Address
    neighborhood: !isEditingForm ? '' : (allOwnerDetails.neighborhood || ''), // Address
    city: !isEditingForm ? '' : (allOwnerDetails.city || ''), // Address
    state: !isEditingForm ? '' : (allOwnerDetails.state || ''), // Address
    country: !isEditingForm ? '' : (allOwnerDetails.country || ''), // Address
    postalCode: !isEditingForm ? '' : Mask.cep(allOwnerDetails.postalCode || ''), // Address
    nationality: !isEditingForm ? '' : (allOwnerDetails.nationality || ''), // Owner
    maritalStatus: !isEditingForm ? undefined
      : (allOwnerDetails.maritalStatus || undefined), // Owner
    profession: !isEditingForm ? 'Não informado' : (allOwnerDetails.profession || 'Não informado'), // Owner
    emailForOperation: !isEditingForm ? '' : (allOwnerDetails.emailForOperation || ''), // Owner
    transferDay: !isEditingForm ? 10
      : (allOwnerDetails.transfer_day || 10),
    bank: !isEditingForm ? -1 : (allOwnerDetails.bank || -1), // Bank
    branchNumber: !isEditingForm ? '' : (allOwnerDetails.branchNumber || ''), // Bank
    accountNumber: !isEditingForm ? '' : (allOwnerDetails.accountNumber || ''), // Bank
    accountType: !isEditingForm ? '' : (allOwnerDetails.accountType || ''), // Bank
    pixKey: !isEditingForm ? '' : (allOwnerDetails.pixKey || ''), // Bank
    pixKeyType: !isEditingForm ? undefined : (allOwnerDetails.pixKeyType || undefined), // Bank
    entityName: !isEditingForm ? '' : (allOwnerDetails.entityName || ''), // Bank
    bankCpfOrCnpj: allOwnerDetails.bankCpf === '' ? Mask.cnpj(allOwnerDetails?.bankCnpj || '') : Mask.cpf(allOwnerDetails?.bankCpf || ''), // Bank
    invoiceEntityName: !isEditingForm ? '' : (allOwnerDetails.invoiceEntityName || ''), // InvoiceDetails
    invoiceCpfOrCnpj: allOwnerDetails.invoiceCpf === '' ? Mask.cnpj(allOwnerDetails?.invoiceCnpj || '') : Mask.cpf(allOwnerDetails?.invoiceCpf || ''), // InvoiceDetails
    invoiceEmail: !isEditingForm ? '' : (allOwnerDetails.invoiceEmail || ''), // InvoiceDetails
    invoicePhoneNumber1: !isEditingForm ? '' : Mask.phone(allOwnerDetails.invoicePhoneNumber || ''), // InvoiceDetails
    invoiceStreet: !isEditingForm ? '' : (allOwnerDetails.invoiceAddress || ''), // InvoiceDetails
    invoiceAddressNumber: !isEditingForm ? '' : (allOwnerDetails.invoiceAddressNumber || ''), // InvoiceDetails
    invoiceAddressComplement: !isEditingForm ? '' : (allOwnerDetails.invoiceComplement || ''), // InvoiceDetails
    invoiceDistrict: !isEditingForm ? '' : (allOwnerDetails.invoiceDistrict || ''), // InvoiceDetails
    invoiceCity: !isEditingForm ? '' : (allOwnerDetails.invoiceCity || ''), // InvoiceDetails
    invoiceState: !isEditingForm ? '' : (allOwnerDetails.invoiceState || ''), // InvoiceDetails
    invoiceCountry: '', // InvoiceDetails
    invoicePostalCode: !isEditingForm ? '' : Mask.cep(allOwnerDetails.invoicePostalCode || ''), // InvoiceDetails
    zipCode: '',
    ownerId: !isEditingForm ? undefined : (allOwnerDetails.ownerId || undefined),
    isIndividual: !isEditingForm ? true : (allOwnerDetails?.isIndividual || true),
  };

  const validationInsert = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email('Digite um e-mail valido').required(),
    password: Yup.string().required().min(8, 'Mínimo 8 caracteres')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'A senha precisa ter no mínimo uma letra maiúscula ou uma letra minúscula, '
      + 'um número e um caracter especial')
      .matches(passwordFirstNameRegexp || /./, 'Senha fraca')
      .matches(passwordLastNameRegexp || /./, 'Senha fraca'),
    passwordConfirmation: Yup.string().required().min(8, 'Mínimo 8 caracteres').oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
    phoneNumber1: Yup.string().required(),
    gender: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string(),
    complement: Yup.string(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    bank: Yup.string().required(),
    branchNumber: Yup.string().required(),
    accountNumber: Yup.string().required(),
    accountType: Yup.string().required(),
    pixKeyType: Yup.string().required(),
    entityName: Yup.string().required(),
    invoiceEntityName: Yup.string().required(),
    invoiceEmail: Yup.string().required().email('Digite um e-mail válido'),
    invoicePhoneNumber1: Yup.string().required(),
    invoiceStreet: Yup.string().required(),
    invoiceDistrict: Yup.string().required(),
    invoiceCity: Yup.string().required(),
    invoiceState: Yup.string().required(),
    invoiceCountry: Yup.string().required(),
    invoicePostalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    isIndividual: Yup.boolean().required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
    bankCpfOrCnpj: Yup.string().test(
      'test-bank-cpf-or-cnpj', 'Insira um CPF/CNPJ válido', (documentNumber: any) => {
        const isValidCPF = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 14;
        const isValidCNPJ = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 18;
        return (isValidCPF || isValidCNPJ);
      },
    ),
    invoiceCpfOrCnpj: Yup.string().test(
      'test-invoice-cpf-or-cnpj', 'Insira um CPF/CNPJ válido', (documentNumber: any) => {
        const isValidCPF = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 14;
        const isValidCNPJ = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 18;
        return (isValidCPF || isValidCNPJ);
      },
    ),
  });

  const validationEdit = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    street: Yup.string().required(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    invoicePostalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    isIndividual: Yup.boolean().required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
    bankCpfOrCnpj: Yup.string().test(
      'test-bank-cpf-or-cnpj', 'Insira um CPF/CNPJ válido', (documentNumber: any) => {
        const isValidCPF = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 14;
        const isValidCNPJ = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 18;
        return (isValidCPF || isValidCNPJ);
      },
    ),
    invoiceCpfOrCnpj: Yup.string().test(
      'test-invoice-cpf-or-cnpj', 'Insira um CPF/CNPJ válido', (documentNumber: any) => {
        const isValidCPF = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 14;
        const isValidCNPJ = !['', 'null', 'undefined'].includes(`${documentNumber}`) && `${documentNumber}`.length === 18;
        return (isValidCPF || isValidCNPJ);
      },
    ),
  });

  const handleInsertSubmit = async (values: AddOwnerFormikValuesType) => {
    event?.preventDefault();

    // (CPF and CNPJ converted to number)
    const onlyNumbersCPFformikValue = onlyNumbers(`${values.cpf}`);
    const onlyNumbersCNPJformikValue = onlyNumbers(`${values.cnpj}`);
    const onlyNumbersBankCPForCNPJformikValue = onlyNumbers(`${values.bankCpfOrCnpj}`);
    const onlyNumbersInvoiceCPForCNPJformikValue = onlyNumbers(`${values.invoiceCpfOrCnpj}`);

    // postal code (CEP converted to number)
    const onlyNumbersCEPformikValue = onlyNumbers(`${values.postalCode}`);
    const onlyNumbersInvoiceCEPformikValue = onlyNumbers(`${values.invoicePostalCode}`);

    // phone number (phone converted to number)
    const onlyNumbersPhoneNumber1formikValue = Mask.phoneToNumber(`${values.phoneNumber1}`);
    const onlyNumbersPhoneNumber2formikValue = Mask.phoneToNumber(`${values.phoneNumber2}`);
    const onlyNumbersInvoicePhoneNumber1formikValue = Mask.phoneToNumber(`${values.invoicePhoneNumber1}`);

    let createdAddressId: number = -1;
    let createdUserID : number = -1;
    let createdInvoiceAddressId: number = -1;
    let createdOwnerId: number = -1;
    let createdBankDetailsId: number = -1;
    let createdInvoiceDetails: number = -1;

    try {
      const address: PostAddressObject = {
        city: values.city,
        street: values.street,
        complement: values.complement,
        country: values.country,
        neighborhood: values.neighborhood,
        number: values.number,
        postal_code: onlyNumbersCEPformikValue,
        state: values.state,
      }; // Objeto enviado a API /account/address

      const addressResponse = await postAddress(address);
      createdAddressId = addressResponse?.id || -1;

      const user: PostUserObject = {
        first_name: values.firstName,
        last_name: values.lastName,
        nickname: values.nickname,
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        phone_number1: values.phoneNumber1 !== '' ? onlyNumbersPhoneNumber1formikValue : '',
        phone_number2: values.phoneNumber2 !== '' ? onlyNumbersPhoneNumber2formikValue : '',
        gender: values.gender,
        birth_date: values?.birthDate === null ? undefined : formatDate(values?.birthDate),
        cpf: values.isIndividual ? onlyNumbersCPFformikValue : '',
        cnpj: !values.isIndividual ? onlyNumbersCNPJformikValue : '',
        corporate_name: values.corporateName,
        trading_name: values.tradingName,
        main_address: createdAddressId,
        main_role: 'Owner',
        pipedrive_person_id: values.personID,
        is_individual: values.isIndividual,
      }; // Objeto enviado a API /users

      createdUserID = await postUser(user);
      if (isSameAddress === true) {
        const owner: PostOwnerObject = {
          user: createdUserID,
          invoice_address: createdAddressId || -1,
          profession: values.profession === '' ? 'Não informado' : values.profession,
          email_for_operation: values.emailForOperation,
          marital_status: values.maritalStatus,
          nationality: values.nationality,
          transfer_day: values.transferDay,
        }; // Objeto enviado a API /account/owner/
        createdOwnerId = await postOwner(owner);
      } else {
        const invoiceAddress: PostAddressObject = {
          city: values.invoiceCity,
          country: values.invoiceCountry,
          complement: values.invoiceAddressComplement,
          neighborhood: values.invoiceDistrict,
          state: values.invoiceState,
          number: values.invoiceAddressNumber,
          postal_code: onlyNumbersInvoiceCEPformikValue,
          street: values.invoiceStreet,
        };

        const addressResponse2 = await postAddress(invoiceAddress);
        createdInvoiceAddressId = addressResponse2?.id || -1;

        const owner: PostOwnerObject = {
          user: createdUserID,
          invoice_address: createdInvoiceAddressId || -1,
          profession: values.profession === '' ? 'Não informado' : values.profession,
          email_for_operation: values.emailForOperation,
          marital_status: values.maritalStatus,
          nationality: values.nationality,
          transfer_day: values.transferDay,
        }; // Objeto enviado a API /account/owner/
        createdOwnerId = await postOwner(owner);
      }

      const bankDetail: PostBankDetailsOwnerObject = {
        entity_name: values.entityName,
        branch_number: values.branchNumber,
        account_type: values.accountType,
        bank: values.bank,
        account_number: values.accountNumber,
        pix_key: values.pixKeyType === 'Dont_Have' ? undefined : values.pixKey,
        pix_key_type: values.pixKeyType === 'Dont_Have' ? undefined : values.pixKeyType,
        user: createdUserID,
        cpf: values.bankCpfOrCnpj.length === 14
          ? onlyNumbersBankCPForCNPJformikValue
          : undefined,
        cnpj: values.bankCpfOrCnpj.length === 18
          ? onlyNumbersBankCPForCNPJformikValue
          : undefined,
      };

      createdBankDetailsId = await postBankDetails(bankDetail);

      const invoiceDetails: PostInvoiceDetailsOwnerObject = {
        address: values.invoiceStreet,
        address_number: values.invoiceAddressNumber,
        city: values.invoiceCity,
        district: values.invoiceDistrict,
        postal_code: onlyNumbersInvoiceCEPformikValue,
        state: values.state,
        user: createdUserID,
        complement: values.invoiceAddressComplement,
        cpf: values.invoiceCpfOrCnpj.length === 14
          ? onlyNumbersInvoiceCPForCNPJformikValue
          : undefined,
        cnpj: values.invoiceCpfOrCnpj.length === 18
          ? onlyNumbersInvoiceCPForCNPJformikValue
          : undefined,
        email: values.invoiceEmail,
        invoice_entity_name: values.invoiceEntityName,
        phone_number: values.invoicePhoneNumber1 !== '' ? onlyNumbersInvoicePhoneNumber1formikValue : '',
      };

      createdInvoiceDetails = await postInvoiceDetails(invoiceDetails);
      if (createdBankDetailsId !== -1 && createdInvoiceDetails !== -1) {
        await patchOwner({
          default_bank_details: createdBankDetailsId,
          default_invoice_details: createdInvoiceDetails,
        }, createdOwnerId);
      }
      navigate('/inserirdados');
      toast.success('Proprietário inserido com sucesso!');
    } catch (e: unknown) {
      if (createdOwnerId !== -1) {
        await deleteOwner(createdOwnerId);
      }
      if (createdUserID !== -1) {
        await deleteUser(createdUserID);
      }
      if (createdBankDetailsId !== -1) {
        await deleteBankDetails(createdBankDetailsId);
      }
      if (createdAddressId !== -1) {
        await deleteAddress(createdAddressId);
      }
      if (createdInvoiceDetails !== -1) {
        await deleteInvoceDetails(createdInvoiceDetails);
      }
      if (createdInvoiceAddressId !== -1) {
        await deleteAddress(createdInvoiceAddressId);
      }
      if (e instanceof Error) {
        toastErrorRequest(e);
      } else {
        toast.error('Não foi possível inserir o proprietário');
      }
    }
  };

  const handleEditSubmit = async (values: AddOwnerFormikValuesType) => {
    event?.preventDefault();

    // (CPF and CNPJ converted to number)
    const onlyNumbersCPFformikValue = onlyNumbers(`${values.cpf}`);
    const onlyNumbersCNPJformikValue = onlyNumbers(`${values.cnpj}`);
    const onlyNumbersBankCPForCNPJformikValue = onlyNumbers(`${values.bankCpfOrCnpj}`);
    const onlyNumbersInvoiceCPForCNPJformikValue = onlyNumbers(`${values.invoiceCpfOrCnpj}`);
    const onlyNumbersCPFownerDetails = onlyNumbers(`${allOwnerDetails.cpf}`);
    const onlyNumbersCNPJownerDetails = onlyNumbers(`${allOwnerDetails.cnpj}`);
    const onlyNumbersBankCPFownerDetails = onlyNumbers(`${allOwnerDetails.bankCpf}`);
    const onlyNumbersBankCNPJownerDetails = onlyNumbers(`${allOwnerDetails.bankCnpj}`);
    const onlyNumbersInvoiceCPFownerDetails = onlyNumbers(`${allOwnerDetails.invoiceCpf}`);
    const onlyNumbersInvoiceCNPJownerDetails = onlyNumbers(`${allOwnerDetails.invoiceCnpj}`);

    // postal code (CEP converted to number)
    const onlyNumbersCEPformikValue = onlyNumbers(`${values.postalCode}`);
    const onlyNumbersInvoiceCEPformikValue = onlyNumbers(`${values.invoicePostalCode}`);
    const onlyNumbersCEPownerDetails = onlyNumbers(`${allOwnerDetails.postalCode}`);
    const onlyNumbersInvoiceCEPownerDetails = onlyNumbers(`${allOwnerDetails.invoicePostalCode}`);

    // phone number (phone converted to number)
    const onlyNumbersPhoneNumber1formikValue = Mask.phoneToNumber(`${values.phoneNumber1}`);
    const onlyNumbersPhoneNumber2formikValue = Mask.phoneToNumber(`${values.phoneNumber2}`);
    const onlyNumbersInvoicePhoneNumber1formikValue = Mask.phoneToNumber(`${values.invoicePhoneNumber1}`);
    const onlyNumbersPhoneNumber1ownerDetails = Mask.phoneToNumber(`${allOwnerDetails.phoneNumber1}`);
    const onlyNumbersPhoneNumber2ownerDetails = Mask.phoneToNumber(`${allOwnerDetails.phoneNumber2}`);
    const onlyNumbersInvoicePhoneNumber1ownerDetails = Mask.phoneToNumber(`${allOwnerDetails.invoicePhoneNumber}`);

    let patchedAddress = false;
    let patchedUser = false;
    let patchedOwner = false;
    let patchedBankDetails = false;
    let patchedInvoiceDetails = false;
    const haveDefaultBank = !!allOwnerDetails.defaultBankDetails;
    const haveDefaultInvoice = !!allOwnerDetails.defaultInvoiceDetails;
    let bankId = allOwnerDetails.defaultBankDetails;
    let invoiceId = allOwnerDetails.defaultInvoiceDetails;

    try {
      const editedOwner: PatchOwner = {
        nickname: values.nickname,
        profession: values.profession,
        nationality: values.nationality === allOwnerDetails.nationality
          ? undefined : values.nationality,
        marital_status: values.maritalStatus === allOwnerDetails.maritalStatus
          ? undefined : values.maritalStatus,
        email_for_operation: values.emailForOperation === allOwnerDetails.emailForOperation
          ? undefined : values.emailForOperation,
        user: allOwnerDetails.user.id,
        invoice_address: allOwnerDetails.invoiceAddressId,
        default_bank_details: bankId,
        default_invoice_details: allOwnerDetails.defaultInvoiceDetails,
        transfer_day: allOwnerDetails.transfer_day,
      };
      await patchEditOwner(values.ownerId || 0, editedOwner);
      patchedOwner = true;

      const editedUser: PatchEditUser = {
        main_address: allOwnerDetails.mainAddress,
        postal_address: allOwnerDetails.postalAddress || allOwnerDetails.mainAddress,
        pipedrive_person_id: values.personID,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email === allOwnerDetails.email ? undefined : values.email,
        phone_number1: values.phoneNumber1 === allOwnerDetails.phoneNumber1
          ? undefined : onlyNumbersPhoneNumber1formikValue,
        phone_number2: values.phoneNumber2 === allOwnerDetails.phoneNumber2
          ? undefined : onlyNumbersPhoneNumber2formikValue,
        gender: values.gender,
        birth_date: ((formatDate(values?.birthDate || undefined) === allOwnerDetails.birthDate)
            || values.birthDate?.getFullYear() === new Date().getFullYear())
          ? undefined : formatDate(values?.birthDate || undefined),
        cpf: values.isIndividual
          ? (([onlyNumbersCPFownerDetails, ''].includes(onlyNumbersCPFformikValue)) ? undefined : onlyNumbersCPFformikValue)
          : undefined,
        cnpj: !values.isIndividual
          ? (([onlyNumbersCNPJownerDetails, ''].includes(onlyNumbersCNPJformikValue)) ? undefined : onlyNumbersCNPJformikValue)
          : undefined,
        corporate_name: values.corporateName === allOwnerDetails.corporateName
          ? undefined : values.corporateName,
        trading_name: values.tradingName === allOwnerDetails.tradingName
          ? undefined : values.tradingName,
        nickname: values.nickname === allOwnerDetails.nickname ? undefined : values.nickname,
        main_role: allOwnerDetails.mainRole,
        is_individual: values.isIndividual === allOwnerDetails.isIndividual
          ? undefined : values.isIndividual,
      };
      await patchEditUser(allOwnerDetails.user.id, editedUser);
      patchedUser = true;

      const editedAddress: PatchEditAddress = {
        neighborhood: values.neighborhood,
        country: values.country,
        street: values.street,
        number: values.number === allOwnerDetails.number ? undefined : values.number,
        complement: values.complement === allOwnerDetails.complement
          ? undefined : values.complement,
        city: values.city,
        state: values.state,
        postal_code: onlyNumbersCEPformikValue,
      };
      await patchEditAddress(allOwnerDetails.addressId, editedAddress);
      patchedAddress = true;

      if (haveDefaultBank) {
        const editedBankDetails: PatchEditBankDetails = {
          user: allOwnerDetails.user.id,
          account_number: values.accountNumber === allOwnerDetails.accountNumber
            ? undefined : values.accountNumber,
          account_type: values.accountType === allOwnerDetails.accountType
            ? undefined : values.accountType,
          bank: values.bank === allOwnerDetails.bank ? undefined : values.bank,
          branch_number: values.branchNumber === allOwnerDetails.branchNumber
            ? undefined : values.branchNumber,
          cpf: values.bankCpfOrCnpj.length === 14
            ? (onlyNumbersBankCPForCNPJformikValue === onlyNumbersBankCPFownerDetails
              ? undefined : onlyNumbersBankCPForCNPJformikValue)
            : onlyNumbersBankCPForCNPJformikValue === onlyNumbersBankCPFownerDetails
              ? undefined : '',
          cnpj: values.bankCpfOrCnpj.length === 18
            ? (onlyNumbersBankCPForCNPJformikValue === onlyNumbersBankCNPJownerDetails
              ? undefined : onlyNumbersBankCPForCNPJformikValue)
            : onlyNumbersBankCPForCNPJformikValue === onlyNumbersBankCNPJownerDetails
              ? undefined : '',
          entity_name: values.entityName === allOwnerDetails.entityName
            ? undefined : values.entityName,
          pix_key: values.pixKey === allOwnerDetails.pixKey ? undefined : values.pixKey,
          pix_key_type: (values.pixKeyType === 'Dont_Have' || values.pixKeyType === allOwnerDetails.pixKeyType)
            ? undefined : values.pixKeyType,
        };
        await patchEditBankDetails(allOwnerDetails.ownerBankId, editedBankDetails);
        patchedBankDetails = true;
      } else {
        const postBankDetailsObject: PostBankDetailsOwnerObject = {
          entity_name: values.entityName,
          branch_number: values.branchNumber,
          account_type: values.accountType,
          bank: values.bank,
          account_number: values.accountNumber,
          pix_key: values.pixKey,
          pix_key_type: values.pixKeyType === 'Dont_Have' ? undefined : values.pixKeyType,
          user: allOwnerDetails.user.id,
          cpf: values.bankCpfOrCnpj.length === 14 ? onlyNumbersBankCPForCNPJformikValue : undefined,
          cnpj: values.bankCpfOrCnpj.length === 18
            ? onlyNumbersBankCPForCNPJformikValue : undefined,
        };
        bankId = await postBankDetails(postBankDetailsObject);
        await patchEditOwner(values.ownerId || 0, {
          profession: values.profession,
          user: allOwnerDetails.user.id,
          invoice_address: allOwnerDetails.invoiceAddressId,
          default_bank_details: bankId,
          default_invoice_details: allOwnerDetails.defaultInvoiceDetails,
          nickname: values.nickname,
          pipedrive_person_id: values.personID,
        });
        patchedBankDetails = true;
      }

      if (haveDefaultInvoice && allOwnerDetails.invoiceId) {
        const editedInvoiceDetails: PatchEditInvoiceDetails = {
          address_number: values.invoiceAddressNumber === allOwnerDetails.invoiceAddressNumber
            ? undefined : values.invoiceAddressNumber,
          city: values.invoiceCity === allOwnerDetails.invoiceCity ? undefined : values.invoiceCity,
          cpf: values.invoiceCpfOrCnpj.length === 14
            ? (onlyNumbersInvoiceCPForCNPJformikValue === onlyNumbersInvoiceCPFownerDetails
              ? undefined : onlyNumbersInvoiceCPForCNPJformikValue)
            : onlyNumbersInvoiceCPForCNPJformikValue === onlyNumbersInvoiceCPFownerDetails
              ? undefined : '',
          cnpj: values.invoiceCpfOrCnpj.length === 18
            ? (onlyNumbersInvoiceCPForCNPJformikValue === onlyNumbersInvoiceCNPJownerDetails
              ? undefined : onlyNumbersInvoiceCPForCNPJformikValue)
            : onlyNumbersInvoiceCPForCNPJformikValue === onlyNumbersInvoiceCNPJownerDetails
              ? undefined : '',
          complement: values.invoiceAddressComplement === allOwnerDetails.invoiceComplement
            ? undefined : values.invoiceAddressComplement,
          district: values.invoiceDistrict === allOwnerDetails.invoiceDistrict
            ? undefined : values.invoiceDistrict,
          email: values.invoiceEmail === allOwnerDetails.invoiceEmail
            ? undefined : values.invoiceEmail,
          invoice_entity_name: values.invoiceEntityName === allOwnerDetails.invoiceEntityName
            ? undefined : values.invoiceEntityName,
          phone_number: values.invoicePhoneNumber1 === allOwnerDetails.invoicePhoneNumber
            ? undefined : onlyNumbersInvoicePhoneNumber1formikValue,
          postal_code: onlyNumbersInvoiceCEPformikValue === onlyNumbersInvoiceCEPownerDetails
            ? undefined : onlyNumbersInvoiceCEPformikValue,
          state: values.invoiceState === allOwnerDetails.invoiceState
            ? undefined : values.invoiceState,
          address: values.invoiceStreet === allOwnerDetails.invoiceAddress
            ? undefined : values.invoiceStreet,
          user: allOwnerDetails.user.id,
        };
        await patchEditInvoiceDetails(allOwnerDetails.invoiceId, editedInvoiceDetails);
        patchedInvoiceDetails = true;
      } else {
        const postInvoiceDetailsObject = {
          address: values.invoiceStreet,
          address_number: values.invoiceAddressNumber,
          city: values.invoiceCity,
          district: values.invoiceDistrict,
          postal_code: onlyNumbersInvoiceCEPformikValue,
          state: values.state,
          user: allOwnerDetails.user.id,
          complement: values.invoiceAddressComplement,
          cpf: values.invoiceCpfOrCnpj.length === 14
            ? onlyNumbersInvoiceCPForCNPJformikValue
            : undefined,
          cnpj: values.invoiceCpfOrCnpj.length === 18
            ? onlyNumbersInvoiceCPForCNPJformikValue
            : undefined,
          email: values.invoiceEmail,
          invoice_entity_name: values.invoiceEntityName,
          phone_number: values.invoicePhoneNumber1 !== '' ? onlyNumbersInvoicePhoneNumber1formikValue : '',
        };
        invoiceId = await postInvoiceDetails(postInvoiceDetailsObject);
        patchedInvoiceDetails = true;
      }

      patchOwner({
        default_bank_details: bankId || 0,
        default_invoice_details: invoiceId || 0,
      }, values.ownerId || 0);

      navigate('/editardados');
      toast.success('Proprietário editado com sucesso!');
    } catch (e: unknown) {
      if (patchedOwner !== false) {
        await patchEditOwner(values.ownerId || 0, {
          invoice_address: allOwnerDetails.invoiceAddressId,
          profession: allOwnerDetails.profession,
          user: allOwnerDetails.user.id,
          default_bank_details: allOwnerDetails.defaultBankDetails,
          default_invoice_details: allOwnerDetails.defaultInvoiceDetails,
          email_for_operation: allOwnerDetails.emailForOperation,
          marital_status: allOwnerDetails.maritalStatus,
          nationality: allOwnerDetails.nationality,
          transfer_day: allOwnerDetails.transfer_day,
          nickname: allOwnerDetails.nickname,
        });
      }
      if (patchedUser !== false) {
        await patchEditUser(allOwnerDetails.user.id, {
          first_name: allOwnerDetails.firstName,
          gender: allOwnerDetails.gender,
          last_name: allOwnerDetails.lastName,
          main_address: allOwnerDetails.mainAddress,
          postal_address: allOwnerDetails.postalAddress,
          birth_date: allOwnerDetails.birthDate,
          cnpj: onlyNumbersCNPJownerDetails,
          cpf: onlyNumbersCPFownerDetails,
          corporate_name: allOwnerDetails.corporateName,
          email: allOwnerDetails.email,
          nickname: allOwnerDetails.nickname,
          phone_number1: onlyNumbersPhoneNumber1ownerDetails,
          phone_number2: onlyNumbersPhoneNumber2ownerDetails,
          trading_name: allOwnerDetails.tradingName,
          main_role: allOwnerDetails.mainRole,
          is_individual: allOwnerDetails.isIndividual,
          pipedrive_person_id: values?.personID,
        });
      }
      if (patchedBankDetails !== false) {
        await patchEditBankDetails(allOwnerDetails.ownerBankId, {
          user: allOwnerDetails.user.id,
          account_number: allOwnerDetails.accountNumber,
          account_type: allOwnerDetails.accountType,
          bank: allOwnerDetails.bank,
          branch_number: allOwnerDetails.branchNumber,
          cnpj: onlyNumbersBankCNPJownerDetails,
          cpf: onlyNumbersBankCPFownerDetails,
          entity_name: allOwnerDetails.entityName,
          is_default: allOwnerDetails.isDefault,
          pix_key: allOwnerDetails.pixKey,
        });
      }
      if (patchedAddress !== false) {
        await patchEditAddress(allOwnerDetails.addressId, {
          city: allOwnerDetails.city,
          country: allOwnerDetails.country,
          neighborhood: allOwnerDetails.neighborhood,
          state: allOwnerDetails.state,
          street: allOwnerDetails.street,
          complement: allOwnerDetails.complement,
          number: allOwnerDetails.number,
          postal_code: onlyNumbersCEPownerDetails,
        });
      }
      if (patchedInvoiceDetails !== false) {
        await patchEditInvoiceDetails(allOwnerDetails.invoiceId, {
          address: allOwnerDetails.invoiceAddress,
          address_number: allOwnerDetails.invoiceAddressNumber,
          city: allOwnerDetails.invoiceCity,
          complement: allOwnerDetails.invoiceComplement,
          cnpj: onlyNumbersInvoiceCNPJownerDetails,
          cpf: onlyNumbersInvoiceCPFownerDetails,
          district: allOwnerDetails.invoiceDistrict,
          email: allOwnerDetails.invoiceEmail,
          invoice_entity_name: allOwnerDetails.invoiceEntityName,
          phone_number: onlyNumbersInvoicePhoneNumber1ownerDetails,
          postal_code: onlyNumbersInvoiceCEPownerDetails,
          state: allOwnerDetails.invoiceState,
          user: allOwnerDetails.invoiceUser,
        });
      }

      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
  };

  const formik = useFormik<AddOwnerFormikValuesType>({
    initialValues,
    validationSchema: isEditingForm ? validationEdit : validationInsert,
    onSubmit: async (values) => {
      if (!isEditingForm) {
        await handleInsertSubmit(values);
      } else {
        await handleEditSubmit(values);
      }
      formik.resetForm();
    },
  });

  function handleRegexFirstName() {
    const regexpAux = `^((?!${formik.values.firstName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordFirstNameRegexp(regexp);
  }

  function handleRegexLastName() {
    const regexpAux = `^((?!${formik.values.lastName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordLastNameRegexp(regexp);
  }

  function handleToggleIsSameAddress() {
    formik.setFieldValue('invoiceCity', formik.values.city);
    formik.setFieldValue('invoiceCountry', formik.values.country);
    formik.setFieldValue('invoiceAddressComplement', formik.values.complement);
    formik.setFieldValue('invoiceDistrict', formik.values.neighborhood);
    formik.setFieldValue('invoiceAddressNumber', formik.values.number);
    formik.setFieldValue('invoicePostalCode', formik.values.postalCode);
    formik.setFieldValue('invoiceStreet', formik.values.street);
    formik.setFieldValue('invoiceState', formik.values.state);

    setIsSameAddres((state) => !state);
  }

  async function getOwnerDetails(idOwner: number) {
    let ownerDetails: OwnerDetailsProps = {} as OwnerDetailsProps;
    const owner = await getOwnerDetailsById(idOwner);
    const user = await getUserDetailsById(owner.user.id);
    let ownerBank = {} as GetOwnerBankDetailsById;
    if (owner.default_bank_details !== null) {
      ownerBank = await getOwnerBankDetailsById(owner.default_bank_details);
    }

    let ownerInvoice = {} as GetOwnerInvoiceDetailsById;
    if (owner.default_invoice_details !== null) {
      ownerInvoice = await getOwnerInvoiceDetailsById(owner.default_invoice_details);
    }

    ownerDetails = {
      profession: owner.profession,
      emailForOperation: owner.email_for_operation,
      maritalStatus: owner.marital_status,
      nationality: owner.nationality,
      firstName: user.first_name,
      accountNumber: ownerBank.account_number,
      accountType: ownerBank.account_type,
      bank: ownerBank.bank?.id,
      birthDate: user.birth_date,
      branchNumber: ownerBank.branch_number,
      city: user.main_address.city,
      complement: user.main_address.complement,
      cnpj: Mask.cnpj(user.cnpj),
      cpf: Mask.cpf(user.cpf),
      corporateName: user.corporate_name,
      country: user.main_address.country,
      email: user.email,
      entityName: ownerBank.entity_name,
      gender: user.gender,
      invoiceCity: ownerInvoice.city,
      invoiceComplement: ownerInvoice.complement,
      invoiceCnpj: Mask.cnpj(ownerInvoice.cnpj),
      invoiceCpf: Mask.cpf(ownerInvoice.cpf),
      invoiceEmail: ownerInvoice.email,
      invoiceEntityName: ownerInvoice.invoice_entity_name,
      invoiceDistrict: ownerInvoice.district,
      invoiceAddressNumber: ownerInvoice.address_number,
      invoicePhoneNumber: Mask.phone(ownerInvoice.phone_number),
      invoicePostalCode: Mask.cep(ownerInvoice.postal_code),
      invoiceState: ownerInvoice.state,
      invoiceAddress: ownerInvoice.address,
      lastName: user.last_name,
      neighborhood: user.main_address.neighborhood,
      nickname: user.nickname,
      number: user.main_address.number,
      phoneNumber1: Mask.phone(user.phone_number1),
      phoneNumber2: Mask.phone(user.phone_number2),
      pixKey: ownerBank.pix_key,
      pixKeyType: ownerBank.pix_key_type,
      postalCode: Mask.cep(user.main_address.postal_code),
      state: user.main_role,
      street: user.main_address.street,
      tradingName: user.trading_name,
      ownerId: owner.id,
      userId: user.id,
      addressId: user.main_address.id,
      ownerBankId: ownerBank.id,
      invoiceId: ownerInvoice.id,
      bankUser: ownerBank.user,
      invoiceAddressId: owner.invoice_address,
      mainAddress: user.main_address.id,
      postalAddress: user?.postal_address?.id || user.main_address.id,
      user: { ...owner.user },
      bankCnpj: Mask.cnpj(ownerBank.cnpj),
      bankCpf: Mask.cpf(ownerBank.cpf),
      condominium: user.main_address.condominium,
      defaultBankDetails: owner.default_bank_details,
      defaultInvoiceDetails: owner.default_invoice_details,
      invoiceUser: ownerInvoice.user,
      mainRole: user.main_role,
      isIndividual: user.is_individual,
      pipedrive_person_id: user.pipedrive_person_id,
    };
    setAllOwnerDetails(ownerDetails);
  }

  const handleClickNextForm = () => {
    setFormState('owner');
    formik.setFieldValue('formType', 'owner');
  };

  const handleBackForm = () => {
    setFormState('user');
    formik.setFieldValue('formType', 'user');
  };

  const checkAllFieldsIsFilled = () => {
    if (isEditingForm) {
      if (formik.values.firstName === '') {
        return false;
      }
      if (formik.values.lastName === '') {
        return false;
      }
      if (formik.values.gender === '') {
        return false;
      }
      if (formik.values.street === '') {
        return false;
      }
      if (formik.values.neighborhood === '') {
        return false;
      }
      if (formik.values.city === '') {
        return false;
      }
      if (formik.values.state === '') {
        return false;
      }
      if (formik.values.country === '') {
        return false;
      }
      if (formik.values.postalCode === '') {
        return false;
      }
      return true;
    }
    if (formik.values.firstName === '') {
      return false;
    }
    if (formik.values.lastName === '') {
      return false;
    }
    if (formik.values.email === '') {
      return false;
    }
    if (formik.values.password === '') {
      return false;
    }
    if (formik.values.passwordConfirmation === '') {
      return false;
    }
    if (formik.values.phoneNumber1 === '') {
      return false;
    }
    if (formik.values.gender === '') {
      return false;
    }
    if (formik.values.cpf === null && formik.values.cnpj === null) {
      return false;
    }
    if (formik.values.cpf === '' && formik.values.cnpj === '') {
      return false;
    }
    if (formik.values.cpf === '' && formik.values.cnpj === null) {
      return false;
    }
    if (formik.values.cpf === null && formik.values.cnpj === '') {
      return false;
    }
    if (formik.values.isIndividual === true && formik.values.cpf === '') {
      return false;
    }
    if (formik.values.isIndividual === false && (formik.values.cnpj === '' || formik.values.tradingName === '' || formik.values.corporateName === '')) {
      return false;
    }
    if (formik.values.street === '') {
      return false;
    }
    if (formik.values.neighborhood === '') {
      return false;
    }
    if (formik.values.city === '') {
      return false;
    }
    if (formik.values.state === '') {
      return false;
    }
    if (formik.values.country === '') {
      return false;
    }
    if (formik.values.postalCode === '') {
      return false;
    }
    return true;
  };

  const handleChangePersonType = (manualType?: boolean) => {
    if (manualType) {
      formik.setFieldValue('isIndividual', manualType);
    } else {
      formik.setFieldValue('isIndividual', !formik.values.isIndividual);
    }
  };

  const checkSubmit = async () => {
    try {
      const validation = isEditingForm ? validationEdit : validationInsert;
      await validation.validate(formik.values, { abortEarly: false });
    } catch (errors) {
      formik.setSubmitting(false);

      Object.values(formik.errors).forEach((item) => {
        toast.error(`${item}`);
      });
    }
  };

  useEffect(() => {
    if (formik.isSubmitting) {
      checkSubmit();
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    setFormState(formik.values.formType);
  }, [formik.values.formType]);

  useEffect(() => {
    if (isEditingForm) {
      if (checkAllFieldsIsFilled() && formik.values.ownerId !== undefined) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else if (checkAllFieldsIsFilled()) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.ownerId !== undefined) {
      getOwnerDetails(formik.values.ownerId);
    } else {
      formik.resetForm();
    }
  }, [formik.values.ownerId]);

  useEffect(() => {
    if (isEditingForm && Object.keys(allOwnerDetails).length !== 0) {
      formik.setValues({
        ...initialValues,
        isIndividual: allOwnerDetails?.isIndividual !== undefined
          ? allOwnerDetails?.isIndividual : true,
      });
    }
  }, [isEditingForm, allOwnerDetails]);

  useEffect(() => {
    if (formik.values.firstName.length > 0) {
      handleRegexFirstName();
    }
  }, [formik.values.firstName]);

  useEffect(() => {
    if (formik.values.lastName.length > 0) {
      handleRegexLastName();
    }
  }, [formik.values.lastName]);

  useEffect(() => {
    if (formik?.values?.birthDate
      && formatDate(formik?.values?.birthDate) !== allOwnerDetails.birthDate) {
      const age = handleAge(formik?.values?.birthDate);
      if ((age < 12 || age > 123) && age !== 0) {
        formik.setFieldError('birthDate', 'A idade não é válida!');
      }
    }
  }, [formik.values.birthDate]);

  return (
    <Container>
      <div className="header">
        <div>
          <Title>{isEditingForm ? 'Editar proprietário' : 'Novo proprietário'}</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          {formState === 'user' && (
            <FormAddUser
              formik={formik}
              isEditingForm={isEditingForm}
              handleChangePersonType={handleChangePersonType}
            />
          )}
          {formState === 'owner' && (
          <FormAddOwner
            formik={formik}
            handleToggleIsSameAddres={handleToggleIsSameAddress}
            isSameAddress={isSameAddress}
            isEditingForm={isEditingForm}
          />
          )}
        </div>
        <ButtonsContainer>
          <div>
            {formState === 'user' && (
              <>
                <FormButton type="button" variant="outlined" onClick={() => { navigate(isEditingForm ? '/editardados' : '/inserirdados'); }}>Cancelar</FormButton>
                <FormButton
                  type="button"
                  onClick={() => handleClickNextForm()}
                  disable={disable}
                >
                  Próximo
                </FormButton>
              </>
            )}
            {formState === 'owner' && (
              <>
                <FormButton type="button" variant="outlined" onClick={() => { handleBackForm(); }}>Voltar</FormButton>
                <FormButton type="submit" disable={formik.isSubmitting}>Salvar</FormButton>
              </>
            )}
          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default InsertAddOwnerData;
