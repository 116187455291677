import React from 'react';
import { Button, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ButtonContainer, StyledMenu } from './styles';
import { changeHostTedStatus } from '../../../../../services/HostTedList/request';
import { IStatus } from '../../../../../services/HostTedList/types';
import { useToast } from '../../../../../context/ToastContext';
import { useHostTedList } from '../../../../../hooks/HostTedListHook/useHostTedList';
import { IStatementImage } from '../../../types';

interface IAdjustButton {
  tedId: number;
  statementImage: IStatementImage;
}

const AdjustButton = ({ tedId, statementImage }: IAdjustButton) => {
  const toast = useToast();
  const {
    handleListItems, handleModalImage, handleOpenModalID, listItems,
  } = useHostTedList();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const verifyItemStatus = () => {
    const item = listItems.find((itemStats) => itemStats.id === tedId);
    if (item) {
      return item.status;
    }
    return null;
  };

  const actualItemStatus = verifyItemStatus();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (newStatus: IStatus) => {
    handleClose();
    const status: IStatus = newStatus || 'Concluded';

    const params = {
      ted_status: status,
      ted_ids: [tedId],
    };

    const response = changeHostTedStatus(params)
      .then(() => {
        toast.success('Status alterado com sucesso !');

        const newListItems = listItems.map((item) => {
          if (item.id === tedId) {
            // eslint-disable-next-line no-param-reassign
            item.status = status;
          }
          return item;
        });
        handleListItems(newListItems);
      })
      .catch(() => toast.error('Erro ao concluir TED'));
    return response;
  };

  return (
    <>
      <ButtonContainer>
        <Button onClick={handleClick} startIcon={<MoreVert />}>
          Ajustar
        </Button>
        <StyledMenu
          className="menu"
          id="simple-menu"
          elevation={2}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actualItemStatus !== 'Concluded' && (
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#54DBB2',
                border: '2px solid #54DBB2',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Concluded')}
            >
              Concluir
            </MenuItem>
          )}

          {actualItemStatus === 'Concluded' && (
          <>
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#FF9900',
                border: '2px solid #FF9900',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Pending')}
            >
              Com ajustes
            </MenuItem>
            <MenuItem
              style={{
                borderRadius: 5,
                color: '#FF2732',
                border: '2px solid #FF2732',
                margin: '2px 10px 10px 10px',
                fontWeight: 'bold',
              }}
              onClick={() => handleChangeStatus('Not_Concluded')}
            >
              Não concluído
            </MenuItem>
          </>
          )}

          <MenuItem
            style={{
              borderRadius: 5,
              color: '#4B68FF',
              border: '2px solid #4B68FF',
              margin: '2px 10px 10px 10px',
              fontWeight: 'bold',
            }}
            onClick={() => handleChangeStatus('Exception')}
          >
            Exceção
          </MenuItem>

          <MenuItem
            style={{
              fontWeight: 'bold',
              borderRadius: 5,
              border: '2px solid #393a3a',
              margin: '10px 10px 2px 10px',
            }}
            onClick={() => {
              handleOpenModalID(tedId);
              handleModalImage(statementImage);
              handleClose();
            }}
          >
            Anexar Comprovante
          </MenuItem>
        </StyledMenu>
      </ButtonContainer>
    </>
  );
};

export default AdjustButton;
