import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
} from 'react';

import { GetOwnersData } from '../../services/InsertData/types';
import { PipedriveOwnerInfo } from '../../services/Onboarding/types';

import {
  DatesType,
  PropertyInformation,
  Values,
} from './types';

interface OnboardingHandoverContextData {
  owner: GetOwnersData;
  setOwner: (a: GetOwnersData) => void;
  propertyDetails: PropertyInformation;
  setPropertyDetails: (a: PropertyInformation) => void;
  values: Values;
  setValues: (a: Values) => void;
  dates: DatesType;
  setDates: (a: DatesType) => void;
  reloadOwners: boolean;
  setReloadOwners: (a: boolean) => void;
  setInfosPipeDrive: (a: PipedriveOwnerInfo) => void;
  infosPipeDrive: PipedriveOwnerInfo;
  setDealID: (a: number) => void;
  dealID: number;
  setIdNewOwner: (a: number) => void;
  idNewOwner: number
}

interface Props {
  children: ReactNode;
}

const OnboardingHandoverContext = createContext<OnboardingHandoverContextData>({} as
  OnboardingHandoverContextData);

export const OnboardingHandoverProvider = ({
  children,
}: Props) => {
  const [owner, setOwner] = useState<GetOwnersData>({} as GetOwnersData);
  const [idNewOwner, setIdNewOwner] = useState<number>(-1);
  const [propertyDetails,
    setPropertyDetails] = useState<PropertyInformation>({} as PropertyInformation);
  const [values, setValues] = useState<Values>({} as Values);
  const [dates, setDates] = useState<DatesType>({} as DatesType);
  const [reloadOwners, setReloadOwners] = useState<boolean>(false);
  const [infosPipeDrive,
    setInfosPipeDrive] = useState<PipedriveOwnerInfo>({} as PipedriveOwnerInfo);
  const [dealID, setDealID] = useState(0);

  return (
    <OnboardingHandoverContext.Provider value={{
      owner,
      setOwner,
      propertyDetails,
      setPropertyDetails,
      setValues,
      values,
      dates,
      setDates,
      reloadOwners,
      setReloadOwners,
      infosPipeDrive,
      setInfosPipeDrive,
      dealID,
      setDealID,
      idNewOwner,
      setIdNewOwner,
    }}
    >
      {children}
    </OnboardingHandoverContext.Provider>
  );
};

export function useOnboardingHandover(): OnboardingHandoverContextData {
  const {
    owner,
    setOwner,
    propertyDetails,
    setPropertyDetails,
    setValues,
    values,
    dates,
    setDates,
    reloadOwners,
    setReloadOwners,
    infosPipeDrive,
    setInfosPipeDrive,
    dealID,
    setDealID,
    idNewOwner,
    setIdNewOwner,
  } = useContext(OnboardingHandoverContext);
  return {
    owner,
    setOwner,
    propertyDetails,
    setPropertyDetails,
    setValues,
    values,
    dates,
    setDates,
    reloadOwners,
    setReloadOwners,
    infosPipeDrive,
    setInfosPipeDrive,
    dealID,
    setDealID,
    idNewOwner,
    setIdNewOwner,
  };
}
