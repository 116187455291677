/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Info } from '@mui/icons-material';
import CardInfo from './CardInfo';
import Guests from './Guests';
import ComingPet from './ComingPet';
import AddGuestButton from './AddGuestButton';
import GuestQuantity from './GuestQuantity';
import BedOrganization from './BedOrganization';
import ControlledSwitchButton from '../../../../ControlledSwitchButton';
import CheckinCheckoutAuthorization from './CheckinCheckoutAuthorization';
import { FormButton } from '../../../..';

import {
  Container,
  Content,
  Title,
  Header,
  AddGuestContainer,
  TopContent,
  ButtonsContainer,
  Row,
  Text,
  Error,
  GuestCapacityText,
  ButtonsContent,
} from './styles';

import {
  getBedArrangement,
  patchCompReservations,
  postGuestReservations,
  patchGuestReservations,
} from '../../../../../services/CompReservations/request';

import {
  BedArrangementType,
  CompReservationsType,
  Guest,
  GuestReservation,
  PreCheckinAdminUpdateType,
} from '../../../../../services/CompReservations/types';

import { useToast } from '../../../../../context/ToastContext';
import { useCompleteReservation } from '../../../../../hooks/CompleteReservationHook/useCompleteReservation';
import { getReservationsListUpdated } from '../../../../../context/CompleteReservationContext/utils';
import {
  ErrorMessage,
  useToastErrorMessage,
} from '../../../../../utils/Messages';
import Tooltip from '../../../../Tooltip';

interface MainContentProps {
  reservation: CompReservationsType;
  allReservations: CompReservationsType[];
  idProperty: number;
  checkin: string;
  checkout: string;
  daysLeft: number;
  idLocation: number;
  onClickFinish: Function;
}

const MainContent = ({
  reservation,
  allReservations,
  idProperty,
  checkin,
  checkout,
  daysLeft,
  idLocation,
  onClickFinish,
}: MainContentProps) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [listBedArrangement, setListBedArrangement] = useState<BedArrangementType[]>();
  const { reservationId, updateReservationsList, guestFormIsValid } = useCompleteReservation();

  const [guestMain, setGuestMain] = useState<Guest>(reservation.guests.length !== 0
    ? reservation.guests.filter((guest: Guest) => guest.is_principal)?.[0]
    : {
      isNewGuest: true,
      ...reservation.guest_owner,
      phone_number: reservation.guest_owner.phone_number1,
      email: reservation.guest_owner.email,
    });

  const [guestsSecondary, setGuestsSecondary] = useState<Array<Guest>>([
    ...reservation.guests
      .filter((guest: Guest) => !guest.is_principal)
      .map((item: Guest) => ({
        ...item,
        isNewGuest: false,
      })),
  ]);

  const [needCradle, setNeedCradle] = useState<boolean>(reservation?.need_cradle || false);

  useEffect(() => {
    async function getBedArrangements() {
      const results = await getBedArrangement(idLocation);
      setListBedArrangement(results);
    }

    getBedArrangements();
  }, []);

  const replaceAll = (
    string: string, search: string, replace: string,
  ) => string.split(search).join(replace);

  const initialValues = {
    quantityAdult: reservation?.adult_guest_quantity || 0,
    guestCapacity: reservation?.property?.guest_capacity || 0,
    comingPet: reservation?.has_pet !== 0,
    petsQuantity: reservation?.has_pet || 0,
    bedOrganization: reservation?.bed_arrangement || '',
    bedOrganizationList: reservation?.bed_arrangement_list || [''],
    finishCompReservation: reservation?.is_pre_checkin_completed || false,
    lateCheckoutAt: reservation?.late_checkout_at || '',
    earlyCheckinAt: reservation?.early_checkin_at || '',
    earlyCheckin: !!reservation?.early_checkin_at,
    lateCheckout: !!reservation?.late_checkout_at,
    child06GuestQuantity: reservation?.child_0_6_guest_quantity || 0,
    child612GuestQuantity: reservation?.child_6_12_guest_quantity || 0,
  };

  const validation = Yup.object().shape({
    quantityAdult: Yup.number()
      .min(1, 'Valor mínimo = 1')
      .max(initialValues.guestCapacity,
        `A quantidade máxima de hóspedes é ${initialValues.guestCapacity}`)
      .required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      let reservationUpdated: CompReservationsType = {
        ...getReservationsListUpdated(reservation),
      };

      try {
        const data: PreCheckinAdminUpdateType = {
          bed_arrangement: values.bedOrganizationList.join(','),
          has_pet:
            values.comingPet.toString() === 'true'
              ? values.petsQuantity === 0
                ? 1
                : values.petsQuantity
              : 0,
          is_pre_checkin_completed: values.finishCompReservation,
          adult_guest_quantity: values.quantityAdult,
          child_0_6_guest_quantity: values.child06GuestQuantity,
          child_6_12_guest_quantity: values.child612GuestQuantity,
          need_cradle: needCradle,
          early_checkin_at:
            values.earlyCheckin.toString() === 'true'
              ? values.earlyCheckinAt
              : null,
          late_checkout_at:
            values.lateCheckout.toString() === 'true'
              ? values.lateCheckoutAt
              : null,
        };

        const response = await patchCompReservations(data, reservationId);

        reservationUpdated = {
          ...getReservationsListUpdated(reservationUpdated),
          ...response,
        };
      } catch (e) {
        toast.error((e as Error).message || ErrorMessage.default());
        toastErrorRequest(e);
      }

      try {
        const guestsResponses: Guest[] = [];

        const guests = [{ ...guestMain }, ...guestsSecondary];

        await Promise.all(guests.map(async (item) => {
          const guest: GuestReservation = {
            document: replaceAll(
              replaceAll(
                item?.document || '', '.', '',
              ),
              '-',
              '',
            ),
            back_document_photo:
                item?.back_document_photo?.uid || item?.back_document_photo,
            front_document_photo:
                item?.front_document_photo?.uid || item?.front_document_photo,
            email: item?.email || '',
            name: item?.name || '',
            phone_number: item?.phone_number || '',
            is_principal: item.is_principal ? item.is_principal : false,
            reservation: reservationId,
          };

          let guestUpdated: Guest = { ...item };

          if (item.isNewGuest) {
            guestUpdated = await postGuestReservations(guest);
          } else if (item?.id) {
            guestUpdated = await patchGuestReservations(item.id, guest);
          }

          guestsResponses.push({ ...guestUpdated, isNewGuest: false });
        }));

        reservationUpdated = {
          ...getReservationsListUpdated(reservationUpdated),
          guests: guestsResponses,
          bed_arrangement_list: values.bedOrganizationList,
        };

        updateReservationsList(allReservations, [{ ...reservationUpdated }]);

        setGuestMain(() => ({
          ...(reservationUpdated.guests.filter((item) => item.is_principal)?.[0] || guestMain),
          isNewGuest: false,
        }));

        setGuestsSecondary([
          ...reservationUpdated.guests
            .filter((guest: Guest) => !guest.is_principal)
            .map((item: Guest) => ({
              ...item,
              isNewGuest: false,
            })),
        ]);

        toast.success('Reserva atualizada com sucesso!');
      } catch (e) {
        toast.error((e as Error).message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    },
  });

  async function handleSaveAndSendEmail() {
    await formik?.setFieldValue('finishCompReservation', true);
    await formik?.setFieldTouched(
      'finishCompReservation', true, false,
    );
    formik.handleSubmit();
    onClickFinish();
  }

  const guestCapacityIsValid = (): boolean => {
    const maxGuests = Number(formik.values.guestCapacity);
    const adultGuests = Number(formik.values.quantityAdult);
    const totalChild = Number(formik.values.child06GuestQuantity)
      + Number(formik.values.child612GuestQuantity);

    const totalGuests = adultGuests + Number(totalChild);

    if (totalGuests > maxGuests || adultGuests === 0) {
      return false;
    }
    return totalGuests <= maxGuests;
  };

  const activeButton = guestCapacityIsValid();

  useEffect(() => {
    setNeedCradle(formik.values.bedOrganizationList.includes('Cradle'));
  }, [formik.values.bedOrganizationList]);

  return (
    <>
      <Container onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Content>
          <TopContent>
            <Header>
              <Title> do hóspede</Title>
            </Header>

            <CardInfo
              daysLeft={daysLeft}
              idProperty={idProperty}
              checkin={checkin}
              checkout={checkout}
            />
            <GuestQuantity
              text="Quantos adultos?"
              formik={formik}
              idName="quantityAdult"
            />
            <GuestQuantity
              text="Quantas crianças de 0 a 6 anos?"
              formik={formik}
              idName="child06GuestQuantity"
            />
            <GuestQuantity
              text="Quantas crianças de 6 a 12 anos?"
              formik={formik}
              idName="child612GuestQuantity"
            />
            <GuestCapacityText>{`* Escolha no máximo ${formik.values.guestCapacity} hóspedes!`}</GuestCapacityText>
            <CheckinCheckoutAuthorization
              formik={formik}
              id="earlyCheckin"
              idTextField="earlyCheckinAt"
              label="Autorizado checkin antecipado?"
              value={formik.values.earlyCheckinAt}
            />
            <CheckinCheckoutAuthorization
              formik={formik}
              id="lateCheckout"
              idTextField="lateCheckoutAt"
              label="Autorizado checkout postergado?"
              value={formik.values.lateCheckoutAt}
            />
            <ComingPet formik={formik} />
            {listBedArrangement && listBedArrangement?.length >= 1 && (
              <BedOrganization
                formik={formik}
                listBedArrangement={listBedArrangement}
              />
            )}
            <AddGuestContainer>
              <AddGuestButton
                guestsSecondary={guestsSecondary}
                setGuestsSecondary={setGuestsSecondary}
              />
            </AddGuestContainer>
            <Guests
              key={reservation.guest_owner.id}
              index={1}
              isMain
              guestId={reservation.guest_owner.id}
              guestInformation={guestMain}
              setGuestMain={setGuestMain}
              guestsSecondary={guestsSecondary}
              setGuestsSecondary={setGuestsSecondary}
            />

            {guestsSecondary.map((guest, index) => (
              <Guests
                key={guest.id}
                index={index + 2}
                guestId={guest.id}
                guestInformation={guest}
                guestsSecondary={guestsSecondary}
                setGuestsSecondary={setGuestsSecondary}
              />
            ))}
          </TopContent>
        </Content>

        <ButtonsContainer>
          <>
            {!activeButton && (
              <Error>
                *Verifique a quantidade mínima e máxima de hóspedes
                {' '}
                <Tooltip text="Você precisa selecionar ao menos um hóspede principal. O número total de hóspedes não pode ser maior que a capacidade do imóvel.">
                  <Info />
                </Tooltip>
                {' '}
              </Error>
            )}

            <ButtonsContent>
              <FormButton
                disable={!activeButton}
                type="submit"
                variant="outlined"
              >
                Salvar
              </FormButton>
              <FormButton
                disable={!activeButton}
                type="button"
                onClick={handleSaveAndSendEmail}
              >
                Finalizar
              </FormButton>
            </ButtonsContent>
          </>
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default MainContent;
