import {
  ProgressCircle,
  ProgressBar,
  ProgressIconContainer,
  IconContainer,
} from './styles';

interface ProgressIconProps {
  progress: number;
  image: string | undefined;
  haveFailed: boolean;
}

export const ProgressIcon = ({ progress, image, haveFailed }: ProgressIconProps) => {
  function calculateDashOffset() {
    const minValue = 0;
    const maxValue = 320;
    const minProgress = 0;
    const maxProgress = 100;

    const dashOffset = ((progress - minProgress) * (maxValue - minValue))
    / (maxProgress - minProgress);

    return dashOffset;
  }
  const isComplete = progress === 100;
  return (
    <ProgressIconContainer>
      <IconContainer>
        <img src={image} alt="ícone" />
      </IconContainer>
      <ProgressBar>
        <ProgressCircle cx="50" cy="50" r="50" />
        <ProgressCircle haveFailed={haveFailed} isComplete={isComplete} dashOffset={calculateDashOffset()} cx="50" cy="50" r="50" />
      </ProgressBar>
    </ProgressIconContainer>
  );
};

export default ProgressIcon;
