import { Close } from '@mui/icons-material';
import React from 'react';
import { useToast } from '../../../../context/ToastContext';
import { DeleteOwnerInvoice } from '../../../../services/Owner/request';
import { useToastErrorMessage } from '../../../../utils/Messages';
import {
  ButtonCancel,
  ButtonClose,
  ButtonSave,
  Container,
  HeaderModalNewBankAccount,
  RowButtons,
  Wrapper,
} from './styles';

interface Props {
  openModal: boolean;
  setOpenModal: (a: boolean) => void;
  idInvoice: number;
  getListOwnerInvoices: () => void;
}

const ModalConfirmDeleteDataFiscalNote = ({
  openModal,
  setOpenModal,
  idInvoice,
  getListOwnerInvoices,
}: Props) : JSX.Element => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  async function handleDeleteOwnerInvoice(id: number) {
    try {
      await DeleteOwnerInvoice(id);
      toast.success('Dados excluídos com sucesso!');
    } catch (e: unknown) {
      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
    getListOwnerInvoices();
    handleCloseModal();
  }

  function handleWrapperClick(e: React.MouseEvent<HTMLElement>) {
    const classList = (e.target as Element).classList.value.split(' ');
    if (classList.includes('area-close')) {
      handleCloseModal();
    }
  }
  return (
    <Wrapper openModal={openModal} className="area-close" onClick={(e) => handleWrapperClick(e)}>
      <Container>
        <ButtonClose onClick={handleCloseModal}>
          <Close />
          Fechar
        </ButtonClose>
        <HeaderModalNewBankAccount>
          <h1>Excluir esses dados?</h1>
        </HeaderModalNewBankAccount>
        <RowButtons>
          <ButtonCancel onClick={handleCloseModal} type="button">
            Cancelar
          </ButtonCancel>
          <ButtonSave type="button" onClick={() => handleDeleteOwnerInvoice(idInvoice)}>
            Excluir
          </ButtonSave>
        </RowButtons>
      </Container>
    </Wrapper>
  );
};

export default ModalConfirmDeleteDataFiscalNote;
