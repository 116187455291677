import React from 'react';
// import { NoDataAnimation } from '../../../../assets/animations/components/NoDataAnimation';

import { Container } from './styles';

const NoDataFound = () => (
  <Container>
    {/* <NoDataAnimation /> */}
    <h1>Esta integração está em manutenção</h1>
    <h1>
      Clique no botão de
      {' '}
      <span>Exportar Lista</span>
    </h1>
    <h1>para gerar o CSV com as informações</h1>
  </Container>
);

const MemoisedNoData = React.memo(() => (
  <NoDataFound />
));

export default MemoisedNoData;
