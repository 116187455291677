/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import { getPropertiesOwner } from '../../services/Owner/request';

import { useOwnerCardProperties } from '../../hooks/OwnerPage/OwnerCardProperties/useOwnerCardProperties';
import { useViewMode } from '../../context/ViewMode/ViewMode';

import OwnerReport from './OwnerReport';
import GridRevenue from './GridRevenue';
import PropertyList from './PropertyList';
import cotesaDesk from '../../assets/banners/cotesa-seazone-desktop.png';
import cotesaMobile from '../../assets/banners/cotesa-seazone-mobile.png';

import {
  Container,
  ContainerBanner,
} from './styles';
import { useMobile } from '../../hooks/useMobile/useMobile';
import { analyticsEvent } from '../../pages/Partners/utils/analytics';
import { useUser } from '../../context/UserContext';

const OwnersPage: React.FC = () => {
  const { userInformation } = useUser();
  const { isMobile } = useMobile();
  const { mode } = useViewMode();
  const { propertiesList, setPropertiesList } = useOwnerCardProperties();
  const [loadingCardProperties, setLoadingCardProperties] = useState<boolean>(true);

  const getProperties = async () => {
    setLoadingCardProperties(true);

    const validateMode = (mode && mode) || undefined;
    const values = await getPropertiesOwner(undefined, validateMode);
    setPropertiesList(values);
    setLoadingCardProperties(false);
  };

  const handleAddAnalytics = () => {
    analyticsEvent({
      name: 'Cotesa_Promo_Click',
      userInformation,
    });
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <>
      <Container>
        <ContainerBanner>
          <a onClick={handleAddAnalytics} href="https://www.cassollux.com.br/seazone " target="_blank">
            {isMobile ? <img src={cotesaMobile} alt="mobile" /> : <img src={cotesaDesk} alt="desktop" />}
          </a>
        </ContainerBanner>

        <PropertyList
          ownerProperties={propertiesList}
          isLoading={loadingCardProperties}
        />
        {/* <OpportunitiesSeazone /> */}

        {/* <FinancialSummary /> */}

        <GridRevenue propertiesList={propertiesList} isLoading={loadingCardProperties} />

        <OwnerReport propertiesList={propertiesList} />

        {/* <EventSection /> */}

      </Container>
    </>
  );
};

export default OwnersPage;
