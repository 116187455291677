import React, { useEffect } from 'react';

import { ConfirmationAnimation } from '../../../../assets/animations';

import {
  PropertyInformation,
  Values,
} from '../../../../context/OnboardingContext/types';

import { GetOwnersData } from '../../../../services/InsertData/types';

import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';

import { Container } from './styles';

const CardFinish = () => {
  const { handleClickPhase } = useOnboardingPage();
  const {
    setPropertyDetails,
    setValues,
    setOwner,
  } = useOnboardingHandover();

  useEffect(() => {
    setTimeout(() => {
      setOwner({} as GetOwnersData);
      setPropertyDetails({} as PropertyInformation);
      setValues({} as Values);
      handleClickPhase(0);
    }, 3000);
  }, []);

  return (
    <Container>
      <ConfirmationAnimation />
      <h1>Cadastro realizado com sucesso!</h1>
    </Container>
  );
};

export default CardFinish;
