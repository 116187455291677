import { FileProps } from '../../context/FileContext/types';

export type ExpenseParams = {
  property_id?: number;
  host_id?: number;
  register_date_start?: string,
  register_date_end?: string,
  refund_date_start?: string,
  refund_date_end?: string,
  expense_status?: ExpenseStatus,
  approval_date_start?: string,
  approval_date_end?: string,
};

export type ImageProps = {
  category?: string,
  name?: string,
  url?: string,
  size?: number,
  content_type?: string,
};

export type ExpensePropsPagination = {
  count: number,
  next: string | null,
  previous: string | null,
};

export interface ExpensePropsPaginationResults extends ExpensePropsPagination {
  results: ExpenseResponseProps[],
  nextPage: number,
  hasMoreExpenses: boolean,
  current_page: number;
  total_pages: number;
}

export type ExpenseFile = {
  id: number;
  category: 'Maintenance' | 'Statement'
  expense: number
  file: FileProps
};

export type ExpenseResponseProps = {
  id?: number;
  property: {
    id: number,
    code: string,
  }
  property_id?: number;
  register_date?: Date,
  expense_date?: string,
  approval_date?: string,
  cash_date?: string,
  reason?: ExpenseReason,
  description?: string,
  supplier?: string,
  supplier_phonenumber: string,
  supplier_rating?: number,
  value?: number,
  expense_status?: ExpenseStatus | undefined,
  pending_reason?: string,
  refund?: string,
  refund_date_start?: string,
  refund_date_end?: string,
  statement_image?: ImageProps | string | null,
  maintenance_image?: ImageProps | string | null,
  owner_approval?: boolean,
  responsible_user?: {
    id?: number,
    first_name?: string,
    last_name?: string,
    trading_name?: string,
    corporate_name?: string,
  },
  registered_by?: {
    id?: number,
    first_name?: string,
    last_name?: string,
    trading_name?: string,
    corporate_name?: string,
  },
  paid_by?: string,
  host_id?: number,
  maintenance_files?: ExpenseFile[],
  statement_files?: ExpenseFile[],
};

export type ExpenseProps = {
  id?: number;
  host_id?: number,
  property?: number;
  property_id?: number;
  register_date_start?: string,
  register_date_end?: string,
  expense_date?: string,
  reason?: ExpenseReason | any,
  description?: string,
  supplier?: string,
  supplier_phonenumber?: string,
  supplier_rating?: number,
  value?: number,
  expense_status?: ExpenseStatus | any,
  pending_reason?: string,
  refund?: string | null,
  refund_date_start?: string,
  refund_date_end?: string,
  statement_image?: string | null,
  maintenance_image?: string | null,
  owner_approval?: boolean,
  responsible_user?: number,
  registered_by?: number,
  paid_by?: string,
  maintenance_files?: ExpenseFile[],
  statement_files?: ExpenseFile[],
};

export type ExpensePatchProps = ExpenseProps;

export type ExpenseStatus = 'Pre_Approved' | 'Approved' | 'Analyzing' | 'Denied' | 'Canceled' | 'Paid' | 'Pending' | '';

export type ExpenseReason =
'Account_Management_Energy' | 'Account_Management_Water' | 'Account_Management_Condominium' |
'Account_Management_Internet' | 'Account_Management_Garden' | 'Account_Management_Pool' |
'Account_Management_IPTU' | 'Account_Management_TV' | 'Account_Management_Subscription' |
'Maintenance' | 'Puchase_Required_Items' | 'Third_party_services' | 'Buying_Layette' |
'Onboarding_Keys_Cleaning' | 'Onboarding_Laundry' | 'Onboarding_Administrative' | 'Account_Management_Gas' |
'Reccurent_Expense'| 'Reservation_Commission' | 'Guest_Damage' | 'Owner_Cleaning' |
'Onboarding_Commission' | 'Seazone_Charges' | 'Membership_Fee_Madego' | 'Layette_parcel_Madego' |
'Nothing';

export enum ExpenseReasonValue {
  ACCOUNT_MANAGEMENT_ENERGY = 'Account_Management_Energy',
  ACCOUNT_MANAGEMENT_WATER = 'Account_Management_Water',
  ACCOUNT_MANAGEMENT_CONDOMINIUM = 'Account_Management_Condominium',
  ACCOUNT_MANAGEMENT_INTERNET = 'Account_Management_Internet',
  ACCOUNT_MANAGEMENT_GARDEN = 'Account_Management_Garden',
  ACCOUNT_MANAGEMENT_POOL = 'Account_Management_Pool',
  ACCOUNT_MANAGEMENT_IPTU = 'Account_Management_IPTU',
  ACCOUNT_MANAGEMENT_TV = 'Account_Management_TV',
  ACCOUNT_MANAGEMENT_SUBSCRIPTION = 'Account_Management_Subscription',
  ACCOUNT_MANAGEMENT_GAS = 'Account_Management_Gas',
  MAINTENANCE = 'Maintenance',
  PURCHASE_REQUIRED_ITEMS = 'Puchase_Required_Items',
  THIRD_PARTY_SERVICES = 'Third_party_services',
  ONBOARDING_KEYS_CLEANING = 'Onboarding_Keys_Cleaning',
  ONBOARDING_LAUNDRY = 'Onboarding_Laundry',
  ONBOARDING_ADMINISTRATIVE = 'Onboarding_Administrative',
  RECCURENT_EXPENSE = 'Reccurent_Expense',
  BUYING_LAYETTE = 'Buying_Layette',
  LAYETTE_DAMAGE = 'Layette_Damage',
  GUEST_DAMAGE = 'Guest_Damage',
  ONBOARDING_COMMISSION = 'Onboarding_Commission',
  SEAZONE_CHARGES = 'Seazone_Charges',
  // RESERVATION_COMMISSION = 'Reservation_Commission',
  // OWNER_CLEANING = 'Owner_Cleaning',
  OWNER_RESERVATION = 'Owner_Reservation',
  MEMBERSHIP_FEE_MADEGO = 'Membership_Fee_Madego',
  LAYETTE_PARCEL_MADEGO = 'Layette_parcel_Madego',
  PRO_PHOTO_REVIEW = 'Professional_Photo_Review',
}

export enum ExpenseReasonLabel {
  ACCOUNT_MANAGEMENT_ENERGY = 'Gestão de Contas (Luz)',
  ACCOUNT_MANAGEMENT_WATER = 'Gestão de Contas (Água)',
  ACCOUNT_MANAGEMENT_CONDOMINIUM = 'Gestão de Contas (Condomínio)',
  ACCOUNT_MANAGEMENT_INTERNET = 'Gestão de Contas (Internet)',
  ACCOUNT_MANAGEMENT_GARDEN = 'Gestão de Contas  (Jardim)',
  ACCOUNT_MANAGEMENT_POOL = 'Gestão de Contas (Piscina)',
  ACCOUNT_MANAGEMENT_IPTU = 'Gestão de Contas (IPTU)',
  ACCOUNT_MANAGEMENT_TV = 'Gestão de Contas  (TV)',
  ACCOUNT_MANAGEMENT_SUBSCRIPTION = 'Gestão de Contas  (Mensalidade)',
  ACCOUNT_MANAGEMENT_GAS = 'Gestão de contas (Gás)',
  MAINTENANCE = 'Manutenção (reformas, melhorias, etc)',
  PURCHASE_REQUIRED_ITEMS = 'Compra de itens obrigatórios (talheres, copos, etc)',
  THIRD_PARTY_SERVICES = 'Serviços terceirizados (piscineiro, jardineiro...)',
  ONBOARDING_KEYS_CLEANING = 'Implantação (cópia de chaves)',
  ONBOARDING_LAUNDRY = 'Implantação (limpeza e lavagem de goma)',
  ONBOARDING_ADMINISTRATIVE = 'Onboarding (Administrativo)',
  RECCURENT_EXPENSE = 'Despesas Recorrentes (condomínio, etc)',
  BUYING_LAYETTE = 'Compra de enxoval (desgaste)',
  LAYETTE_DAMAGE = 'Danos de enxoval (reposição)',
  GUEST_DAMAGE = 'Danos de hóspede (reposição)',
  NOTHING = 'Não Informado',
  ONBOARDING_COMMISSION = 'Onboarding (Comissão)',
  SEAZONE_CHARGES = 'Despesa Seazone',
  // RESERVATION_COMMISSION = 'Comissão de reserva',
  // OWNER_CLEANING = 'Limpeza Proprietário',
  OWNER_RESERVATION = 'Reserva de Proprietário (comissão e limpeza)',
  MEMBERSHIP_FEE_MADEGO = 'Madego - Taxa de adesão',
  LAYETTE_PARCEL_MADEGO = 'Madego - Parcela de Enxoval',
  PRO_PHOTO_REVIEW = 'Fotos Profissionais (Revisão)',
}

export type ExpenseReportProps = {
  start_date: string;
  end_date: string;
};

export interface PostExpenseFiles {
  expense: number
  files: string[]
  category?: 'Maintenance' | 'Statement'
}

export interface AllInformationExpenseProps {
  statementExpenseFiles: ExpenseFile[]
  maintenanceExpenseFiles: ExpenseFile[]
}

export interface SimpleProperty {
  id: number
  code: string
  status: 'Active' | 'Inactive' | 'Onboarding' | 'Closed' | 'Signed_Contract'
}

export interface SimpleHost {
  id: number
  full_name: string
  is_host_active: boolean
  user_active: string
}
