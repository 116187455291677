import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 6px 16px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background: #E2FFF6;
  }
`;
export const Name = styled.div`
  width: 30%;
  padding-right: 60px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  display: flex;
  align-items: center;

  color: #151B26;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  justify-content: center;



  width: 20%;
  border-left: 2px solid #E7EAF1;

  &.qtd-property {
    width: 10%;
    > p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.email{
    width: 23%;
    > p {
      width: 80%;
    }
  }
  &.address{
    width: 18%;
      > p {
        width: 80%;
      }
  }
  p {
    width: 50%;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  svg {
    color: #909AAC;
    margin-left: 10px;

  }
`;
