import styled, { css } from 'styled-components';

interface Props {
  $fontWeight?: 'normal' | 'bold' | 'regular';
  $fontSize?: string;
  $variant?: string;
  $ellipsis?: boolean;
  $color?: string;
  $nowrap?: boolean;
  $width?: string;
  copyClipboard?: boolean;
  children?: React.ReactNode;
}

const CopyClipboard = styled.div`
  display: none;
`;

const TextStyled = styled.div<Props>`
  font-weight: ${(props: any) => props.$fontWeight};
  font-size: ${(props: any) => props.$fontSize}px;
  color: ${(props: any) => props.$color};
  line-height: 18px;

  ${(props) => props.copyClipboard
    && css`
      cursor: pointer;
    `}

  ${(props) => props.$nowrap
    && css`
      white-space: nowrap;
    `}

  ${(props) => props.$width
    && css`
      width: ${() => props.$width};
    `}

  ${(props) => props.$ellipsis
  && css`
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &:hover {
    ${CopyClipboard} {
      display: block;
    }
  }
`;

TextStyled.defaultProps = {
  $fontWeight: 'normal',
  $fontSize: '12',
  $variant: 'normal',
};

export const Text = (props: any) => {
  const {
    $fontWeight,
    $color,
    $fontSize,
    $variant,
    $ellipsis,
    $nowrap,
    $width,
    copyClipboard,
    className,
    children,
  } = props;
  return (
    <>
      <TextStyled
        $fontWeight={$fontWeight}
        $color={$color}
        $fontSize={$fontSize}
        $variant={$variant}
        $ellipsis={$ellipsis}
        $nowrap={$nowrap}
        $width={$width}
        copyClipboard={copyClipboard}
        className={className}
      >
        {children}
      </TextStyled>
    </>
  );
};
