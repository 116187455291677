import React, { useEffect, useState } from 'react';
import {
  useParams, useLocation,
} from 'react-router-dom';

import { Person, Business, Info } from '@mui/icons-material';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useToast } from '../../context/ToastContext';

import { getPropertyId } from '../../services/Property/request';
import { PropertyDetails } from '../../services/Property/types';

import { formatCEPToShow, numberToCurrency } from '../../utils/Formatter';
import { translatePropertyStatus } from '../../utils/Translator';

import Breadcrumb, { Way } from '../../components/Breadcrump/Breadcrump';
// import RulesProperty from '../../components/RulesProperty/RulesProperty';
import AmenitiesProperty from '../../components/AmenetiesProperty/Amenities';
import CommentProperty from '../../components/CommentProperty';

import UnderConstructionIcon from '../../assets/icons/propertyDetails/underconstruction.svg';
import CopyIcon from '../../assets/icons/generals/copyBlack.svg';

import {
  ADDComment,
  Alignment,
  ButtonChange,
  CardInfo,
  CardsValues,
  WrapperContainer,
  Container,
  ContainerCenter,
  // ContainerColuns,
  ContainerDetails,
  ContainerLocalization, ContainerMoreInfo,
  ContentComment,
  DividerComment,
  HeaderInformation,
  InitialsName,
  MoreComments,
  PropertyBeds,
  Row,
  Status,
  Title,
  TitleContainer,
  CopyButton,
  OtaContainer,
  ConstructionContainer,
  ModalOwner,
  ModalHost,
  ContentRow,
  WrapperModalOwner,
  WrapperModalHost,
  ButtonOwnerInfo,
  ButtonHostInfo,
  CopyContainer,
  ContainerColuns,
} from './styles';

import guestsiIcon from '../../assets/icons/propertySearch/guests.svg';
import cleaningIcon from '../../assets/icons/propertySearch/cleaning.svg';
import bondAmountIcon from '../../assets/icons/propertySearch/bondAmount.svg';
import arrowDown from '../../assets/icons/generals/arrow_down.svg';
import propertyImage from '../../assets/icons/generals/property-not-found.svg';
import airbnbLogo from '../../assets/icons/otas/airbnblogo.png';
import bookingLogo from '../../assets/icons/otas/bookinglogo.png';
import staysLogo from '../../assets/icons/otas/staysLogo.svg';
import decolarLogo from '../../assets/icons/otas/decolarLogo.svg';
import BackButton from '../../components/BackButton';
import Tooltip from '../../components/Tooltip';
import iconWhatsapp from '../../assets/icons/controll/whatsapp.svg';
import { TextField } from '../../components';

import { createComment, getComments } from '../../services/Comments/request';
import { CommentPropertyUser as CommentPropertyType } from '../../services/Comments/types';
import { useToastErrorMessage } from '../../utils/Messages';
import { useUser } from '../../context/UserContext';
import copyIcon from '../../assets/icons/generals/copy.svg';

const PropertyDetailsPage = (): JSX.Element => {
  const { id } = useParams();
  const location = useLocation();
  const [property, setProperty] = useState<PropertyDetails>();
  const [way, setWay] = useState<Array<Way>>();
  const [openModalOwner, setOpenModalOwner] = useState<boolean>(false);
  const [openModalHost, setOpenModalHost] = useState<boolean>(false);
  const [commentField, setCommentField] = useState<boolean>(true);
  const [limitComments, setLimitComments] = useState(2);
  const [listComments, setListComments] = useState<CommentPropertyType[]>([]);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { userInformation } = useUser();

  function handleMoreLess() {
    if (listComments) {
      setLimitComments(limitComments === 2 ? Object.keys(listComments).length : 2);
    }
  }

  const [mobile, setMobile] = useState<boolean>(false);
  const [reloadComment, setReloadComment] = useState<boolean>(false);
  const validation = Yup.object().shape({
    comment: Yup.string().required(),
  });

  const initialsNameSeparator = (name: string): string => {
    const resultadoEspaco = name.split(' ', 2);
    const first = resultadoEspaco[0].substring(0, 1);
    const second = resultadoEspaco[1].substring(0, 1);
    const abrev: string = (first + second);
    return abrev;
  };

  useEffect(() => {
    document.title = 'Sapron | Detalhes do Imóvel';
  }, []);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      if (property?.id) {
        try {
          await createComment({ property: property?.id, comment: values.comment });
          toast.success('Comentário salvo com sucesso!');
          resetForm();
          setReloadComment(true);
          setCommentField(false);
          setCommentField(true);
        } catch (error) {
          toastErrorRequest(error);
        }
      }
    },
  });

  function useWindowSize() {
    const { innerWidth: width } = window;
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', useWindowSize);
  }, [mobile]);

  useEffect(() => {
    useWindowSize();
  }, []);

  useEffect(() => {
    async function getListComment() {
      if (property?.id) {
        const list = await getComments(property?.id);
        setListComments(list);
        setReloadComment(false);
        setLimitComments(Object.keys(list).length);
      }
    }
    getListComment();
  }, [property, reloadComment]);

  const getProperty = async () => {
    if (id) {
      const values: PropertyDetails = await getPropertyId(id);
      setProperty(values);
      const waysAux = [{
        title: values?.code || '',
        path: '#',
      }];
      if (location.state) {
        waysAux.unshift(location.state);
      } else {
        waysAux.unshift({ title: 'Imóveis', path: '/buscadepropriedades' });
      }

      setWay(waysAux);
    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  const [positionModal, setPositionModal] = useState({
    left: 100,
    top: 200,
    side: 'left',
    vertical: 'top',
    width: 1000,
    height: 1000,
  });

  const handleClickOwner = (event: any) => {
    const { innerWidth: width, innerHeight: height } = window;

    setPositionModal({
      left: event?.clientX,
      top: event?.clientY,
      side: width / 2 >= event?.clientX ? 'left' : 'right',
      vertical: height / 2 >= event?.clientY ? 'top' : 'bottom',
      width,
      height,
    });

    setOpenModalOwner(true);
  };

  const handleClickHost = (event: any) => {
    const { innerWidth: width, innerHeight: height } = window;

    setPositionModal({
      left: event?.clientX,
      top: event?.clientY,
      side: width / 2 >= event?.clientX ? 'left' : 'right',
      vertical: height / 2 >= event?.clientY ? 'top' : 'bottom',
      width,
      height,
    });

    setOpenModalHost(true);
  };

  const Copy = () => (
    <img src={copyIcon} alt="icone de copiar" className="copyIcon" />
  );

  const copyToClipboard = (text: string | EventTarget | any) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  const phoneNumberWhats = (phone: string | undefined) => phone?.replace('+055', '55')
    .replace(/\s+/g, '')
    .replace('(', '')
    .replace(')', '');

  return (
    <>
      <WrapperModalOwner openModalOwner={openModalOwner} onClick={() => setOpenModalOwner(false)}>
        <ModalOwner openModalOwner={openModalOwner} positionModal={positionModal}>
          <ContentRow>
            {property?.owners?.[0].user?.trading_name ? <Business /> : <Person />}
            <p>
              {property?.owners?.[0].user?.trading_name
                ? property?.owners?.[0].user?.trading_name : `${property?.owners?.[0].user?.first_name} ${property?.owners?.[0].user?.last_name}`}

            </p>
          </ContentRow>
          <ContentRow className="phoneNumber" onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phoneNumberWhats(property?.owners?.[0].user?.phone_number1)}`, '_blank')}>
            <img src={iconWhatsapp} alt="ìcone whatsapp" />
            <p>{property?.owners?.[0].user?.phone_number1}</p>
          </ContentRow>
          {property?.owners?.[0].user?.phone_number2 && (
          <ContentRow className="phoneNumber">
            <p>{property?.owners?.[0].user?.phone_number2}</p>
          </ContentRow>
          )}
        </ModalOwner>
      </WrapperModalOwner>
      <WrapperModalHost openModalHost={openModalHost} onClick={() => setOpenModalHost(false)}>
        <ModalHost openModalHost={openModalHost} positionModal={positionModal}>
          <ContentRow>
            {property?.host?.trading_name ? <Business /> : <Person />}
            <p>
              {property?.host?.trading_name
                ? property?.host?.trading_name : `${property?.host?.first_name} ${property?.host?.last_name}`}

            </p>
          </ContentRow>
          <ContentRow className="phoneNumber" onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phoneNumberWhats(property?.host?.phone_number_1)}`, '_blank')}>
            <img src={iconWhatsapp} alt="ìcone whatsapp" />
            <p>{property?.host?.phone_number_1}</p>
          </ContentRow>
          {property?.host?.phone_number_2 && (
          <ContentRow className="phoneNumber">
            <p>{property?.host?.phone_number_2}</p>
          </ContentRow>
          )}
        </ModalHost>
      </WrapperModalHost>
      <WrapperContainer>
        <Container>
          <Breadcrumb ways={way} />
          <BackButton haveBackgroudColor css={{ marginBottom: '1rem' }} />
          {property && (
          <ContainerCenter>
            <ContainerLocalization>
              <img src={propertyImage} alt={property.code} />
              <TitleContainer>
                <Title>Localização</Title>
                <Tooltip text="Copiar endereço">
                  <CopyButton
                    onClick={() => navigator.clipboard.writeText(`
                    ${property.address?.street} ${property.address?.number} ${property.address?.complement},
                    ${property.address?.neighborhood}, ${property.address?.city} - ${property.address?.state} CEP
                    - ${property.address?.postal_code && formatCEPToShow(property.address?.postal_code)}
                  `)}
                  >
                    <img src={CopyIcon} alt="copy icon" />
                  </CopyButton>
                </Tooltip>
              </TitleContainer>
              <p>
                {property.address?.street}
                {' '}
                {property.address?.number}
                {' '}
                {property.address?.complement}
                ,
                {' '}
                {property.address?.neighborhood}
                ,
                {' '}
                {property.address?.city}
                {' '}
                -
                {' '}
                {property.address?.state}
                {' '}
                .
                CEP
                {' '}
                {property.address?.postal_code && formatCEPToShow(property.address?.postal_code)}
              </p>
              <a
                href={`https://www.google.com/maps/place/${property.address?.street}+${property.address?.number}+${property.address?.neighborhood}+${property.address?.city}+${property.address?.state}`}
                className="style-see-localization"
                target="_blank"
              >
                Ver localização
              </a>
              <Title className="otaTitle">Links</Title>
              <OtaContainer>
                {property && property.ota_links && property.ota_links.map((otaLink) => {
                  if (otaLink.ota === 'AIR' && otaLink.link !== null) {
                    return (
                      <div>
                        <a href={otaLink.link} target="_blank">
                          <img src={airbnbLogo} alt="Airbnb logo" />
                        </a>
                        <Tooltip text="Copiar link Airbnb">
                          <CopyContainer onClick={() => copyToClipboard(otaLink.link || 'Número não informado')}>
                            <Copy />
                          </CopyContainer>
                        </Tooltip>
                      </div>
                    );
                  }
                  if (otaLink.ota === 'BOO' && otaLink.link !== null) {
                    return (
                      <div>
                        <a href={otaLink.link} target="_blank">
                          <img src={bookingLogo} alt="Booking logo" />
                        </a>
                        <Tooltip text="Copiar link Booking">
                          <CopyContainer onClick={() => copyToClipboard(otaLink.link || 'Número não informado')}>
                            <Copy />
                          </CopyContainer>
                        </Tooltip>
                      </div>
                    );
                  }
                  if (otaLink.ota === 'STA' && otaLink.link !== null) {
                    return (
                      <div>
                        <a href={otaLink.link} target="_blank">
                          <img src={staysLogo} alt="Stays logo" />
                        </a>
                        <Tooltip text="Copiar link stays">
                          <CopyContainer onClick={() => copyToClipboard(otaLink.link || 'Número não informado')}>
                            <Copy />
                          </CopyContainer>
                        </Tooltip>
                      </div>
                    );
                  }
                  if (otaLink.ota === 'DEC' && otaLink.link !== null) {
                    return (
                      <div>
                        <a href={otaLink.link} target="_blank">
                          <img src={decolarLogo} alt="Decolar logo" />
                        </a>
                        <Tooltip text="Copiar link Decolar">
                          <CopyContainer onClick={() => copyToClipboard(otaLink.link || 'Número não informado')}>
                            <Copy />
                          </CopyContainer>
                        </Tooltip>
                      </div>
                    );
                  }
                  return null;
                })}
              </OtaContainer>
            </ContainerLocalization>
            <ContainerDetails>
              <HeaderInformation>
                <Title>
                  {property.code}
                </Title>
                <ButtonChange
                  href={`https://app.pipefy.com/public/form/3aELjYRf?motivo_padronizado_1=${property && property.code}&nome_do_solicitante_1=${userInformation?.trading_name
                    ? userInformation.trading_name : `${userInformation?.first_name}  ${userInformation?.last_name}`}`}
                  target="_blank"
                >
                  Solicitar alteração no anúncio
                </ButtonChange>
              </HeaderInformation>
              <ButtonOwnerInfo onClick={handleClickOwner}>
                <p className="owner">
                  Proprietário:
                  {' '}
                  {`${property.owners?.[0].user?.first_name}  ${property.owners?.[0].user?.last_name}`}
                </p>
                <Info fontSize="small" />
              </ButtonOwnerInfo>
              <ButtonHostInfo onClick={handleClickHost}>
                <p className="host">
                  Anfitrião:
                  {' '}
                  {`${property.host?.first_name} ${property.host?.last_name}`}
                </p>
                <Info fontSize="small" />
              </ButtonHostInfo>
              <span className="location">
                {property.address?.neighborhood}
                ,
                {' '}
                {property.address?.city}
              </span>
              <Row>
                <p>
                  {property.address?.street}
                  ,
                  {' '}
                  {property.address?.neighborhood}
                  ,
                  {' '}
                  {property.address?.city}
                  {' '}
                  {/* {property.lavatory_quantity}
                {' '}
                {property.lavatory_quantity
                  && property.lavatory_quantity === 1 ? 'lavatório' : 'lavatórios'}
                .
                {' '} */}
                  {property.bathroom_quantity}
                  {' '}
                  {property.address?.postal_code}
                </p>

                <Status>{translatePropertyStatus(property.status || '')}</Status>
              </Row>
              <CardsValues>
                <CardInfo>
                  <span>Máx. de hóspedes</span>
                  <Alignment>
                    <img src={guestsiIcon} alt="ícone de hópedes" />
                    <p>{property.guest_capacity}</p>
                  </Alignment>
                </CardInfo>
                <Tooltip text="Valor bruto na plataforma">
                  <CardInfo>
                    <span>Taxa de limpeza</span>
                    <Alignment>
                      <img src={cleaningIcon} alt="ícone de limpeza" />
                      <p>{numberToCurrency(property.cleaning_fee)}</p>
                    </Alignment>
                  </CardInfo>
                </Tooltip>
                <CardInfo>
                  <span>Caução</span>
                  <Alignment>
                    <img src={bondAmountIcon} alt="Ícone de caução" />
                    <p>{numberToCurrency(property.bond_amount)}</p>
                  </Alignment>
                </CardInfo>
              </CardsValues>
              <Title>Camas</Title>
              <PropertyBeds>
                {property.double_bed_quantity !== 0 && (
                <li>
                  {property.double_bed_quantity}
                  {' '}
                  {property.double_bed_quantity && property.double_bed_quantity > 1 ? 'Camas de casal' : 'Cama de casal'}
                </li>
                )}
                {property.single_bed_quantity !== 0 && (
                <li>
                  {property.single_bed_quantity}
                  {' '}
                  {property.single_bed_quantity && property.single_bed_quantity > 1 ? 'Camas de solteiro' : 'Cama de solteiro'}
                </li>
                )}
                {property.king_bed_quantity !== 0 && (
                <li>
                  {property.king_bed_quantity}
                  {' '}
                  {property.king_bed_quantity && property.king_bed_quantity > 1 ? 'Camas king' : 'Cama king'}
                </li>
                )}
                {property.queen_bed_quantity !== 0 && (
                <li>
                  {property.queen_bed_quantity}
                  {' '}
                  {property.queen_bed_quantity && property.queen_bed_quantity > 1 ? 'Camas queen' : 'Cama queen'}
                </li>
                )}
                {property.single_sofa_bed_quantity !== 0 && (
                <li>
                  {property.single_sofa_bed_quantity}
                  {' '}
                  {property.single_sofa_bed_quantity && property.single_sofa_bed_quantity > 1 ? 'Sofás-cama individual' : 'Sofá-cama individual'}
                </li>
                )}
                {property.double_sofa_bed_quantity !== 0 && (
                <li>
                  {property.double_sofa_bed_quantity}
                  {' '}
                  {property.double_sofa_bed_quantity && property.double_sofa_bed_quantity > 1 ? 'Sofás-cama duplo' : 'Sofá-cama duplo'}
                </li>
                )}
              </PropertyBeds>
              <ContainerMoreInfo>
                <Title>Regras</Title>
                <ConstructionContainer>
                  <img src={UnderConstructionIcon} alt="Em construção" />
                  <h3>Em Construção</h3>
                </ConstructionContainer>
                {/* <ContainerColuns>
                  {property.id && (
                  <RulesProperty propertyId={property.id} />
                  )}
                </ContainerColuns> */}
              </ContainerMoreInfo>
              <ContainerMoreInfo>
                <Title>Comodidades</Title>
                <ContainerColuns>
                  {property.id && (
                  <AmenitiesProperty propertyId={property.id} />
                  )}
                </ContainerColuns>
              </ContainerMoreInfo>
            </ContainerDetails>
          </ContainerCenter>
          )}
          <ContentComment>
            <h1>Comentários</h1>
            <DividerComment />
            {listComments.map((option, i) => {
              if (i < limitComments) {
                return (
                  <CommentProperty
                    key={option.id}
                    nameAuthor={option.user.name || ''}
                    comment={option.comment || ''}
                    date={option.created_at || ''}
                    idComment={option.id || 0}
                    property={option.property}
                    setReloadComment={setReloadComment}
                  />
                );
              }
              return null;
            })}
            <MoreComments
              onClick={() => handleMoreLess()}
            >
              {limitComments === 2 ? 'Mostrar mais comentários' : 'Mostrar menos comentários'}
              <img
                className={limitComments === 2 ? 'more' : 'less'}
                src={arrowDown}
                alt="Seta para baixo"
              />
            </MoreComments>
            <ADDComment>
              <InitialsName className="user">{initialsNameSeparator(`${userInformation?.first_name} ${userInformation?.last_name}` || '')}</InitialsName>
              <form onSubmit={formik.handleSubmit}>
                {commentField && (
                <TextField
                  id="comment"
                  placeholder="Adicionar comentário"
                  formik={formik}
                  activeButton
                />
                )}
              </form>
            </ADDComment>
          </ContentComment>
        </Container>
      </WrapperContainer>
    </>
  );
};
export default PropertyDetailsPage;
