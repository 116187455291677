import { useEffect, useState } from 'react';

import incompleteSeazoneStepLogo from '../../../assets/icons/ownerPage/incompleteSeazoneStep.svg';
import completeSeazoneStepLogo from '../../../assets/icons/ownerPage/completeSeazoneStep.svg';
import failedSeazoneStepLogo from '../../../assets/icons/ownerPage/failedSeazoneStep.svg';

import { ControllerProperPayStatus, ImportControllerProps } from '../../../services/FinancialClose/types';
import { getControllerProperPayStatus, putTriggerUpdateFinancialCloseTable, putTriggerUpdateFinancialCloseTables } from '../../../services/FinancialClose/request';
import { useToast } from '../../../context/ToastContext';

import { ProgressIcon } from './ProgressIcon';
import { FinancialUpdateTable } from './FinancialUpdateTable';
import FormButton from '../../FormButton';

import {
  Divider,
  OpenFinancialUpdateModalBackdrop,
  OpenFinancialUpdateModalButtonsContainer,
  OpenFinancialUpdateModalCard,
  OpenFinancialUpdateModalCloseButton,
  OpenFinancialUpdateModalCloseIcon,
  OpenFinancialUpdateModalContentContainer,
  OpenFinancialUpdateModalHeader,
  OpenFinancialUpdateModalHeaderTitle,
  OpenFinancialUpdateModalStatusContainer,
  OpenFinancialUpdateModalText,
  OpenFinancialUpdateModalTextContainer,
} from './styles';

interface OpenFinancialUpdateModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const OpenFinancialUpdateModal = ({
  isModalOpen,
  handleCloseModal,
}: OpenFinancialUpdateModalProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [properPayStatusController,
    setProperPayStatusController] = useState<ControllerProperPayStatus>({
  } as ControllerProperPayStatus);

  async function handleGetProperPayControllerStatus() {
    try {
      setIsLoading(true);
      const response = await getControllerProperPayStatus();
      setProperPayStatusController(response);
      setIsLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        toast.alert('Falha ao recuperar os dados de atualização!');
      }
      setIsLoading(false);
    }
  }

  function handleProgress() {
    const total = Object.values(properPayStatusController).reduce((acc, array) => {
      acc.total += array.length;
      acc.completed += array.filter((item) => item.status === 'Completed').length;
      return acc;
    }, { completed: 0, total: 0 });
    const percent = (total.completed / total.total) * 100 || 0;
    return percent;
  }

  function handleHaveFailed() {
    return Object.values(properPayStatusController).some((array) => array.some((item) => item.status === 'Failed'));
  }

  const progress = handleProgress();
  const haveFailed = handleHaveFailed();

  function handleImage() {
    if (haveFailed) {
      return failedSeazoneStepLogo;
    }
    if (progress === 100) {
      return completeSeazoneStepLogo;
    }
    return incompleteSeazoneStepLogo;
  }
  const image = handleImage();

  function handleText() {
    if (haveFailed) {
      return 'Tivemos problema em importar alguma tabela, clique no botão abaixo para importar novamente as que falharam';
    }
    if (progress === 100) {
      return 'Último processo de importação concluído, para iniciar uma nova importação clique no botão Iniciar importação';
    }
    return 'Processo em andamento! Aguarde enquanto as tabelas estão sendo atualizadas';
  }
  const text = handleText();

  async function handleTriggerUpdateTables() {
    try {
      setIsImporting(true);
      await putTriggerUpdateFinancialCloseTables();
      handleCloseModal();
      toast.success('Processo iniciado com sucesso, em alguns minutos os dados estará atualizado');
      setIsImporting(false);
    } catch (e) {
      setIsImporting(false);
      if (e instanceof Error) {
        toast.alert('Falha ao iniciar o processo.');
      }
    }
  }

  async function handleFailedUpdateTables() {
    try {
      setIsImporting(true);
      const failedTables: ImportControllerProps[] = Object.values(properPayStatusController)
        .reduce((failed, array) => {
          const objectsFailed = array.filter((item) => item.status === 'Failed');
          return failed.concat(objectsFailed);
        }, []);

      const tableNames = failedTables.map((item) => `${item.table_name}`);

      if (tableNames.length > 0) {
        await putTriggerUpdateFinancialCloseTable(tableNames);
      }

      setIsImporting(false);
      handleCloseModal();
    } catch (e) {
      setIsImporting(false);
      if (e instanceof Error) {
        toast.alert('Falha ao iniciar o processo.');
      }
    }
  }

  useEffect(() => {
    handleGetProperPayControllerStatus();
  }, []);

  return (
    <OpenFinancialUpdateModalBackdrop
      isModalOpen={isModalOpen}
      onClick={handleCloseModal}
    >
      <OpenFinancialUpdateModalCard
        onClick={(e) => e.stopPropagation()}
      >
        <OpenFinancialUpdateModalHeader>
          <OpenFinancialUpdateModalHeaderTitle>
            Atualização de dados do financeiro
          </OpenFinancialUpdateModalHeaderTitle>
          <OpenFinancialUpdateModalCloseButton
            whileTap={{ scale: 0.8 }}
            onClick={handleCloseModal}
          >
            <OpenFinancialUpdateModalCloseIcon fontSize="large" />
          </OpenFinancialUpdateModalCloseButton>
        </OpenFinancialUpdateModalHeader>
        <Divider />
        <OpenFinancialUpdateModalContentContainer>
          <OpenFinancialUpdateModalStatusContainer>
            <ProgressIcon
              image={image}
              progress={progress}
              haveFailed={haveFailed}
            />
          </OpenFinancialUpdateModalStatusContainer>
          <OpenFinancialUpdateModalTextContainer>
            <OpenFinancialUpdateModalText>{text}</OpenFinancialUpdateModalText>
          </OpenFinancialUpdateModalTextContainer>
          <FinancialUpdateTable
            isLoading={isLoading}
            properPayStatusController={properPayStatusController}
          />
        </OpenFinancialUpdateModalContentContainer>
        <OpenFinancialUpdateModalButtonsContainer>
          {(progress === 100 && !haveFailed) && (
            <FormButton
              type="button"
              onClick={handleTriggerUpdateTables}
              disable={isImporting}
            >
              Iniciar importação
            </FormButton>
          )}
          {haveFailed && (
            <FormButton
              type="button"
              onClick={handleFailedUpdateTables}
              disable={isImporting}
            >
              Refazer importação falhas
            </FormButton>
          )}
        </OpenFinancialUpdateModalButtonsContainer>
      </OpenFinancialUpdateModalCard>
    </OpenFinancialUpdateModalBackdrop>
  );
};

export default OpenFinancialUpdateModal;
